import { AxiosResponse, AxiosError } from "axios";
import { useQuery } from "react-query";
import { request } from "@utils/axios-util";

export const useCreateRoom = (gameId: string | undefined) => {
  const queryFn = () =>
    request({
      url: `/sdgs/multi/${gameId}/rooms/create`,
      method: "get",
    });

  return useQuery(["multi-game-create-room", gameId], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error);
    },
    select: (res: AxiosResponse) => {
      return res.data;
    },
    enabled: false,
  });
};

export const useGetRoomPlayers = (
  gameId: string | undefined,
  roomId: string | undefined
) => {
  // console.log("[useGetRoomPlayers] roomId:", roomId)

  const queryFn = () =>
    request({
      url: `/sdgs/multi/${gameId}/rooms/${roomId}/players`,
      method: "get",
    });

  return useQuery(["multi-game-get-room-players", gameId, roomId], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error);
    },
    select: (res: AxiosResponse) => {
      return res.data;
    },
    cacheTime: 0, // 立即移除快取
    staleTime: 0, // 保證每次查詢都是新鮮資料
    enabled: !!roomId,
  });
};

export const useStartGame = (
  gameId: string | undefined,
  roomId: string | undefined
) => {
  const queryFn = () =>
    request({
      url: `/sdgs/multi/${gameId}/rooms/${roomId}/start`,
      method: "get",
    });

  return useQuery(["multi-game-start"], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error);
    },
    select: (res: AxiosResponse) => {
      return res.data;
    },
    cacheTime: 0, // 立即移除快取
    staleTime: 0, // 保證每次查詢都是新鮮資料
    enabled: false,
  });
};

export const useLeaveRoom = (
  gameId: string | undefined,
  roomId: string | undefined,
  in_game: boolean = false
) => {
  const queryFn = () => {
    return request({
      url: `/sdgs/multi/${gameId}/rooms/${roomId}/leave`,
      method: "get",
      params: { in_game },
    });
  };

  return useQuery(["multi-game-leave-room", gameId, roomId, in_game], queryFn, {
    onError: (error: AxiosError) => {
      console.log(error);
    },
    select: (res: AxiosResponse) => {
      return res.data;
    },
    cacheTime: 0, // 立即移除快取
    staleTime: 0, // 保證每次查詢都是新鮮資料
    enabled: false,
  });
};
