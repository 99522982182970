import React, { useState } from "react";
import { ToggleLeft, ToggleRight } from "lucide-react";

interface ToggleSectionProps {
  label: string;
  children: React.ReactNode;
  defaultEnabled?: boolean;
  helpText?: string;
}

const ToggleSection: React.FC<ToggleSectionProps> = ({
  label,
  children,
  defaultEnabled = false,
  helpText,
}) => {
  const [isEnabled, setIsEnabled] = useState(defaultEnabled);

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div>
          <h3 className="text-base font-medium text-gray-900">{label}</h3>
          {helpText && <p className="text-sm text-gray-500">{helpText}</p>}
        </div>
        <button
          onClick={() => setIsEnabled(!isEnabled)}
          className="focus:outline-none"
          aria-label="啟用設定"
        >
          {isEnabled ? (
            <ToggleRight className="w-8 h-8 text-blue-600" />
          ) : (
            <ToggleLeft className="w-8 h-8 text-gray-400" />
          )}
        </button>
      </div>
      {isEnabled && children}
    </div>
  );
};

export default ToggleSection;
