import React, { useRef, useEffect } from "react";
import { WriteScoreDimension } from "sdgs_types";

interface WriteScoreDisplayProps {
  writeScoreDimension: WriteScoreDimension;
}

const WriteScoreDisplay: React.FC<WriteScoreDisplayProps> = ({
  writeScoreDimension,
}) => {
  const cardRef = useRef<HTMLUListElement>(null);

  useEffect(() => {
    const throttle = (func: any, limit: number) => {
      let inThrottle: boolean;
      return function (this: any) {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => (inThrottle = false), limit);
        }
      };
    };

    const handleMouseMove = (e: MouseEvent) => {
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const x = e.clientX;
      const y = e.clientY;

      const rotateX = (centerY - y) * (20 / centerY);
      const rotateY = (x - centerX) * (20 / centerX);

      if (cardRef.current) {
        cardRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
      }
    };

    document.body.addEventListener("mousemove", throttle(handleMouseMove, 10));

    return () => {
      document.body.removeEventListener(
        "mousemove",
        throttle(handleMouseMove, 10)
      );
    };
  }, []);

  return (
    <div className="text-holder">
      <div className="scene">
        <ul className="writing-score" ref={cardRef} data-floatingcard>
          <li>
            <span className="field">Content</span>
            <div className="score">
              <strong>{writeScoreDimension.content}</strong>
              <span>/ 5</span>
            </div>
          </li>
          <li>
            <span className="field">Organization</span>
            <div className="score">
              <strong>{writeScoreDimension.organization}</strong>
              <span>/ 5</span>
            </div>
          </li>
          <li>
            <span className="field">Grammar and Syntax</span>
            <div className="score">
              <strong>{writeScoreDimension.grammarAndSyntax}</strong>
              <span>/ 5</span>
            </div>
          </li>
          <li>
            <span className="field">Vocabulary and Spelling</span>
            <div className="score">
              <strong>{writeScoreDimension.vocabularyAndSpelling}</strong>
              <span>/ 5</span>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default WriteScoreDisplay;
