import { useState, useEffect, useRef, useCallback } from "react"

interface UseCountdownOptions {
  initialTime: number
  onComplete?: (() => void | undefined) | undefined
}

const useCountdown = ({ initialTime, onComplete }: UseCountdownOptions) => {
  const [countdown, setCountdown] = useState<number | null>(null)
  const intervalRef = useRef<number | null>(null)
  const [completed, setCompleted] = useState(false)

  const startCountdown = useCallback(() => {
    setCountdown(initialTime)
    console.log("startCountdown開始倒數計時")
    intervalRef.current = window.setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown === null) return null
        if (prevCountdown <= 1) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current)
          }
          setCompleted(true)
          return null
        }
        return prevCountdown - 1
      })
    }, 1000)
  }, [initialTime])

  const stopCountdown = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }
    setCountdown(null)
  }, [])

  useEffect(() => {
    if (completed && onComplete) {
      stopCountdown()
      onComplete()
    }
  }, [completed, onComplete, stopCountdown])

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current)
      }
    }
  }, [])

  return {
    countdown,
    startCountdown,
    stopCountdown,
  }
}

export default useCountdown
