import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { request } from "@utils/axios-util"
import { StageProps } from "@utils/types"
import { useUserSettings } from "@core/context/UserSettingsContext"

export default function useStage() {
  const { settings } = useUserSettings()
  const difficulty = settings.difficulty

  const queryFn = () =>
    request({
      url: `/game/menu`,
      method: "get",
      params: {
        difficulty,
      },
    })

  return useQuery(["fetch-game-menu-api", difficulty], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error)
    },
    select: (res: AxiosResponse): StageProps[] => {
      return res.data
    },
  })
}
