import { ElsaAPIResponse } from "./types";

interface ElsaSentenceDisplayProps {
  response: ElsaAPIResponse | null;
  originalText: string;
}

const ElsaSentenceDisplay: React.FC<ElsaSentenceDisplayProps> = ({
  response,
  originalText,
}) => {
  if (!response) {
    return <div>{originalText}</div>;
  }

  if (!response.success || response.utterance.length === 0) {
    return <div>No valid data to display</div>;
  }

  const { sentence, words } = response.utterance[0];

  const renderWord = (
    word: ElsaAPIResponse["utterance"][0]["words"][0],
    text: string
  ) => {
    if (word.decision === "correct") {
      return (
        <span key={word.end_index} className="green">
          {word.text_orig}
          {text[word.end_index + 1] || ""}
        </span>
      );
    }

    return word.phonemes.map((phoneme, index) => {
      const isLastPhoneme = index === word.phonemes.length - 1;
      const phonemeText = text.substring(
        phoneme.start_index,
        phoneme.end_index + 1
      );
      const endSpace = isLastPhoneme ? text[word.end_index + 1] || "" : "";

      return (
        <span
          key={`${word.end_index}-${phoneme.end_index}`}
          className={phoneme.decision === "correct" ? "green" : "red"}
        >
          {phonemeText}
          {endSpace}
        </span>
      );
    });
  };

  return <strong>{words.map((word) => renderWord(word, sentence))}</strong>;
};

export default ElsaSentenceDisplay;
