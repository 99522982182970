import React from "react";
import TextInputField from "../common/TextInputField";
import TextAreaField from "../common/TextAreaField";
import { levelDataProbs } from "@core/sdgs_types";

interface BasicSettingsTabProps {
  level: levelDataProbs;
  onLevelChange: (level: Partial<levelDataProbs>) => void;
}

export const BasicSettingsTab: React.FC<BasicSettingsTabProps> = ({
  level,
  onLevelChange,
}) => {
  return (
    <div className="basic-settings">
      <TextInputField
        label="關卡名稱"
        placeholder=""
        isRequired={true}
        helpText="簡短切題的名字，幫助玩家了解遊戲內容"
        value={level.title || ""}
        onChange={(value) => onLevelChange({ title: value })}
      />
      <TextInputField
        label="副標題"
        placeholder=""
        isRequired={true}
        helpText="吸睛的名字，要能夠吸引玩家進入遊戲"
        value={level.subtitle || ""}
        onChange={(value) => onLevelChange({ subtitle: value })}
      />
      <TextAreaField
        label="關卡說明"
        placeholder=""
        isRequired={true}
        helpText="給玩家閱讀的關卡說明，可以是遊戲目標，遊戲規則等等"
        value={level.description || ""}
        onChange={(value) => onLevelChange({ description: value })}
      />
      <TextAreaField
        label="劇情敘述"
        placeholder=""
        isRequired={false}
        helpText="遊戲劇情敘述，用於引導劇情，生成關卡劇情與題目內容"
        value={level.story || ""}
        onChange={(value) => onLevelChange({ story: value })}
      />
    </div>
  );
};
