interface SelfChatItemProps {
  avatarUrl: string;
  avatarName: string;
  message: string;
  isShow?: boolean;
}

const SelfChatItem: React.FC<SelfChatItemProps> = ({
  avatarUrl,
  avatarName,
  message,
  isShow = true,
}) => {
  return (
    <div className={`chat-item self ${isShow ? "--active" : ""}`}>
      <div className="chat-item-container">
        <div className="avatar">
          <div
            className="img"
            style={{ backgroundImage: `url('${avatarUrl}')` }}
          ></div>
          <div className="name">{avatarName}</div>
        </div>
        <div className="content">
          <p>{message}</p>
          <div className="chat-fn-bar"></div>
        </div>
      </div>
    </div>
  );
};

export default SelfChatItem;
