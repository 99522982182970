import { useEffect, useRef } from "react";
import {
  GamePerformanceData,
  GameRewardsResult,
  highlightToSkillClass,
} from "@core/sdgs_types";
import useAddScore from "@hooks/useAddScore";

interface TalkGameScoreBoardProps {
  gamePerformanceData: GamePerformanceData;
  gameRewardsResult: GameRewardsResult;
}

const TalkGameScoreBoard: React.FC<TalkGameScoreBoardProps> = ({
  gamePerformanceData,
  gameRewardsResult,
}) => {
  const { highlights, totalExp } = gameRewardsResult;

  const { mutate: mutateScore } = useAddScore();
  const isScoreSended = useRef<boolean>(false);
  useEffect(() => {
    if (gameRewardsResult && !isScoreSended.current && totalExp > 0) {
      console.log("上傳totalExp到排行榜", totalExp);
      mutateScore({
        int_score: totalExp,
      });
      isScoreSended.current = true;
    }
  }, [gameRewardsResult]);
  return (
    <div className="result-item">
      <div className="ri-top">
        <span>分數</span>
        <strong>{gamePerformanceData.score}</strong>
        <span>分</span>
      </div>
      <div className="ri-middle">
        {highlights.length > 0 &&
          highlights.map((highlight, index) => (
            <i key={index} className={highlightToSkillClass(highlight)}></i>
          ))}
      </div>
      <div className="ri-bottom">
        <strong>+{totalExp}</strong>
        <span>EXP</span>
      </div>
    </div>
  );
};

export default TalkGameScoreBoard;
