import { Outlet } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Settings from "./Settings";
import { useProfile } from "@core/context/UserSettingsContext";
import ReactGA from "react-ga4";
export default function ImSettingsFrame() {
  const [searchParams, setSearchParams] = useSearchParams();
  useProfile();

  const toggleSettings = () => {
    const openSetting = searchParams.get("settings") === "open";
    ReactGA.event({
      category: "設定頁",
      action: openSetting ? "開啟設定頁" : "關閉設定頁",
    });
    if (openSetting) setSearchParams({}, { replace: true });
    else setSearchParams({ settings: "open" }, { replace: true });
  };

  return (
    <>
      <div className="game-fn-area" onClick={toggleSettings}>
        <div className={`nav-toggle `}>
          <div className="hamburger">
            <i></i>
            <i></i>
            <i></i>
            <span>MENU</span>
          </div>
        </div>
      </div>

      <Settings />
      <Outlet />
    </>
  );
}
