import { useState, useEffect, useRef } from "react"
import { useSearchParams } from "react-router-dom"
import { useSound } from "@core/context/SoundContext"
import ReactGA from "react-ga4"

type StoryModeProps = {
  storyLines: StoryLineProps[]
  onComplete?: () => void
}
type StoryLineProps = {
  content: string
  options: {
    content: string
    onClick?: () => void
  }[]
}

export default function StoryMode({ storyLines, onComplete }: StoryModeProps) {
  const { playSound } = useSound()
  const [, setSearchParams] = useSearchParams()
  const [step, setStep] = useState(0)
  const [displayedContent, setDisplayedContent] = useState("")
  const [isComplete, setIsComplete] = useState(false)
  const currentStory = storyLines[step]
  const contentIndex = useRef(0)
  const isSkipping = useRef(false)

  useEffect(() => {
    // playSound("menu_toggle")
    setSearchParams({ story: "open" }, { replace: true })
    // return () => {
    //   playSound("menu_toggle")
    // }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      if (isSkipping.current) {
        ReactGA.event({
          category: "劇情模式",
          action: "跳過對話",
        })
        setDisplayedContent(currentStory.content)
        setIsComplete(true)
        clearInterval(interval)
        return
      }
      if (contentIndex.current < currentStory.content.length) {
        setDisplayedContent(prev => {
          contentIndex.current += 1
          return prev + currentStory.content[contentIndex.current - 1]
        })
      } else {
        setIsComplete(true)
        clearInterval(interval)
      }
    }, 40)

    return () => clearInterval(interval)
  }, [step, currentStory.content])

  const handleClick = () => {
    if (!isComplete) {
      isSkipping.current = true
    }
  }

  const handleOptionClick = (option: StoryLineProps["options"][0]) => {
    if (option.onClick) {
      option.onClick()
    }
    if (step < storyLines.length - 1) {
      setStep(step + 1)
      setDisplayedContent("")
      setIsComplete(false)
      contentIndex.current = 0
      isSkipping.current = false
    } else {
      setSearchParams({}, { replace: true })
      if (onComplete) onComplete()
    }
  }

  return (
    <div className="game-story-area" onClick={handleClick}>
      <div className="container">
        <div className="chat-item npc --active">
          <div className="chat-item-container">
            <div className="avatar">
              <div
                className="img"
                style={{
                  backgroundImage: `url(http://www.cameo.tw/ntso/SDGs_botrun_image/SDGs_hakasaiwoman.png)`,
                }}
              ></div>
              <div className="name">哈卡賽博士</div>
            </div>
            <div className="content">
              <div className="markdown-wrapper">
                {displayedContent.split("\n").map((line, index) => (
                  <p key={index}>{line}</p>
                ))}
              </div>
            </div>
          </div>
        </div>
        {isComplete && (
          <div className="story-btn-area">
            {currentStory.options.map((option, index) => (
              <div
                key={index}
                className="btn-system --large"
                onClick={() => {
                  playSound("menu_click")
                  handleOptionClick(option)
                }}
              >
                <span>{option.content}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
