import { levelDataProbs } from "@core/sdgs_types";
import { BasicSettingsTab } from "../tabs/BasicSettingsTab";
import { GameSettingsTab } from "../tabs/GameSettingsTab";
import { ContentSettingsTab } from "../tabs/ContentSettingsTab";
import { VisualSettingsTab } from "../tabs/VisualSettingsTab";
import { CustomPromptTab } from "../tabs/CustomPromptTab";
import { Question, CharacterProbs } from "@core/sdgs_types";
import { gameConfigs, stringToGameType } from "./CreateContentConfig";
import { WritingSettingsTab } from "../tabs/WritingSettingsTab";
interface GetTabsProps {
  level: levelDataProbs;
  onLevelChange: (updates: Partial<levelDataProbs>) => void;
  formatedQuestions?: Question[];
  setFormatedQuestions?: (questions: Question[]) => void;
  processing?: boolean;
  onGenerateContent?: () => void;
  onFormatQuestions?: () => void;
  isMaterialLocked?: boolean;
  onMaterialLockToggle?: (locked: boolean) => void;
  onCharacterSelect?: (character: CharacterProbs) => void;
}

export const getTabs = ({
  level,
  onLevelChange,
  formatedQuestions,
  setFormatedQuestions,
  processing,
  onGenerateContent,
  onFormatQuestions,
  isMaterialLocked,
  onMaterialLockToggle,
  onCharacterSelect,
}: GetTabsProps) => {
  const { tabs } = gameConfigs[stringToGameType(level.gameType)];
  const commonTabs = [
    {
      label: "基本設定",
      content: <BasicSettingsTab level={level} onLevelChange={onLevelChange} />,
    },
    {
      label: "遊戲設定",
      content: (
        <GameSettingsTab
          level={level}
          onLevelChange={onLevelChange}
          onCharacterSelect={
            onCharacterSelect ?? ((character: CharacterProbs) => {})
          }
        />
      ),
    },
    {
      label: "視覺設定",
      content: (
        <VisualSettingsTab level={level} onLevelChange={onLevelChange} />
      ),
    },
  ];
  const fixQuestionTabs = [
    ...commonTabs,
    {
      label: "內容設定",
      content: (
        <ContentSettingsTab
          level={level}
          onLevelChange={onLevelChange}
          formatedQuestions={formatedQuestions || []}
          setFormatedQuestions={setFormatedQuestions || (() => {})}
          processing={processing || false}
          onGenerateContent={onGenerateContent ?? (() => {})}
          onFormatQuestions={onFormatQuestions ?? (() => {})}
          isMaterialLocked={isMaterialLocked ?? false}
          onMaterialLockToggle={
            onMaterialLockToggle ?? ((isLocked: boolean) => {})
          }
        />
      ),
    },
  ];
  if (tabs === "freeFormTabs") {
    return [
      ...fixQuestionTabs,
      {
        label: "客製化題詞",
        content: (
          <CustomPromptTab level={level} onLevelChange={onLevelChange} />
        ),
      },
    ];
  } else if (tabs === "fixQuestionTabs") {
    return fixQuestionTabs;
  } else if (tabs === "writeGameTabs") {
    return [
      ...commonTabs,
      {
        label: "寫作設定",
        content: (
          <WritingSettingsTab level={level} onLevelChange={onLevelChange} />
        ),
      },
    ];
  }

  return commonTabs; // 預設返回 commonTabs
};
