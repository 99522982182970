import { useMutation } from "react-query"
import { request } from "@utils/axios-util"

export default function useLogger() {
  const mutationFn = (data: object) =>
    request({
      url: `/game/log`,
      method: "POST",
      data: data,
    })

  return useMutation({
    mutationFn,
  })
}
