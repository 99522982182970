export function correctText(text: string): string {
    // 分段並修正段落縮排和空行
    const paragraphs = text.split(/\n+/).map(paragraph => paragraph.trim());

    // 修正標點符號後的空格，首字母大寫，並在必要時添加句點
    const correctedParagraphs = paragraphs.map(paragraph => {
        // 首字母大寫
        if (paragraph.length > 0) {
            paragraph = paragraph.charAt(0).toUpperCase() + paragraph.slice(1);
        }

        // 在標點符號後添加空格
        paragraph = paragraph.replace(/([.,?!;:])(?=\S)/g, "$1 ");

        // 去除多餘的空格
        paragraph = paragraph.replace(/\s{2,}/g, ' ');

        // 如果段落末尾沒有標點符號，則添加句點
        if (!/[.,?!;:]$/.test(paragraph)) {
            paragraph += '.';
        }

        return paragraph;
    });

    // 將段落連接並添加段落縮排
    return correctedParagraphs.map(paragraph => `    ${paragraph}`).join('\n\n');
}