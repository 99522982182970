import { SetStateAction, Dispatch } from "react";
import { QuestionTypeEnum } from "./types";
import { AiMessage } from "./types";

export const displayTextGradually = (
  text: string,
  setAiMessage: Dispatch<SetStateAction<AiMessage>>,
  options: string[],
  questionType: QuestionTypeEnum,
  onComplete: () => void
) => {
  let currentIndex = 0;
  const delay = 120; // 每 80 毫秒顯示一個字符
  const intervalId = setInterval(() => {
    if (currentIndex < text.length) {
      const chunkSize = Math.floor(Math.random() * 8) + 5; // 隨機選擇 5-12 個字符
      currentIndex = Math.min(currentIndex + chunkSize, text.length);
      const currentText = text.slice(0, currentIndex);

      setAiMessage({
        content: currentText,
        options: options,
        currentQuestionType: questionType,
      });
    } else {
      clearInterval(intervalId);
      onComplete();
    }
  }, delay);

  // 返回一個清理函數，以便在需要時停止顯示
  return () => clearInterval(intervalId);
};
