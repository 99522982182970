import { useState, useEffect, useCallback, useRef } from "react";
import useMultiGameUtils from "@components/GameTeam/useMultiGameUtils";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import { PlayerInfo } from "@core/sdgs_types";

import {
  sendUserResultMessage,
  UserResultMessage,
  HandleReciveAllTypeMessage,
  DanmakuMessage,
  sendDanmakuMessage,
  sendCommandMessage,
  messageType,
  CommandMessage,
} from "../../../OneChat/SendUserResultMessage";

type UserResultWithScore = UserResultMessage & { score: number };
interface MultiPlayerRoundFlowHook {
  timeRemaining: number;
  currentRound: number;
  sendUserRoundResult: (
    message: string,
    isSuccess: boolean,
    duration: number
  ) => void;
  sendUserGameEnd: (result: any) => void;
  handleLeaveRoom: () => void;
  currentRoundScore: Record<string, number>;
  totalScore: Record<string, number>;
  participants: PlayerInfo[];
  onUserSendDanmaku: (message: string) => void;
  answeredPlayers: Record<string, boolean>;
}

const useMultiPlayerRoundFlow = (
  roundDuration: number = 60,
  onRoundEnd: () => void,
  handleSendDanmaku: (message: string) => void,
  onAllUserGameEnd: (results: Record<string, any>) => void
): MultiPlayerRoundFlowHook => {
  const {
    participants,
    handleSendMessage,
    handleLeaveRoom,
    messageHistory,
    lastUserMessage,
  } = useMultiGameUtils();
  const user = useRecoilValue(userAuthState);
  const [playerResults, setPlayerResults] = useState<
    Record<number, Record<string, UserResultWithScore>>
  >({ 0: {} });
  const [timeRemaining, setTimeRemaining] = useState(roundDuration);
  const [currentRound, setCurrentRound] = useState(0);
  const [isRoundInProgress, setIsRoundInProgress] = useState(false);
  const [currentRoundScore, setCurrentRoundScore] = useState<
    Record<string, number>
  >({});
  const [answeredPlayers, setAnsweredPlayers] = useState<
    Record<string, boolean>
  >({}); // 用來記錄玩家是否已經行動

  const [totalScore, setTotalScore] = useState<Record<string, number>>({});
  const [isRoundEnding, setIsRoundEnding] = useState(false);
  const roundEndingRef = useRef(false);
  const resetRound = useCallback(() => {
    //add a new round result record
    setTimeRemaining(roundDuration);
    setIsRoundInProgress(true);
    setIsRoundEnding(false);
    roundEndingRef.current = false;
  }, [roundDuration]);
  const getNickname = useCallback(
    (username: string) => {
      const player = participants.find(
        (player) => player.username === username
      );
      return player?.nickname || username;
    },
    [participants]
  );

  const startNextRound = useCallback(() => {
    if (roundEndingRef.current) {
      // Prevent multiple calls
      return;
    }
    roundEndingRef.current = true;
    setPlayerResults((prev) => ({
      ...prev,
      [currentRound + 1]: {},
    }));
    setAnsweredPlayers({});
    setCurrentRound((prev) => prev + 1);
    onRoundEnd();
    resetRound();
  }, [resetRound, onRoundEnd, currentRound, setPlayerResults]);

  const recordPlayerResult = useCallback(
    (username: string, action: UserResultMessage, RoundIndex: number) => {
      const roundExists = RoundIndex in playerResults;
      const playerResultExists =
        roundExists && username in playerResults[RoundIndex];

      if (playerResultExists) {
        console.log(`玩家 ${username} 在第 ${RoundIndex} 回合已經有紀錄結果了`);
        return;
      }
      const calculateScore = (correct: boolean, time: number): number => {
        if (!correct) return 0;
        // 假设最大得分为100，每秒扣1分，最低得分为60
        return Math.max(100 - time, 60);
      };
      const score = calculateScore(action.isSuccess, action.duration);
      setAnsweredPlayers((prev) => ({
        ...prev,
        [username]: true,
      }));
      setPlayerResults((prev) => ({
        ...prev,
        [RoundIndex]: {
          ...prev[RoundIndex],
          [username]: {
            ...action,
            score,
          },
        },
      }));
    },
    [setPlayerResults]
  );

  const getLastRounPlayerScore = useCallback(
    (
      playerResults: Record<string, UserResultWithScore>
    ): Record<string, number> => {
      return Object.entries(playerResults).reduce((acc, [username, result]) => {
        return {
          ...acc,
          [username]: result.score,
        };
      }, {});
    },
    []
  );

  const getUserTotalScore = useCallback(
    (
      playerResults: Record<number, Record<string, UserResultWithScore>>
    ): Record<string, number> => {
      return Object.entries(playerResults).reduce((acc, [, results]) => {
        return Object.entries(results).reduce(
          (acc, [username, result]) => {
            return {
              ...acc,
              [username]: (acc[username] || 0) + result.score,
            };
          },
          { ...acc }
        );
      }, {} as Record<string, number>);
    },
    []
  );
  useEffect(() => {
    if (playerResults[currentRound]) {
      setCurrentRoundScore(getLastRounPlayerScore(playerResults[currentRound]));
      setTotalScore(getUserTotalScore(playerResults));
    }
  }, [playerResults]);

  const [allPlayerResults, setAllPlayerResults] = useState<Record<string, any>>(
    {}
  );
  // 監控遊戲結束結果
  const isGameEndExecuted = useRef(false);
  useEffect(() => {
    if (
      !isGameEndExecuted.current &&
      participants.length > 0 &&
      Object.keys(allPlayerResults).length === participants.length
    ) {
      isGameEndExecuted.current = true;
      onAllUserGameEnd(allPlayerResults);
    }
  }, [allPlayerResults, participants, onAllUserGameEnd]);

  useEffect(() => {
    if (lastUserMessage.content && lastUserMessage.username) {
      const baseMessage = HandleReciveAllTypeMessage(lastUserMessage.content);
      if (!baseMessage) return;

      switch (baseMessage.type) {
        case messageType.Command:
          const commandMessage = baseMessage.content as CommandMessage;
          if (commandMessage.command === "GameEnd") {
            const sender = lastUserMessage.username;
            if (allPlayerResults[sender]) {
              console.log(`玩家 ${sender} 已經有紀錄遊戲結果了，不重複紀錄`);
              return;
            }
            const result = JSON.parse(commandMessage.parameters[0]);
            setAllPlayerResults((prev) => ({
              ...prev,
              [sender]: result,
            }));
          }
          break;
        case messageType.USER_RESULT:
          const userResultMessage = baseMessage.content as UserResultMessage;
          recordPlayerResult(
            lastUserMessage.username,
            userResultMessage,
            userResultMessage.roundIndex
          );
          break;
        case messageType.Danmaku:
          // to do 處理彈幕
          const danmakuMessage = baseMessage.content as DanmakuMessage;
          if (lastUserMessage.username !== user?.username) {
            const userNickname = participants.find(
              (player) => player.username === lastUserMessage.username
            )?.nickname;
            handleSendDanmaku(userNickname + ":" + danmakuMessage.text);
          }
          break;
        default:
          break;
      }
    }
  }, [lastUserMessage]);
  //當玩家發送彈幕時, 傳送給其他玩家
  const onUserSendDanmaku = useCallback(
    (message: string) => {
      sendDanmakuMessage(message, handleSendMessage);
    },
    [handleSendMessage]
  );
  const sendUserGameEnd = useCallback(
    (result: any) => {
      const resultString = JSON.stringify(result);
      // 連續發送三次訊息，每次間隔200ms
      sendCommandMessage("GameEnd", [resultString], handleSendMessage);

      // 同時更新本地結果
      setAllPlayerResults((prev) => ({
        ...prev,
        [user?.username || "anonymous"]: result,
      }));
    },
    [handleSendMessage, user?.username]
  );
  const sendUserRoundResult = useCallback(
    (message: string, isSuccess: boolean, duration: number) => {
      sendUserResultMessage(
        message,
        isSuccess,
        duration,
        currentRound,
        handleSendMessage
      );

      recordPlayerResult(
        user?.username || "anonymous",
        {
          content: message,
          isSuccess,
          duration,
          roundIndex: currentRound,
        },
        currentRound
      );
    },
    [handleSendMessage, sendUserResultMessage, recordPlayerResult, user]
  );
  const sendUserDanmaku = useCallback(
    (message: string) => {
      handleSendMessage(message);
    },
    [handleSendMessage]
  );

  useEffect(() => {
    if (isRoundEnding) return; // Skip if round is already ending
    if (!participants || participants.length === 0) return;
    if (!playerResults[currentRound]) return;
    if (
      Object.keys(playerResults[currentRound]).length !== participants.length
    ) {
      console.log(
        "player record not match结果数量与参与者数量不匹配,playerResults : ",
        currentRound,
        playerResults[currentRound]
      );
      console.log("當前回合的參與者數量 : ", participants.length);
      console.log(
        "當前回合的結果數量 : ",
        Object.keys(playerResults[currentRound]).length
      );
      return; // 如果當前回合的結果不存在或者结果数量与参与者数量不匹配，则直接返回
    }

    const allActed = participants.every((player) => {
      return playerResults[currentRound][player.username] !== undefined;
    });

    if (allActed && !roundEndingRef.current) {
      console.log(
        "allActed 這回合結束",
        playerResults[currentRound],
        currentRound
      );
      setIsRoundEnding(true);
      startNextRound();
    }
  }, [playerResults]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isRoundInProgress && timeRemaining > 0 && !isRoundEnding) {
      timer = setInterval(() => {
        setTimeRemaining((prev) => {
          if (prev <= 1) {
            clearInterval(timer);
            //startNextRound();
            //onRoundTimeEnd();
            return roundDuration;
          }
          return prev - 1;
        });
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isRoundInProgress, timeRemaining, startNextRound, roundDuration]);

  return {
    timeRemaining,
    currentRound,
    sendUserRoundResult,
    handleLeaveRoom,
    currentRoundScore,
    totalScore,
    participants,
    onUserSendDanmaku,
    answeredPlayers,
    sendUserGameEnd,
  };
};

export default useMultiPlayerRoundFlow;
