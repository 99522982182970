import React, { useEffect, useState } from "react";
import VoicePlayer from "@core/components/GeneralComponent/VoicePlayer"; // 確保導入路徑正確
import EnhancedMessage from "@core/components/GeneralComponent/EnhancedMessage"; // 確保導入路徑正確
import { useAITranslateToCH } from "@hooks/useApi";
import { useTextToSpeech } from "@core/hooks/useApi";
import { Keywords, getVoice } from "@core/sdgs_types";
interface ChatItemProps {
  avatarUrl: string;
  avatarName: string;
  message: string;
  keywords: Keywords;
  voiceName: string;
  isShow: boolean;
  onMessageSend: (message: string) => void;
  suggestions: string[];
  isNewMessageBegin: boolean;
  onAdviceClick: () => void;
  isForceVoiceStop: boolean;
}

const PlantBotChatItem: React.FC<ChatItemProps> = ({
  avatarUrl,
  avatarName,
  message,
  keywords,
  voiceName,
  isShow,
  onMessageSend,
  suggestions,
  isNewMessageBegin,
  onAdviceClick,
  isForceVoiceStop,
}) => {
  const { translatedText, translateCH } = useAITranslateToCH();
  const { voiceUrl, hint, error, loading, text2voice } = useTextToSpeech();
  const [popupActive, setPopupActive] = useState(false);

  const toggleTranslation = () => {
    console.log("Translation toggled");
    translateCH(message);
    setPopupActive((prev) => !prev);
  };

  useEffect(() => {
    if (isNewMessageBegin && voiceName) {
      setPopupActive(false);
      if (message) {
        console.log(
          "清除所有語音元件，文字更新Text updated 執行轉語音:",
          message
        ); // 文字更新
        text2voice(message, getVoice(voiceName));
      }
    }
  }, [isNewMessageBegin, voiceName]);

  return (
    <div className={`chat-item npc ${isShow ? "--active" : ""}`}>
      <div className="chat-item-container">
        <div className="avatar">
          <div
            className="img"
            style={{ backgroundImage: `url('${avatarUrl}')` }}
          ></div>
          <div className="name">{avatarName}</div>
        </div>
        <div className="content">
          <EnhancedMessage
            message={message}
            keywords={keywords}
            voiceName={voiceName}
          />
          <div
            className={`translate ${popupActive ? "--active" : ""}`}
            data-translate
          >
            <p>{translatedText}</p>
          </div>
          <div className="chat-fn-bar">
            <div className="left">
              {voiceUrl ? (
                <VoicePlayer
                  voiceUrl={voiceUrl}
                  isForceStop={isForceVoiceStop}
                />
              ) : (
                <div>{hint}</div>
              )}

              {/*   <div
                className="btn --white --small"
                data-btntranslate
                onClick={toggleTranslation}
              >
                <i className="icon icon-2"></i>
              </div>
              <div className="btn --white --small" onClick={onAdviceClick}>
                <i className="icon icon-17"></i>
                <span>提示</span>
              </div> */}
            </div>

            <div className="right">
              {suggestions.map((btn, index) => (
                <div
                  className="btn system-sound"
                  key={index}
                  onClick={() => {
                    onMessageSend(btn);
                  }}
                >
                  <span>{btn}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlantBotChatItem;
