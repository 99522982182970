import React from "react";
import CharacterDropdown from "../common/CharacterDropdown";
import Dropdown from "../common/Dropdown";
import Checkbox from "../common/Checkbox";
import {
  levelDataProbs,
  CharacterProbs,
  LearningLevel,
} from "@core/sdgs_types";

interface GameSettingsTabProps {
  level: levelDataProbs;
  onLevelChange: (level: Partial<levelDataProbs>) => void;
  onCharacterSelect: (character: CharacterProbs) => void;
}

export const GameSettingsTab: React.FC<GameSettingsTabProps> = ({
  level,
  onLevelChange,
  onCharacterSelect,
}) => {
  return (
    <div className="game-settings">
      <CharacterDropdown
        label="選擇學伴角色"
        onSelected={onCharacterSelect}
        defaultOption={level.avatarName ? level.avatarName : "旅人"}
        wellcomeMessage={level.wellcomeMessage}
        onChangeWellcomeMessage={(value) =>
          onLevelChange({ wellcomeMessage: value })
        }
      />
      <Dropdown
        label="難度選擇"
        options={LearningLevel}
        defaultValue={level.difficulty}
        isRequired={true}
        onChange={(value) => onLevelChange({ difficulty: value })}
      />
      <Dropdown
        label="題目數量"
        options={{
          5: "5",
          10: "10",
          15: "15",
          20: "20",
        }}
        defaultValue={level.numberOfQuestions}
        isRequired={true}
        onChange={(value) => onLevelChange({ numberOfQuestions: value })}
      />
      <Checkbox
        label="是否能多人遊玩"
        isRequired={false}
        onChange={(checked) => onLevelChange({ multiPlayer: checked })}
        defaultChecked={level.multiPlayer}
      />
    </div>
  );
};
