import React from "react"
import ChoiceItem from "./SelectionItem"

import { LabbelSwitch } from "../../utils/functions"
interface SelectionInputAreaProps {
  items: string[]
  onItemClick: (text: string) => void
}

const SelectionInputArea: React.FC<SelectionInputAreaProps> = ({ items, onItemClick }) => {
  return (
    <div className={"input-mcq-container " + LabbelSwitch(items)}>
      <div className="option-container">
        {items.map((item, index) => (
          <ChoiceItem key={index} label={item} onClick={() => onItemClick(item)} />
        ))}
      </div>
    </div>
  )
}

export default SelectionInputArea
