import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import { AxiosResponse, AxiosError } from "axios";
import { useMutation, useQuery } from "react-query";
import { request } from "@utils/axios-util";
import { UserData, SettingsType } from "@utils/types";
import i18n from "@utils/i18n";
import { useSearchParams } from "react-router-dom";

interface UserSettingsContextType {
  profile: UserData | undefined;
  settings: SettingsType;
  setSettings: React.Dispatch<React.SetStateAction<SettingsType>>;
  setProfile: React.Dispatch<React.SetStateAction<UserData | undefined>>;
  defaultSettings: SettingsType;
}

const UserSettingsContext = createContext<UserSettingsContextType | undefined>(
  undefined
);

const useProfile = () => {
  const queryFn = () =>
    request({
      url: `/game/user/info`,
      method: "get",
    });

  const getter = useQuery(["fetch-player-settings-api"], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error);
    },
    select: (res: AxiosResponse): UserData => {
      return res.data;
    },
  });

  const mutationFn = (data: object) =>
    request({
      url: `/user`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });

  const setter = useMutation({
    mutationFn,
  });
  return { getter, setter };
};

const UserSettingsProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const { getter, setter } = useProfile();
  const { data } = getter;
  const { mutate } = setter;
  const [, setSearchParams] = useSearchParams();
  const defaultSettings: SettingsType = {
    language: "zh",
    difficulty: "beginner",
    backgroundMusic: true,
    soundEffects: true,
    autoNpcVoice: true,
    movingBackground: true,
  };
  const [settings, setSettings] = useState<SettingsType>(defaultSettings);
  const [profile, setProfile] = useState<UserData>();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (data && data.config) {
      setProfile(data);
      setSettings(data.config);
      setInitialized(true);
    }
  }, [data]);

  useEffect(() => {
    i18n.changeLanguage(settings.language).then(() => {
      setSearchParams((searchParams) => new URLSearchParams(searchParams));
    });
  }, [settings.language]);

  useEffect(() => {
    if (initialized) mutate({ config: settings });
  }, [JSON.stringify(settings)]);

  return (
    <UserSettingsContext.Provider
      value={{ profile, setProfile, settings, setSettings, defaultSettings }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

const useUserSettings = (): UserSettingsContextType => {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error(
      "useUserSettings must be used within a UserSettingsProvider"
    );
  }
  return context;
};

export { UserSettingsProvider, useUserSettings, useProfile };
export type { UserSettingsContextType };
