// 處理遊戲表現相關的邏輯
import { useCallback, useState } from "react";
import {
  GamePerformanceData,
  GameRewardsResult,
  LearningJourneyLog,
} from "@core/sdgs_types";

import calculateGamePerformance from "./GameEnd/calculateGamePerformance";
import { calculateGameRewards } from "./GameEnd/calculateGameRewards";

export function useGamePerformance() {
  const [gamePerformanceData, setGamePerformanceData] =
    useState<GamePerformanceData>({
      score: 0,
      averageAnswerTime: 0,
      gameDuration: 0,
    });

  const [gameRewardsResult, setGameRewardsResult] = useState<GameRewardsResult>(
    {
      highlights: [],
      totalExp: 0,
      completion: "",
      dynamicEncouragement: "",
    }
  );

  const calculatePerformanceAndRewards = useCallback(
    (learningJourneyLog: LearningJourneyLog | undefined) => {
      if (!learningJourneyLog) {
        console.error("學習歷程紀錄尚未初始化, 無法計算遊戲表現");
        return {
          gamePerformanceData: gamePerformanceData,
          gameRewardsResult: gameRewardsResult,
        };
      }
      const newGamePerformanceData =
        calculateGamePerformance(learningJourneyLog);
      const newGameRewardsResult = calculateGameRewards(newGamePerformanceData);

      setGamePerformanceData(newGamePerformanceData);
      setGameRewardsResult(newGameRewardsResult);
      return {
        gamePerformanceData: newGamePerformanceData,
        gameRewardsResult: newGameRewardsResult,
      };
    },
    []
  );

  return {
    gamePerformanceData,
    gameRewardsResult,
    calculatePerformanceAndRewards,
  };
}
