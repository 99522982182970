import { useState, useEffect } from "react";
import {
  useAiOutputGenerator,
  AutoTypeDescription,
} from "@core/hooks/useAiOutputGenerator";
import {
  LearningJourneyLog,
  OneLearningLog,
  WriteScoreDimension,
} from "sdgs_types";

export interface WriteResult extends WriteScoreDimension {
  messagesToShow: string;
  isScoreGenerated: boolean;
}

const responseType: AutoTypeDescription<WriteScoreDimension> = {
  content: "number",
  organization: "number",
  grammarAndSyntax: "number",
  vocabularyAndSpelling: "number",
  comment: "string",
};

export const useWritingScore = () => {
  const [writeResult, setWriteResult] = useState<WriteResult>({
    content: 0,
    organization: 0,
    grammarAndSyntax: 0,
    vocabularyAndSpelling: 0,
    comment: "",
    messagesToShow: "做得不錯啊，等一下，分數生成中。",
    isScoreGenerated: false,
  });

  const { result, error, generateOutput } =
    useAiOutputGenerator<WriteScoreDimension>({
      defaultSystemPrompt: `你是一位英語程度的評分員，
評分原則：
總分為20分，基於大考中心英語作文評分機制。
評分維度包括：內容(content)、組織(organization)、文法句構(grammarAndSyntax)、字彙拼字(vocabularyAndSpelling)。
每個維度分為優(4-5分)、可(3分)、差(1-2分)、劣(0分)四個等級。
comment:評語，一句話，簡潔明瞭，直接指出優缺點，要很口語的語氣，鼓勵為主，20字以內`,
      globalConfigPromptKey: "WrittingScore",
      levelDataPromptKey: "scoreEvaluatePrompt",
      responseType,
    });

  const generateScoreAndComment = (
    writingSubject: string,
    rule: string,
    description: string,
    writingText: string,
    evaluatePlayerName: string,
    conversationLogs: OneLearningLog[]
  ) => {
    if (writingText && writingSubject) {
      const conversationLogsStr = JSON.stringify(conversationLogs);
      const userMessage = `這是由多人一起完成的接龍寫作，需要請你評分其中一位玩家的分數，寫作的主題是：${writingSubject}, 寫作的規則是：${rule}, 寫作的描述是：${description}, 玩家們一起完成的作文如下: ${writingText}, 玩家之間的對話紀錄如下: ${conversationLogsStr}, 要你請評分玩家是：${evaluatePlayerName}`;
      generateOutput(userMessage);
    }
  };

  useEffect(() => {
    if (result) {
      try {
        const newWriteResult: WriteResult = {
          content: result.content,
          organization: result.organization,
          grammarAndSyntax: result.grammarAndSyntax,
          vocabularyAndSpelling: result.vocabularyAndSpelling,
          comment: result.comment,
          messagesToShow: result.comment,
          isScoreGenerated: true,
        };

        setWriteResult(newWriteResult);
      } catch (e) {
        console.error("Error processing AI response:", e);
        setWriteResult((prev) => ({
          ...prev,
          messagesToShow: "這次沒有分數，希望你會喜歡。",
          isScoreGenerated: false,
        }));
      }
    }
  }, [result]);

  return {
    writeResult,
    generateScoreAndComment,
  };
};
