import React, { useCallback } from "react";
import SimplePopup from "@core/components/GeneralComponent/SimplePopup";
import ElsaGame from "@core/components/ImmersiveMenu/Game/ElsaModule/ElsaGame";

interface ElsaPluginProps {
  praticeText: string;
  onEnd: (score: number, praticeCount: number) => void;
  isPopupVisible: boolean;
  setIsPopupVisible: (isVisible: boolean) => void;
}

const ElsaPlugin: React.FC<ElsaPluginProps> = ({
  praticeText,
  onEnd,
  isPopupVisible,
  setIsPopupVisible,
}) => {
  const hangleEnd = (score: number, praticeCount: number) => {
    setIsPopupVisible(false);
    onEnd(score, praticeCount);
  };

  return (
    <SimplePopup
      isPopup={isPopupVisible}
      setIsPopup={setIsPopupVisible}
      popupClassName="for-elsa"
      popupContainerClassName="container"
    >
      <ElsaGame sentence={praticeText} onEnd={hangleEnd} />
    </SimplePopup>
  );
};
export default ElsaPlugin;
