import {
  useState,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import SimplePopup from "@core/components/GeneralComponent/SimplePopup";
import WriteGameEndContainer from "./WriteGameEndContainer";
import { useWritingScore } from "@core/hooks/useWritingScore";
import { LearningJourneyLog, OneLearningLog } from "@core/sdgs_types";
import { WriteScoreDimension } from "sdgs_types";
import PieChartComponent from "@core/components/ImmersiveMenu/Game/EndComponents/PieChartComponent";
import { useParams } from "react-router-dom";
import ShareLink from "@core/components/ImmersiveMenu/Game/EndComponents/ShareLink";
import Confettiful from "@components/Effects/Confettiful";
import { WriteResult } from "@core/hooks/useWritingScore";
interface WritingGameEndPopupProps {
  isPopup: boolean;
  setIsPopup: (isVisible: boolean) => void;
  learningJourneyLog: LearningJourneyLog;
  leaningJourneyLogUrl?: string;
  onScoreGenerated: (writeResult: WriteResult) => void;
}

export interface WritingGameEndPopupRef {
  generateScoreAndComment: (
    writingSubject: string,
    rule: string,
    description: string,
    writingText: string,
    evaluatePlayerName: string,
    conversationLogs: OneLearningLog[]
  ) => void;
  showScoresAtEnd: (scores: { username: string; score: number }[]) => void;
}

const WriteGameEndPopup = forwardRef<
  WritingGameEndPopupRef,
  WritingGameEndPopupProps
>(
  (
    {
      isPopup,
      setIsPopup,
      learningJourneyLog,
      leaningJourneyLogUrl,
      onScoreGenerated,
    },
    ref
  ) => {
    const { roomId } = useParams();
    const [playerScores, setPlayerScores] = useState<
      { username: string; score: number }[]
    >([]);
    const [showConfetti, setShowConfetti] = useState(false);

    const { writeResult, generateScoreAndComment } = useWritingScore();

    const showScoresAtEnd = useCallback(
      (scores: { username: string; score: number }[]) => {
        setPlayerScores(scores);
      },
      []
    );

    useImperativeHandle(ref, () => ({
      generateScoreAndComment,
      showScoresAtEnd,
    }));

    useEffect(() => {
      if (writeResult.isScoreGenerated) {
        setShowConfetti(true);
        onScoreGenerated(writeResult);
      }
    }, [writeResult.isScoreGenerated]);

    return (
      <SimplePopup
        isPopup={isPopup}
        setIsPopup={setIsPopup}
        popupClassName="for-share"
        isShowCloseBtn={false}
      >
        {showConfetti && <Confettiful duration={5000} />}
        {writeResult.isScoreGenerated ? (
          <WriteGameEndContainer
            avatarUrl={"/images/avatar/avatar.png"}
            description={writeResult.messagesToShow}
            writeScoreDimension={writeResult}
            learningJourneyLog={learningJourneyLog}
            leaningJourneyLogUrl={leaningJourneyLogUrl}
          />
        ) : (
          <div className="end-container --lengthy">
            <div className="end-frame">
              <div className="center">
                <div className="score-generating">評分中，請稍候...</div>
              </div>
            </div>
          </div>
        )}
      </SimplePopup>
    );
  }
);

export default WriteGameEndPopup;
