import { result } from "lodash";

export type UserResultMessage = {
  content: string;
  isSuccess: boolean;
  duration: number;
  roundIndex: number;
};
export type DanmakuMessage = {
  text: string;
};
export type CommandMessage = {
  command: string;
  parameters: string[];
};

export type MessagBaseType = {
  type: messageType;
  content: string;
};
export type PharsedMessageType = {
  type: messageType;
  content: UserResultMessage | DanmakuMessage | CommandMessage;
};
export enum messageType {
  USER_RESULT = "USER_RESULT", // 每一回合，所有玩家的回答結果
  Danmaku = "Danmaku", // 彈幕
  Command = "Command", // 傳遞給所有玩家的指令
}
export const packToBaseMessage = (
  type: messageType,
  jsonContent: string
): string => {
  const messagePackage: MessagBaseType = {
    type,
    content: jsonContent,
  };
  return JSON.stringify(messagePackage);
};
export const HandleReciveAllTypeMessage = (
  jsonMessage: string
): PharsedMessageType | undefined => {
  try {
    const receivedMessage: MessagBaseType = JSON.parse(jsonMessage);

    switch (receivedMessage.type) {
      case messageType.USER_RESULT:
        const userResultMessage = receiveUserResultMessage(
          receivedMessage.content
        );
        console.log("USER_RESULT:", userResultMessage);
        return { type: receivedMessage.type, content: userResultMessage };
      case messageType.Danmaku:
        const danmakuMessage = receiveDanmakuMessage(receivedMessage.content);
        return { type: receivedMessage.type, content: danmakuMessage };
      case messageType.Command:
        const commandMessage = receiveCommandMessage(receivedMessage.content);
        console.log("Command:", commandMessage);
        return { type: receivedMessage.type, content: commandMessage };

      default:
        return undefined;
    }
  } catch (error) {
    console.error("Error parsing received message:", error);
    return undefined;
  }
};
export const sendCommandMessage = (
  command: string,
  parameters: any[],

  sendFunction: (message: string) => void
): void => {
  const messagePackage: CommandMessage = {
    command,
    parameters,
  };
  // 將訊息轉換為 JSON 字符串
  const jsonMessage = packToBaseMessage(
    messageType.Command,
    JSON.stringify(messagePackage)
  );

  // 使用提供的 sendFunction 發送訊息
  sendFunction(jsonMessage);

  // 記錄發送的訊息
  console.log("傳送command message:", jsonMessage);
};
export const sendDanmakuMessage = (
  text: string,
  sendFunction: (message: string) => void
): void => {
  const messagePackage: DanmakuMessage = {
    text,
  };
  // 將訊息轉換為 JSON 字符串
  const jsonMessage = packToBaseMessage(
    messageType.Danmaku,
    JSON.stringify(messagePackage)
  );

  // 使用提供的 sendFunction 發送訊息
  sendFunction(jsonMessage);

  // 記錄發送的訊息
  console.log("傳送dammaku message:", jsonMessage);
};
const receiveCommandMessage = (content: string): CommandMessage => {
  try {
    const commandMessage: CommandMessage = JSON.parse(content);

    return commandMessage;
  } catch (error) {
    console.error("Error parsing received message:", error);
    return {
      command: "Error parsing message",
      parameters: [],
    };
  }
};
const receiveDanmakuMessage = (jsonMessage: string): DanmakuMessage => {
  try {
    // 將接收到的 JSON 字符串解析為 FormattedMessage 對象
    const receivedMessage: DanmakuMessage = JSON.parse(jsonMessage);

    // 返回解析後的訊息對象
    return receivedMessage;
  } catch (error) {
    console.error("Error parsing received message:", error);
    // 如果解析失敗,返回一個錯誤訊息
    return {
      text: "Error parsing message",
    };
  }
};

export const sendUserResultMessage = (
  content: string,
  isSuccess: boolean,
  duration: number,
  roundIndex: number,
  sendFunction: (message: string) => void
): void => {
  const messagePackage: UserResultMessage = {
    content,
    isSuccess,
    duration,
    roundIndex,
  };
  // 將訊息轉換為 JSON 字符串
  const jsonMessage = packToBaseMessage(
    messageType.USER_RESULT,
    JSON.stringify(messagePackage)
  );

  sendFunction(jsonMessage);

  // 記錄發送的訊息
  console.log("傳送formatted message:", jsonMessage);
};
export const receiveUserResultMessage = (
  jsonMessage: string
): UserResultMessage => {
  try {
    // 將接收到的 JSON 字符串解析為 FormattedMessage 對象
    const receivedMessage: UserResultMessage = JSON.parse(jsonMessage);

    // 記錄接收到的訊息
    console.log("receiveUserResultMessage:", receivedMessage);

    // 返回解析後的訊息對象
    return receivedMessage;
  } catch (error) {
    console.error("Error parsing received message:", error);
    // 如果解析失敗,返回一個錯誤訊息
    return {
      content: "Error parsing message",
      isSuccess: false,
      duration: 0,
      roundIndex: 0,
    };
  }
};
