import React, { useState, useEffect, useRef } from "react"
import { useParams } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { useGetRoomPlayers, useStartGame, useLeaveRoom } from "@core/hooks/useMultiGameApis"
import useWebSocket, { ReadyState } from "react-use-websocket"
import { useRecoilValue } from "recoil"
import { userAuthState } from "@core/recoil/atoms"
import { set } from "lodash"

const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL + "/sdgs/multi/ws"
interface GameWaitingPageProps {
  onButtonClick?: () => void // 這裡使用可選屬性，並提供預設函數
}

interface SystemData {
  code: string
  [key: string]: any
}

//這裡是等待合體的頁面

const GameTeamWaiting: React.FC<GameWaitingPageProps> = ({
  onButtonClick = () => console.log("button is pressed"),
}) => {
  const { gameType, gameId, roomId } = useParams()
  const { data: roomPlayersData, refetch: getRoomPlayers } = useGetRoomPlayers(gameId, roomId)
  const navigate = useNavigate()
  const user = useRecoilValue(userAuthState)
  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    `${SOCKET_URL}/${gameId}/${roomId}/${user?.username}`,
    {
      // heartbeat: {
      //   message: JSON.stringify({ type: 'ping' }),
      //   returnMessage: JSON.stringify({ type: 'pong' }),
      //   interval: 5000, // every 5 seconds, a ping message will be sent
      // },
      shouldReconnect: closeEvent => {
        // 自定義自動重連邏輯
        return true
      },
      reconnectAttempts: 10, // 最大重連次數
      reconnectInterval: 3000, // 重連間隔時間 (毫秒)
    }
  )
  const { refetch: startGame, data: startGameResult } = useStartGame(gameId, roomId)
  const { refetch: leaveRoom, data: leaveRoomResult } = useLeaveRoom(gameId, roomId)
  const [intPlayerCount, setPlayerCount] = useState(1)
  const isLeaveByGameStartRef = useRef(false)
  const naviStartGame = () => {
    if (gameType) {
      navigate(`/g/${gameType}/${gameId}/room/${roomId}/play`)
    } else {
      console.log("gameType is undefined，使用gameId")
      navigate(`/g/${gameId}/room/${roomId}/play`)
    }
  }
  const naviQuitWaiting = () => {
    if (gameType) {
      navigate(`/g/${gameType}/${gameId}/room`)
    } else {
      console.log("gameType is undefined，使用gameId")
      navigate(`/g/${gameId}/room`)
    }
  }
  const handleSystemMessage = (systemData: SystemData) => {
    switch (systemData.code) {
      case "system_player_join":
        getRoomPlayers()
        break
      case "system_player_leave":
        getRoomPlayers()
        break
      case "system_game_start":
        const roomKey = `game:${gameId}:room:${roomId}`
        if (systemData.data != roomKey) {
          return
        }
        isLeaveByGameStartRef.current = true
        naviStartGame()
        break
    }
  }

  const quitWaiting = () => {
    naviQuitWaiting()
  }

  useEffect(() => {
    if (lastMessage !== null) {
      const parsedData = JSON.parse(lastMessage.data)
      if (parsedData.system) {
        handleSystemMessage(parsedData.system)
      }
    }
  }, [lastMessage])

  useEffect(() => {
    console.log("roomPlayersData", roomPlayersData)
    setPlayerCount(roomPlayersData?.players.length || 1)
    if (roomPlayersData?.is_success) {
      const isUserInRoom = (roomPlayersData.players as { username: string }[]).some(
        player => player.username === user?.username
      )
      if (!isUserInRoom) {
        alert("You are not in the room!")
        naviQuitWaiting()
      }
    }

    if (!roomPlayersData?.is_success) {
      if (roomPlayersData?.error_code === "err_room_not_found") {
        alert("[01] Room not exist!")
        naviQuitWaiting()
        // navigate("/multi")
      }
    }
  }, [roomPlayersData])

  useEffect(() => {
    // 監控 readyState 的變化
    if (readyState === ReadyState.CLOSED) {
      console.log("[TeamWaiting] WebSocket 連線已斷開")
    } else if (readyState === ReadyState.OPEN) {
      console.log("[TeamWaiting] WebSocket 連線已建立")
    } else if (readyState === ReadyState.CONNECTING) {
      console.log("[TeamWaiting] WebSocket 連線中...")
    }
  }, [readyState])

  useEffect(() => {
    if (leaveRoomResult === undefined) {
      return
    }

    if (!leaveRoomResult?.is_success) {
      if (leaveRoomResult?.error_code === "err_room_not_found") {
        alert("[03] Room not exist!")
      }
      if (leaveRoomResult?.error_code === "error_player_already_disconnected") {
        alert("Player already disconnected!")
      }
    }
    naviQuitWaiting()
  }, [leaveRoomResult])

  useEffect(() => {
    if (startGameResult === undefined) {
      return
    }

    if (!startGameResult?.is_success) {
      if (startGameResult?.error_code === "err_room_not_found") {
        alert("[02] Room not exist!")
      }
      naviQuitWaiting()
    }
    if (startGameResult?.is_success) {
      isLeaveByGameStartRef.current = true
      naviStartGame()
    }
  }, [startGameResult])

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = "" // 標準瀏覽器需要這行代碼
      if (!isLeaveByGameStartRef.current) {
        console.log("[TeamWaiting] handleBeforeUnload")
        leaveRoom()
      }
    }

    window.addEventListener("beforeunload", handleBeforeUnload)
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    return () => {
      if (!isLeaveByGameStartRef.current) {
        console.log("[TeamWaiting] cleanup")
        leaveRoom()
      }
    }
  }, [])

  const handleCopyRoomId = () => {
    navigator.clipboard.writeText(roomId ?? "")
  }

  const avatars = roomPlayersData?.players.map(
    (
      player: {
        username: string
        nickname: string
        avatar: {
          image: string
        }
      },
      index: number
    ) => {
      const avatarFileName =
        player.avatar.image || (index === 0 ? "avatar.png" : `avatar-${index + 1}.png`)

      return (
        <div className="avatar" key={player.username}>
          <div
            className="img"
            style={{
              backgroundImage: `url('${avatarFileName}')`,
            }}
          ></div>
          <div className="name">{player.nickname || player.username}</div>
        </div>
      )
    }
  )

  return (
    <div className="teamup-container">
      <div className="sci-frame teamup">
        <i className="deco-top"></i>
        <div className="teamup-item">
          <p>複製挑戰號碼給隊友</p>
          <div className="teamup-num">
            <p>{roomId}</p>
            <i className="icon icon-22" onClick={handleCopyRoomId}>
              {" "}
            </i>
          </div>
          <h6 className="divider-text">隊員合體中</h6>
          <div className="teamup-avatar">
            <div className="group">{avatars}</div>
          </div>
          <div className="btn-area">
            <button className="btn-system" onClick={() => quitWaiting()}>
              <span>離開隊伍</span>
            </button>
            {user?.username == roomPlayersData?.host && intPlayerCount > 1 && (
              <button className="btn-system" onClick={() => startGame()}>
                <span>
                  <i className="icon icon-40"></i> 開始遊戲
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
export default GameTeamWaiting
