import React, { useState } from "react";
import TabButtons from "@core/components/GeneralComponent/TabButtons";

import { useNavigate } from "react-router-dom";
const NaviMenu: React.FC = () => {
  const navigate = useNavigate();
  const buttons = [
    {
      label: "新關卡",
      onClick: () => navigate("/createLevel/l/"),
    },
    {
      label: "新角色",
      onClick: () => navigate("/createLevel/bds/b/"),
    },
    {
      label: "我的角色庫",
      onClick: () => navigate("/createLevel/bds"),
    },
    {
      label: "我的關卡庫",
      onClick: () => navigate("/createLevel"),
    },
    {
      label: "回到遊戲",
      onClick: () => navigate("/"),
    },
  ];

  return <TabButtons buttons={buttons} />;
};

export default NaviMenu;
