import TextToAudioBtn from "../GeneralComponent/TextToAudioBtn"
interface AdviceProps {
  advice: ExplanationData
  voiceName?: string
}
export interface ExplanationData {
  explanation: string
  example: string[]
  translation: string[]
}

export const Advice: React.FC<AdviceProps> = ({ advice, voiceName }) => (
  <div className="advice">
    <ul className="notebook-ul">
      <li>
        <p className="text-main ff-en">{advice.explanation}</p>
      </li>
      {advice.example.map((item, index) => (
        <li key={index}>
          <div>
            <p className="text-main ff-en">{item}</p>
            <p className="text-second">{advice.translation[index]}</p>
            {voiceName && <TextToAudioBtn text={item} voiceName={voiceName} />}
          </div>
        </li>
      ))}
    </ul>
  </div>
)
