import { useParams } from "react-router-dom";
import { useAiChat } from "@hooks/useAiChat";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import { jsonPharseTool } from "@utils/jsonPharseTool";
import React, { useState, useEffect, useRef } from "react";
import TextInputField from "@components/CreateContent/common/TextInputField";
import TextAreaField from "@components/CreateContent/common/TextAreaField";
import Dropdown from "@components/CreateContent/common/Dropdown";
import { useUploadFileToDrive } from "@hooks/useApi";
import ImageGenerateField from "@components/CreateContent/common/ImageGenerateField";
import { AIGenerateCharacterResponse, CharacterProbs } from "@core/sdgs_types";
import { useLocation } from "react-router-dom";
import { useCache } from "@core/context/CacheContext";
import { v4 as uuidv4 } from "uuid";
import useLogger from "@hooks/useLogger";
export const useAIGenerateCharacter = () => {
  const { aiResponse, aiChat } = useAiChat();
  const [character, setCharacter] =
    useState<AIGenerateCharacterResponse | null>(null);
  const [processing, setProcessing] = useState<boolean>(false);

  const generateCharacter = (
    name: string,
    appearance: string,
    personality: string,
    wellcomeMessage?: string
  ) => {
    const systemInstruction = `
    你是個對話類型遊戲角色的創造者，請用繁體中文回答，
    遊戲的主要ＴＡ是高中生。
    遊戲是透過對話學習英語的遊戲
    你產生的遊戲角色，必須要有吸引力，不能太過於無聊，不能太過於正經，不能沒有特色。
    請以高中生會喜歡的風格來設計角色。
    例如，動漫的風格，遊戲的風格，中二的風格，小文青風格，戀愛風格等等。
    請以以下格式輸出:：
      {
        "Name": "${name}",
        "Appearance": "詳細的外觀描述",
        "TalkStyle": "詳細的人格描述，主要是說話的風格，特定的口頭禪，回應對話的價值觀等等",
        "wellcomeMessage": "歡迎詞，角色出現時會說的第一句話，需要符合以下格式：=劇情選擇= 歡迎詞 [選項1] [選項2] [選項3]。"
      }
    `;

    const userMessage =
      appearance || personality
        ? `角色名稱: ${name}, 外觀設定: ${appearance}, 人格設定: ${personality}, 歡迎詞: ${wellcomeMessage}`
        : `角色名稱: ${name}, 外觀設定: Generate a unique appearance, 人格設定: Generate a unique personality, 歡迎詞: Generate a unique wellcome message`;

    aiChat(userMessage, systemInstruction, true);
    console.log("Generate character : ", userMessage);
    setProcessing(true);
  };

  useEffect(() => {
    if (aiResponse && aiResponse !== "建議生成中...") {
      try {
        const response: AIGenerateCharacterResponse =
          jsonPharseTool(aiResponse);
        setCharacter(response);
        console.log("AI generate done response:", response);
      } catch (e) {
        console.error("Error parsing AI response:", e);
      }
      setProcessing(false);
    }
  }, [aiResponse]);

  return { character, processing, generateCharacter };
};
type CharacterGeneratorProps = {
  loadedCharacter?: CharacterProbs;
  onCharacterGenerated: (character: CharacterProbs) => void;
};

export const CharacterGenerator: React.FC<CharacterGeneratorProps> = ({
  loadedCharacter = null,
  onCharacterGenerated,
}) => {
  const { mutate } = useLogger();

  const location = useLocation();
  const sessionRef = useRef("");
  const { clearCache } = useCache();
  // 角色資料夾LearningBuddy
  const { buddyID } = useParams();
  const rootFolderId =
    process.env.REACT_APP_ROOT_FOLDER_ID || "1ZHRyYiqhzSmxUCSweGWiYXkagFS6TLGc";
  const botrunFolderID =
    process.env.REACT_APP_BOTRUN_FOLDER_ID ||
    "1OMclEQmYz4Pqgqd4P1-9wVqHDA3LHF1J";
  const [name, setName] = useState("");
  const [appearance, setAppearance] = useState("");
  const [personality, setPersonality] = useState("");
  const [welcome_msg, setWelcomeMsg] = useState("");
  const [isEditableForAll, setIsEditableForAll] = useState(false);
  const { character, processing, generateCharacter } = useAIGenerateCharacter();
  const voiceNameOptions: Record<string, string> = {
    alloy: "合金（男） - 聲音如鋼鐵般堅定，適合在你需要一些正式感時使用。",
    echo: "回音（女） - 聲音溫暖且共鳴，就像在給你一個暖心的擁抱。",
    fable:
      "寓言（女） - 她的聲音像在講故事，適合用來哄孩子睡覺，或者講個好故事。",
    onyx: "瑪瑙（男） - 聲音深沉有力，聽起來就像在發佈重要公告。",
    nova: "新星（女） - 清晰又友善，適合日常交流，就像和老朋友聊天一樣。",
    shimmer:
      "閃耀（女） - 聲音輕快明亮，就像陽光灑在你臉上，適合輕鬆愉快的內容。",
  };
  const [voiceName, setVoiceName] = useState<string>("nova");
  const [imageUrl, setImageUrl] = useState<string>("");
  const user = useRecoilValue(userAuthState);
  useEffect(() => {
    sessionRef.current = uuidv4();
    mutate({
      type: "進入關卡編輯器",
      username: user?.username,
      session_id: sessionRef.current,
    });
  }, []);
  const handleSelectionChange = (
    selectedValue: string,
    description?: string
  ) => {
    setVoiceName(selectedValue);
  };

  const {
    response,
    error: uploadError,
    loading: uplaodLoading,
    uploadFileToDrive,
  } = useUploadFileToDrive();
  useEffect(() => {
    if (loadedCharacter) {
      setName(loadedCharacter.Name);
      setAppearance(loadedCharacter.Appearance);
      setPersonality(loadedCharacter.TalkStyle);
      setWelcomeMsg(loadedCharacter.wellcomeMessage);
      setImageUrl(loadedCharacter.imageUrl);
      setVoiceName(loadedCharacter.VoiceName);
      if (loadedCharacter.IsEditableForAll != undefined) {
        setIsEditableForAll(loadedCharacter.IsEditableForAll);
      }
    }
  }, [loadedCharacter]);
  const isEditableAndUerMatch = () => {
    if (loadedCharacter === null) {
      return true;
    }

    if (loadedCharacter.Creator == undefined) {
      return true;
    }
    return isEditableForAll || loadedCharacter.Creator === user?.username;
  };
  const handleGenerate = () => {
    if (processing) {
      console.log("still processing,Skip generating character");
      return;
    }
    console.log("Generate character", name, appearance, personality);
    generateCharacter(name, appearance, personality);
  };
  const getCharacterContent = (newCharacter: AIGenerateCharacterResponse) => {
    if (newCharacter) {
      return `
      @welcome_msg("${newCharacter.wellcomeMessage}")
      <CharacterData>
      botrunId:波${newCharacter.Name}
      Name: ${newCharacter.Name}
      Appearance:${newCharacter.Appearance}
      TalkStyle: ${newCharacter.TalkStyle}
      wellcomeMessage: ${newCharacter.wellcomeMessage}
      imageUrl: ${imageUrl}
      VoiceName:${voiceName}
      Creator:${user?.username}
      IsEditableForAll:${isEditableForAll}
      </CharacterData>
      `;
    }
    return "";
  };
  const handleSave = () => {
    if (!name || !appearance || !personality) {
      console.log("missing character info, Skip saving character");
      alert("請填寫完整角色資料");
      return;
    }
    if (uplaodLoading) {
      console.log("still uploading,Skip saving character");
      return;
    }
    if (buddyID) {
      mutate({
        type: "修改角色",
        username: user?.username,
        character_name: name,
        session_id: sessionRef.current,
        location: location.pathname,
      });
    } else {
      mutate({
        type: "建立新角色",
        username: user?.username,
        character_name: name,
        session_id: sessionRef.current,
        location: location.pathname,
      });
    }

    console.log("Save character", name, appearance, personality);
    const newCharacter: AIGenerateCharacterResponse = {
      Name: name,
      Appearance: appearance,
      TalkStyle: personality,
      wellcomeMessage: welcome_msg,
    };
    const uploadBDFile = async () => {
      await uploadFileToDrive(
        getCharacterContent(newCharacter),
        newCharacter?.Name + ".bd",
        rootFolderId,
        "document",
        buddyID,
        "users/" + user?.username + "/LearningBuddy"
      );
    };
    uploadBDFile();
    /*
    const uploadBotrunFile = async () => {
      await uploadFileToDrive(
        getCharacterContent(newCharacter),
        "波" + newCharacter?.Name + ".botrun",
        botrunFolderID,
        "document"
      );
    };
    uploadBotrunFile();
    */
    clearCache();
    console.log("upload character:", getCharacterContent(newCharacter));
  };

  useEffect(() => {
    //ai生成角色後，更新角色資料
    if (character) {
      setName(character["Name"]);
      setAppearance(character["Appearance"]);
      setPersonality(character["TalkStyle"]);
      setWelcomeMsg(character["wellcomeMessage"]);
      const newCharacter: CharacterProbs = {
        Name: character["Name"],
        Appearance: character["Appearance"],
        TalkStyle: character["TalkStyle"],
        wellcomeMessage: character["wellcomeMessage"],
        imageUrl: imageUrl,
        VoiceName: voiceName,
        Creator: user?.username,
        ID: "",
      };
      onCharacterGenerated(newCharacter);
      console.log("Character generated:", character);
    }
  }, [character]);

  useEffect(() => {
    //手動更新角色資料
    const newCharacter: CharacterProbs = {
      Name: name,
      Appearance: appearance,
      TalkStyle: personality,
      wellcomeMessage: welcome_msg,
      imageUrl: imageUrl,
      VoiceName: voiceName,
      Creator: user?.username,
      ID: "",
    };

    onCharacterGenerated(newCharacter);
  }, [name, appearance, personality, welcome_msg, imageUrl, voiceName]);
  return (
    <>
      <TextInputField
        label="角色名稱"
        placeholder=""
        isRequired={true}
        helpText="請輸入最多 10 字"
        value={name}
        onChange={(value) => {
          setName(value);
        }}
      />
      <Dropdown
        label="聲音選擇"
        options={voiceNameOptions}
        defaultValue="nova"
        isRequired={true}
        onChange={handleSelectionChange}
      />
      <TextAreaField
        label="外觀設定"
        placeholder=""
        isRequired={true}
        helpText="詳細的外觀描，角色設定，名稱，性別年紀，口頭禪，說話風格等等"
        value={appearance}
        onChange={(value) => setAppearance(value)}
      />
      <TextAreaField
        label="說話風格設定"
        placeholder=""
        isRequired={true}
        helpText="角色設定，名稱，性別年紀，口頭禪，說話風格等等"
        value={personality}
        onChange={(value) => setPersonality(value)}
      />
      <TextInputField
        label="歡迎詞"
        placeholder=""
        isRequired={true}
        helpText="角色出現時會說的第一句話，需要符合以下格式：=劇情選擇= 歡迎詞 [選項1] [選項2] [選項3]"
        value={welcome_msg}
        onChange={(value) => {
          setWelcomeMsg(value);
        }}
      />
      <ImageGenerateField
        label="角色頭像"
        imageName={name}
        imageDescription={appearance}
        message={welcome_msg}
        onImagedChanged={(value) => setImageUrl(value)}
        isRequired={true}
        defaultUrl={imageUrl}
      />
      {/*  <OptionSelector
        title="編輯權限"
        options={["所有人", "只有自己"]}
        defaultOption={isEditableForAll ? "所有人" : "只有自己"}
        onSelectionChange={(selectedValue: string) => {
          if (selectedValue === "所有人") {
            setIsEditableForAll(true);
          } else {
            setIsEditableForAll(false);
          }
        }}
      /> */}
      {isEditableAndUerMatch() ? (
        <div className="form-item btn-area">
          <div className="left">
            <button
              className="btn --large"
              onClick={handleGenerate}
              disabled={processing}
            >
              {processing ? <span>生成中...</span> : <span>AI生成角色</span>}
            </button>
            <button
              className="btn --large"
              onClick={handleSave}
              disabled={uplaodLoading}
            >
              {name && appearance && personality ? (
                uplaodLoading ? (
                  <span>儲存中...</span>
                ) : (
                  <span>儲存角色</span>
                )
              ) : (
                <span>填寫後儲存角色</span>
              )}
            </button>
          </div>
          <div className="right"></div>
        </div>
      ) : (
        <span>無編輯權限</span>
      )}
    </>
  );
};
