import { LearningJourneyLog, GamePerformanceData } from "@core/sdgs_types";
import { getSecondsBetweenISOTimestamps } from "@utils/functions";

const calculateGamePerformance = (
  learningJourneyLog: LearningJourneyLog
): GamePerformanceData => {
  const { logs, gameDuration, gamestart_timestamp, gameend_timestamp } =
    learningJourneyLog;
  if (!logs || logs.length === 0) {
    console.log(
      "logs is empty 沒有logs無法計算遊戲結果",
      learningJourneyLog.logs
    );
    return {
      score: 0,
      averageAnswerTime: 0,
      gameDuration: 0,
    };
  }

  let answerDurations: number[] = [];
  let storyDurations: number[] = [];
  let score = 0;
  let scoreCount = 0;

  let qaAnswerDurations: number[] = [];
  let pronunciationAnswerDurations: number[] = [];
  let qaScores: number[] = [];
  let pronunciationScores: number[] = [];
  let pronunciationPraticCounts: number[] = [];
  let singleChoiceScores: number[] = [];
  let multipleChoiceScores: number[] = [];
  for (let i = 0; i < logs.length - 1; i++) {
    const currentLog = logs[i];
    const nextLog = logs[i + 1];
    if (currentLog.userType === "ai" && nextLog.userType === "player") {
      const duration = getSecondsBetweenISOTimestamps(
        currentLog.timestamp,
        nextLog.timestamp
      );

      if (
        nextLog.questionType === "劇情選擇" ||
        nextLog.questionType === "advice"
      ) {
        storyDurations.push(duration);
      } else {
        scoreCount++;
        score += nextLog.score || 0;
        // console.log("加總score", score, scoreCount);
        answerDurations.push(duration);

        switch (nextLog.questionType) {
          case "單選":
            singleChoiceScores.push(nextLog.score || 0);
            break;
          case "多選":
            multipleChoiceScores.push(nextLog.score || 0);
            break;
          case "問答":
            qaAnswerDurations.push(duration);
            if (nextLog.score !== undefined) qaScores.push(nextLog.score);
            break;
          case "發音":
            pronunciationAnswerDurations.push(duration);
            pronunciationPraticCounts.push(nextLog.praticeCount || 1);
            if (nextLog.score !== undefined)
              pronunciationScores.push(nextLog.score);
            break;
        }
      }
    }
  }

  const calculateAverage = (arr: number[]): number | null =>
    arr.length > 0 ? arr.reduce((sum, val) => sum + val, 0) / arr.length : null;

  const averageAnswerTime = calculateAverage(answerDurations);
  const averageStoryTime = calculateAverage(storyDurations);
  const averageQATime = calculateAverage(qaAnswerDurations);
  const averagePronunciationTime = calculateAverage(
    pronunciationAnswerDurations
  );
  const averageQAScore = calculateAverage(qaScores);
  const averagePronunciationScore = calculateAverage(pronunciationScores);
  const averagePronunciationPraticCount = calculateAverage(
    pronunciationPraticCounts
  );
  const totalTime =
    gameDuration ||
    (gameend_timestamp && gamestart_timestamp
      ? getSecondsBetweenISOTimestamps(gamestart_timestamp, gameend_timestamp)
      : 0);

  let result: GamePerformanceData = {
    score: scoreCount > 0 ? Math.floor(score / scoreCount) : 0,
    gameDuration: totalTime,
    averageAnswerTime: averageAnswerTime !== null ? averageAnswerTime : 0,
  };
  if (averageStoryTime !== null) result.averageStoryTime = averageStoryTime;
  if (averageQATime !== null) result.averageQATime = averageQATime;
  if (averagePronunciationTime !== null)
    result.averagePronunciationTime = averagePronunciationTime;
  if (averageQAScore !== null) result.averageQAScore = averageQAScore;
  if (averagePronunciationScore !== null)
    result.averagePronunciationScore = averagePronunciationScore;
  if (averagePronunciationPraticCount !== null)
    result.averagePronunciationPraticCount = averagePronunciationPraticCount;

  return result;
};

export default calculateGamePerformance;
