import React, { useEffect } from "react";
import SimplePopup from "../GeneralComponent/SimplePopup";
import { Avatar } from "./AdviceAvatar";
import { Advice, ExplanationData } from "./Advice";
import { responseType } from "../AiAdvicePopup/QuestionAdvicePopup";
import { useAiOutputGenerator } from "../../hooks/useAiOutputGenerator";
import { useTranslation } from "react-i18next";
interface WritingAdviceProps {
  avatarUrl: string;
  subject: string;
  rule: string;
  description: string;
  writingText: string;
  isPopupVisible: boolean;
  setIsPopupVisible: (isVisible: boolean) => void;
  voiceName: string;
}

const WritingdvicePopup: React.FC<WritingAdviceProps> = ({
  avatarUrl,
  subject,
  rule,
  description,
  writingText,
  isPopupVisible,
  setIsPopupVisible,
  voiceName,
}) => {
  const { t } = useTranslation();
  const defaultAdvice: ExplanationData = {
    example: [],
    translation: [],
    explanation: t("WaitAdvice"),
  };
  const { result, generateOutput } = useAiOutputGenerator<ExplanationData>({
    defaultSystemPrompt: `是一位針對台灣的高中生程度進行英語教學的輔導助手，注意，請直接生成解釋與例句與例句翻譯，不需要輸出其他多餘的文字。
      目的是鼓勵使用者無痛的學英語。
      請用繁體中文來說明解釋，例句的英文要夠簡單，簡潔，請根據，目前的提問問問題，先有解釋，再給予使用者建議的英文練習例句內容，例句數量在兩句即可，最多不要超過三句。
`,
    globalConfigPromptKey: "WritingAdvicePrompt",
    responseType,
  });

  useEffect(() => {
    if (isPopupVisible === false) {
      return;
    }
    console.log("更新 writingText advice", writingText);
    console.log("更新 subject advice", subject);
    if (subject && writingText) {
      const userInput = `寫作的主題：${subject}, 寫作的描述：${description}, 寫作的規則：${rule} 目前作文的進度： ${writingText}, 請問下一句該如何寫？`;
      console.log("讓ai給你建議：userInput", userInput);
      generateOutput(userInput);
    }
  }, [subject, writingText, isPopupVisible]);

  return (
    <>
      <SimplePopup
        isPopup={isPopupVisible}
        setIsPopup={setIsPopupVisible}
        popupClassName="for-dialogue"
      >
        <div className="dialogue-container">
          <div className="container">
            <Advice advice={result ? result : defaultAdvice} />
          </div>
        </div>
        <Avatar url={avatarUrl} />
      </SimplePopup>
    </>
  );
};

export default WritingdvicePopup;
