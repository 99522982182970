import { MenuOption } from "@core/sdgs_types";
import { useListGameMenu } from "@core/hooks/useApi"; // 確保路徑是正確的
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import MenuBTN from "@components/CreateContent/CreatorNavi/MenuItems/CreatorMenuBTN";
import AnimatedBG from "@components/GeneralComponent/AnimatedBG";
import NaviMenu from "@components/CreateContent/CreatorNavi/CreatorNaviMenu";
import { useRecoilState } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import MetaTagsProps from "@components/SEOTags/MetaTages";

import Overlay from "@components/Overlay";
// 讀取學習夥伴目錄
export default function MenuBuddies() {
  const [user] = useRecoilState(userAuthState);
  const navigate = useNavigate();
  // 指定的資料夾 ID 或 URL 和最大文件數量
  const rootFolderId =
    process.env.REACT_APP_ROOT_FOLDER_ID || "1ZHRyYiqhzSmxUCSweGWiYXkagFS6TLGc";

  // 使用 useState 来模拟数据的加载状态，未來可以改为使用 useQuery

  const { gameMenu, error, loading } = useListGameMenu(
    rootFolderId,
    ".bd",
    user?.username + "/LearningBuddy"
  );

  const onBuddyOptionClick = (option: MenuOption) => {
    console.log(`buddy ${option.name} clicked!`);
    ReactGA.event({
      category: "學習夥伴編輯",
      action: option.name,
    });

    console.log("選擇了 option.id", option.id);
    navigate(`/createLevel/bds/b/${option.id}`);
  };
  if (loading) return <Overlay show={true} />;
  return (
    <div className="game-container">
      <MetaTagsProps
        title="immersive AI學習夥伴編輯器"
        description="創造屬於你自己的ＡＩ學伴"
      />
      <div className="game-stage-container">
        <div className="game-logo">
          <h1>immerse</h1>
          <p>編輯你的學習夥伴</p>
        </div>
        <NaviMenu />
        <ul className="game-menu">
          {gameMenu.map((option) => (
            <li key={option.id}>
              <MenuBTN option={option} menuCallBack={onBuddyOptionClick} />
            </li>
          ))}
          <li key="建立新角色">
            <MenuBTN
              option={{ id: "", name: "建立新角色" }}
              menuCallBack={onBuddyOptionClick}
            />{" "}
          </li>
        </ul>
      </div>
      <AnimatedBG />
    </div>
  );
}
