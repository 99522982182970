export enum GameType {
  自由口說 = "自由口說",
  漸進學習 = "漸進學習",
  口說練習 = "口說練習",
  時代角色對談 = "時代角色對談",
  秘境逃脫快問快答 = "秘境逃脫快問快答",
  願景圖卡生成 = "願景圖卡生成",
  植樹遊戲 = "植樹遊戲",
  日語學習 = "日語學習",
  心理測驗 = "心理測驗",
  寫作接龍 = "寫作接龍",
}
export const stringToGameType = (gameType: string): GameType => {
  return GameType[gameType as keyof typeof GameType];
};

interface GameConfig {
  description: string;
  additionalPrompt: string;
  runtimePrompt: string;
  fileExtension: string;
  isFixQuestion: boolean; // 更新屬性名稱
  tabs: string;
}

export const gameConfigs: Record<GameType, GameConfig> = {
  [GameType.自由口說]: {
    description: "情境式對談練習，開放回答，沒有正確答案",
    additionalPrompt: "FreeConversationGameAdditionalPrompt",
    runtimePrompt: "GameFlow_FreeConversation",
    fileExtension: ".talk_game",
    isFixQuestion: false,
    tabs: "freeFormTabs",
  },
  [GameType.漸進學習]: {
    description: "漸進式學習，從簡單到困難, 會紀錄過去學過的學習記憶點",
    additionalPrompt: "FreeConversationGameAdditionalPrompt",
    runtimePrompt: "GameFlow_FreeConversation",
    fileExtension: ".talk_game",
    isFixQuestion: false,
    tabs: "freeFormTabs",
  },
  [GameType.口說練習]: {
    description: "SGGs次主題一：口說單字、片語發音",
    additionalPrompt: "ConversationGameAdditionalPrompt",
    runtimePrompt: "GameFlow_Conversation",
    fileExtension: ".talk_game",
    isFixQuestion: true,
    tabs: "fixQuestionTabs",
  },
  [GameType.時代角色對談]: {
    description: "SGGs次主題二，口說練習＋單字選擇",
    additionalPrompt: "StoryGameAdditionalPrompt",
    runtimePrompt: "GameFlow_StoryGame",
    fileExtension: ".talk_game",
    isFixQuestion: true,
    tabs: "fixQuestionTabs",
  },
  [GameType.秘境逃脫快問快答]: {
    description: "次SGGs主題三，單字選擇＋口說練習",
    additionalPrompt: "EscapeGameAdditionalPrompt",
    runtimePrompt: "GameFlow_EscapeGame",
    fileExtension: ".talk_game",
    isFixQuestion: true,
    tabs: "fixQuestionTabs",
  },
  [GameType.願景圖卡生成]: {
    description: "SGGs次主題四，寫作單字片語",
    additionalPrompt: "CardGameAdditionalPrompt",
    runtimePrompt: "GameFlow_CardGame",
    fileExtension: ".card_game",
    isFixQuestion: true,
    tabs: "fixQuestionTabs",
  },
  [GameType.植樹遊戲]: {
    description: "植樹教育遊戲",
    additionalPrompt: "TreeGameAdditionalPrompt",
    runtimePrompt: "GameFlow_Tree",
    fileExtension: ".talk_game",
    isFixQuestion: true,
    tabs: "fixQuestionTabs",
  },
  [GameType.日語學習]: {
    description: "日語學習遊戲",
    additionalPrompt: "JapaneseGameAdditionalPrompt",
    runtimePrompt: "GameFlow_Japanese",
    fileExtension: ".talk_game",
    isFixQuestion: true,
    tabs: "fixQuestionTabs",
  },
  [GameType.心理測驗]: {
    description: "心理測驗遊戲",
    additionalPrompt: "PsychologyGameAdditionalPrompt",
    runtimePrompt: "GameFlow_RandomQuestion",
    fileExtension: ".talk_game",
    isFixQuestion: true,
    tabs: "fixQuestionTabs",
  },
  [GameType.寫作接龍]: {
    description: "寫作接龍遊戲",
    additionalPrompt: "WriteGameAdditionalPrompt",
    runtimePrompt: "GameFlow_FixQuestion",
    fileExtension: ".write_game",
    isFixQuestion: false,
    tabs: "writeGameTabs",
  },
};
export const gameOptions: Record<string, string> = (() => {
  const options: Record<string, string> = {};
  for (const [key, config] of Object.entries(gameConfigs)) {
    options[key] = config.description;
  }
  return options;
})();
export const freeFormGameTypes: string[] = (() => {
  const types: string[] = [];
  for (const [key, config] of Object.entries(gameConfigs)) {
    if (config.isFixQuestion === false) {
      types.push(key);
    }
  }
  return types;
})();
export const additionalGenerationPrompName: Record<string, string> = (() => {
  const options: Record<string, string> = {};
  for (const [key, config] of Object.entries(gameConfigs)) {
    options[key] = config.additionalPrompt;
  }
  return options;
})();
export const runtimeGamePromptName: Record<string, string> = (() => {
  const options: Record<string, string> = {};
  for (const [key, config] of Object.entries(gameConfigs)) {
    options[key] = config.runtimePrompt;
  }
  return options;
})();

export const getFileExtensionNameByType = (gameType: string): string => {
  if (gameType in gameConfigs) {
    return gameConfigs[gameType as GameType].fileExtension;
  }
  throw new Error(`Invalid game type: ${gameType}`);
};

export const LearningLevelPrompt: Record<string, string> = {
  A2: "CEFR A2 - 初學者：在出題與回應的時候請只要使用基本日常詞彙（1000-1500），簡單句型（現在時、過去時、未來時），主題要限制在簡單日常交流, 出題的時候可以使用中英文夾雜，讓學生不害怕閱讀與回答",
  B1: "CEFR B1 - 中級：更多詞彙（2500-3000），使用完整句型（現在完成時、條件句），大部分旅遊生活等等的情境，請大多使用完整英文句子進行出題與回應, 在比較困難的題目中可以使用中英文加雜讓學生容易理解",
  B2: "CEFR B2 - 中高級：較廣泛詞彙，複雜句型（被動語態、間接引語），流利日常和專業交流，請完全使用英文句子進行出題與回應",
  C1: "CEFR C1 - 高級：廣泛詞彙，高度複雜句型，靈活應對各種情況，請完全使用英文句子進行出題與回應",
  Adaptive:
    "自適應：根據玩家表現調整難度，請隨著玩家答題的成功率提升，逐漸提高英語對話的難度，或是相反的降低難度配合玩家的程度。最低的程度，可以以台灣國小四年級的學生程度為主，使用中英文夾雜的方式進行出題與回應",
};
