import React, { useRef } from "react";
import { useEffect } from "react";
interface CardProps {
  imageUrl: string;
}

const ImageCard: React.FC<CardProps> = ({ imageUrl }) => {
  const cardRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const throttle = (func: any, limit: number) => {
      let inThrottle: boolean;
      return function (this: any) {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => (inThrottle = false), limit);
        }
      };
    };

    const handleMouseMove = (e: MouseEvent) => {
      const centerX = window.innerWidth / 2;
      const centerY = window.innerHeight / 2;
      const x = e.clientX;
      const y = e.clientY;

      const rotateX = (centerY - y) * (20 / centerY);
      const rotateY = (x - centerX) * (20 / centerX);

      if (cardRef.current) {
        cardRef.current.style.transform = `rotateX(${rotateX}deg) rotateY(${rotateY}deg)`;
      }
    };

    document.body.addEventListener("mousemove", throttle(handleMouseMove, 10));

    return () => {
      document.body.removeEventListener(
        "mousemove",
        throttle(handleMouseMove, 10)
      );
    };
  }, []);
  return (
    <div className="text-holder">
      <div className="scene">
        <div className="card" ref={cardRef}>
          <div className="gen-card">
            <div className="content">
              <div
                className="img"
                style={{ backgroundImage: `url(${imageUrl})` }}
              ></div>
              <div className="text">獨佔圖片</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageCard;
