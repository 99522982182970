import { useCallback, useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import useLogger from "@hooks/useLogger";
import { request } from "@utils/axios-util";
import { AxiosResponse, AxiosError } from "axios";
import { LearningJourneyLog } from "@core/sdgs_types";
import { v4 as uuidv4 } from "uuid";
interface LevelPlayMemory {
  timestamp: number;
  learnedContent: string[];
  mistakes: string[];
  score?: number;
  timeSpent: number; // 以秒為單位
}
interface LevelStatistics {
  totalPlayCount: number;
  averageScore?: number;
  totalTimeSpent: number;
}
export interface UserLevelMemoryLog {
  type: "關卡記憶";
  gameId: string;
  gameType: string;
  log_sn: string;
  // 關卡統計資料
  statistics: LevelStatistics;

  // 關卡記憶
  playMemories: LevelPlayMemory[];
}
interface GetLevelMemoryResponse {
  is_success: boolean;
  data: UserLevelMemoryLog[];
}

export const useUserLevelMemory = (gameId?: string) => {
  const { mutate } = useLogger();
  const [levelMemoryData, setLevelMemoryData] =
    useState<UserLevelMemoryLog | null>(null);

  // 使用 useMemo 保存查詢結果
  const levelMemory = useMemo(() => levelMemoryData, [levelMemoryData]);

  // 將查詢邏輯封裝成函數
  const fetchLevelMemory = useCallback(async () => {
    console.log("執行fetchLevelMemory", gameId);
    if (!gameId) return;

    try {
      const response = await request<GetLevelMemoryResponse>({
        url: "/game/log",
        method: "get",
        params: {
          query: JSON.stringify({
            gameId: gameId,
            type: "關卡記憶",
          }),
        },
      });

      let result: UserLevelMemoryLog;

      if (response.data.is_success) {
        console.log("獲取關卡記憶:", response.data);
        result = response.data.data[0];
      } else {
        console.log("沒有歷史記錄，返回初始結構");
        result = {
          type: "關卡記憶",
          gameId: gameId,
          gameType: "",
          statistics: {
            totalPlayCount: 0,
            averageScore: 0,
            totalTimeSpent: 0,
          },
          log_sn: uuidv4(),
          playMemories: [],
        };
      }

      setLevelMemoryData(result);
      return result;
    } catch (error) {
      console.error("獲取關卡記憶失敗:", error);
      throw error;
    }
  }, [gameId]);

  // 記錄新的關卡記憶
  const recordLevelMemory = useCallback(
    (
      gameId: string,
      gameType: string,
      learningJourneyLog: LearningJourneyLog
    ) => {
      // 合併現有記憶與新記憶
      const learnedContent = learningJourneyLog.logs.reduce(
        (acc: string[], log, index) => {
          if (
            log.userType === "ai" &&
            index < learningJourneyLog.logs.length - 1 &&
            log.questionType !== "劇情選擇" &&
            log.questionType !== "advice" &&
            log.questionType !== "發音" &&
            index + 1 < learningJourneyLog.logs.length &&
            learningJourneyLog.logs[index + 1].isCorrect === true
          ) {
            acc.push(
              `題目: ${log.content}, 回答: ${
                learningJourneyLog.logs[index + 1].content
              }`
            );
          }
          return acc;
        },
        []
      );
      const mistakes = learningJourneyLog.logs.reduce(
        (acc: string[], log, index) => {
          if (log.isCorrect === false && index > 0) {
            const question = learningJourneyLog.logs[index - 1].content;
            const wrongAnswer = log.content;
            acc.push(`題目: ${question}, 錯誤回答: ${wrongAnswer}`);
          }
          return acc;
        },
        []
      );
      const newLevelPlayMemory: LevelPlayMemory = {
        timestamp: Date.now(),
        learnedContent: learnedContent,
        mistakes: mistakes,
        score: learningJourneyLog.finalScore,
        timeSpent: learningJourneyLog.gameDuration || 0,
      };
      const newMemory: UserLevelMemoryLog = {
        type: "關卡記憶",
        gameId: gameId || "",
        gameType: gameType || "",
        log_sn: levelMemory?.log_sn || uuidv4(),
        statistics: {
          totalPlayCount: (levelMemory?.statistics.totalPlayCount || 0) + 1,

          averageScore: calculateNewAverage(
            levelMemory?.statistics.averageScore || 0,
            levelMemory?.statistics.totalPlayCount || 0,
            learningJourneyLog.finalScore || 0
          ),
          totalTimeSpent:
            (levelMemory?.statistics.totalTimeSpent || 0) +
            (learningJourneyLog.gameDuration || 0),
        },

        playMemories: [
          ...([newLevelPlayMemory] || []),
          ...(levelMemory?.playMemories || []),
        ].slice(0, 50), // 只保留最近50筆記錄
      };

      console.log("記錄關卡學習記憶", newMemory);
      mutate(newMemory);
    },
    [levelMemory, mutate]
  );
  // 整理所有學習記錄和錯誤
  const getLearnedAndMistakes = useCallback(() => {
    if (!levelMemory?.playMemories?.length) {
      return "";
    }

    // 收集所有不重複的學習內容
    const allLearned = new Set<string>();
    levelMemory.playMemories.forEach((memory) => {
      memory.learnedContent?.forEach((content) => allLearned.add(content));
    });

    // 收集最後一次的錯誤
    const lastMistakes = levelMemory.playMemories[0]?.mistakes || [];
    const learnedSummary =
      allLearned.size > 0
        ? `過去學習的紀錄，過去已經學會的知識點包涵：${Array.from(
            allLearned
          ).join("、")}`
        : "";
    const mistakesSummary =
      lastMistakes.length > 0
        ? `上一次學習的錯誤點有：${lastMistakes.join("、")}`
        : "";
    return learnedSummary + mistakesSummary;
  }, [levelMemory]);
  return {
    levelMemory,
    fetchLevelMemory,
    recordLevelMemory,
    getLearnedAndMistakes,
  };
};

// 計算新的平均分數
function calculateNewAverage(
  currentAverage: number,
  currentCount: number,
  newScore: number
): number {
  if (!newScore) return currentAverage;
  const newTotal = currentAverage * currentCount + newScore;
  return Number((newTotal / (currentCount + 1)).toFixed(2));
}
