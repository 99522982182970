import useLoadGeneralData from "@core/hooks/LoadData/useLoadGeneralData";
import { CharacterProbs } from "@core/sdgs_types";
import { aiBuddyPropsTags, CharacterDataLabels } from "@core/sdgs_types";
import { useEffect } from "react";

const useLoadAvatar = (
  avatarId: string
): { characterData: CharacterProbs; loading: boolean; error: any } => {
  const {
    gameData: characterData,
    loading,
    error,
    loadData,
  } = useLoadGeneralData<CharacterProbs>();
  useEffect(() => {
    if (avatarId && avatarId !== "" && avatarId !== "undefined") {
      //console.log("資料中有包含avatarId, Load character data for AI buddy");
      loadData(avatarId, aiBuddyPropsTags, CharacterDataLabels);
    } else {
      console.log("資料中沒有包含avatarId");
    }
  }, [avatarId, loadData]);

  return {
    characterData: characterData || ({} as CharacterProbs),
    loading,
    error,
  };
};
export default useLoadAvatar;
