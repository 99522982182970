import { useRecoilValue } from 'recoil';
import useSpeechToText from '@hooks/useSpeechToText';
import { userAuthState } from '@core/recoil/atoms';

const useHandleSpeechToText = () => {
  const user = useRecoilValue(userAuthState);
  const { mutate: mutateSpeechToText } = useSpeechToText();

  const toBase64String = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        const base64String = reader.result?.toString().replace(/^data:[^,]*,/, '') || '';
        resolve(base64String);
      });
      reader.readAsDataURL(file);
    });
  };

  return async (file: File): Promise<string> => {
    const audioData = await toBase64String(file);
    const data = {
      audio_data: audioData
    };

    return new Promise<string>((resolve, reject) => {
      mutateSpeechToText(data, {
        onSuccess: res => {
          resolve(res.data.transcribe_audio_to_text);
        },
        onError: (error: any) => {
          console.log(error.message);
          resolve('');
        },
      });
    });
  };
};

export default useHandleSpeechToText;