import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { request } from "@utils/axios-util"
import { AvatarProps } from "@utils/types"

export default function useAvatar() {
  const queryFn = () =>
    request({
      url: `/game/avatar/list`,
      method: "get",
    })

  return useQuery(["fetch-avatar-list-api"], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error)
    },
    select: (res: AxiosResponse): AvatarProps[] => {
      return res.data
    },
  })
}
