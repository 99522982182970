import { Question, CharacterProbs, CompleteAIGameData } from "@core/sdgs_types";

export const generateAdvice = (
  coversations: string[], //coversations是要與玩家對話的內容，要以角色扮演的語氣
  nextQuestion: Question | null,
  currentQuestion: Question,
  userAnswer: string,
  forceToNextQuestion: boolean = false,
  characterData: CharacterProbs,
  gameData: CompleteAIGameData,
  advicePrompt: string,
  aiChat: (userMessage: string, systemInstruction: string) => Promise<void>
) => {
  let characterPrompts = "";
  const historyMessage = coversations.join(";");
  if (characterData) {
    characterPrompts = `你將會角色扮演的方式與玩家進行對談，所以口語講話的語氣與口頭禪要符合以下角色的設定：
  <角色設定>
  ${characterData?.Name}，${characterData?.Appearance}，${characterData?.TalkStyle}。
  </角色設定>
  請注意，請不要講出角色的wellcome message。這是給你參考的角色設定用的。
  `;
  }

  let questionPrompts = "";
  if (forceToNextQuestion) {
    if (nextQuestion) {
      questionPrompts = `請message中，告訴玩家,下一題的題目是：${nextQuestion.question}，`;
    } else {
      questionPrompts = `請不要再同一題上繼續練習了，直接換下個問題吧！`;
    }
  }

  let systemPrompt =
    advicePrompt ||
    `你是一位英語遊戲小助手，請用繁體中文回答，玩家會根據問題進行回答，如果玩家的回答在英語的語言學習上是有問題的，例如文法錯誤單字錯誤等等，不用太過於嚴肅，如果答案有接近，就可以給對。
  如果玩家有小的英文錯誤，也可以放過讓他成功。
  請給予幽默有趣的簡短的改進建議，如果玩家回答的正確，給予有趣的鼓勵。
  如果玩家回答錯誤，請給予簡短有趣的提示，讓玩家可以學習到正確的答案。
  如果是開放式回答，或是跟語言學習無關的題目類型，請回應有趣的互動應，並給予success。
  `;

  if (gameData.AIGameData.customPrompts.answerAdvicePrompt) {
    systemPrompt = gameData.AIGameData.customPrompts.answerAdvicePrompt;
  }

  const levelDataPrompts = `
  角色扮演的遊戲的背景資訊如下：
  <遊戲背景>
  遊戲名稱：${gameData.IntroductionProps.title}
  sub_title：${gameData.IntroductionProps.subtitle}
  遊戲說明：${gameData.IntroductionProps.description}
  遊戲劇情：${gameData.levelData?.story}
  </遊戲背景>

  請以以下json格式輸出:
   {
     "dialogue": "",  //dialogue是要與玩家對話的內容，要以角色扮演的語氣
     "success": boolean,
     "score": number, //score是百分制。
     "advice": "", //advice是純粹學習簡短建議。
 }`;

  const systemInstruction = `${characterPrompts}\n${questionPrompts}\n${systemPrompt}\n${levelDataPrompts}`;
  let questionPromps = `目前玩家的題目是： ${currentQuestion.question},題目的型態是：${currentQuestion.type},
  如果是多選題，玩家的回答必須要有所有答案才正確，如果沒有，請給予錯誤的評分，並且給予零分。`;
  if (currentQuestion.answer) {
    console.log("題目的正確答案是：", currentQuestion.answer);
    questionPromps += `題目的正確答案是： ${currentQuestion.answer},`;
  }
  if (currentQuestion.options) {
    questionPromps += `可選的選項有： ${currentQuestion.options.join(",")},`;
  }
  const userMessage =
    questionPromps +
    `\n玩家這次的回答是： ${userAnswer}。僅供參考用，過去歷史對話紀錄：${historyMessage}，`;

  aiChat(userMessage, systemInstruction);
};
