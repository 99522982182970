import React, { useMemo } from "react";

import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";

import { PlayerInfo } from "@core/sdgs_types";

export interface ScoreboardProps {
  players: PlayerInfo[];
  totalScore: Record<string, number>;
  currentRoundScore: Record<string, number>;
  isActive: boolean;
  timeLeft: number;
  answeredPlayers: Record<string, boolean>;
  currentRound?: number;
}

const MultiplayerScoreboard: React.FC<ScoreboardProps> = ({
  players,
  totalScore,
  currentRoundScore,
  isActive,
  timeLeft,
  answeredPlayers,
  currentRound = 1,
}) => {
  const user = useRecoilValue(userAuthState);

  interface PlayerState {
    status: "correct" | "wrong" | null;
    scoreChange: number;
  }
  const checkAnswerClassName = (playername: string) => {
    if (playername in answeredPlayers) {
      return "--active";
    }
    return "";
  };
  const renderPlayerStatus = (player: PlayerState) => {
    if (player.status === null) return null;

    return (
      <div className={`status status-${player.status}`}>
        <i
          className={`icon icon-${
            player.status === "correct" ? "33" : "34"
          } --small`}
        ></i>
        <span>{player.status === "correct" ? "正確" : "答錯了"}</span>
        <span
          className={
            player.scoreChange >= 0 ? "score-increase" : "score-decrease"
          }
        >
          {player.scoreChange >= 0
            ? `+${player.scoreChange}`
            : player.scoreChange}
        </span>
      </div>
    );
  };
  return (
    <div
      className={`game-multi-area ${isActive ? "--active" : ""}`}
      data-flowaction="256"
    >
      <div className="container">
        <div className="multi-billboard  --separate">
          {/* <div className="countdown">
            <span>Round {currentRound}</span>
            <div className="count-num" data-count>
              {timeLeft}
            </div> 
          </div>*/}
          <div className="contestants" data-contestants>
            {players.map((player, index) => (
              <div
                className={`item  ${
                  player.username == user?.username ? "--self" : ""
                }`}
              >
                <div className="avatar">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url('${player.avatarUrl}')`,
                    }}
                  ></div>
                </div>
                <div className="text-holder">
                  <span className="name">
                    {" "}
                    {player.nickname +
                      (player.username in answeredPlayers
                        ? "..完成"
                        : "..答題中")}
                  </span>
                  <span className="grade">
                    {totalScore ? totalScore[player.username] || 0 : 0}
                  </span>
                  <span
                    className={`score ${checkAnswerClassName(player.username)}`}
                  >
                    {currentRoundScore[player.username] || 0}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MultiplayerScoreboard;
