import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useGetRoomPlayers, useLeaveRoom } from "@core/hooks/useMultiGameApis";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import { PlayerInfo } from "@core/sdgs_types";
const SOCKET_URL = process.env.REACT_APP_WEB_SOCKET_URL + "/sdgs/multi/ws";
interface SystemData {
  code: string;
  [key: string]: any;
}
type userMessageSet = {
  content: string;
  username: string;
};

const useMultiGameUtils = () => {
  const { gameType, gameId, roomId } = useParams();
  const navigate = useNavigate();
  const [messageHistory, setMessageHistory] = useState<string[]>([]);
  const [lastUserMessage, setLastUserMessage] = useState<userMessageSet>({
    content: "",
    username: "",
  });
  const [participants, setParticipants] = useState<PlayerInfo[]>([]);
  const { data: roomPlayersData, refetch: getRoomPlayers } =
    useGetRoomPlayers(gameId, roomId);
  const { refetch: leaveGame } = useLeaveRoom(gameId, roomId, true);
  const user = useRecoilValue(userAuthState);

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    `${SOCKET_URL}/${gameId}/${roomId}/${user?.username}/game`,
    {
      /* 
      heartbeat: {
        message: JSON.stringify({ type: "ping" }),
        returnMessage: JSON.stringify({ type: "pong" }),
        interval: 5000, // every 5 seconds, a ping message will be sent
      }, */
      shouldReconnect: (closeEvent) => {
        // 自定義自動重連邏輯
        return true;
      },
      reconnectAttempts: 10, // 最大重連次數
      reconnectInterval: 3000, // 重連間隔時間 (毫秒)
    }
  );

  const naviQuitWaiting = () => {
    if (gameType) {
      navigate(`/g/${gameType}/${gameId}/room`);
    } else {
      console.log("gameType is undefined，使用gameId");
      navigate(`/g/${gameId}/room`);
    }
  };

  const handleSystemMessage = (systemData: SystemData) => {
    let message = "";
    switch (systemData.code) {
      case "system_player_join":
        getRoomPlayers();
        message = `${systemData.data} joined room!`;
        break;
      case "system_player_leave":
        getRoomPlayers();
        message = `${systemData.data} left room!`;
        alert("Player left room! Game will be terminated.");
        naviQuitWaiting();
        break;
    }
    return message;
  };

  useEffect(() => {
    if (lastMessage !== null) {
      const parsedData = JSON.parse(lastMessage.data);
      if (parsedData.system) {
        const showMessage = handleSystemMessage(parsedData.system);
        setMessageHistory((prev) => [...prev, showMessage]);
        return;
      }
      if (parsedData.message) {
        const msg = `${parsedData.sender_id}: ${parsedData.message}`;
        setMessageHistory((prev) => [...prev, msg]);
        setLastUserMessage({
          content: parsedData.message,
          username: parsedData.sender_id,
        });
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    console.log("[useMultiGameUtils] roomPlayersData", roomPlayersData);
    if (roomPlayersData?.is_success) {
      const isUserInRoom = (
        roomPlayersData.players as { username: string }[]
      ).some((player) => player.username === user?.username);

      if (!isUserInRoom) {
        alert("You are not in the room!");
        naviQuitWaiting();
      } else {
        //測試用，自己加入了avatarUrl
        const testUserInfo = roomPlayersData.players.map(
          (
            player: {
              username: string;
              nickname: string;
              avatar: {
                image: string;
              };
            },
            index: number
          ) => ({
            username: player.username,
            nickname: player.nickname,
            avatarUrl:
              player.avatar.image ||
              `../../../../images/avatar/${
                index === 0 ? "avatar.png" : `avatar-${index + 1}.png`
              }`,
          })
        );

        setParticipants(testUserInfo);
      }
    }

    if (!roomPlayersData?.is_success) {
      if (roomPlayersData?.error_code === "err_room_not_found") {
        alert("[useMultiGameUtils] Room not exist!");
        naviQuitWaiting();
      }
    }
  }, [roomPlayersData]);

  useEffect(() => {
    if (roomId) {
      console.log("[useMultiGameUtils] getRoomPlayers", roomId);
      getRoomPlayers();
    }
  }, [roomId]);

  useEffect(() => {
    // 監控 readyState 的變化
    if (readyState === ReadyState.CLOSED) {
      console.log("[useMultiGameUtils] WebSocket 連線已斷開");
    } else if (readyState === ReadyState.OPEN) {
      console.log("[useMultiGameUtils] WebSocket 連線已建立");
    } else if (readyState === ReadyState.CONNECTING) {
      console.log("[useMultiGameUtils] WebSocket 連線中...");
    }
  }, [readyState]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      console.log("[useMultiGameUtils] handleBeforeUnload");
      event.preventDefault();
      event.returnValue = ""; // 標準瀏覽器需要這行代碼
      leaveGame();
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      console.log("[useMultiGameUtils] removeEventListener");
      window.removeEventListener("beforeunload", handleBeforeUnload);
      console.log("[useMultiGameUtils] leaveGame");
      leaveGame();
    };
  }, []);

  const handleSendMessage = (message: string) => {
    const jsonMessage = {
      user_input: message,
      jwt_token: user?.token,
    };

    sendMessage(JSON.stringify(jsonMessage));
    setMessageHistory((prev) => [...prev, message]);
  };

  const handleLeaveRoom = async () => {
    const { data } = await leaveGame();
    if (!data?.is_success) {
      if (data?.error_code === "err_room_not_found") {
        alert("[useMultiGameUtils] handleLeaveRoom / Room not exist!");
      }
    }
    naviQuitWaiting();
  };

  return {
    readyState,
    participants,
    handleSendMessage,
    handleLeaveRoom,
    messageHistory,
    lastUserMessage,
  };
};

export default useMultiGameUtils;

/* demo of how to use this hook
 const { isMultiplayerGame, participants, handleSendMessage, handleLeaveRoom, messageHistory } = useMultiGameUtils();
*/
