import React, { useEffect, useState } from "react";
import { useTextToSpeech } from "@core/hooks/useApi";
import SimpleAudioPlayer from "./SimpleAudioPlayer"; // 確保導入路徑正確
import { use } from "i18next";
interface TextAudioBtnProps {
  text: string;
  voiceName?: string;
}
// 這個元件是一個按鈕，載入的時候，會先做text 2 voice, 之後 出現點擊按鈕，點擊後會將文字轉換為語音播放
const TextToAudioBtn: React.FC<TextAudioBtnProps> = ({
  text,
  voiceName = "nova",
}) => {
  const { voiceUrl, hint, error, loading, text2voice } = useTextToSpeech();

  useEffect(() => {
    console.log("TextToAudioBtn建立語音按鈕:", text);
    text2voice(text, voiceName);
  }, []);

  useEffect(() => {
    console.log("TextToAudioBtn 語音更新:", voiceUrl);
  }, [voiceUrl]);

  return (
    <div>
      {voiceUrl ? <SimpleAudioPlayer audioUrl={voiceUrl} /> : <div>{hint}</div>}
    </div>
  );
};

export default TextToAudioBtn;
