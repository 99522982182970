import { useState, useEffect, useCallback, useRef } from "react";
import GameInputArea, {
  input_mode,
} from "@components/GeneralComponent/GameInputArea";
import { truncateString } from "@utils/functions";
import MetaTagsProps from "@components/SEOTags/MetaTages";
import useHandleSpeechToText from "@hooks/useHandleSpeechToText";
import BotChatItem from "@components/GeneralComponent/BotChatItem";
import SelfChatItem from "@components/GeneralComponent/SelfChatItem";
import { useGenerateImage } from "@hooks/useApi";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import QuestionAdvicePopup from "@components/AiAdvicePopup/QuestionAdvicePopup";
import useUserInput from "@components/OneChat/useUserInput";
import { useTranslation } from "react-i18next";
import { promptsState } from "@core/recoil/sdgs_atoms";
import { useSearchParams } from "react-router-dom";
import useTalkGameCommunication from "../TalkGame/hooks/useTalkGameCommunication";
import ElsaPlugin from "@core/components/ImmersiveMenu/Game/ElsaModule/ElsaPlugin";
import { useSound } from "@core/context/SoundContext";
import { QuestionTypeEnum } from "../TalkGame/types";
import FixQuestionGameEnd from "../TalkGame/GameEnd/FixQuestionGameEnd";
import Overlay from "@components/Overlay";
import {
  CompleteAIGameData,
  LearningJourneyLog,
  PronunciationResult,
} from "@core/sdgs_types";
import { UserResult } from "../TalkGame/types";
import { useGamePerformance } from "../TalkGame/useGamePerformance";
import { useLearningJourney } from "../TalkGame/useLearningJourneyHandler";
interface AdaptiveTalkGameProps {
  CompleteAIGameData: CompleteAIGameData;
  onEnd: () => void;
  onScored: (score: any) => void;
  leaningJourneyLogUrl: string;
  onLogLearningJourney: (learning_journey_log: any) => void;
  onRoundEnd?: (userResult: UserResult) => void;
  NextQuestionCallBack?: (nextQuestion: () => void) => void;
  learningJourneyLogRef?: React.RefObject<LearningJourneyLog>;
  useDefaultGameEndComponent?: boolean;
  levelMemory?: string;
}

export default function AdaptiveTalkGame({
  CompleteAIGameData,
  onEnd,
  onScored,
  leaningJourneyLogUrl,
  onLogLearningJourney,
  onRoundEnd,
  NextQuestionCallBack,
  learningJourneyLogRef,
  useDefaultGameEndComponent = true,
  levelMemory = "",
}: AdaptiveTalkGameProps) {
  const { playSound } = useSound();
  const [isElsaPopupVisible, setIsElsaPopupVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useRecoilValue(userAuthState);
  const prompts = useRecoilValue(promptsState);
  const {
    gamePerformanceData: performanceData,
    gameRewardsResult: rewardsResult,
    calculatePerformanceAndRewards,
  } = useGamePerformance();
  const {
    logGameStart,
    learningJourneyLog,
    logUserAnswer,
    logGameEnd,
    logAIMessage,
  } = useLearningJourney(onLogLearningJourney, learningJourneyLogRef);
  const onUserAnswerResult = (userResult: UserResult) => {
    logUserAnswer(
      userResult,
      user?.username || "player",
      aiMessage.currentQuestionType
    );
    if (userResult.isCorrect) {
      playSound("right");
    } else {
      playSound("wrong");
    }
    onRoundEnd && onRoundEnd(userResult);
  };

  const {
    sendUserMessage,
    startNewChat,
    nextQuestion,
    aiMessage,
    status,
    loading,
  } = useTalkGameCommunication(CompleteAIGameData, onUserAnswerResult);
  const handleSpeechToText = useHandleSpeechToText();
  const { imageUrl, generateImage, imagePrompt } = useGenerateImage();
  const [isAdvicePopupShow, setIsAdvicePopupShow] = useState(false);
  const [isMicOpen, setIsMicOpen] = useState(false);
  const isMessageSent = useRef(false);

  const { t } = useTranslation();
  const { userLastInput, setUserLastInput, isUserSentMsg, setIsUserSentMsg } =
    useUserInput();

  const refIsResultCalculated = useRef<boolean>(false);
  // 同步外部和內部的 learning journey log

  useEffect(() => {
    // 把下一個問題的callback傳給父組件,讓其他組件可以控制下一個問題的開始
    NextQuestionCallBack && NextQuestionCallBack(nextQuestion);
  }, [nextQuestion]);

  useEffect(() => {
    if (!startNewChat) return;
    startNewChat();
    logGameStart();
  }, [startNewChat]);

  useEffect(() => {
    if (!aiMessage?.content) return;
    if (status === "idle") {
      isMessageSent.current = false;
      setIsUserSentMsg(false);
      setIsMicOpen(false);
      logAIMessage(aiMessage, CompleteAIGameData.AIGameData.avatarName);
    }
  }, [aiMessage, status]);

  useEffect(() => {
    if (status === "game_end" && !refIsResultCalculated.current) {
      refIsResultCalculated.current = true;
      const { gamePerformanceData, gameRewardsResult } =
        calculatePerformanceAndRewards(learningJourneyLog);
      logGameEnd(imageUrl, onScored, gamePerformanceData, gameRewardsResult);
      onEnd();
    }
  }, [status]);
  const handleImageGeneration = (message: string) => {
    const imagePrompts = `使用者描述的視覺元素是：${message}。${
      prompts.ImagePrompt ? " 整體的視覺風格：" + prompts.ImagePrompt : ""
    }, ${
      CompleteAIGameData.AIGameData.customPrompts.imagePrompt
        ? " 額外的提示：" +
          CompleteAIGameData.AIGameData.customPrompts.imagePrompt
        : ""
    }`;
    generateImage(imagePrompts);
  };
  const onUserMessageSent = (message: string) => {
    if (isMessageSent.current) {
      console.log("已經送出過訊息了, 重複送出訊息", message);
      return;
    }
    isMessageSent.current = true;

    // 當在特定題型的時候，擷取玩家回答的時候，進行特殊處理
    switch (aiMessage.currentQuestionType) {
      case QuestionTypeEnum.生圖:
        handleImageGeneration(message);
        break;
    }
    setIsUserSentMsg(true);
    setUserLastInput(message);
    sendUserMessage(message);
  };

  const inputTypeSelected = () => {
    let result = input_mode.VOICE;

    if (aiMessage.currentQuestionType === QuestionTypeEnum.多選) {
      result = input_mode.MULTI;
    }
    if (aiMessage.currentQuestionType == QuestionTypeEnum.單選) {
      result = input_mode.CHOIC;
    }
    if (aiMessage.currentQuestionType == QuestionTypeEnum.發音) {
      result = input_mode.ELSA;
    }
    return result;
  };

  useEffect(() => {
    if (aiMessage.currentQuestionType === QuestionTypeEnum.劇情選擇) {
      setSearchParams({ story: "open" }, { replace: true });
      return;
    } else searchParams.delete("story");

    if (aiMessage.currentQuestionType === QuestionTypeEnum.生圖) {
      setSearchParams({ image: "open" }, { replace: true });
      return;
    } else searchParams.delete("image");

    setSearchParams(searchParams, { replace: true });
  }, [aiMessage]);
  const onElsaEnd = (score: number, praticeCount: number) => {
    const pronunciationResult: PronunciationResult = {
      game_type: "發音",
      score: score,
      praticeCount: praticeCount,
    };
    onUserMessageSent(JSON.stringify(pronunciationResult));
  };
  if (loading) return <Overlay show={true} />;
  return (
    <>
      <MetaTagsProps
        title={truncateString(
          "immers - " + CompleteAIGameData.IntroductionProps?.title,
          50
        )}
        description={truncateString(
          CompleteAIGameData.IntroductionProps?.description || "",
          50
        )}
        image={CompleteAIGameData.IntroductionProps?.backgroundImageUrl}
        url={`https://english.botrun.ai/}`}
      />
      <ElsaPlugin
        praticeText={
          aiMessage.options
            ? aiMessage.options.length > 0
              ? aiMessage.options[0]
              : ""
            : ""
        }
        onEnd={onElsaEnd}
        isPopupVisible={isElsaPopupVisible}
        setIsPopupVisible={setIsElsaPopupVisible}
      />
      <div className="game-main-area">
        <div className="container">
          <BotChatItem
            avatarUrl={CompleteAIGameData.AIGameData.avatarUrl}
            avatarName={CompleteAIGameData.AIGameData.avatarName}
            message={aiMessage.content}
            keywords={CompleteAIGameData.Keywords}
            voiceName={CompleteAIGameData.AIGameData.avatarVoice}
            isShow={true}
            onMessageSend={onUserMessageSent}
            suggestions={[]}
            isNewMessageBegin={status === "idle"}
            onAdviceClick={() => {
              setIsAdvicePopupShow(true);
            }}
            isForceVoiceStop={isMicOpen || isUserSentMsg}
          />

          <SelfChatItem
            avatarUrl={CompleteAIGameData.AIGameData.playerUrl}
            avatarName={user?.username || t("player")}
            message={userLastInput}
            isShow={status != "ai_response" && isUserSentMsg}
          />
        </div>
      </div>
      {isUserSentMsg ? null : (
        <GameInputArea
          onMessageSend={onUserMessageSent}
          handleSpeechToText={handleSpeechToText}
          isActive={status === "idle"}
          setMicOpen={setIsMicOpen}
          lastBotSuggestions={aiMessage.options}
          DefaultMode={inputTypeSelected()}
          setIsElsaPopupVisible={setIsElsaPopupVisible}
        />
      )}

      <QuestionAdvicePopup
        avatarUrl={CompleteAIGameData.AIGameData.avatarUrl}
        lastBotMessage={aiMessage.content}
        keywords={CompleteAIGameData.Keywords}
        isPopupVisible={isAdvicePopupShow}
        setIsPopupVisible={setIsAdvicePopupShow}
        voiceName={CompleteAIGameData.AIGameData.avatarVoice}
      />
      <div className="effect-container"></div>

      <FixQuestionGameEnd
        isShow={status === "game_end" && useDefaultGameEndComponent}
        avatarUrl={CompleteAIGameData.AIGameData.avatarUrl}
        imageUrl={imageUrl || ""}
        gameRewardsResult={rewardsResult}
        gamePerformanceData={performanceData}
        leaningJourneyLogUrl={leaningJourneyLogUrl}
        learningJourneyLog={learningJourneyLog}
      />
    </>
  );
}
