import { ElsaAPIResponse, ElsaPayload } from "./types";

export async function toBase64String(blob: Blob): Promise<string> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const base64String =
        reader.result?.toString().replace(/^data:[^,]*,/, "") || "";
      resolve(base64String);
    });
    reader.readAsDataURL(blob);
  });
}

export async function callElsaAPI(
  APIToken: string,
  payload: ElsaPayload
): Promise<Response> {
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  return fetch(`${baseUrl}/elsa_score`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${APIToken}`,
    },
    body: JSON.stringify(payload),
  });
}

export async function processAudioData(
  audioBlob: Blob,
  APIToken: string,
  payload: ElsaPayload
): Promise<ElsaAPIResponse> {
  const audioData = await toBase64String(audioBlob);
  const response = await callElsaAPI(APIToken, {
    ...payload,
    audio_data: audioData,
  });
  const result = await response.json();
  if (!result.success) {
    throw new Error(result.error_message);
  }
  return result;
}
