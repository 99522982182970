import { useRef, useImperativeHandle, forwardRef } from "react";
import MultiplayerScoreboard from "../../../OneChat/MultiplayerScoreboard";
import Danmaku, { DanmakuRef } from "../../../OneChat/Danmaku";
import useMultiPlayerRoundFlow from "./useMultiPlayerRoundFlow";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import { PlayerScore } from "@core/components/ImmersiveMenu/Game/MultiTalkGame/MultiTalkGameEnd";
import { LearningJourneyLog } from "@core/sdgs_types";
import { ResultCardProps } from "./ResultCard";

export interface MultiplayerUIRef {
  sendUserRoundResult: (
    message: string,
    isSuccess: boolean,
    duration: number
  ) => void;
  timeElapsed: () => number;
  onUserSendDanmaku: (message: string) => void;
  getPlayerScores: () => PlayerScore[];
  sendUserGameEndResut: (result: any) => void;
}
interface MultiplayerUIProps {
  isActive: boolean;
  onRoundEnd: () => void;
  onAllUserGameEnd: (results: Record<string, any>) => void;
  handleSpeechToText: (file: File) => Promise<string>;
  isUserSentMsg: boolean;
}

const MultiplayerUI = forwardRef<MultiplayerUIRef, MultiplayerUIProps>(
  (
    {
      isActive = false,
      onRoundEnd,
      onAllUserGameEnd,
      handleSpeechToText,
      isUserSentMsg = false,
    },
    ref
  ) => {
    const user = useRecoilValue(userAuthState);
    const danmakuRef = useRef<DanmakuRef>(null);
    // 接收其他玩家彈幕消息，並且傳送給danmaku component
    const handleOtherPlayerMessage = (message: string) => {
      if (danmakuRef.current) {
        danmakuRef.current.addMessage(message);
      }
    };
    const RoundDuration = 60;
    const {
      timeRemaining,
      currentRound,
      sendUserRoundResult,
      handleLeaveRoom,
      currentRoundScore,
      totalScore,
      participants,
      onUserSendDanmaku,
      answeredPlayers,
      sendUserGameEnd,
    } = useMultiPlayerRoundFlow(
      RoundDuration,
      onRoundEnd,
      handleOtherPlayerMessage,
      onAllUserGameEnd
    );
    const timeElapsed = () => RoundDuration - timeRemaining;
    const getPlayerScores = () => {
      const returnPlayerScores: PlayerScore[] = participants.map((player) => {
        return {
          username: player.nickname, // 這裡的 username 是指玩家的暱稱 nickname 而不是登入的 username
          score: totalScore[player.username],
          avatarUrl: player.avatarUrl,
        };
      });
      return returnPlayerScores;
    };

    const sendUserGameEndResut = (result: any) => {
      const learningJourneyLog = result as LearningJourneyLog;

      const resultCardProps: ResultCardProps = {
        userName: user?.username || "",
        nickname:
          participants.find((player) => player.username === user?.username)
            ?.nickname || "player",
        avatarUrl:
          participants.find((player) => player.username === user?.username)
            ?.avatarUrl || "",
        score: learningJourneyLog.finalScore || 0,
        contribution: 0,
        gameRewardsResult: learningJourneyLog.gameRewardsResult || {
          highlights: [],
          totalExp: 0,
          completion: "",
          dynamicEncouragement: "",
        },
      };
      console.log("將個人分數傳遞給其他玩家", resultCardProps);
      sendUserGameEnd(resultCardProps);
    };

    // 暴露 handleSendMessage 方法給父組件
    useImperativeHandle(
      ref,
      () => ({
        sendUserRoundResult,
        timeElapsed,
        onUserSendDanmaku,
        getPlayerScores,
        sendUserGameEndResut,
      }),
      [sendUserRoundResult, timeElapsed, onUserSendDanmaku, getPlayerScores]
    );
    return (
      <>
        <MultiplayerScoreboard
          players={participants}
          totalScore={totalScore}
          currentRoundScore={currentRoundScore}
          isActive={isActive}
          timeLeft={timeRemaining}
          answeredPlayers={answeredPlayers}
          currentRound={currentRound}
        />

        <Danmaku
          ref={danmakuRef}
          handleSpeechToText={handleSpeechToText}
          isInputActive={isUserSentMsg}
          defaultOptions={["快一點～", "加油！", "太猛了我！"]}
          onUserSendDanmaku={onUserSendDanmaku}
          sendInterval={400} // 彈幕發送間隔
        />
      </>
    );
  }
);

export default MultiplayerUI;
