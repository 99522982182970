import { useUploadFileToDrive } from "@core/hooks/useApi";
import { useCache } from "@core/context/CacheContext";
import useLogger from "@hooks/useLogger";
import ReactGA from "react-ga4";
import { LevelDataToString } from "./LevelDataFormatters";
import { getFileExtensionNameByType } from "../config/CreateContentConfig";

interface SaveLevelOptions {
  level: any; // 根據實際類型定義
  formatedQuestions: any[]; // 根據實際類型定義
  user: {
    username: string;
  } | null;
  isEditableForAll: boolean;
  sessionRef: React.MutableRefObject<string>;
  levelID?: string;
}

export const useSaveLevel = () => {
  const { mutate } = useLogger();
  const { clearCache } = useCache();
  const { uploadFileToDrive, loading: uploadLoading } = useUploadFileToDrive();

  const saveLevel = async ({
    level,
    formatedQuestions,
    user,
    isEditableForAll,
    sessionRef,
    levelID,
  }: SaveLevelOptions) => {
    // 驗證必要欄位
    if (!level.title || !level.description || !level.learningMaterial) {
      throw new Error("請填寫完資料");
    }

    if (uploadLoading) {
      throw new Error("正在上傳中");
    }

    // 記錄分析事件
    if (levelID) {
      ReactGA.event({
        category: "修改關卡",
        action: level.title,
      });
      mutate({
        type: "修改關卡",
        username: user?.username,
        game_id: levelID,
        game_name: level.title,
        game_type: level.gameType,
        session_id: sessionRef.current,
        location: window.location.pathname,
      });
    } else {
      ReactGA.event({
        category: "建立新關卡",
        action: level.title,
      });
      mutate({
        type: "建立新關卡",
        username: user?.username,
        game_name: level.title,
        game_subtitle: level.subtitle,
        game_type: level.gameType,
        session_id: sessionRef.current,
        location: window.location.pathname,
      });
    }

    // 上傳檔案
    const rootFolderId =
      process.env.REACT_APP_ROOT_FOLDER_ID ||
      "1ZHRyYiqhzSmxUCSweGWiYXkagFS6TLGc";
    const levelData = LevelDataToString(
      level,
      formatedQuestions,
      user?.username ?? "",
      isEditableForAll,
      level.backgroundImageUrl
    );
    console.log("save levelData", levelData);
    const result = await uploadFileToDrive(
      levelData,
      level?.title + getFileExtensionNameByType(level.gameType),
      rootFolderId,
      "document",
      levelID,
      "users/" + user?.username
    );

    clearCache();
    return result;
  };

  return {
    saveLevel,
    isUploading: uploadLoading,
  };
};
