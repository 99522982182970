import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import GameInputArea, {
  input_mode,
} from "@core/components/GeneralComponent/GameInputArea";
import useHandleSpeechToText from "@core/hooks/useHandleSpeechToText";
import { getLanguageName } from "@core/utils/locales-util";
import WritingBoard from "@core/components/ImmersiveMenu/Game/WriteGame/WritingBoard";
import { useTranslation } from "react-i18next";
import UserInfoPopup from "@core/components/GeneralComponent/UserInfoPopup";
import { useSound } from "@core/context/SoundContext";
import WritingdvicePopup from "@components/AiAdvicePopup/WritingAdvice";

import {
  WritingEntry,
  Writer,
  TurnWriteAIEvaluate,
} from "@core/components/ImmersiveMenu/Game/WriteGame/useTurnBasedWriting";
import { LearningJourneyLog, WriteScoreDimension } from "@core/sdgs_types";
interface BaseWritingGameProps {
  title: string;
  descriptions: string;
  rules: string;
  background: string;
  minWords?: number;
  maxWords?: number;
  onEnd: (result: any) => void;

  useWritingLogic: () => {
    currentWriter: Writer | undefined;
    isCurrentUserTurn: boolean;
    writingEntries: WritingEntry[];
    userEntry: (message: string) => void;
    othersEntry: (content: string, writer: Writer) => void;
    combinedText: string;
    aiEvaluateResult: TurnWriteAIEvaluate | null;
  };

  handleSendMessage?: (message: string) => void;
  onLogLearningJourney?: (learning_journey_log: any) => void;
  leaningJourneyLogUrl?: string;
}

export interface BaseWritingGameRef {
  refWritingLearningJourneyLog: LearningJourneyLog;
}
const BaseWritingGame = forwardRef<BaseWritingGameRef, BaseWritingGameProps>(
  (
    {
      title,
      background,
      rules,
      descriptions,
      minWords = 150,
      maxWords = 300,
      onEnd,
      useWritingLogic,
      handleSendMessage,
      onLogLearningJourney,
      leaningJourneyLogUrl,
    }: BaseWritingGameProps,
    ref: React.Ref<BaseWritingGameRef>
  ) => {
    const { t, i18n } = useTranslation();
    const { playSound } = useSound();
    const [isAdvicePopupShow, setIsAdvicePopupShow] = useState(false);
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [isGameEndPopupVisible, setIsGameEndPopupVisible] = useState(false);
    const refWritingLearningJourneyLog = useRef<LearningJourneyLog>({
      logs: [],
      isFinished: false,
      gamestart_timestamp: new Date().toISOString(),
    });
    const {
      currentWriter,
      isCurrentUserTurn,
      writingEntries,
      userEntry,
      combinedText,
      aiEvaluateResult,
    } = useWritingLogic();

    const handleSpeechToText = useHandleSpeechToText();
    const [isMicOpen, setIsMicOpen] = useState(false);

    const onUserMessageSent = (message: string) => {
      userEntry(message);
      if (handleSendMessage) {
        handleSendMessage(message);
      }
    };
    useEffect(() => {
      if (writingEntries.length > 0) {
        const lastEntry = writingEntries[writingEntries.length - 1];
        console.log("last writingEntries", lastEntry);

        refWritingLearningJourneyLog.current.logs.push({
          content: lastEntry.content,
          userType: lastEntry.writer.userType,
          username: lastEntry.writer.username,
          questionType: "writing",
          timestamp: new Date().toISOString(),
        });
        if (onLogLearningJourney) {
          console.log(
            "有新訊息，加入log ",
            refWritingLearningJourneyLog.current
          );
          onLogLearningJourney(refWritingLearningJourneyLog.current);
        }
      }
    }, [writingEntries]);

    const finsLastUserEntryIndex = useCallback((logs: any) => {
      for (let i = logs.length - 1; i >= 0; i--) {
        if (logs[i].userType === "player") {
          return i;
        }
      }
      return -1;
    }, []);
    const addAdviceToLog = useCallback(
      (aiEvaluateResult: TurnWriteAIEvaluate) => {
        const lastUserEntry = finsLastUserEntryIndex(
          refWritingLearningJourneyLog.current.logs
        );
        if (lastUserEntry === -1) {
          console.log("找不到使用者的entry");
          return;
        }
        if (refWritingLearningJourneyLog.current) {
          refWritingLearningJourneyLog.current.logs[lastUserEntry].advice =
            aiEvaluateResult.comment;
          refWritingLearningJourneyLog.current.logs[lastUserEntry].isCorrect =
            aiEvaluateResult.success;
        }

        if (onLogLearningJourney) {
          console.log(
            "加入result ",
            aiEvaluateResult,
            refWritingLearningJourneyLog.current
          );
          onLogLearningJourney(refWritingLearningJourneyLog.current);
        }
      },
      [onLogLearningJourney]
    );

    useEffect(() => {
      if (aiEvaluateResult === null) return;
      console.log("result", aiEvaluateResult);
      addAdviceToLog(aiEvaluateResult);
      if (aiEvaluateResult.success === true) {
        playSound("right");
        setIsPopupVisible(false);
      } else {
        playSound("wrong");
        setIsPopupVisible(true);
      }
    }, [aiEvaluateResult]);

    const handleWritingDone = () => {
      onEnd({ writingSubject: title, writingText: combinedText });
    };

    useImperativeHandle(ref, () => ({
      refWritingLearningJourneyLog: refWritingLearningJourneyLog.current,
    }));

    const handleTimeUp = () => {
      //目前先取消timeup機制，所以不做任何事情
    };

    const onAdviceClick = () => {
      setIsAdvicePopupShow(true);
    };

    return (
      <>
        <div className="game-main-area  --type-write">
          <div className="container">
            <WritingBoard
              title={t("gameTitle", { title })}
              text={combinedText}
              writingEntry={writingEntries}
              handleTimeUp={handleTimeUp}
              userName={currentWriter?.username || ""}
              nickname={currentWriter?.nickname || ""}
              onAdviceClick={onAdviceClick}
              minWords={minWords}
              maxWords={maxWords}
              onCompleted={handleWritingDone}
            />
          </div>
        </div>
        {isCurrentUserTurn && (
          <GameInputArea
            onMessageSend={onUserMessageSent}
            handleSpeechToText={handleSpeechToText}
            isActive={isCurrentUserTurn}
            setMicOpen={setIsMicOpen}
            lastBotSuggestions={[]}
            DefaultMode={input_mode.TEXT}
          />
        )}

        <UserInfoPopup
          isPopup={isPopupVisible}
          setIsPopup={setIsPopupVisible}
          infomation={aiEvaluateResult?.comment || ""}
          handlePopupClose={() => {}}
        />

        <WritingdvicePopup
          avatarUrl={""}
          subject={title}
          rule={rules}
          description={descriptions}
          writingText={combinedText}
          isPopupVisible={isAdvicePopupShow}
          setIsPopupVisible={setIsAdvicePopupShow}
          voiceName={getLanguageName(i18n.language)}
        />
      </>
    );
  }
);

export default BaseWritingGame;
