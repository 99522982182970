import {
  GamePerformanceData,
  GameRewardsResult,
  Highlight,
  LearningJourneyLog,
} from "@core/sdgs_types";
import { WritingContributionAssessment } from "@core/hooks/useWritingContributionAssessment";
import calculateMultiPlayerGamePerformance from "./calculateMultiPlayerGamePerformance";

// Start of Selection
// 計算特定玩家的亮點
const getMultiPlayerHighlights = (
  writingContributionResults: WritingContributionAssessment,
  gamePerformanceData: GamePerformanceData,
  playerName: string
): Highlight[] => {
  const highlights: Highlight[] = [];

  // 找到特定玩家的分數
  const player = Object.values(writingContributionResults).find(
    (player) => player.playerName === playerName
  );

  if (player) {
    const { content, organization, grammarAndSyntax, vocabularyAndSpelling } =
      player;
    const totalScore =
      content + organization + grammarAndSyntax + vocabularyAndSpelling;

    // 判斷亮點
    if (totalScore >= 4.8) highlights.push(Highlight.PERFECT_SCORE);
    else if (totalScore >= 4.2) highlights.push(Highlight.HIGH_SCORE);
  }

  // 使用遊戲表現數據來判斷其他亮點
  if (gamePerformanceData.averageAnswerTime < 10) {
    highlights.push(Highlight.QUICK_ANSWER);
    if (highlights.includes(Highlight.HIGH_SCORE)) {
      highlights.push(Highlight.SPEED_MASTER);
    }
  } else if (
    gamePerformanceData.averageAnswerTime >= 20 &&
    gamePerformanceData.averageAnswerTime <= 30
  ) {
    highlights.push(Highlight.DEEP_THINKER);
  } else if (gamePerformanceData.averageAnswerTime > 30) {
    highlights.push(Highlight.MARATHON_WRITER);
  }

  return highlights;
};

// 計算多人遊戲的經驗值
const calculateMultiPlayerExp = (
  playerResult: WritingContributionAssessment,
  userName: string,
  highlights: Highlight[]
): number => {
  let exp = 10; // 基礎經驗值

  // 修正：直接使用之前找到的玩家資料
  const player = Object.values(playerResult).find(
    (player) => player.userName === userName
  );

  if (!player) {
    console.log("計算多人遊戲的經驗值 player not found", userName);
    console.log("playerResult", playerResult);
    return 1;
  }

  exp += Math.floor(
    (10 *
      (player.content +
        player.organization +
        player.grammarAndSyntax +
        player.vocabularyAndSpelling)) /
      20
  ); //分數經驗值，最高10 exp

  // 亮點獎勵
  exp += highlights.length * 2; //亮點獎勵，最高6 exp , 越多亮點越高
  exp += Math.floor(10 * (player.contributionPercentage / 100)); //貢獻獎勵，最高10 exp

  return exp;
};

export const calculateMultiPlayerGameRewardsForPlayer = (
  learningJourneyLog: LearningJourneyLog,
  writingContributionResults: WritingContributionAssessment,
  playerName: string
): { highlights: Highlight[]; totalExp: number } => {
  const gamePerformanceData = calculateMultiPlayerGamePerformance(
    learningJourneyLog,
    writingContributionResults,
    playerName
  );
  console.log("gamePerformanceData", gamePerformanceData);
  const highlights = getMultiPlayerHighlights(
    writingContributionResults,
    gamePerformanceData,
    playerName
  );
  console.log("highlights", highlights);

  const totalExp = calculateMultiPlayerExp(
    writingContributionResults,
    playerName,
    highlights
  );

  return {
    highlights,
    totalExp,
  };
};
