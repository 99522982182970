import Tippy from "@tippyjs/react"
import "tippy.js/dist/tippy.css"

type FormItemProps = {
  label: string
  name?: string
  options?: { id: string; value: string; label: string }[]
  checkedValue?: string
  id?: string
  checked?: boolean
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  isSwitch?: boolean
  disabled?: boolean
  disableTips?: string
}

export default function FormItem({
  label,
  name,
  options,
  checkedValue,
  id,
  checked,
  handleChange,
  isSwitch,
  disabled = false,
  disableTips = "",
}: FormItemProps) {
  return (
    <div className="form-item">
      <div className="field">{label}</div>
      <div className="value">
        <Tippy content={disableTips} placement="top" disabled={!disabled}>
          {isSwitch ? (
            <div className={`switch ${disabled ? " --disabled" : ""}`}>
              <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={handleChange}
                disabled={disabled}
              />
              <label htmlFor={id}></label>
            </div>
          ) : (
            <div className={`radio-group ${disabled ? " --disabled" : ""}`}>
              {options?.map(option => (
                <div key={option.id}>
                  <input
                    type="radio"
                    id={option.id}
                    name={name}
                    value={option.value}
                    onChange={handleChange}
                    checked={checkedValue === option.value}
                    disabled={disabled}
                  />
                  <label htmlFor={option.id}>{option.label}</label>
                </div>
              ))}
            </div>
          )}
        </Tippy>
      </div>
    </div>
  )
}
