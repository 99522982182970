import { useMutation } from "react-query"
import { request } from "@utils/axios-util"

export default function useAddScore() {
  const mutationFn = (data: Object) =>
    request({
      url: `/sdgs/rank/rank/set_rank/add_score`,
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data,
    })

  return useMutation({
    mutationFn,
  })
}
