import React, { useCallback, useEffect, useState } from "react";
import { useAiChat } from "@core/hooks/useAiChat";

interface AIAssistantButtonProps {
  onComplete: (result: string) => void;
  currentContent: string;
  fieldName: string;
  backgroundInfo?: string;
}

export const AIAssistantButton: React.FC<AIAssistantButtonProps> = ({
  onComplete,
  currentContent,
  fieldName,
  backgroundInfo = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [prompt, setPrompt] = React.useState("");
  const { aiResponse, aiChat } = useAiChat();

  const handleComplete = useCallback(
    (result: string) => {
      onComplete(result);
      setPrompt("");
      setIsOpen(false);
    },
    [onComplete]
  );

  useEffect(() => {
    if (aiResponse) {
      handleComplete(aiResponse);
    }
  }, [aiResponse]);

  const handleSubmit = () => {
    const systemInstruction = `你是一個內容編輯助手。
請根據使用者提供的背景知識和要求，修改既有的內容。
請直接返回修改後的完整內容，不需要任何額外的解釋。
請確保修改後的內容符合背景知識的範圍和限制。`;

    const userMessage = `
背景知識：
${backgroundInfo}

我正在編輯 ${fieldName} 欄位的內容。
目前的內容是：
${currentContent}

我的要求是：
${prompt}

請根據上述背景知識和我的要求修改內容。`;

    aiChat(userMessage, systemInstruction);
  };

  return (
    <>
      <button
        className="ai-assist-button"
        onClick={() => setIsOpen(true)}
        type="button"
      >
        <span className="ai-icon">🤖</span>
        AI修改
      </button>

      {isOpen && (
        <div className="ai-dialog-overlay" onClick={() => setIsOpen(false)}>
          <div className="ai-dialog" onClick={(e) => e.stopPropagation()}>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="請描述您想要如何修改上述內容..."
              className="ai-dialog-textarea"
            />
            <div className="dialog-buttons">
              <button
                onClick={handleSubmit}
                disabled={!prompt.trim()}
                className="submit-button"
              >
                確認
              </button>
              <button
                onClick={() => setIsOpen(false)}
                className="cancel-button"
              >
                取消
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
