import React from "react";
import TextAreaField from "../common/TextAreaField";
import ImageGenerateField from "../common/ImageGenerateField";
import { levelDataProbs } from "@core/sdgs_types";

interface VisualSettingsTabProps {
  level: levelDataProbs;
  onLevelChange: (level: Partial<levelDataProbs>) => void;
}

export const VisualSettingsTab: React.FC<VisualSettingsTabProps> = ({
  level,
  onLevelChange,
}) => {
  return (
    <div className="visual-settings">
      <TextAreaField
        label="場景景敘述"
        placeholder=""
        isRequired={false}
        helpText="遊戲場景敘述，用於生成圖片"
        value={level.visualDescription || ""}
        onChange={(value) => onLevelChange({ visualDescription: value })}
      />
      <ImageGenerateField
        label="主視覺圖片"
        imageName={level.title}
        imageDescription={level.visualDescription || ""}
        message={""}
        onImagedChanged={(url) => onLevelChange({ backgroundImageUrl: url })}
        isRequired={true}
        defaultUrl={level.backgroundImageUrl}
        isAvatar={false}
      />
    </div>
  );
};
