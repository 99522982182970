import React, { useState, useRef } from "react";
import { LearningJourneyLog } from "@core/sdgs_types";
import useHandleSpeechToText from "@hooks/useHandleSpeechToText";
import { useTranslation } from "react-i18next";
import MultiplayerUI, { MultiplayerUIRef } from "./MultiplayerUI";
import MultiGameEnd, {
  PlayerScore,
} from "@core/components/ImmersiveMenu/Game/MultiTalkGame/MultiTalkGameEnd";
import TalkGame, {
  TalkGameProps,
} from "@core/components/ImmersiveMenu/Game/TalkGame/TalkGame";
import { UserResult } from "@core/components/ImmersiveMenu/Game/TalkGame/types";
import { ResultCardProps } from "./ResultCard";
const MultiTalkGame: React.FC<TalkGameProps> = ({
  CompleteAIGameData,
  onEnd,
  onScored,
  leaningJourneyLogUrl,
  onLogLearningJourney,
}) => {
  const handleSpeechToText = useHandleSpeechToText();
  const [playerResults, setPlayerResults] = useState<ResultCardProps[]>([]);
  const isMessageSent = useRef(false);
  const [isUserSentMsg, setIsUserSentMsg] = useState(false);
  const { t } = useTranslation();
  const [isGameEnd, setIsGameEnd] = useState(false);
  const refLearningJourneyLog = useRef<LearningJourneyLog>({
    logs: [],
    isFinished: false,
  });
  const refAllGameEndExcuted = useRef(false);
  const onUserGameEnd = () => {
    //個人完成遊戲，計算出結算資料後，告知其他人
    console.log(
      "onUserGameEnd 傳送已經計算結算資料",
      refLearningJourneyLog.current
    );
    multiplayerUIRef.current?.sendUserGameEndResut(
      refLearningJourneyLog.current
    );
  };
  const onAllUserGameEnd = (allResult: Record<string, any>) => {
    if (refAllGameEndExcuted.current) {
      // 如果已經執行過，就不再執行, todo 查出原因，為何會重複執行
      return;
    }
    refAllGameEndExcuted.current = true;
    // 所有人都完成遊戲，一起進入結尾頁面

    // 計算總分
    const totalScore = Object.values(allResult).reduce((acc, curr) => {
      return acc + curr.score;
    }, 0);
    //更新gameEnd的分數
    const playerCards: ResultCardProps[] = Object.entries(allResult).map(
      ([userName, result]) => ({
        userName: userName,
        nickname: result.nickname,
        avatarUrl: result.avatarUrl,
        score: result.score,
        contribution: Math.round((result.score / totalScore) * 100),
        gameRewardsResult: result.gameRewardsResult,
      })
    );
    console.log("所有玩家結束遊戲，更新玩家卡片", playerCards);
    setPlayerResults(playerCards);
    setIsGameEnd(true);
    onEnd();
  };
  const onUserRoundEnd = (userResult: UserResult) => {
    console.log("玩家回答完畢, 將結果傳送給其他玩家", userResult);
    isMessageSent.current = true;
    setIsUserSentMsg(true);
    const timeElapsed = multiplayerUIRef.current?.timeElapsed();
    const answerTimeElapsed = timeElapsed ? timeElapsed : 0;
    setTimeout(() => {
      multiplayerUIRef.current?.sendUserRoundResult(
        userResult.content,
        true,
        answerTimeElapsed
      );
    }, 600);
  };
  const onRoundEnd = () => {
    console.log("單一 Round end 當所有玩家都答題，開啟下一個round");
    nextQuestionRef.current();
    isMessageSent.current = false;
    setIsUserSentMsg(false);
  };
  const nextQuestionRef = useRef<() => void>(() => {});
  const reciveNextQuestion = (nextQuestion: () => void) => {
    nextQuestionRef.current = nextQuestion;
  };
  const multiplayerUIRef = useRef<MultiplayerUIRef>(null);

  return (
    <>
      <TalkGame
        CompleteAIGameData={CompleteAIGameData}
        onEnd={onUserGameEnd}
        onScored={onScored}
        leaningJourneyLogUrl={leaningJourneyLogUrl}
        onLogLearningJourney={onLogLearningJourney}
        onRoundEnd={onUserRoundEnd}
        NextQuestionCallBack={reciveNextQuestion}
        learningJourneyLogRef={refLearningJourneyLog}
        useDefaultGameEndComponent={false}
      />

      <MultiplayerUI
        ref={multiplayerUIRef}
        isActive={true && !isGameEnd}
        onRoundEnd={onRoundEnd}
        onAllUserGameEnd={onAllUserGameEnd}
        handleSpeechToText={handleSpeechToText}
        isUserSentMsg={isUserSentMsg}
      />
      {playerResults && playerResults.length > 0 && (
        <MultiGameEnd
          leaningJourneyLogUrl={leaningJourneyLogUrl}
          playerCards={playerResults}
          isShow={true}
        />
      )}
    </>
  );
};

export default MultiTalkGame;
