import React, { useState, useEffect } from "react";
import InputTextContainer from "./GameInputTextArea";
import Recorder from "./GameInputRecorder";

import SelectionInputArea from "../SelectionInputArea/SelectionInput";
import MultiSelectionInput from "../SelectionInputArea/MultiSelectionInput";
export type InputAreaProps = {
  onMessageSend: (message: string) => void;
  handleSpeechToText?: (file: File) => Promise<string>;
  isActive: boolean;
  setMicOpen: (isOpen: boolean) => void;
  lastBotSuggestions: string[];
  DefaultMode: input_mode;
  setIsElsaPopupVisible?: (isVisible: boolean) => void;
};
export enum input_mode {
  TEXT = "--text-mode",
  VOICE = "--voice-mode",
  CHOIC = "--mcq-mode",
  MULTI = "--multi-mode",
  ELSA = "--plugin-mode",
}
const GameInputArea: React.FC<InputAreaProps> = ({
  onMessageSend,
  handleSpeechToText,
  isActive: isPlayerTurn,
  setMicOpen,
  lastBotSuggestions,
  DefaultMode,
  setIsElsaPopupVisible,
}) => {
  const [recodingHint, setRecodingHint] = useState<string | null>(null);
  const [inputMode, setInputMode] = useState<input_mode>(DefaultMode);

  const toggleInputMode = () => {
    if (recodingHint != null) return;

    if (inputMode === input_mode.TEXT) {
      setInputMode(input_mode.VOICE);
    }
    if (inputMode === input_mode.VOICE) {
      setInputMode(input_mode.TEXT);
    }
  };
  useEffect(() => {
    setInputMode(DefaultMode);
  }, [DefaultMode]);

  useEffect(() => {
    // 如果有選項，則強制切換到選項模式,但是如果是發音則不切換
    if (DefaultMode === input_mode.ELSA) {
      return;
    }
    if (
      lastBotSuggestions &&
      lastBotSuggestions.length > 0 &&
      DefaultMode !== input_mode.MULTI &&
      DefaultMode !== input_mode.CHOIC
    ) {
      setInputMode(input_mode.CHOIC);
    } else {
      if (inputMode != DefaultMode) {
        setInputMode(DefaultMode);
      }
    }
  }, [lastBotSuggestions]);

  const [text, setText] = useState("");
  useEffect(() => {
    if (text !== "") {
      onMessageSend(text);
      setText("");
    }
  }, [text]);
  return (
    <div
      className={`game-input-area ${inputMode} ${
        isPlayerTurn ? "--active" : ""
      }`}
    >
      <div className="container">
        {isPlayerTurn ? (
          <div>
            {inputMode === input_mode.VOICE && (
              <div className="input-voice-container">
                <Recorder
                  setText={setText}
                  handleSpeechToText={handleSpeechToText}
                  setRecodingHint={setRecodingHint}
                  setMicOpen={setMicOpen}
                />
                <div
                  className="btn-change"
                  onClick={toggleInputMode}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  {recodingHint ?? "想打字點這裡"}
                </div>
              </div>
            )}
            {inputMode === input_mode.TEXT && (
              <div className="input-text-container">
                <InputTextContainer
                  placeholder="請輸入文字"
                  onMessageSend={onMessageSend}
                />
                {/* <div
                  className="btn-change"
                  data-btninputchange
                  onClick={toggleInputMode}
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                >
                  用說的點這裡
                </div> */}
              </div>
            )}
            {inputMode === input_mode.CHOIC && (
              <SelectionInputArea
                items={lastBotSuggestions}
                onItemClick={onMessageSend}
              />
            )}
            {inputMode === input_mode.MULTI && (
              <MultiSelectionInput
                items={lastBotSuggestions}
                onItemClick={onMessageSend}
              />
            )}
            {inputMode === input_mode.ELSA && (
              <div className="input-plugin-container">
                <div
                  className="btn --large"
                  onClick={() => {
                    if (setIsElsaPopupVisible) setIsElsaPopupVisible(true);
                  }}
                >
                  <i className="icon icon-1"></i>
                  <span>開始發音挑戰</span>
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default GameInputArea;
