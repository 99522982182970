import { useState, useCallback, useEffect, useRef } from "react";
import { useAiChat } from "@hooks/useAiChat";
import useLoadAvatar from "../useLoadAvatar";
import { jsonPharseTool } from "@core/utils/jsonPharseTool";
import { CompleteAIGameData } from "@core/sdgs_types";
import { pharseMessageAndOptions, StringToQuestionTypeEnum } from "../funcs";
import { showEncouragement } from "../showEncouragement";
import { useRecoilValue } from "recoil";
import { promptsState } from "@core/recoil/sdgs_atoms";
import {
  Status,
  AiMessage,
  UserResult,
  TalkGameCommunicationResult,
  QuestionTypeEnum,
  aiAnswerAdviceResponse,
} from "../types";
import { displayTextGradually } from "../displayTextGradually";
import {
  getQuestionResult,
  isSkipValidationQuestion,
} from "../UserResultValidation";
import { Question } from "@core/sdgs_types";
import { useGenerateQuestion } from "@hooks/useGenerateQuestion";
import { generateAdvice } from "../generateAdvice";
import { useParams } from "react-router-dom";
const useTalkGameCommunication = (
  gameData: CompleteAIGameData,
  onResult: (userResult: UserResult) => void
): TalkGameCommunicationResult => {
  const { roomId } = useParams();
  const prompts = useRecoilValue(promptsState);
  const {
    characterData,
    loading: avatarLaoding,
    error: avatarError,
  } = useLoadAvatar(gameData.AIGameData.avatarId);
  const questionIndexRef = useRef<number>(-1);
  const maxQuestionCount = useRef<number>(5);
  const gameStartRef = useRef<boolean>(false);
  const conversationRef = useRef<string[]>([]);
  const [aiMessage, setAiMessage] = useState<AiMessage>({
    options: [""],
    currentQuestionType: QuestionTypeEnum.劇情選擇,
    content: "",
  });
  const lastQeustionRef = useRef<Question | null>(null);
  const [status, setStatus] = useState<Status>("idle");
  const userMessageAndResultRef = useRef<UserResult>({
    content: "",
    score: 0,
    advice: "",
  });

  const { aiResponse, aiChat, loading, error } = useAiChat();
  const {
    generateQuestion,
    result,
    error: questionError,
    debugInfo,
  } = useGenerateQuestion({
    coversations: conversationRef.current,
    questionIndex: questionIndexRef.current,
    maxQuestionCount: maxQuestionCount.current,
    characterData,
    gameData,
    prompts,
  });
  ///沒有題目的時候，直接進入自由對談模式，使用歷史資料動態生成問題

  useEffect(() => {
    if (result) {
      console.log("AI產生新的問題", result);
      const responseQuestion = result as unknown as Question;
      const newConversation = `第${questionIndexRef.current + 1}題, type: ${
        responseQuestion.type
      }, game_host: ${responseQuestion.question}\n`;
      console.log("新的對話紀錄", newConversation);
      console.log("更新問題", responseQuestion);
      lastQeustionRef.current = responseQuestion;
      questionIndexRef.current = questionIndexRef.current + 1;
      conversationRef.current.push(newConversation);

      setStatus("ai_response");
      displayTextGradually(
        responseQuestion.question,
        setAiMessage,
        responseQuestion.options,
        StringToQuestionTypeEnum(responseQuestion.type),
        () => {
          setTimeout(() => {
            setStatus("idle");
          }, 2000);
        }
      );
    }
  }, [result]);

  useEffect(() => {
    // AI回應，判斷是否正確，並且給予建議
    if (aiResponse && aiResponse != "建議生成中...") {
      try {
        console.log("取得答案建議answer advice", aiResponse);
        const responseAdvice: aiAnswerAdviceResponse =
          jsonPharseTool(aiResponse);
        setStatus("ai_response");
        displayTextGradually(
          responseAdvice.dialogue,
          setAiMessage,
          ["OK"],
          QuestionTypeEnum.advice,
          () => {
            setStatus("idle");
          }
        );
        const currentQuestion = lastQeustionRef.current;
        const questionType = StringToQuestionTypeEnum(
          currentQuestion?.type || ""
        );
        if (questionType == QuestionTypeEnum.問答) {
          userMessageAndResultRef.current.isCorrect = responseAdvice.success;
          userMessageAndResultRef.current.score = responseAdvice.score;
          userMessageAndResultRef.current.advice = responseAdvice.advice;
          console.log(
            "問答題目，AI判斷是否正確",
            userMessageAndResultRef.current
          );
        } else {
          console.log("非問答題目，AI給予建議", responseAdvice.advice);
          userMessageAndResultRef.current.advice = responseAdvice.advice;
        }

        conversationRef.current.push(
          `第${questionIndexRef.current}題, type:advice, game_host:" + ${responseAdvice.dialogue}\n`
        );
        onResult(userMessageAndResultRef.current);
      } catch (e) {
        console.error("ai產生答題是否正確與建議，判斷失敗，繼續下一題,", e);
        userMessageAndResultRef.current.isCorrect = false;
        userMessageAndResultRef.current.score = 0;
        userMessageAndResultRef.current.advice = "AI判斷失敗，繼續下一題";
        showEncouragement(
          userMessageAndResultRef.current,
          setAiMessage,
          NextQuesitonCheck
        );
        onResult(userMessageAndResultRef.current);
      }
    }
  }, [aiResponse]);

  const startNewChat = useCallback(() => {
    // 開始新的對話, 重置問題索引, 並且顯示歡迎訊息
    if (gameStartRef.current) {
      console.error("遊戲已經開始，不應該再次開始新的對話");
    }
    gameStartRef.current = true;
    if (!gameData.AIGameData.wellcomeMessage) {
      console.error("No wellcomeMessage in gameData.AIGameData");
    }
    let { message, options: wellcomeOptions } = pharseMessageAndOptions(
      gameData.AIGameData.wellcomeMessage
    );
    if (wellcomeOptions.length === 0) {
      wellcomeOptions = ["好的"];
    }
    if (message === "") {
      console.error("message是空的, 使用預設歡迎訊息", gameData);
      message = "準備好要迎接挑戰了嗎！";
    }
    setStatus("ai_response");
    displayTextGradually(
      message,
      setAiMessage,
      wellcomeOptions,
      QuestionTypeEnum.劇情選擇,
      () => {
        setStatus("idle");
      }
    );

    userMessageAndResultRef.current = {
      content: "",
      score: 0,
      advice: "",
    };

    conversationRef.current.push(
      `第${questionIndexRef.current}題, type:劇情選擇, game_host:" + ${message}\n`
    );
    const questions = gameData.questions || [];
    maxQuestionCount.current = gameData.levelData?.numberOfQuestions || 5;
    if (questions.length > 0) {
      maxQuestionCount.current = questions.length;
    }
  }, [gameData, setStatus, setAiMessage]);
  const handleGameEnd = useCallback(() => {
    setAiMessage({
      options: [],
      currentQuestionType: QuestionTypeEnum.評語,
      content: "遊戲結束！",
    });
    setStatus("game_end");
  }, [setAiMessage, setStatus]);

  const NextQuesitonCheck = () => {
    if (!roomId) {
      //如果是單人遊戲，則直接進入下一個問題
      nextQuestion();
      return;
    }
  };

  const nextQuestion = () => {
    // 進入下一個問題,清空使用者回答資料, 如果問題已經問完, 則顯示遊戲結束
    const questions = gameData.questions || [];
    if (questions.length === 0) {
      console.error("No questions in gameData, 開始自由對談模式");
      if (questionIndexRef.current >= maxQuestionCount.current) {
        console.error("已經達到最大問題數，遊戲結束");
        handleGameEnd();
        return;
      }
      generateQuestion(); // 使用新的 hook 來生成問題
      return;
    }
    userMessageAndResultRef.current = {
      content: "",
      score: 0,
      advice: "",
    };

    if (questionIndexRef.current < maxQuestionCount.current - 1) {
      const nextIndex = questionIndexRef.current + 1;
      questionIndexRef.current = nextIndex;
      const data_nextQuestion = questions[nextIndex];
      lastQeustionRef.current = data_nextQuestion;
      console.log("玩家進入下一個問題", data_nextQuestion);
      setStatus("ai_response");
      displayTextGradually(
        data_nextQuestion.question,
        setAiMessage,
        data_nextQuestion.options,
        StringToQuestionTypeEnum(data_nextQuestion.type),
        () => {
          setStatus("idle");
        }
      );
    } else {
      console.log("next question 遊戲結束");
      handleGameEnd();
    }
  };

  const sendUserMessage = useCallback(
    (content: string) => {
      conversationRef.current.push(`player: ${content}\n`);
      userMessageAndResultRef.current.content = content;
      const quesitonList = gameData.questions || [];
      const currentQuestion = lastQeustionRef.current
        ? lastQeustionRef.current
        : quesitonList.length > questionIndexRef.current
        ? quesitonList[questionIndexRef.current]
        : { type: "問答", question: "", options: [], answer: "" };
      const questionType = StringToQuestionTypeEnum(
        currentQuestion?.type || ""
      );
      const nextQuestionData =
        questionIndexRef.current + 1 < quesitonList.length
          ? quesitonList[questionIndexRef.current + 1]
          : null;
      // 如果是劇情選擇或是ai給你的建議,  則不需要判斷答案，進入下一個問題
      if (isSkipValidationQuestion(aiMessage.currentQuestionType)) {
        userMessageAndResultRef.current.isCorrect = true;

        console.log(
          "劇情選擇或是ai給你的建議,  則不需要判斷答案，進入下一個問題, 紀錄學習歷程",
          userMessageAndResultRef.current
        );
        onResult(userMessageAndResultRef.current);

        NextQuesitonCheck();
        return;
      }

      // 如果沒有正確答案，則交給AI判斷，等到AI回應後再呼叫onResult
      if (!currentQuestion?.answer && questionType != QuestionTypeEnum.發音) {
        console.log("沒有正確答案，開放式問答，等待AI判斷");
        generateAdvice(
          conversationRef.current,
          nextQuestionData,
          currentQuestion,
          content,
          true,
          characterData,
          gameData,
          prompts.SimpleAdvice,
          aiChat
        );
        return;
      }

      // 有正確答案的單選題多選題，發音題，直接判斷答案，分數等等
      const result = getQuestionResult(questionType, currentQuestion, content);
      userMessageAndResultRef.current = result;

      // 如果是發音題目,或是答對了，則繼續下一個問題
      if (result.isCorrect || questionType === QuestionTypeEnum.發音) {
        setStatus("ai_response");
        console.log("答對了，顯示鼓勵用語");
        showEncouragement(result, setAiMessage, () => {
          onResult(userMessageAndResultRef.current);
          NextQuesitonCheck();
        });
      } else {
        // 如果答錯了，則呼叫AI給予建議
        generateAdvice(
          conversationRef.current,
          nextQuestionData,
          currentQuestion,
          content,
          true,
          characterData,
          gameData,
          prompts.SimpleAdvice,
          aiChat
        );
      }
    },
    [generateAdvice, onResult, aiChat, gameData, aiMessage]
  );

  useEffect(() => {
    if (loading) {
      setStatus("ai_thinking");
    }
  }, [loading]);

  useEffect(() => {
    if (error) {
      console.error("AI Chat Error:", error);
      setStatus("idle");
    }
  }, [error]);

  return {
    sendUserMessage,
    startNewChat,
    nextQuestion,
    aiMessage,
    status,
    loading: avatarLaoding,
  };
};

export default useTalkGameCommunication;
