import { QuestionTypeEnum } from "./types";
import { SetStateAction, Dispatch } from "react";
import { AiMessage } from "./types";

import { UserResult } from "./types";
import { displayTextGradually } from "./displayTextGradually";
interface EncouragementSet {
  range: [number, number];
  phrases: string[];
}
const encouragementSets: EncouragementSet[] = [
  {
    range: [90, 100],
    phrases: [
      "太棒了！你是來自外星的天才嗎？", // 調侃
      "哇！你確定你沒有偷看答案？", // 調侃
      "完美！你的大腦是怎麼裝的？", // 調侃
      "驚人的表現！你的努力得到了回報！", // 正面
      "excellent! 你的表現讓老師都汗顏了！", // 正面+調侃
      "哼，不過如此。（其實內心：天啊太厲害了！）", // 冷言冷語
    ],
  },
  {
    range: [80, 89],
    phrases: [
      "很棒！再加把勁，說不定下次能進化成天才！", // 調侃
      "不錯嘛，看來你不只是來搶答案的！", // 調侃
      "真行！你的腦細胞終於打醒了嗎？", // 冷言冷語
      "excellent! 你的表現令人印象深刻！", // 正面
      "繼續保持，你離考試滿分只差一個老師的微笑了！", // 調侃
      "還行吧，不過別得意，下次可能就GG了。", // 冷言冷語
    ],
  },
  {
    range: [70, 79],
    phrases: [
      "嗯，不錯，看來你終於開始動腦子了！", // 調侃
      "及格萬歲！要不要考慮轉行當考試專家？", // 調侃
      "還可以，但別驕傲，你還不是最強的！", // 冷言冷語
      "繼續加油！你正在朝著正確的方向前進！", // 正面
      "進步了啊，看來熬夜打遊戲的時間減少了？", // 調侃
      "哼，及格了就想歇息？想成功還早著呢！", // 冷言冷語
    ],
  },
  {
    range: [60, 69],
    phrases: [
      "哇，及格了！要不要請全班吃冰淇淋慶祝一下？", // 調侃
      "及格就想歡呼？別做夢了，繼續加油吧！", // 冷言冷語
      "嗯...至少你沒有墊底，繼續努力！", // 正面+調侃
      "及格萬歲！但別高興太早，下次可能就不一樣了。", // 冷言冷語
      "還不賴嘛，看來你不是完全不會嘛！", // 調侃
      "及格了，但別驕傲，你的對手可能正在埋頭苦讀呢！", // 冷言冷語+鼓勵
    ],
  },
  {
    range: [0, 59],
    phrases: [
      "哎呀，是不是把答案寫在橡皮上，結果用完了？", // 調侃
      "不及格？別灰心，至少你在'如何不及格'這門課上是滿分！", // 調侃
      "嗯...我們要不要一起去買本《學習方法》？", // 調侃
      "失敗乃成功之母，你很快就要當媽了！", // 調侃
      "別氣餒，你只是還沒找到屬於自己的學習方法！", // 正面
      "這個分數...你是不是把重點都忘在枕頭下了？", // 冷言冷語
    ],
  },
];

const getEncouragementPhrase = (score: number): string => {
  const set = encouragementSets.find(
    (s) => score >= s.range[0] && score <= s.range[1]
  );
  if (set) {
    return set.phrases[Math.floor(Math.random() * set.phrases.length)];
  }
  return "繼續努力！"; // 默認鼓勵語
};

const getScoreComment = (score: number): string => {
  if (score >= 90) return "你的表現非常出色！";
  if (score >= 80) return "你做得很好！";
  if (score >= 70) return "你有很大的進步！";
  if (score >= 60) return "及格了，繼續加油！";
  return "不要灰心，繼續努力！";
};

export const showEncouragement = (
  result: UserResult,
  setAiMessage: Dispatch<SetStateAction<AiMessage>>,
  nextQuestion: () => void
) => {
  const encouragementPhrase = getEncouragementPhrase(result.score);
  const scoreComment = getScoreComment(result.score);
  setTimeout(() => {
    displayTextGradually(
      `${
        result.isCorrect ? "答對！" : "加油！"
      } ${encouragementPhrase} ${scoreComment}`,
      setAiMessage,
      [],
      QuestionTypeEnum.評語,
      () => {
        setTimeout(() => {
          nextQuestion();
        }, 2000);
      }
    );
  }, 400);
  /*  setAiMessage({
      options: [],
      currentQuestionType: QuestionTypeEnum.評語,
      content: `${
        result.isCorrect ? "答對！" : "加油！"
      } ${encouragementPhrase} ${scoreComment}`,
    });
  }, 500); */
};
