import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import useContainerClassName from "@hooks/useContainerClassName";
import BackGround from "./BackGround";
import { SoundProvider } from "@core/context/SoundContext";
export default function ImmersiveMenu() {
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const memuClassName = "imrs-page";
  const inGameClassName = "game-container";
  let classNames = memuClassName;
  if (location.pathname.includes("g")) {
    classNames = inGameClassName;
  }
  if (searchParams.get("settings") === "open") {
    classNames += " --show-nav";
  }

  if (searchParams.get("story") === "open") {
    classNames += " --story-mode";
  }

  if (searchParams.get("image") === "open") {
    classNames += " --warning";
  }
  // const classNames = useContainerClassName();
  return (
    <div id="game-frame-container" className={classNames}>
      <SoundProvider>{<Outlet />}</SoundProvider>
      <BackGround />
    </div>
  );
}
