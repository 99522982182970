import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { request } from "@utils/axios-util"
import { useParams, useNavigate } from "react-router-dom"
import { useRecoilState } from "recoil"
import { userAuthState } from "@core/recoil/atoms"
export default function useSessionId(jwt_token: string | undefined) {
  const navigate = useNavigate()
  const { botrunId, sessionId } = useParams()
  const bId = botrunId ?? "index.botrun"
  const [user, setUser] = useRecoilState(userAuthState)
  const getFormData = () => {
    const data = new FormData()
    data.append("jwt_token", jwt_token as string)
    data.append("session_id", sessionId ?? "new")
    data.append("botrun_id", bId)
    return data
  }

  const queryFn = () =>
    request({
      url: "/new_session_id",
      method: "post",
      data: getFormData(),
    })

  const { refetch } = useQuery(["fetch-session-id-api"], {
    queryFn,
    onError: (error: AxiosError) => {
      console.error(error, "取得seesion id 失敗，回到登入頁面")
      setUser(null)
      navigate("/login")
    },
    select: (res: AxiosResponse) => res.data,
    enabled: false,
  })

  const getSessionId = async (isCreatedRef: React.MutableRefObject<boolean>) => {
    isCreatedRef.current = sessionId !== undefined
    if (sessionId) {
      return sessionId
    } else {
      const result = await refetch()
    // navigate(`/b/${bId}/s/${result.data}`)
      return result.data ?? null
    }
  }

  return getSessionId
}
