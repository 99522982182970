import React, { ChangeEvent, useState } from "react";
import { Lock, Unlock } from "lucide-react";
import { AIAssistantButton } from "./AIAssistantButton";

interface TextInputFieldProps {
  label: string;
  placeholder: string;
  isRequired?: boolean;
  helpText?: string;
  onChange: (value: string) => void;
  value: string;
  isShowHelp?: boolean;
  isLocked?: boolean;
  onLockToggle?: (isLocked: boolean) => void;
  useAIAssistant?: boolean;
}

const TextInputField: React.FC<TextInputFieldProps> = ({
  label,
  placeholder,
  isRequired = false,
  helpText,
  onChange,
  value,
  isShowHelp = false,
  isLocked = false,
  onLockToggle,
  useAIAssistant = true,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!isLocked) {
      onChange(event.target.value);
    }
  };

  const handleLockToggle = () => {
    if (onLockToggle) {
      onLockToggle(!isLocked);
    }
  };

  const showLockFeature = !!onLockToggle;

  return (
    <div
      className={`form-item ${isRequired ? "must" : ""} ${
        isShowHelp ? "show-help" : ""
      }`}
    >
      <div className="field">{label}</div>
      <div className="value relative">
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          className={isLocked && showLockFeature ? "bg-gray-100 pr-8" : ""}
          readOnly={isLocked && showLockFeature}
        />
        {showLockFeature && (
          <button
            onClick={handleLockToggle}
            className="absolute right-2 top-1/2 transform -translate-y-1/2"
          >
            {isLocked ? (
              <Lock className="h-5 w-5 text-gray-500" />
            ) : (
              <Unlock className="h-5 w-5 text-gray-500" />
            )}
          </button>
        )}
      </div>
      {helpText && (
        <div className="help-word">
          <i className="icon icon-28 inline"></i>
          {helpText}
        </div>
      )}

      {useAIAssistant && (
        <AIAssistantButton
          onComplete={onChange}
          currentContent={value}
          fieldName={label}
          backgroundInfo={`這是一個遊戲關卡的${label}。
需要注意：
1. 內容應該簡潔有力
2. 符合遊戲關卡設定
3. 吸引玩家興趣
4. 清楚表達主要意思`}
        />
      )}
    </div>
  );
};

export default TextInputField;
