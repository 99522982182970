import { LearningJourneyLog, PlayerInfo } from "@core/sdgs_types";
import { calculateMultiPlayerGameRewardsForPlayer } from "./calculateMultiPlayerGameRewards";
import { ResultCardProps } from "../../MultiTalkGame/ResultCard";
import { WritingContributionAssessment } from "@core/hooks/useWritingContributionAssessment";
import { BaseWritingGameRef } from "../BaseWritingGame";

export const convertContributionResultsToPlayerCards = (
  writingContributionResults: WritingContributionAssessment,
  participants: PlayerInfo[],
  baseWritingGameRef: React.RefObject<BaseWritingGameRef>
): ResultCardProps[] => {
  const playerCards: ResultCardProps[] = [];
  if (!writingContributionResults) return playerCards;
  const players = [
    writingContributionResults.player1,
    writingContributionResults.player2,
    writingContributionResults.player3,
  ].filter((player) => player?.userName);

  const totalScoreOfAllPlayers = players.reduce((total, player) => {
    return (
      total +
      (player.organization +
        player.content +
        player.grammarAndSyntax +
        player.vocabularyAndSpelling)
    );
  }, 0);

  players.forEach((player) => {
    const participant = participants?.find(
      (p) => p.username === player.userName
    );
    if (!participant) {
      console.log("participant not found", player);
      return;
    }

    const gameRewardsResult = calculateMultiPlayerGameRewardsForPlayer(
      baseWritingGameRef.current?.refWritingLearningJourneyLog ||
        ({} as LearningJourneyLog),
      writingContributionResults,
      player.userName
    );

    const totalScore =
      player.organization +
      player.content +
      player.grammarAndSyntax +
      player.vocabularyAndSpelling;

    playerCards.push({
      userName: player.userName || "",
      nickname: participant.nickname || "玩家",
      avatarUrl: participant.avatarUrl || "/images/avatar/avatar.png",
      contribution: Math.round((totalScore / totalScoreOfAllPlayers) * 100),
      gameRewardsResult: {
        highlights: gameRewardsResult.highlights,
        totalExp: gameRewardsResult.totalExp,
        completion: player.contributionComment,
        dynamicEncouragement: "",
      },
      score: totalScore,
    });
  });

  return playerCards;
};
