import { useState, useEffect } from "react";
import {
  useAiOutputGenerator,
  AutoTypeDescription,
} from "@core/hooks/useAiOutputGenerator";
import { OneLearningLog } from "sdgs_types";

// 寫作貢獻度評估的介面
export interface WritingContributionAssessment {
  player1: {
    userName: string;
    content: number;
    organization: number;
    grammarAndSyntax: number;
    vocabularyAndSpelling: number;
    contributionPercentage: number;
    contributionComment: string;
  };
  player2: {
    userName: string;
    content: number;
    organization: number;
    grammarAndSyntax: number;
    vocabularyAndSpelling: number;
    contributionPercentage: number;
    contributionComment: string;
  };
  player3: {
    userName: string;
    content: number;
    organization: number;
    grammarAndSyntax: number;
    vocabularyAndSpelling: number;
    contributionPercentage: number;
    contributionComment: string;
  };
}

// 貢獻度評估的回應類型
const writingContributionResponseType: AutoTypeDescription<WritingContributionAssessment> =
  {
    player1: {
      userName: "string",
      content: "number",
      organization: "number",
      grammarAndSyntax: "number",
      vocabularyAndSpelling: "number",
      contributionPercentage: "number",
      contributionComment: "string",
    },
    player2: {
      userName: "string",
      content: "number",
      organization: "number",
      grammarAndSyntax: "number",
      vocabularyAndSpelling: "number",
      contributionPercentage: "number",
      contributionComment: "string",
    },
    player3: {
      userName: "string",
      content: "number",
      organization: "number",
      grammarAndSyntax: "number",
      vocabularyAndSpelling: "number",
      contributionPercentage: "number",
      contributionComment: "string",
    },
  };

export const useWritingContributionAssessment = () => {
  // 寫作貢獻度評估的 state
  const [writingContributionResults, setWritingContributionResults] =
    useState<WritingContributionAssessment | null>(null);

  // 寫作貢獻度評估的 AI 輸出生成器
  const {
    result: writingContributionResult,
    generateOutput: generateWritingContributionOutput,
  } = useAiOutputGenerator<WritingContributionAssessment>({
    defaultSystemPrompt: `
    你是一位英語程度的評分員，
    評分原則：
   ，基於大考中心英語作文評分機制。
    作文評分維度包括：內容(content)、組織(organization)、文法句構(grammarAndSyntax)、字彙拼字(vocabularyAndSpelling)。
    每個維度分為優(4-5分)、可(3分)、差(1-2分)、劣(0分)四個等級。這四個維度是數值評分，評分範圍0-5分之間。

    1. 根據每個參與者在接龍寫作中的實質貢獻進行評分
    2. contributionPercentage貢獻度比例0-100 之間
    3. 獻度比例評分依據包括：創意性、連貫性、字數、關鍵情節推進
    4. 提供簡潔的評語，說明貢獻的優缺點
    5. 必須填寫三位玩家的貢獻評估，以瞭解每位玩家的對作文內容的貢獻程度
    請完全依據格式輸出
    userName: 玩家名稱
    content: 內容評分
    organization: 組織評分
    grammarAndSyntax: 文法句構評分
    vocabularyAndSpelling: 字彙拼字評分
    contributionPercentage: 貢獻度比例
    contributionComment: 貢獻評語
    預設玩家上限為3人，但也可能是2人
   
    如果玩家人數為2人，第三人的資料請輸出userName為空字串, 其他評分維度為0分
    並且貢獻度以兩人來計算。
    contributionComment評語用繁體中文 20字以內，風趣幽默的進行評語
    `,
    globalConfigPromptKey: "WritingContributionAssessment",
    responseType: writingContributionResponseType,
  });

  // 評估寫作貢獻度的方法
  const generateWritingContributionAssessment = (
    writingSubject: string,
    rule: string,
    description: string,
    writingText: string,
    conversationLogs: OneLearningLog[]
  ) => {
    if (writingText && writingSubject) {
      const conversationLogsStr = JSON.stringify(conversationLogs);
      const userMessage = `請評估這個接龍寫作中每個參與者的作文評分維度與貢獻程度。
主題：${writingSubject}
規則：${rule}
描述：${description}
完整作文：${writingText}
對話紀錄：${conversationLogsStr}`;

      generateWritingContributionOutput(userMessage);
    }
  };

  // 處理寫作貢獻度評估結果的 useEffect
  useEffect(() => {
    if (writingContributionResult) {
      try {
        setWritingContributionResults(writingContributionResult);
      } catch (e) {
        console.error("Error processing writing contribution assessment:", e);
        setWritingContributionResults(null);
      }
    }
  }, [writingContributionResult]);

  return {
    writingContributionResults,
    generateWritingContributionAssessment,
  };
};
