export type ReplacementMap = {
  [key: string]: string;
};

export function replaceKeywords(
  text: string,
  replacements: ReplacementMap
): string {
  return Object.entries(replacements).reduce(
    (currentText, [keyword, realWord]) => {
      const regex = new RegExp(`\\{${keyword}\\}`, "g");
      return currentText.replace(regex, realWord);
    },
    text
  );
}
