import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
  useCallback,
  useEffect,
} from "react";
import GameInputArea, { input_mode } from "../GeneralComponent/GameInputArea";
import DanmakuDisplay from "./DanmakuDisplay";

interface DanmakuProps {
  handleSpeechToText: (file: File) => Promise<string>;
  isInputActive: boolean;
  defaultOptions: string[];
  onUserSendDanmaku: (message: string) => void;
  sendInterval?: number;
}

export interface DanmakuRef {
  addMessage: (message: string) => void;
}

const Danmaku = forwardRef<DanmakuRef, DanmakuProps>(
  (
    {
      handleSpeechToText,
      isInputActive,
      defaultOptions,
      onUserSendDanmaku,
      sendInterval = 500,
    },
    ref
  ) => {
    const [IsMicOpen, setIsMicOpen] = useState(false);
    const addMessageRef = useRef<((message: string) => void) | null>(null);
    const refLastMessageTime = useRef(Date.now());

    // 共用的添加消息函數
    const addMessage = useCallback((message: string) => {
      if (addMessageRef.current) {
        addMessageRef.current(message);
      }
    }, []);

    // 用戶發送消息的處理函數，限制每秒發送數量為一秒2次
    const onUserMessageSent = useCallback(
      (message: string) => {
        const now = Date.now();
        if (now - refLastMessageTime.current > sendInterval) {
          addMessage(message);
          onUserSendDanmaku(message);
          refLastMessageTime.current = now;
        }
      },
      [addMessage, onUserSendDanmaku]
    );

    // 暴露 addMessage 方法給父組件
    useImperativeHandle(
      ref,
      () => ({
        addMessage,
      }),
      [addMessage]
    );

    return (
      <div className="relative h-screen">
        <DanmakuDisplay addMessageRef={addMessageRef} />
        {isInputActive && (
          <div className="absolute bottom-0 left-0 right-0">
            <GameInputArea
              onMessageSend={onUserMessageSent}
              handleSpeechToText={handleSpeechToText}
              isActive={isInputActive}
              setMicOpen={setIsMicOpen}
              lastBotSuggestions={defaultOptions}
              DefaultMode={input_mode.TEXT}
            />
          </div>
        )}
      </div>
    );
  }
);

export default Danmaku;
