import { useState, useEffect, useCallback, useRef } from "react";
import GameInputArea, {
  input_mode,
} from "@components/GeneralComponent/GameInputArea";
import { truncateString } from "@utils/functions";
import MetaTagsProps from "@components/SEOTags/MetaTages";
import useHandleSpeechToText from "@hooks/useHandleSpeechToText";
import { CompleteAIGameData } from "@core/sdgs_types";
import BotChatItem from "@components/GeneralComponent/BotChatItem";
import SelfChatItem from "@components/GeneralComponent/SelfChatItem";
import { useGenerateImage } from "@hooks/useApi";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import QuestionAdvicePopup from "@components/AiAdvicePopup/QuestionAdvicePopup";
import useUserInput from "@components/OneChat/useUserInput";
import { useTranslation } from "react-i18next";
import { LearningJourneyLog } from "@core/sdgs_types";
import { promptsState } from "@core/recoil/sdgs_atoms";
import { useSearchParams } from "react-router-dom";
import useTalkGameCommunication from "./hooks/useTalkGameCommunication";
import ElsaPlugin from "@core/components/ImmersiveMenu/Game/ElsaModule/ElsaPlugin";
import { useSound } from "@core/context/SoundContext";
import { QuestionTypeEnum } from "./types";
import FixQuestionGameEnd from "./GameEnd/FixQuestionGameEnd";
import calculateGamePerformance from "./GameEnd/calculateGamePerformance";
import { calculateGameRewards } from "./GameEnd/calculateGameRewards";
import Overlay from "@components/Overlay";
import {
  GamePerformanceData,
  GameRewardsResult,
  PronunciationResult,
} from "@core/sdgs_types";
import { UserResult } from "./types";
export type TalkGameProps = {
  CompleteAIGameData: CompleteAIGameData;
  onEnd: () => void;
  onScored: (score: any) => void;
  leaningJourneyLogUrl: string;
  onLogLearningJourney: (learning_journey_log: any) => void;
  onRoundEnd?: (userResult: UserResult) => void;
  NextQuestionCallBack?: (nextQuestion: () => void) => void;
  learningJourneyLogRef?: React.RefObject<LearningJourneyLog>;
  useDefaultGameEndComponent?: boolean;
};

export default function TalkGame({
  CompleteAIGameData,
  onEnd,
  onScored,
  leaningJourneyLogUrl,
  onLogLearningJourney,
  onRoundEnd,
  NextQuestionCallBack, // 把下一個問題的callback傳給父組件,讓其他組件可以控制下一個問題的開始
  learningJourneyLogRef,
  useDefaultGameEndComponent = true,
}: TalkGameProps) {
  const { playSound } = useSound();
  const [isElsaPopupVisible, setIsElsaPopupVisible] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const user = useRecoilValue(userAuthState);
  const prompts = useRecoilValue(promptsState);
  const [gamePerformanceData, setGamePerformanceData] =
    useState<GamePerformanceData>({
      score: 0,
      averageAnswerTime: 0,
      gameDuration: 0,
    });
  const [gameRewardsResult, setGameRewardsResult] = useState<GameRewardsResult>(
    {
      highlights: [],
      totalExp: 0,
      completion: "",
      dynamicEncouragement: "",
    }
  );
  const onUserAnswerResult = useCallback(
    (userResult: UserResult) => {
      console.log("學習歷程紀錄，玩家回答結果", userResult);
      if (!refLearningJourneyLog.current) {
        console.error("學習歷程紀錄尚未初始化");
        return;
      }
      refLearningJourneyLog.current.logs.push({
        content: userResult.content,
        userType: "player",
        isCorrect: userResult.isCorrect,
        advice: userResult.advice,
        score: userResult.score,
        username: user?.username || "player",
        questionType: aiMessage.currentQuestionType,
        timestamp: new Date().toISOString(),
        praticeCount: userResult.praticeCount || 1,
      });
      onLogLearningJourney(refLearningJourneyLog.current);
      if (userResult.isCorrect) {
        playSound("right");
      } else {
        playSound("wrong");
      }
      onRoundEnd && onRoundEnd(userResult);
    },
    [playSound, user]
  );

  const {
    sendUserMessage,
    startNewChat,
    nextQuestion,
    aiMessage,
    status,
    loading,
  } = useTalkGameCommunication(CompleteAIGameData, onUserAnswerResult);
  const handleSpeechToText = useHandleSpeechToText();
  const { imageUrl, generateImage, imagePrompt } = useGenerateImage();
  const [isAdvicePopupShow, setIsAdvicePopupShow] = useState(false);
  const [isMicOpen, setIsMicOpen] = useState(false);
  const isMessageSent = useRef(false);

  const { t } = useTranslation();
  const { userLastInput, setUserLastInput, isUserSentMsg, setIsUserSentMsg } =
    useUserInput();

  const refLearningJourneyLog = useRef<LearningJourneyLog>({
    logs: [],
    isFinished: false,
  });
  const refIsResultCalculated = useRef<boolean>(false);
  if (learningJourneyLogRef?.current) {
    refLearningJourneyLog.current = learningJourneyLogRef.current;
  }

  useEffect(() => {
    // 把下一個問題的callback傳給父組件,讓其他組件可以控制下一個問題的開始
    NextQuestionCallBack && NextQuestionCallBack(nextQuestion);
  }, [nextQuestion]);
  useEffect(() => {
    if (!startNewChat) return;
    startNewChat();
    const curTime = new Date().toISOString();
    if (refLearningJourneyLog.current) {
      refLearningJourneyLog.current.gamestart_timestamp = curTime;
    }
  }, [startNewChat]);

  useEffect(() => {
    if (!aiMessage) return;
    if (!aiMessage.content) return;
    if (!refLearningJourneyLog.current) return;
    if (status === "idle") {
      console.log("ai已經回答完畢, 紀錄log", aiMessage);
      isMessageSent.current = false;
      setIsUserSentMsg(false);
      setIsMicOpen(false);

      const lastLog =
        refLearningJourneyLog.current?.logs?.length > 0
          ? refLearningJourneyLog.current.logs[
              refLearningJourneyLog.current.logs.length - 1
            ]
          : undefined;

      if (
        lastLog &&
        lastLog.userType === "ai" &&
        lastLog?.content &&
        (lastLog.content === aiMessage.content ||
          aiMessage.content.startsWith(lastLog.content))
      ) {
        // 如果內容相同或是新訊息以舊訊息開頭，則覆蓋原本的log
        console.log("新的訊息和舊的訊息相同，覆蓋原本的log", aiMessage.content);
        refLearningJourneyLog.current.logs[
          refLearningJourneyLog.current.logs.length - 1
        ] = {
          content: aiMessage.content,
          userType: "ai",
          username: CompleteAIGameData.AIGameData.avatarName,
          options: aiMessage.options,
          questionType: aiMessage.currentQuestionType,
          timestamp: new Date().toISOString(),
        };
      } else {
        // 如果內容不同，則push新的log
        console.log("新的訊息和舊的訊息不同，push新的log", aiMessage.content);
        refLearningJourneyLog.current.logs.push({
          content: aiMessage.content,
          userType: "ai",
          username: CompleteAIGameData.AIGameData.avatarName,
          options: aiMessage.options,
          questionType: aiMessage.currentQuestionType,
          timestamp: new Date().toISOString(),
        });
      }
      onLogLearningJourney(refLearningJourneyLog.current);
    }
  }, [aiMessage, status]);
  useEffect(() => {
    if (status === "game_end" && !refIsResultCalculated.current) {
      if (!refLearningJourneyLog.current) {
        console.error("學習歷程紀錄是空的，無法計算遊戲結果");
        return;
      }
      refIsResultCalculated.current = true;
      console.log("game_end 計算遊戲結果", refLearningJourneyLog.current);
      const gamePerformanceData = calculateGamePerformance(
        refLearningJourneyLog.current
      );
      const gameRewardsResult = calculateGameRewards(gamePerformanceData);
      setGamePerformanceData(gamePerformanceData);
      setGameRewardsResult(gameRewardsResult);

      refLearningJourneyLog.current.isFinished = true;
      refLearningJourneyLog.current.generated_image = imageUrl || "";
      const finalTime = new Date().toISOString();
      refLearningJourneyLog.current.gameend_timestamp = finalTime;

      onScored(gamePerformanceData.score);
      refLearningJourneyLog.current.finalScore = gamePerformanceData.score;
      refLearningJourneyLog.current.comment = gameRewardsResult.completion;
      refLearningJourneyLog.current.gamePerformanceData = gamePerformanceData;
      refLearningJourneyLog.current.gameRewardsResult = gameRewardsResult;
      onLogLearningJourney(refLearningJourneyLog.current);
      onEnd();
    }
  }, [status]);
  const handleImageGeneration = useCallback(
    (message: string) => {
      const imagePrompts = `使用者描述的視覺元素是：${message}。${
        prompts.ImagePrompt ? " 整體的視覺風格：" + prompts.ImagePrompt : ""
      }, ${
        CompleteAIGameData.AIGameData.customPrompts.imagePrompt
          ? " 額外的提示：" +
            CompleteAIGameData.AIGameData.customPrompts.imagePrompt
          : ""
      }`;
      generateImage(imagePrompts);
    },
    [CompleteAIGameData.AIGameData.customPrompts.imagePrompt, generateImage]
  );
  const onUserMessageSent = (message: string) => {
    if (isMessageSent.current) {
      console.log("已經送出過訊息了, 重複送出訊息", message);
      return;
    }
    isMessageSent.current = true;

    // 當在特定題型的時候，擷取玩家回答的時候，進行特殊處理
    switch (aiMessage.currentQuestionType) {
      case QuestionTypeEnum.生圖:
        handleImageGeneration(message);
        break;
    }
    setIsUserSentMsg(true);
    setUserLastInput(message);
    sendUserMessage(message);
  };

  const inputTypeSelected = () => {
    let result = input_mode.VOICE;

    if (aiMessage.currentQuestionType === QuestionTypeEnum.多選) {
      result = input_mode.MULTI;
    }
    if (aiMessage.currentQuestionType == QuestionTypeEnum.單選) {
      result = input_mode.CHOIC;
    }
    if (aiMessage.currentQuestionType == QuestionTypeEnum.發音) {
      result = input_mode.ELSA;
    }
    return result;
  };

  useEffect(() => {
    if (aiMessage.currentQuestionType === QuestionTypeEnum.劇情選擇) {
      setSearchParams({ story: "open" }, { replace: true });
      return;
    } else searchParams.delete("story");

    if (aiMessage.currentQuestionType === QuestionTypeEnum.生圖) {
      setSearchParams({ image: "open" }, { replace: true });
      return;
    } else searchParams.delete("image");

    setSearchParams(searchParams, { replace: true });
  }, [aiMessage]);
  const onElsaEnd = (score: number, praticeCount: number) => {
    const pronunciationResult: PronunciationResult = {
      game_type: "發音",
      score: score,
      praticeCount: praticeCount,
    };
    onUserMessageSent(JSON.stringify(pronunciationResult));
  };
  if (loading) return <Overlay show={true} />;
  return (
    <>
      <MetaTagsProps
        title={truncateString(
          "immers - " + CompleteAIGameData.IntroductionProps?.title,
          50
        )}
        description={truncateString(
          CompleteAIGameData.IntroductionProps?.description || "",
          50
        )}
        image={CompleteAIGameData.IntroductionProps?.backgroundImageUrl}
        url={`https://english.botrun.ai/}`}
      />
      <ElsaPlugin
        praticeText={
          aiMessage.options
            ? aiMessage.options.length > 0
              ? aiMessage.options[0]
              : ""
            : ""
        }
        onEnd={onElsaEnd}
        isPopupVisible={isElsaPopupVisible}
        setIsPopupVisible={setIsElsaPopupVisible}
      />
      <div className="game-main-area">
        <div className="container">
          <BotChatItem
            avatarUrl={CompleteAIGameData.AIGameData.avatarUrl}
            avatarName={CompleteAIGameData.AIGameData.avatarName}
            message={aiMessage.content}
            keywords={CompleteAIGameData.Keywords}
            voiceName={CompleteAIGameData.AIGameData.avatarVoice}
            isShow={true}
            onMessageSend={onUserMessageSent}
            suggestions={[]}
            isNewMessageBegin={status === "idle"}
            onAdviceClick={() => {
              setIsAdvicePopupShow(true);
            }}
            isForceVoiceStop={isMicOpen || isUserSentMsg}
          />

          <SelfChatItem
            avatarUrl={CompleteAIGameData.AIGameData.playerUrl}
            avatarName={user?.username || t("player")}
            message={userLastInput}
            isShow={status != "ai_response" && isUserSentMsg}
          />
        </div>
      </div>
      {isUserSentMsg ? null : (
        <GameInputArea
          onMessageSend={onUserMessageSent}
          handleSpeechToText={handleSpeechToText}
          isActive={status === "idle"}
          setMicOpen={setIsMicOpen}
          lastBotSuggestions={aiMessage.options}
          DefaultMode={inputTypeSelected()}
          setIsElsaPopupVisible={setIsElsaPopupVisible}
        />
      )}

      <QuestionAdvicePopup
        avatarUrl={CompleteAIGameData.AIGameData.avatarUrl}
        lastBotMessage={aiMessage.content}
        keywords={CompleteAIGameData.Keywords}
        isPopupVisible={isAdvicePopupShow}
        setIsPopupVisible={setIsAdvicePopupShow}
        voiceName={CompleteAIGameData.AIGameData.avatarVoice}
      />
      <div className="effect-container"></div>

      <FixQuestionGameEnd
        isShow={status === "game_end" && useDefaultGameEndComponent}
        avatarUrl={CompleteAIGameData.AIGameData.avatarUrl}
        imageUrl={imageUrl || ""}
        gameRewardsResult={gameRewardsResult}
        gamePerformanceData={gamePerformanceData}
        leaningJourneyLogUrl={leaningJourneyLogUrl}
        learningJourneyLog={refLearningJourneyLog.current}
      />
    </>
  );
}
