import React from "react";
import { useNavigate } from "react-router-dom";

const CreatorNavi: React.FC = () => {
  const navigate = useNavigate();

  return (
    <nav className="db-nav">
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          navigate("/createLevel/l/");
        }}
      >
        <span>新關卡</span>
      </a>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          navigate("/createLevel/bds/b/");
        }}
      >
        <span>新角色</span>
      </a>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          navigate("/createLevel/bds/");
        }}
      >
        <span>我的角色庫</span>
      </a>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          navigate("/createLevel");
        }}
      >
        <span>我的關卡庫</span>
      </a>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          navigate("/");
        }}
      >
        <span>回到遊戲</span>
      </a>
    </nav>
  );
};

export default CreatorNavi;
