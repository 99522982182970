import EnhancedMessage from "../GeneralComponent/EnhancedMessage";
import { Keywords } from "../../sdgs_types";
interface DialogueContentProps {
  dialogueText: string;
  tipText: string;
  keywords: Keywords;
}

export const DialogueContent: React.FC<DialogueContentProps> = ({
  dialogueText,
  tipText,
  keywords,
}) => (
  <div className="target">
    <div className="target-content">
      <EnhancedMessage message={dialogueText} keywords={keywords} />
    </div>
    <div className="tips">
      <i className="icon icon-12"></i>
      <span>{tipText}</span>
    </div>
  </div>
);
