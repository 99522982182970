export default function index({ show }: { show: boolean }) {
  return (
    <div className={`game-popup-area for-loading ${show ? "--active" : ""}`}>
      <div className="popup-container">
        <div className="loading-container">
          <div className="loading-text">
            <i>L</i>
            <i>O</i>
            <i>A</i>
            <i>D</i>
            <i>I</i>
            <i>N</i>
            <i>G</i>
          </div>
          <span className="small-text">遊戲載入中</span>
        </div>
      </div>
    </div>
  )
}
