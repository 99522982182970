import React, { useState } from "react";

interface TabButton {
  label: string;
  onClick: () => void;
}

interface TabButtonsProps {
  buttons: TabButton[];
  defaultIndex?: number;
}

const TabButtons: React.FC<TabButtonsProps> = ({
  buttons,
  defaultIndex = 0,
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);

  return (
    <div className="tab-buttons">
      {buttons.map((button, index) => (
        <button
          key={index}
          className={activeIndex === index ? "--active" : ""}
          onClick={() => {
            setActiveIndex(index);
            button.onClick();
          }}
        >
          {button.label}
        </button>
      ))}
    </div>
  );
};

export default TabButtons;
