import BaseWritingGame from "./BaseWritingGame";
import {
  useTurnBasedWriting,
  Writer,
} from "@core/components/ImmersiveMenu/Game/WriteGame/useTurnBasedWriting";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import { useEffect, useState } from "react";
import { useRef } from "react";
import WriteGameEndPopup, {
  WritingGameEndPopupRef,
} from "./GameEnd/WriteGameEndPopup";
import { BaseWritingGameRef } from "./BaseWritingGame";
import { useProfile } from "@core/context/UserSettingsContext";
import { WriteResult } from "@core/hooks/useWritingScore";
import { calculateGameRewards } from "./GameEnd/calculateGameRewards";
import calculateGamePerformance from "../WriteGame/GameEnd/calculateGamePerformance";
import { LearningJourneyLog } from "@core/sdgs_types";
interface SinglePlayerWritingGameProps {
  title: string;
  descriptions: string;
  rules: string;
  background: string;
  minWords?: number;
  maxWords?: number;
  onEnd: () => void;
  onScored?: (score: any) => void;
  onLogLearningJourney?: (learning_journey_log: any) => void;
  leaningJourneyLogUrl?: string;
}
export const sendGameEndLog = (
  logFunction: (log: any) => void,
  learningJourneyLog: LearningJourneyLog
) => {
  learningJourneyLog.gameend_timestamp = new Date().toISOString();
  console.log(
    "寫作遊戲結束，送出最後的學習歷程結束的時間點",
    learningJourneyLog
  );
  logFunction(learningJourneyLog);
};
export const sendFinalResultLog = (
  logFunction: (log: any) => void,
  writeResult: WriteResult,
  learningJourneyLog: LearningJourneyLog
) => {
  console.log(
    "寫作遊戲結束，送出最後的學習歷程結果sendFinalResultLog",
    writeResult
  );
  const finalScore =
    writeResult.content +
    writeResult.organization +
    writeResult.grammarAndSyntax +
    writeResult.vocabularyAndSpelling;

  const gamePerformanceData = calculateGamePerformance(
    learningJourneyLog,
    writeResult
  );
  const gameRewardsResult = calculateGameRewards(
    gamePerformanceData,
    writeResult
  );
  learningJourneyLog.isFinished = true;
  learningJourneyLog.finalScore = finalScore;
  learningJourneyLog.comment = writeResult.comment;
  learningJourneyLog.gamePerformanceData = gamePerformanceData;
  learningJourneyLog.gameRewardsResult = gameRewardsResult;
  logFunction(learningJourneyLog);
};
export default function WriteGame(props: SinglePlayerWritingGameProps) {
  const user = useRecoilValue(userAuthState);
  const { getter } = useProfile();
  const { data: profile, refetch } = getter;
  const [writerList, setwriterList] = useState<Writer[]>([]);
  const [isEndPopupVisible, setIsEndPopupVisible] = useState(false);
  const endPopupRef = useRef<WritingGameEndPopupRef>(null);
  const writingGameRef = useRef<BaseWritingGameRef>(null);
  useEffect(() => {
    if (user) {
      const writers: Writer[] = [
        { username: "AI作者", nickname: "AI", userType: "ai" },
        {
          username: user.username,
          nickname: profile?.nickname || "玩家",
          userType: "player",
        },
      ];
      setwriterList(writers);
    }
  }, [user]);

  const handleGameEnd = async (result: any) => {
    setIsEndPopupVisible(true);
    if (writingGameRef.current && props.onLogLearningJourney) {
      sendGameEndLog(
        props.onLogLearningJourney,
        writingGameRef.current.refWritingLearningJourneyLog
      );
    }

    endPopupRef.current?.generateScoreAndComment(
      props.title,
      props.rules,
      props.descriptions,
      result.writingText,
      profile?.nickname || "玩家",
      result.conversationLogs
    );
    props.onEnd();
  };

  const useWritingLogic = () =>
    useTurnBasedWriting(
      writerList,
      props.title,
      props.descriptions,
      props.rules
    );
  const handleScoreGenerated = (writeResult: WriteResult) => {
    // 送出寫作的最後學習歷程總結
    if (props.onLogLearningJourney) {
      sendFinalResultLog(
        props.onLogLearningJourney,
        writeResult,
        writingGameRef.current?.refWritingLearningJourneyLog!
      );
    }
    const finalScore =
      writeResult.content +
      writeResult.organization +
      writeResult.grammarAndSyntax +
      writeResult.vocabularyAndSpelling;
    props.onScored?.(finalScore);
  };

  return (
    <>
      <BaseWritingGame
        ref={writingGameRef}
        title={props.title}
        background={props.background}
        rules={props.rules}
        descriptions={props.descriptions}
        minWords={props.minWords}
        maxWords={props.maxWords}
        onEnd={handleGameEnd}
        useWritingLogic={useWritingLogic}
        onLogLearningJourney={props.onLogLearningJourney}
        leaningJourneyLogUrl={props.leaningJourneyLogUrl}
      />
      <WriteGameEndPopup
        ref={endPopupRef}
        isPopup={isEndPopupVisible}
        setIsPopup={setIsEndPopupVisible}
        learningJourneyLog={
          writingGameRef.current?.refWritingLearningJourneyLog!
        }
        leaningJourneyLogUrl={props.leaningJourneyLogUrl}
        onScoreGenerated={handleScoreGenerated}
      />
    </>
  );
}
