import { MessageProps } from "@botrun/chat"
import { useState, useRef } from "react"
import { userAuthState } from "@core/recoil/atoms"
import { useRecoilValue } from "recoil"
import useSessionId from "@hooks/useSessionId"
import useBotrunWebSocket from "@hooks/useBotrunWebSocket"

export function useBotrunCommunication() {
  const user = useRecoilValue(userAuthState)
  // const [botrunId, setBotrunID] = useState<string | null>("index.botrun")
  // const id = botrunId ? botrunId : "index.botrun"
  const [sessionId, setSessionId] = useState<string | null>(null)

  const getSessionId = useSessionId(user?.token)
  const [messages, setMessages] = useState<MessageProps[]>([])
  const [assistant, setAssistant] = useState<string | null>(null)

  // const [enableWS, setEnableWS] = useState<boolean>(true)
  const [isSocketError, setIsSocketError] = useState<boolean>(false)

  // const { data: welcomeMessage } = useWelcome({ botrunId: id, isCreated: sessionId !== undefined })
  const isCreatedRef = useRef(true)

  const starNewChat = async (newBotrunId: string) => {
    setSessionId(null) // 重設 session ID，強制重新獲取
    setAssistant(null)
    // setIsSocketError(false)
    const newID = await getSessionId(isCreatedRef)
    setSessionId(newID)
    console.log("已取得 sessionId", sessionId)
    if (newBotrunId) {
      console.log("啟動遊戲 newBotrunId", newBotrunId)
      //setBotrunID(newBotrunId);

      setTimeout(() => {
        sendMessage(newBotrunId, newID)
      }, 500) // 1000 毫秒後執行
    }

    // setIsSocketError(true) // 更新狀態以通知用戶有錯誤發生
  }

  // function whichAssistant() {
  //   if (assistant === null) return null
  //   if (assistant === "responding")
  //     return (
  //       <>
  //         <button className="br-btn small" onClick={stopResponding}>
  //           <i className="icon icon-1"></i>
  //           <span>停止回應</span>
  //         </button>
  //         <p>波特人正在輸入訊息...</p>
  //       </>
  //     )
  //   else if (assistant === "upload") return <p>正在上傳檔案...</p>
  //   else if (assistant === "speech2Text") return <p>正在轉換文字中...</p>
  //   else return <p>{assistant}</p>
  // }
  // const resendLastMessage = (sessionId: string) => {
  //   setIsSocketError(false)
  //   const lastMessage = messages[messages.length - 1]
  //   if (lastMessage) {
  //     sendJsonMessage({
  //       user_input: lastMessage.content,
  //       jwt_token: user?.token,
  //       session_id: sessionId,
  //     })
  //     setAssistant("responding")
  //   }
  // }
  // 這個函數用於發送用戶輸入的訊息
  const onMessageSend = async (message: string) => {
    //  const sessionId = await getSessionId(isCreatedRef)
    if (sessionId) sendMessage(message, sessionId)
    else console.log("sessionId is null", sessionId)
  }

  const { sendJsonMessage } = useBotrunWebSocket({
    // enable: enableWS,
    enable: true,
    setAssistant,
    setMessages,
    onMessageSend,
    setIsSocketError,
  })

  const sendMessage = (message: string, sessionId: string) => {
    console.log("sendMessage", message, sessionId)
    const newMessage: MessageProps = {
      sender: "self",
      avatar:
        "https://images.unsplash.com/photo-1485178575877-1a13bf489dfe?q=80&w=801&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      name: user?.username as string,
      content: message,
    }

    //setMessages(prev => [...prev, newMessage])
    // 使用函數式更新來檢查前一條消息和新消息是否相同
    setMessages(prevMessages => {
      const lastMessage = prevMessages[prevMessages.length - 1]
      if (lastMessage && lastMessage.content === newMessage.content) {
        console.log("遇到重複傳送相同訊息的狀況", newMessage.content, "不添加新消息")
        return prevMessages // 如果與前一條消息相同，則不添加新消息
      }
      return [...prevMessages, newMessage] // 否則添加新消息
    })

    sendJsonMessage({
      user_input: message,
      jwt_token: user?.token,
      session_id: sessionId,
    })
    setAssistant("responding")
  }
  const addBotMessage = (message: string) => {
    const newMessage: MessageProps = {
      sender: "others",
      avatar: "",
      name: "default",
      content: message,
    }
    setMessages(prev => [...prev, newMessage])
  }
  const addSystemMessage = (message: string) => {
    const newMessage: MessageProps = {
      sender: "system",
      avatar: "",
      name: "default",
      content: message,
    }
    setMessages(prev => [...prev, newMessage])
  }

  //todo: 重置訊息
  // const resetMessage = () => {
  //   if (!welcomeMessage) {
  //     setMessages([])
  //     return
  //   }
  // }

  // const stopResponding = () => {
  //   setAssistant(null)
  //   setEnableWS(false)
  //   setTimeout(() => {
  //     setEnableWS(true)
  //   }, 200)
  // }

  // useEffect(() => {
  //   if (welcomeMessage && botrunId !== "index.botrun") resetMessage()
  // }, [welcomeMessage, botrunId])

  // useEffect(() => {
  //   if (!sessionId) {
  //     resetMessage()
  //     stopResponding()
  //   }
  // }, [sessionId])

  return {
    addSystemMessage,
    messages,
    sendUserMessage: onMessageSend,
    // whichAssistant,
    assistant,
    starNewChat,
    addBotMessage,
  }
}
