import React, { useState, useEffect } from "react"
import GameInputArea, { input_mode } from "../GeneralComponent/GameInputArea"
import { useBotrunCommunication } from "../../hooks/useBotrunCommunication"
import useHandleSpeechToText from "../../hooks/useHandleSpeechToText"
import BotChatItem from "../GeneralComponent/BotChatItem"
import SelfChatItem from "../GeneralComponent/SelfChatItem"
import { useOneChatMessages } from "../../hooks/useOneChatMessage"
import { useRecoilValue } from "recoil"
import { userAuthState } from "@core/recoil/atoms"
import { Keywords } from "../../sdgs_types"
interface avatarProps {
  avatarUrl: string
  avatarName: string
  avatarVoice: string
  botrunId: string
}

export const SimpleChat: React.FC<avatarProps> = avatarProps => {
  const user = useRecoilValue(userAuthState)
  const { messages, sendUserMessage, starNewChat, assistant } = useBotrunCommunication()
  const {
    lastBotMessage,
    lastSelfMessage,
    isBotChatShow,
    isSelfChatShow,
    isPlayerTurn,
    gameFlow,
    lastBotSuggestions,
  } = useOneChatMessages(messages)

  const handleSpeechToText = useHandleSpeechToText()
  const [hasGameStarted, setHasGameStarted] = useState(false)
  const [userLastInput, setUserLastInput] = useState("")
  const [isUserSentMsg, setIsUserSentMsg] = useState(false)
  const [isMicOpen, setIsMicOpen] = useState(false)

  useEffect(() => {
    if (assistant === null) {
      setIsUserSentMsg(false)
      setIsMicOpen(false)
    }
  }, [assistant])

  useEffect(() => {
    if (!hasGameStarted) {
      console.log("OneChat元件第一次載入，啟動遊戲", messages)
      starNewChat(avatarProps.botrunId)
      setHasGameStarted(true)
    }
  }, [hasGameStarted])

  const onUserMessageSent = (message: string) => {
    setIsUserSentMsg(true)
    setUserLastInput(message)
    sendUserMessage(message)
  }
  const onAdviceClick = () => {}
  const emptyKeyword: Keywords = []
  return (
    <div
      className={`game-container --type-betalayout-mode-desktop
      `}
    >
      <div className="game-main-area">
        <div className="container">
          <BotChatItem
            avatarUrl={avatarProps.avatarUrl}
            avatarName={avatarProps.avatarName}
            message={lastBotMessage}
            keywords={emptyKeyword}
            voiceName={avatarProps.avatarVoice}
            isShow={isBotChatShow}
            onMessageSend={onUserMessageSent}
            suggestions={[]}
            isNewMessageBegin={assistant === null}
            onAdviceClick={onAdviceClick}
            isForceVoiceStop={isMicOpen || isUserSentMsg}
          />

          <SelfChatItem
            avatarUrl={""}
            avatarName={user?.username || "Player"}
            message={userLastInput}
            isShow={isSelfChatShow && isUserSentMsg}
          />
        </div>
      </div>
      {assistant ? null : isUserSentMsg ? null : (
        <GameInputArea
          onMessageSend={onUserMessageSent}
          handleSpeechToText={handleSpeechToText}
          isActive={isPlayerTurn}
          setMicOpen={setIsMicOpen}
          lastBotSuggestions={lastBotSuggestions}
          DefaultMode={input_mode.TEXT}
        />
      )}
    </div>
  )
}
