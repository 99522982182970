import { CharacterProbs } from "@core/sdgs_types";
import { useListGameMenu } from "@core/hooks/useApi"; // 確保路徑是正確的
import React, { useEffect, useState } from "react";
import Dropdown from "./Dropdown";
import { aiBuddyPropsTags, CharacterDataLabels } from "@core/sdgs_types";
import useLoadGeneralData from "@core/hooks/LoadData/useLoadGeneralData";
import TextInputField from "./TextInputField";
import { useRecoilState } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import Overlay from "@components/Overlay";
interface CharacterDropdownProps {
  label: string;
  onSelected: (character: CharacterProbs) => void;
  defaultOption: string;
  wellcomeMessage: string;
  onChangeWellcomeMessage?: (value: string) => void;
}
const CharacterDropdown: React.FC<CharacterDropdownProps> = ({
  label,
  onSelected,
  defaultOption,
  wellcomeMessage,
  onChangeWellcomeMessage,
}) => {
  const [user] = useRecoilState(userAuthState);
  const rootFolderId =
    process.env.REACT_APP_ROOT_FOLDER_ID || "1ZHRyYiqhzSmxUCSweGWiYXkagFS6TLGc";

  // 使用 useState 来模拟数据的加载状态，未來可以改为使用 useQuery

  const { gameMenu, error, loading } = useListGameMenu(
    rootFolderId,
    ".bd",
    user?.username + "/LearningBuddy"
  );
  const { gameMenu: defaultMenu, loading: defualtLoading } = useListGameMenu(
    rootFolderId,
    ".bd",
    "_Default"
  );

  const {
    gameData: characterData,
    loading: dataLoading,
    error: dataLoadingError,
    loadData,
  } = useLoadGeneralData<CharacterProbs>();
  const [selectedOption, setSelectedOption] = useState<string>("");
  const [characterOptions, setCharacterOptions] = useState<
    Record<string, string>
  >({});

  const getOptionIDInMenu = (name: string) => {
    const allMenu = defaultMenu.concat(gameMenu);

    if (allMenu.length === 0) return "";
    return allMenu.find((option) => option.name === name)?.id;
  };
  useEffect(() => {
    const allMenu = defaultMenu.concat(gameMenu);

    if (allMenu === null) return;
    if (allMenu === undefined) return;
    if (allMenu.length === 0) return;
    allMenu.map((option) => {
      setCharacterOptions((prev) => ({
        ...prev,
        [option.name]: "",
      }));
    });
    const nameList = allMenu.map((option) => option.name);
    if (nameList.includes(defaultOption)) {
      console.log("選項中有找到了defaultOption", defaultOption);
      setSelectedOption(defaultOption);
    } else {
      console.log(
        "選項中沒有找到了defaultOption, 使用預設第一選項",
        defaultOption,
        allMenu[0].name,
        nameList
      );
      setSelectedOption(allMenu[0].name);
    }
  }, [gameMenu, defaultMenu]);
  useEffect(() => {
    if (selectedOption) {
      const fileID = getOptionIDInMenu(selectedOption);
      if (!fileID || fileID == undefined) return;
      loadData(fileID, aiBuddyPropsTags, CharacterDataLabels);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (characterData) {
      const fileID = getOptionIDInMenu(selectedOption);
      const newCharacter: CharacterProbs = {
        ...characterData,
        ID: fileID || "",
      };
      console.log("laod角色資料", characterData, characterData.wellcomeMessage);
      if (onChangeWellcomeMessage)
        onChangeWellcomeMessage(characterData.wellcomeMessage);

      onSelected(newCharacter);
    }
  }, [characterData]);

  useEffect(() => {
    if (characterData && wellcomeMessage == "") {
      if (characterData?.wellcomeMessage) {
        console.log(
          "空的，但是角色有固定歡迎詞",
          characterData?.wellcomeMessage
        );
        if (onChangeWellcomeMessage)
          onChangeWellcomeMessage(characterData?.wellcomeMessage);
      } else {
        console.log(
          "空的，角色歡迎詞也是空的，改用預設",
          characterData?.wellcomeMessage,
          characterData
        );
        if (onChangeWellcomeMessage)
          onChangeWellcomeMessage("=劇情選擇= 想要接受挑戰嗎, [yes]");
      }
    }
  }, [wellcomeMessage]);

  const onCharacterSelect = (key: string, value: string) => {
    setSelectedOption(key);
  };

  if (loading) return <Overlay show={true} />;
  return (
    <>
      {selectedOption && (
        <Dropdown
          label={label}
          options={characterOptions}
          onChange={onCharacterSelect}
          defaultValue={selectedOption}
        />
      )}
      {characterData ? (
        <div className="avatar">
          <div
            className="img"
            style={{ backgroundImage: `url('${characterData.imageUrl}')` }}
          ></div>
        </div>
      ) : (
        ""
      )}
      {characterData ? (
        <TextInputField
          label="welcomeMessage"
          placeholder="=劇情選擇= 想要接受挑戰嗎, [yes]"
          isRequired={true}
          helpText="=劇情選擇= 歡迎訊息, [選項]"
          value={wellcomeMessage}
          onChange={(value) => {
            onChangeWellcomeMessage && onChangeWellcomeMessage(value);
          }}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default CharacterDropdown;
