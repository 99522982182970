import React, { useState, useEffect } from "react";

interface DropdownProps {
  label: string;
  options: Record<string, string>;
  isRequired?: boolean;
  onChange: (key: string, value: string) => void;
  defaultValue?: string;
  isShowHelp?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  isRequired = false,
  onChange,
  defaultValue = "",
  isShowHelp = true,
}) => {
  const [selectedOption, setSelectedOption] = useState<string>(
    defaultValue
      ? defaultValue
      : Object.keys(options).length > 0
      ? Object.entries(options)[0][0]
      : ""
  );
  useEffect(() => {
    //console.log("defaultValue is changed", defaultValue);
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    onChange(selectedValue, options[selectedValue]);
  };

  return (
    <div
      className={`form-item ${isRequired ? "must" : ""} ${
        isShowHelp ? "show-help" : ""
      } `}
    >
      <div className="field">{label}</div>
      <div className="value">
        <select value={selectedOption} onChange={handleChange}>
          {Object.entries(options).map(([key, value]) => (
            <option key={key} value={key}>
              {key}
            </option>
          ))}
        </select>
        {selectedOption && options[selectedOption] && (
          <>
            <div className="help-word">
              <i className="icon icon-28 inline"></i>
              {options[selectedOption]}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Dropdown;
