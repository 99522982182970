import React, { ChangeEvent, CSSProperties, useEffect } from "react";
import { Question } from "@core/sdgs_types";
import Dropdown from "./Dropdown";
import { QuestionType } from "@core/sdgs_types";

import { ChevronUp, ChevronDown } from "lucide-react";
interface QuestionListProps {
  questions: Question[];
  setQuestions: (questions: Question[]) => void;
}
const QuestionList: React.FC<QuestionListProps> = ({
  questions,
  setQuestions,
}) => {
  const styles: { [key: string]: CSSProperties } = {
    questionList: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
    },
    questionItem: {
      border: "1px solid #ddd",
      padding: "15px",
      borderRadius: "5px",
    },
    questionHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "10px",
    },
    questionControls: {
      display: "flex",
      gap: "5px",
    },
    questionInputs: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    input: {
      padding: "5px",
      borderRadius: "3px",
      border: "1px solid #ccc",
    },
    options: {
      marginTop: "15px",
    },
    optionItem: {
      display: "flex",
      alignItems: "center",
      marginBottom: "5px",
    },
    button: {
      background: "none",
      border: "none",
      fontSize: "18px",
      cursor: "pointer",
      padding: "0 5px",
    },
    removeBtn: {
      color: "#ff4d4f",
    },
    addBtn: {
      color: "#52c41a",
    },
    moveBtn: {
      color: "#1890ff",
    },
    addQuestionBtn: {
      alignSelf: "flex-start",
      marginTop: "10px",
      padding: "8px 16px",
      backgroundColor: "#2e7d32",
      color: "white",
      border: "none",
      borderRadius: "4px",
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "bold",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    },
  };

  const handleTypeChange = (index: number, typeName: string) => {
    const newQuestions = [...questions];
    newQuestions[index].type = typeName;
    setQuestions(newQuestions);
  };

  const handleQuestionChange = (
    index: number,
    event: ChangeEvent<HTMLTextAreaElement>
  ) => {
    const newQuestions = [...questions];
    newQuestions[index].question = event.target.value;
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (
    index: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newQuestions = [...questions];
    newQuestions[index].answer = event.target.value;
    setQuestions(newQuestions);
  };

  const handleOptionsChange = (
    index: number,
    optionIndex: number,
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const newQuestions = [...questions];
    newQuestions[index].options[optionIndex] = event.target.value;
    setQuestions(newQuestions);
  };

  const addOption = (index: number) => {
    const newQuestions = [...questions];
    newQuestions[index].options.push("");
    setQuestions(newQuestions);
  };

  const removeOption = (index: number, optionIndex: number) => {
    const newQuestions = [...questions];
    newQuestions[index].options.splice(optionIndex, 1);
    setQuestions(newQuestions);
  };

  const addQuestion = () => {
    const newQuestion: Question = {
      type: "單選",
      question: "",
      answer: "",
      options: [],
    };
    setQuestions([...questions, newQuestion]);
  };

  const removeQuestion = (index: number) => {
    const newQuestions = [...questions];
    newQuestions.splice(index, 1);
    setQuestions(newQuestions);
  };
  const moveQuestion = (index: number, direction: "up" | "down") => {
    const newQuestions = [...questions];
    if (direction === "up" && index > 0) {
      [newQuestions[index - 1], newQuestions[index]] = [
        newQuestions[index],
        newQuestions[index - 1],
      ];
    } else if (direction === "down" && index < newQuestions.length - 1) {
      [newQuestions[index], newQuestions[index + 1]] = [
        newQuestions[index + 1],
        newQuestions[index],
      ];
    }
    setQuestions(newQuestions);
  };

  return (
    <div style={styles.questionList}>
      {questions.map((entry: Question, index: number) =>
        // if entry is undefined, return null
        !entry ? null : (
          <div key={`question-${index}`} style={styles.questionItem}>
            <div style={styles.questionHeader}>
              <span>題目 {index + 1}</span>
              <div style={styles.questionControls}>
                <button
                  onClick={() => moveQuestion(index, "up")}
                  style={{ ...styles.button, ...styles.moveBtn }}
                  title="Move Up"
                  disabled={index === 0}
                >
                  <ChevronUp size={18} />
                </button>
                <button
                  onClick={() => moveQuestion(index, "down")}
                  style={{ ...styles.button, ...styles.moveBtn }}
                  title="Move Down"
                  disabled={index === questions.length - 1}
                >
                  <ChevronDown size={18} />
                </button>
                <button
                  onClick={() => removeQuestion(index)}
                  style={{ ...styles.button, ...styles.removeBtn }}
                  title="Remove Question"
                >
                  ×
                </button>
              </div>
            </div>
            <div style={styles.questionInputs}>
              <Dropdown
                key={`dropdown-${index}-${entry.type}`}
                label="類型"
                options={QuestionType}
                defaultValue={entry.type}
                isRequired={true}
                onChange={(selectedValue: string, description?: string) => {
                  handleTypeChange(index, selectedValue);
                }}
              />
              <textarea
                placeholder="提問"
                value={entry.question}
                onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                  handleQuestionChange(index, e)
                }
                style={styles.textarea}
              />
              <input
                type="text"
                placeholder="答案, 多選範例：apple, orange, banana"
                value={entry.answer}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleAnswerChange(index, e)
                }
                style={styles.input}
              />
            </div>
            <div style={styles.options}>
              <span>選項</span>
              {entry.options?.map((option: string, optionIndex: number) => (
                <div key={optionIndex} style={styles.optionItem}>
                  <input
                    type="text"
                    placeholder={`選項 ${optionIndex + 1}`}
                    value={option}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleOptionsChange(index, optionIndex, e)
                    }
                    style={styles.input}
                  />
                  <button
                    onClick={() => removeOption(index, optionIndex)}
                    style={{ ...styles.button, ...styles.removeBtn }}
                    title="Remove Option"
                  >
                    ×
                  </button>
                </div>
              ))}
              <button
                onClick={() => addOption(index)}
                style={{ ...styles.button, ...styles.addBtn }}
              >
                +
              </button>
            </div>
          </div>
        )
      )}
      <button onClick={addQuestion} style={styles.addQuestionBtn}>
        + Add New Question
      </button>
    </div>
  );
};

export default QuestionList;
