import React, { useState, useMemo, useEffect } from "react";
import CountBoard from "./CountBoard";
import { userAuthState } from "@core/recoil/atoms";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { WritingEntry } from "./useTurnBasedWriting";
interface WritingBoardProps {
  title: string;
  text: string;
  writingEntry: WritingEntry[];
  handleTimeUp: () => void;
  duration?: number;
  userName: string;
  nickname: string;
  onAdviceClick: () => void;
  minWords: number;
  maxWords: number;
  onCompleted: () => void;
}

const WritingBoard: React.FC<WritingBoardProps> = ({
  title,
  text,
  writingEntry,
  handleTimeUp,
  duration = 1200, // 20 minutes in seconds
  userName,
  nickname,
  onAdviceClick,
  minWords,
  maxWords,
  onCompleted,
}) => {
  const { t } = useTranslation();
  const user = useRecoilValue(userAuthState);
  const isSelfTurn = user?.username === userName;

  const [wordCount, setWordCount] = useState(0);
  const { isGameEndable } = useMemo(() => {
    const count = text
      .trim()
      .split(/\s+/)
      .filter((word) => word.length > 0).length;
    setWordCount(count);
    const isGameEndable = count >= minWords;
    return { isGameEndable };
  }, [text, minWords, maxWords]);

  return (
    <div className="write-billboard --active">
      <div className="top-area">
        <div className="left">
          <p>{title}</p>
        </div>
        <div className="right">
          <CountBoard count={duration} onTimeUp={handleTimeUp} />
        </div>
      </div>
      <div className="bottom-area">
        <div className="article">
          <p>
            {writingEntry.map((entry, i) => (
              <span
                key={i}
                className={
                  entry.writer.username === user?.username ? "self" : ""
                }
              >
                {entry.content}
              </span>
            ))}
            {isSelfTurn ? (
              <>
                <strong className="part --yourturn">
                  <i className="icon icon-27"></i>
                  {t("yourTurn", { username: nickname })}
                </strong>
                <strong className="part" onClick={onAdviceClick}>
                  <i className="icon icon-17"></i>
                  {t("needHelp")}
                </strong>
                {isGameEndable && (
                  <strong className="part --finished" onClick={onCompleted}>
                    <i className="icon icon-18"></i>
                    {t("writingComplete")}
                  </strong>
                )}
              </>
            ) : (
              <strong className="part --others">
                <i className="icon icon-28"></i>
                {t("userWriting", { writer: nickname })}
              </strong>
            )}
          </p>
        </div>
      </div>
      <div className="fn-area">
        <i className="icon icon-16"></i>
        <span>
          {wordCount < minWords
            ? t("insufficientWords", { count: minWords - wordCount })
            : wordCount > maxWords
            ? t("overWordLimit", { count: wordCount - maxWords })
            : t("canFinishWriting")}
        </span>
      </div>
    </div>
  );
};

export default WritingBoard;
