import React, { useEffect, useRef } from "react";

interface AnimatedBGProps {
  backgroundImageUrl?: string;
}

const AnimatedBG: React.FC<AnimatedBGProps> = ({
  backgroundImageUrl = "/images/bg-stage.jpg",
}) => {
  const bgRef = useRef<HTMLDivElement>(null);
  const panSpeed = 12; // 背景圖移動速度，單位為像素/秒

  useEffect(() => {
    let lastTimestamp: number | null = null;

    const moveBackground = (timestamp: number) => {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const elapsed = timestamp - lastTimestamp;
      const moveDistance = (elapsed / 1000) * panSpeed; // 移動的距離，單位為像素

      if (bgRef.current) {
        const currentPosX =
          parseFloat(bgRef.current.style.backgroundPositionX || "0") || 0;
        bgRef.current.style.backgroundPositionX = `${
          currentPosX - moveDistance
        }px`;
      }

      lastTimestamp = timestamp;
      requestAnimationFrame(moveBackground);
    };

    requestAnimationFrame(moveBackground);

    return () => cancelAnimationFrame(moveBackground as unknown as number); // 清除動畫幀
  }, []);

  return (
    <>
      <div className="game-bg-area">
        <div
          className="bg for-stage"
          style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundPositionX: "0px", // 確保初始值為 0
            backgroundRepeat: "repeat-x", // 確保背景圖水平重複
          }}
          ref={bgRef}
        ></div>
      </div>
      <div className="footer">
        <span className="contact-us">
          Copyright © 2024 CAMEO INC. All rights reserved.{" "}
          <a
            href="https://forms.gle/yKsUNUrU72oEv3ah8"
            target="_blank"
            rel="noopener noreferrer"
          >
            聯繫我們
          </a>
        </span>
      </div>
    </>
  );
};

export default AnimatedBG;
