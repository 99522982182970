import { AxiosResponse, AxiosError } from "axios"
import { useMutation, useQuery } from "react-query"
import { request } from "@utils/axios-util"
import { ProgressProps } from "@utils/types"
import { userAuthState } from "@core/recoil/atoms"
import { useRecoilValue } from "recoil"
import { useUserSettings } from "@core/context/UserSettingsContext"

export default function usePlayerProgress() {
  const user = useRecoilValue(userAuthState)
  const { settings } = useUserSettings()
  const difficulty = settings.difficulty

  const queryFn = () =>
    request({
      url: `/game/player/progress`,
      method: "get",
      params: {
        player_id: user?.username,
        difficulty,
      },
    })

  const getter = useQuery(["fetch-player-progress-api", user?.id, difficulty], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error)
    },
    select: (res: AxiosResponse): ProgressProps => {
      return res.data
    },
  })

  const mutationFn = (data: object) =>
    request({
      url: `/game/player/progress`,
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    })

  const setter = useMutation({
    mutationFn,
  })
  return { getter, setter }
}
