import React, { useState, ChangeEvent, FormEvent, useEffect } from "react";
import { SimpleChat } from "../../SimpleChat";
import { useParams } from "react-router-dom";
import useLoadGeneralData from "@core/hooks/LoadData/useLoadGeneralData";
import { aiBuddyPropsTags, CharacterDataLabels } from "@core/sdgs_types";
import { CharacterGenerator } from "./CharacterGenerator";
import { CharacterProbs } from "@core/sdgs_types";
import { useUploadFileToDrive } from "../../../hooks/useApi";
import CreatorNavi from "../CreatorNavi";

import Overlay from "@components/Overlay";
const CreateCharacter: React.FC = () => {
  const { buddyID } = useParams();
  const [toggleTest, setToggleTest] = useState<boolean>(false);
  const {
    response,
    error: uploadError,
    loading: uplaodLoading,
    uploadFileToDrive,
  } = useUploadFileToDrive();
  useEffect(() => {
    console.log("upload done, response:", response);
  }, [response]);

  const {
    gameData,
    loading: dataLoading,
    error: dataLoadingError,
    loadData,
  } = useLoadGeneralData<CharacterProbs>();
  let defautCharacter: CharacterProbs = {
    Name: "",
    Appearance: "",
    TalkStyle: "",
    wellcomeMessage: "",
    imageUrl: "",
    VoiceName: "nova",
    ID: "",
  };
  const [character, setCharacter] = useState<CharacterProbs | null>(
    defautCharacter
  );
  useEffect(() => {
    // 創建一個新的 link 元素
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/css/dashboard.css";

    // 將 link 元素添加到 head 中
    document.head.appendChild(link);

    // 清理函數：當組件卸載時移除 link 元素
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  useEffect(() => {
    // 你可以在這裡使用 buddyID 來進行相關操作
    if (buddyID) {
      console.log(`有Buddy ID: ${buddyID}, 要進行讀取檔案，並且顯示資訊`);
      loadData(buddyID, aiBuddyPropsTags, CharacterDataLabels);
    }
  }, [buddyID]);

  useEffect(() => {
    if (gameData) {
      console.log("讀取到的資料:", gameData);
      setCharacter(gameData);
    }
  }, [gameData]);

  const handleCharacterGenerated = (newCharacter: CharacterProbs) => {
    console.log("Character generated:", newCharacter);
    setCharacter(newCharacter);
  };
  const handleTestCharacter = () => {
    // 測試角色對話
    if (!character || character?.TalkStyle === "") {
      alert("請先建立角色");
      return;
    }
    setToggleTest((prev) => !prev);
  };

  if (dataLoading) return <Overlay show={true} />;
  return (
    <div className="db-container">
      <CreatorNavi />
      <div className="db-body">
        <div className="header"></div>
        <div className="content">
          <div className="container">
            <div className="form-container">
              <div className="form-item title">
                <h2>建立角色</h2>
              </div>
              {gameData ? (
                <CharacterGenerator
                  loadedCharacter={gameData}
                  onCharacterGenerated={handleCharacterGenerated}
                />
              ) : (
                <CharacterGenerator
                  onCharacterGenerated={handleCharacterGenerated}
                />
              )}

              {/*    <div>
                <div className={`form-item btn-area`}>
                  <div className="left">
                    <button
                      className="btn --large"
                      onClick={handleTestCharacter}
                    >
                      <span>測試角色</span>
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {toggleTest ? (
          <SimpleChat
            avatarUrl={character?.imageUrl || ""}
            avatarName={character?.Name || ""}
            avatarVoice={character?.VoiceName || ""}
            botrunId={"波" + character?.Name}
          />
        ) : null}
      </div>
    </div>
  );
};

export default CreateCharacter;
