import { useState, useEffect, useRef, useCallback } from "react";
import TalkGameScoreBoard from "./TalkGameScoreBoard";
import { LearningJourneyLog } from "@core/sdgs_types";
import Card from "@core/components/GameEnd/ImageCard";
import AvatarMemo from "../../EndComponents/AvatarMemo";
import SimplePopup from "@core/components/GeneralComponent/SimplePopup";
import ShareLink from "@core/components/ImmersiveMenu/Game/EndComponents/ShareLink";
import { GamePerformanceData, GameRewardsResult } from "@core/sdgs_types";
import Confettiful from "@components/Effects/Confettiful";

//// 固定問題類型的遊戲結束畫面，只需要計算分數，不需要AI評分
type GameEndDataProps = {
  isShow: boolean;
  avatarUrl: string;
  imageUrl: string;
  learningJourneyLog: LearningJourneyLog;
  leaningJourneyLogUrl: string;
  gameRewardsResult: GameRewardsResult;
  gamePerformanceData: GamePerformanceData;
};

const FixQuestionGameEnd: React.FC<GameEndDataProps> = ({
  isShow,
  avatarUrl,
  imageUrl,
  leaningJourneyLogUrl,
  gameRewardsResult,
  gamePerformanceData,
}) => {
  const [isPopup, setIsPopup] = useState(isShow);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setIsPopup(isShow);
    if (isShow) {
      setShowConfetti(true);
    }
  }, [isShow]);

  return (
    <SimplePopup
      isPopup={isPopup}
      setIsPopup={setIsPopup}
      popupClassName={"for-share"}
      isShowCloseBtn={false}
    >
      {showConfetti && <Confettiful duration={5000} />}
      <div className={`end-container ${imageUrl === "" ? "--brief" : ""}`}>
        <div className="end-frame">
          <div className="left">
            <AvatarMemo
              avatarUrl={avatarUrl}
              avatarComment={gameRewardsResult.dynamicEncouragement}
              resultCompletion={gameRewardsResult.completion}
            />
          </div>

          <div className="center">
            {imageUrl && <Card imageUrl={imageUrl} />}
          </div>
          <div className="right">
            {
              <TalkGameScoreBoard
                gamePerformanceData={gamePerformanceData}
                gameRewardsResult={gameRewardsResult}
              />
            }
          </div>

          <i className="highlight for-right"></i>
        </div>
      </div>
      <ShareLink
        imageUrl={imageUrl}
        leaningJourneyLogUrl={leaningJourneyLogUrl}
      />
    </SimplePopup>
  );
};

export default FixQuestionGameEnd;
