import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { correctText } from "../../../../utils/writting-util";
import { useRecoilValue } from "recoil";

import { userAuthState } from "@core/recoil/atoms";
import {
  useAiOutputGenerator,
  AutoTypeDescription,
} from "../../../../hooks/useAiOutputGenerator";

export interface Writer {
  username: string;
  nickname: string;
  userType: "player" | "ai";
}
export interface WritingEntry {
  writer: Writer;
  content: string;
}
export interface TurnWriteAIEvaluate {
  success: boolean;
  comment: string;
}
interface TurnWriteAIWriter {
  nextPharse: string;
}

const defaultSystemPrompt = `你是一位英語接龍玩家，要一起完成一篇文章，使用者會給你一句話，以英語正確性，來給予判斷使用者的這一句子是否在英文上正確，如果不正確要怎樣改進。並且接著寫下一句nextPharse，nextPharse一定要接下去寫，不要重複或是變成改正使用者的句子`;
// 根據具體需求定義 responseType
const aiEvaluateType: AutoTypeDescription<TurnWriteAIEvaluate> = {
  success: "boolean",
  comment: "string",
};
const aiWriterType: AutoTypeDescription<TurnWriteAIWriter> = {
  nextPharse: "string",
};
export const useTurnBasedWriting = (
  writers: Writer[],
  subject: string,
  description: string,
  rules: string
) => {
  const { result: aiEvaluateResult, generateOutput: generateEvaluateOutput } =
    useAiOutputGenerator<TurnWriteAIEvaluate>({
      defaultSystemPrompt,
      globalConfigPromptKey: "turnWriteEvaluatePrompt",
      levelDataPromptKey: "turnWritePrompt",
      responseType: aiEvaluateType,
    });
  const { result: aiWriterResult, generateOutput: generateAIWriterOutput } =
    useAiOutputGenerator<TurnWriteAIWriter>({
      defaultSystemPrompt,
      globalConfigPromptKey: "turnWritePrompt",
      levelDataPromptKey: "turnWritePrompt",
      responseType: aiWriterType,
    });
  const user = useRecoilValue(userAuthState);
  const isGameStartedRef = useRef(false);
  const isAiStartedRef = useRef(false);
  const [currentWriter, setCurrentWriter] = useState<Writer | undefined>(
    undefined
  );
  const [writingEntries, setWritingEntries] = useState<WritingEntry[]>([]);

  const isCurrentUserTurn = currentWriter?.username === user?.username || false;
  const findNextWriter = useCallback(
    (currentWriter: Writer) => {
      if (writers.length === 0) return currentWriter;
      const currentIndex = writers.findIndex(
        (w) => w.username === currentWriter.username
      );
      console.log(
        `findNextWriter: ${
          writers[(currentIndex + 1) % writers.length].username
        } `
      );
      return writers[(currentIndex + 1) % writers.length];
    },
    [writers]
  );

  const addEntry = useCallback(
    (content: string, writer: Writer) => {
      const correct_pharse = correctText(content);
      setWritingEntries((prev) => [
        ...prev,
        { writer, content: correct_pharse },
      ]);
      setNextWriter(findNextWriter(writer));
    },
    [findNextWriter]
  );

  const setNextWriter = useCallback((nextWriter: Writer) => {
    setCurrentWriter(nextWriter);
  }, []);
  useEffect(() => {
    if (writers.length > 0 && !isGameStartedRef.current) {
      setCurrentWriter(writers[0]);
      isGameStartedRef.current = true;
    }
  }, [writers]);

  useEffect(() => {
    if (
      !isAiStartedRef.current &&
      subject &&
      currentWriter &&
      currentWriter.userType === "ai" &&
      writingEntries.length === 0 &&
      generateAIWriterOutput
    ) {
      console.log(
        "開頭第一句ai來寫,currentWriter：",
        currentWriter,
        subject,
        description,
        rules
      );
      isAiStartedRef.current = true;
      const total_userInput = `作文的主題是：${subject}，接龍寫作的遊戲背景說明：${description}, 寫作的目標規則是：${rules}。請你開始撰寫寫第一句子開頭，只要寫一句，不要太長，方便讓使用者可以往下接下去`;
      generateAIWriterOutput(total_userInput);
    }
  }, [currentWriter, subject, generateAIWriterOutput]);

  // 使用者寫作
  const userEntry = (message: string) => {
    const totoal_userInput = `接龍寫作遊戲的主題是：${subject}，接龍寫作的遊戲背景說明：${description}, 寫作的目標規則是：${rules}。 已經寫過的文章全文。：${combinedText}. 這是使用者最新寫的一段句子： ${message}。`;
    //console.log(`userEntry 送出 換ai寫: ${totoal_userInput}`);
    generateEvaluateOutput(totoal_userInput);

    // 下一位寫作者如果是ai，就直接生成ai的寫作
    const nextWriter = findNextWriter(
      currentWriter
        ? currentWriter
        : ({
            username: user?.username || "player",
            nickname:
              writers.find((w) => w.username === user?.username)?.nickname ||
              "玩家",
            userType: "player",
          } as Writer)
    );
    if (nextWriter.userType === "ai") {
      generateAIWriterOutput(
        totoal_userInput + " 請你依據以上內容，接著往下寫一句寫作內文。"
      );
    }

    addEntry(message, {
      username: user?.username || "player",
      nickname:
        writers.find((w) => w.username === user?.username)?.nickname || "玩家",
      userType: "player",
    });
  };

  // ai 寫作
  useEffect(() => {
    if (aiWriterResult && currentWriter) {
      console.log(`AI 寫完了, currentWriter: ${currentWriter.username}`);
      addEntry(aiWriterResult.nextPharse, currentWriter);
    }
  }, [aiWriterResult]);

  // 多人寫作 呼叫
  // 01 需要player list
  // 02 需要一個websocket 來傳遞訊息解析劇情

  const combinedText = useMemo(() => {
    return correctText(writingEntries.map((entry) => entry.content).join("\n"));
  }, [writingEntries]);

  return {
    currentWriter,
    isCurrentUserTurn,
    writingEntries,
    userEntry,
    othersEntry: addEntry,
    combinedText,
    aiEvaluateResult,
  };
};
