import { useRecoilValue } from "recoil"
import { userAuthState } from "@core/recoil/atoms"
import { Routes, Route, useLocation } from "react-router-dom"
import GameLoginPage from "./components/GameLoginPage"
import CameoLoginPage from "./components/CameoLoginPage"
import ReactGA from "react-ga4"
import Game from "@components/ImmersiveMenu/Game"
import GameListMenu from "@components/ImmersiveMenu/GameListMenu"
import ImmersiveMenu from "@components/ImmersiveMenu"
import ImSettingsFrame from "./components/ImmersiveMenu/ImSettingsFrame"
import { useProfile } from "@core/context/UserSettingsContext"
import { useEffect } from "react"
import Intro from "@components/ImmersiveMenu/Intro"
import MenuBuddies from "@components/CreateContent/CreatorNavi/MenuItems/MenuBuddies"
import MenuLevels from "@components/CreateContent/CreatorNavi/MenuItems/MenuLevels"
import CreateCharacter from "@components/CreateContent/character/CreateCharacter"
import { CreateSDGSLevel } from "@components/CreateContent/CreateSDGSLevel"
import GameTeamWaiting from "@components/GameTeam/TeamWaiting"
import GameTeamCreateOrJoin from "@components/GameTeam/TeamCreateOrJoin"
import TalkGameLog from "@core/components/ImmersiveMenu/TalkGameLog"
import { useState } from "react"
import Overlay from "@components/Overlay"
import CreateAvatar from "@components/ImmersiveMenu/CreateAvatar"
import PersonalMenu from "./components/ImmersiveMenu/GameListMenu/PersonalMenu"
import useLogger from "@hooks/useLogger"
import { useRef } from "react"
import PlantTreeGame from "@components/ImmersiveMenu/PlantTreeGame"
ReactGA.initialize(process.env.REACT_APP_GA_ID || "")

const AppImmersive = () => {
  const [isInitialLoading, setIsInitialLoading] = useState(true)
  const user = useRecoilValue(userAuthState)
  const { getter } = useProfile()
  const { data: profile, refetch } = getter
  const location = useLocation()
  const { mutate } = useLogger()
  const isUserLoginRef = useRef(false)
  useEffect(() => {
    if (user) {
      console.log("玩家登入遊戲", user.username)
      mutate({
        type: "登入",
        username: user.username,
        nickname: profile?.nickname,
        location: location.pathname,
      })
      setTimeout(() => {
        refetch()
        isUserLoginRef.current = true
      }, 200)
    }
  }, [user])

  useEffect(() => {
    console.log("immersive 啟動")
    // 添加初始延遲
    setTimeout(() => {
      setIsInitialLoading(false)
    }, 1000) // 1秒延遲
  }, [])
  const isProfileReady = (): boolean => {
    if (
      user &&
      profile &&
      profile.nickname &&
      profile.nickname !== null &&
      profile.nickname !== "" &&
      profile.avatarId &&
      profile.avatarId !== ""
    ) {
      return true
    }
    return false
  }

  if (isInitialLoading) return <Overlay show={true} />
  return (
    <Routes>
      <Route path="/" element={<ImmersiveMenu />}>
        {user !== null ? (
          <>
            <Route path="/tree/:gameType/:gameId/play" element={<PlantTreeGame />} />
            {isProfileReady() ? (
              <Route element={<ImSettingsFrame />}>
                <Route index element={<GameListMenu />} />
                <Route path="g/:gameType/:gameId" element={<Intro />} />
                <Route path="/g/:gameType/:gameId/play" element={<Game />} />

                <Route path="g/:gameType/:gameId/room" element={<GameTeamCreateOrJoin />} />
                <Route path="g/:gameType/:gameId/room/:roomId" element={<GameTeamWaiting />} />
                <Route path="g/:gameType/:gameId/room/:roomId/play" element={<Game />} />
                <Route path="log/:sessionId" element={<TalkGameLog />} />
                <Route path="ms/:userId?/:userNickName?" element={<PersonalMenu />} />
              </Route>
            ) : (
              <>{isUserLoginRef.current && <Route index element={<CreateAvatar />} />}</>
            )}
          </>
        ) : (
          <>
            <Route path="*" element={<GameLoginPage naviUrl={location.pathname} />} />
            <Route index element={<GameLoginPage />} />
          </>
        )}
        <Route path="/login" element={<GameLoginPage />} />
        <Route path="/cameo-login" element={<CameoLoginPage />} />
      </Route>
      {user && (
        <>
          <Route path="/createLevel" element={<MenuLevels />} />
          <Route path="/createLevel/l/" element={<CreateSDGSLevel />} />
          <Route path="/createLevel/l/:levelID" element={<CreateSDGSLevel />} />
          <Route path="/createLevel/bds/" element={<MenuBuddies />} />
          <Route path="/createLevel/bds/b/:buddyID" element={<CreateCharacter />} />
          <Route path="/createLevel/bds/b/" element={<CreateCharacter />} />
        </>
      )}
    </Routes>
  )
}

export default AppImmersive
