import useAddScore from "@core/hooks/useAddScore";
import { userAuthState } from "@core/recoil/atoms";
import { GameRewardsResult, highlightToSkillClass } from "@core/sdgs_types";
import { useEffect, useRef } from "react";
import { useRecoilValue } from "recoil";
export interface ResultCardProps {
  userName: string;
  nickname: string;
  avatarUrl: string;
  score: number;
  contribution: number;
  gameRewardsResult: GameRewardsResult;
  showCommentOrHighlights?: boolean; //是否顯示評論或高亮
}

const ResultCard: React.FC<ResultCardProps> = ({
  userName,
  nickname,
  avatarUrl,
  score,
  contribution,
  gameRewardsResult,
  showCommentOrHighlights = false,
}) => {
  const { mutate: mutateScore } = useAddScore();
  const user = useRecoilValue(userAuthState);
  const isScoreSended = useRef<boolean>(false);
  useEffect(() => {
    if (
      gameRewardsResult &&
      gameRewardsResult.totalExp > 0 &&
      !isScoreSended.current
    ) {
      if (user?.username === userName) {
        console.log(
          "多人遊戲，上傳自己的totalExp到排行榜",
          gameRewardsResult.totalExp
        );
        mutateScore({
          int_score: gameRewardsResult.totalExp,
        });
        isScoreSended.current = true;
      }
    }
  }, [gameRewardsResult, user]);
  return (
    <div className="multi-result-item">
      <div className="card-inner">
        {/* 卡片正面 */}
        <div className="card-front">
          <div className="name">{nickname}</div>
          <div className="avatar">
            <div
              className="img"
              style={{
                backgroundImage: `url(${avatarUrl})`,
              }}
            />
          </div>
        </div>

        {/* 卡片背面 */}
        <div className="card-back">
          <div className="result-item">
            <div className="ri-top">
              <span>分數</span>
              <strong>{score}</strong>
              <span>分</span>
            </div>
            <div className="ri-middle">
              {!showCommentOrHighlights && (
                <>
                  {gameRewardsResult.highlights.map((highLight) => (
                    <i
                      key={Math.random()}
                      className={highlightToSkillClass(highLight)}
                    />
                  ))}
                </>
              )}
              {showCommentOrHighlights && (
                <div className="words">
                  <p>{gameRewardsResult.completion}</p>
                </div>
              )}
            </div>
            <div className="ri-bottom">
              <strong>+{gameRewardsResult.totalExp}</strong>
              <span>EXP</span>
            </div>
          </div>

          <div className="info">
            <div className="contribution">
              貢獻度 <strong>{contribution}%</strong>
            </div>
            <div className="name">{nickname}</div>
          </div>

          <div className="avatar">
            <div
              className="img"
              style={{
                backgroundImage: `url(${avatarUrl})`,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCard;
