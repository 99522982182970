import { useEffect, useRef } from "react"
import { SettingsType } from "@utils/types"
import { useLocation } from "react-router-dom"

const MUSIC_URL = "https://www.cameo.tw/ntso/SDGs_music/background_melody.mp3"

export default function useMusic(settings: SettingsType) {
  const location = useLocation()
  const audioRef = useRef<HTMLAudioElement | null>(null)

  const playMusic = () => {
    if (audioRef.current)
      audioRef.current.play().catch(error => console.error("Error playing audio:", error))
  }

  useEffect(() => {
    if (!audioRef.current) {
      audioRef.current = new Audio(MUSIC_URL)
      audioRef.current.loop = true
      audioRef.current.volume = 0.1
    }

    const handleUserInteraction = () => {
      playMusic()
      document.removeEventListener("click", handleUserInteraction)
    }

    if (settings.backgroundMusic) {
      document.addEventListener("click", handleUserInteraction)
    } else {
      audioRef.current.pause()
      document.removeEventListener("click", handleUserInteraction)
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current = null
      }
      document.removeEventListener("click", handleUserInteraction)
    }
  }, [settings.backgroundMusic])

  useEffect(() => {
    const path = location.pathname
    if (path.includes("play")) {
      if (audioRef.current) {
        audioRef.current.pause()
        audioRef.current.currentTime = 0
      }
    } else if (settings.backgroundMusic) playMusic()
  }, [location])

  return playMusic
}
