import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  ReactNode,
  useEffect,
  useRef,
} from "react"

interface AudioContextType {
  playBackground: () => void
  stopBackground: () => void
  playAudio: (audioType: AudioType) => void
  toggleMute: () => void
  isMuted: boolean
}

const AudioContext = createContext<AudioContextType>({
  playBackground: () => {},
  stopBackground: () => {},
  playAudio: () => {},
  toggleMute: () => {},
  isMuted: false,
})

export const useAudio = () => useContext(AudioContext)

interface AudioProviderProps {
  children: ReactNode
}

export enum AudioType {
  Background = "background",
  Click = "click",
  ChoiceRight = "choiceRight",
  ChoiceWrong = "choiceWrong",
  GameSuccess = "gameSuccess",
  GameStart = "gameStart",
  System = "system",
}

const defaultAudioUrls = {
  [AudioType.Background]: "https://www.cameo.tw/ntso/SDGs_music/background_soft.mp3",
  [AudioType.Click]: "https://www.cameo.tw/ntso/SDGs_music/click.aac",
  [AudioType.GameSuccess]: "https://www.cameo.tw/ntso/SDGs_music/done_surprice.aac",
  [AudioType.GameStart]: "https://www.cameo.tw/ntso/SDGs_music/click_01.aac",
  [AudioType.ChoiceRight]: "https://www.cameo.tw/ntso/SDGs_music/click_rightanswer.aac",
  [AudioType.ChoiceWrong]: "https://www.cameo.tw/ntso/SDGs_music/click_wronganswer.aac",
  [AudioType.System]: "https://www.cameo.tw/ntso/SDGs_music/click_translate.aac",
}

const defaultVolume = {
  background: 0.1,
  click: 1,
}

export const AudioProvider: React.FC<AudioProviderProps> = ({ children }) => {
  const [isMuted, setIsMuted] = useState(false)
  const audioRefs = useRef<Record<AudioType, HTMLAudioElement | null>>({
    [AudioType.Background]: null,
    [AudioType.Click]: null,
    [AudioType.ChoiceRight]: null,
    [AudioType.ChoiceWrong]: null,
    [AudioType.GameSuccess]: null,
    [AudioType.GameStart]: null,
    [AudioType.System]: null,
  })
  const lastPlayTimeRef = useRef<Record<AudioType, number>>({
    [AudioType.Background]: 0,
    [AudioType.Click]: 0,
    [AudioType.ChoiceRight]: 0,
    [AudioType.ChoiceWrong]: 0,
    [AudioType.GameSuccess]: 0,
    [AudioType.GameStart]: 0,
    [AudioType.System]: 0,
  })

  useEffect(() => {
    // 初始化音頻元素
    Object.entries(defaultAudioUrls).forEach(([type, url]) => {
      const audio = new Audio(url)
      audio.preload = "auto"
      audio.volume = type === AudioType.Background ? defaultVolume.background : defaultVolume.click
      if (type === AudioType.Background) {
        audio.loop = true
      }
      audioRefs.current[type as AudioType] = audio
    })

    // 清理函數
    return () => {
      Object.values(audioRefs.current).forEach(audio => {
        if (audio) {
          audio.pause()
          audio.src = ""
        }
      })
    }
  }, [])

  const playAudio = useCallback(
    (audioType: AudioType) => {
      if (isMuted) return

      const currentTime = Date.now()
      if (currentTime - lastPlayTimeRef.current[audioType] < 500) return

      lastPlayTimeRef.current[audioType] = currentTime

      const audio = audioRefs.current[audioType]
      if (audio) {
        audio.currentTime = 0
        audio.play().catch(error => console.error("音頻播放失敗:", error))
      }
    },
    [isMuted]
  )

  const stopAudio = useCallback((audioType: AudioType) => {
    const audio = audioRefs.current[audioType]
    if (audio) {
      audio.pause()
      audio.currentTime = 0
    }
  }, [])

  const playBackground = useCallback(() => {
    const backgroundAudio = audioRefs.current[AudioType.Background]
    if (backgroundAudio && !isMuted) {
      backgroundAudio.play().catch(error => console.error("背景音樂播放失敗:", error))
    }
  }, [isMuted])

  const stopBackground = useCallback(() => {
    stopAudio(AudioType.Background)
  }, [stopAudio])

  const toggleMute = useCallback(() => {
    setIsMuted(prev => {
      const newMuted = !prev
      Object.values(audioRefs.current).forEach(audio => {
        if (audio) {
          audio.muted = newMuted
        }
      })
      return newMuted
    })
  }, [])

  useEffect(() => {
    const handleClick = (audioType: AudioType) => (event: MouseEvent) => {
      playAudio(audioType)
    }

    const addClickListeners = () => {
      Object.values(AudioType).forEach(audioType => {
        const className = `${audioType}-sound`
        const buttons = document.querySelectorAll<HTMLElement>(`.${className}`)
        buttons.forEach(button => {
          const listener = handleClick(audioType)
          button.addEventListener("click", listener)
          button.dataset.audioListener = listener.toString()
        })
      })
    }

    const removeClickListeners = () => {
      Object.values(AudioType).forEach(audioType => {
        const className = `${audioType}-sound`
        const buttons = document.querySelectorAll<HTMLElement>(`.${className}`)
        buttons.forEach(button => {
          const listener = button.dataset.audioListener
          if (listener) {
            button.removeEventListener("click", eval(listener))
            delete button.dataset.audioListener
          }
        })
      })
    }

    const observer = new MutationObserver(() => {
      removeClickListeners()
      addClickListeners()
    })

    observer.observe(document.body, { childList: true, subtree: true })
    addClickListeners()

    return () => {
      observer.disconnect()
      removeClickListeners()
    }
  }, [playAudio])

  return (
    <AudioContext.Provider
      value={{
        playBackground,
        stopBackground,
        toggleMute,
        playAudio,
        isMuted,
      }}
    >
      {children}
    </AudioContext.Provider>
  )
}
