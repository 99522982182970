import React, { useState } from "react"
import ChoiceItem from "./SelectionItem"
import { LabbelSwitch } from "../../utils/functions"
interface MultiSelectionInputProps {
  items: string[]
  onItemClick: (selectedItems: string) => void
}

const MultiSelectionInput: React.FC<MultiSelectionInputProps> = ({ items, onItemClick }) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const handleItemClick = (item: string) => {
    setSelectedItems(prevSelectedItems =>
      prevSelectedItems.includes(item)
        ? prevSelectedItems.filter(selectedItem => selectedItem !== item)
        : [...prevSelectedItems, item]
    )
  }

  const handleSubmit = () => {
    const result = selectedItems.join(", ")
    onItemClick(result)
  }

  return (
    <div className={"input-mcq-container --for-multi " + LabbelSwitch(items)}>
      <div className="option-container">
        {items.map((item, index) => (
          <ChoiceItem
            key={index}
            label={item}
            onClick={() => handleItemClick(item)}
            isSelected={selectedItems.includes(item)}
          />
        ))}
      </div>
      <button className="btn" onClick={handleSubmit}>
        <i className="icon icon-10"></i>
      </button>
    </div>
  )
}

export default MultiSelectionInput
