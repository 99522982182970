import React, { useEffect, useState } from "react";
import { useGenerateImage } from "@core/hooks/useApi";
import EnhancedMessage from "@components/GeneralComponent/EnhancedMessage";
import { useRecoilValue } from "recoil";
import { promptsState } from "@core/recoil/sdgs_atoms";
interface ImageGenerateFieldProps {
  label: string;
  imageName: string;
  imageDescription: string;
  message: string;
  onImagedChanged: (value: string) => void;
  isRequired: boolean;
  defaultUrl?: string;
  isAvatar?: boolean;
}

const ImageGenerateField: React.FC<ImageGenerateFieldProps> = ({
  label,
  imageName,
  imageDescription,
  message,
  onImagedChanged,
  isRequired,
  defaultUrl = "",
  isAvatar = true,
}) => {
  const prompts = useRecoilValue(promptsState);
  const { imageUrl, error, loading, generateImage, imagePrompt } =
    useGenerateImage();
  const [finalImageUrl, setFinalImageUrl] = useState<string>("");
  const handleGenerateCharacterImage = () => {
    if (imageDescription === "") {
      alert("請先建立描述");
      return;
    }
    if (loading) {
      console.log("still working ,Skip");
      return;
    }
    // 生成角色頭像
    let imageSystemInstruction = prompts.BackgroundImage;
    if (imageSystemInstruction === undefined) {
      imageSystemInstruction = "生成遊戲背景圖。";
    }
    if (isAvatar) {
      imageSystemInstruction = prompts.CharacterImage;
      if (imageSystemInstruction === undefined) {
        imageSystemInstruction =
          "生成單一半身肖像圖，背景單純,請注意，務必是完整的半身肖像的圖像，頭不能被裁切，要有頭到胸口的位置， 也不用背面側面，只需要正面。以下是角色外觀：" +
          imageDescription;
      }
    }
    console.log(
      "Generate character image",
      imageSystemInstruction + imageDescription
    );

    generateImage(imageSystemInstruction + imageDescription);
  };
  useEffect(() => {
    if (imageUrl) {
      onImagedChanged(imageUrl);
      setFinalImageUrl(imageUrl);
    }
  }, [imageUrl]);

  useEffect(() => {
    if (defaultUrl) {
      setFinalImageUrl(defaultUrl);
    }
  }, [defaultUrl]);

  const uploadImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 上傳圖片, todo 驗證上傳是否正確
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setFinalImageUrl(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleDownloadImage = () => {
    if (finalImageUrl) {
      window.open(finalImageUrl, "_blank");
    }
  };
  return (
    <div className={`form-item ${isRequired ? "must" : ""}`}>
      <div className="field">{label}</div>
      <div className="value">
        {/* <input
          type="file"
          id="avatar"
          name="avatar"
          accept="image/*"
          onChange={uploadImage}
        /> */}
        <button
          className="btn --small"
          onClick={handleGenerateCharacterImage}
          disabled={loading}
        >
          {loading ? <span>生成中請等待...</span> : <span>AI生圖</span>}
        </button>
        {finalImageUrl && (
          <button className="btn --small" onClick={handleDownloadImage}>
            下載圖片
          </button>
        )}
      </div>
      {finalImageUrl && isAvatar ? (
        <div className={`chat-item npc --active`}>
          <div className="chat-item-container">
            <div className="avatar">
              <div
                className="img"
                style={{ backgroundImage: `url('${finalImageUrl}')` }}
              ></div>
              <div className="name">{imageName}</div>
            </div>
            <div className="content">
              {" "}
              <EnhancedMessage message={message} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <img src={finalImageUrl} alt="generatedImage" />
        </div>
      )}
    </div>
  );
};

export default ImageGenerateField;
