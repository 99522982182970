import React from "react";
import TextAreaField from "../common/TextAreaField";
import Collapse from "../common/Collapse";
import { levelDataProbs } from "@core/sdgs_types";

import ToggleSection from "../common/ToggleSection";
interface CustomPromptTabProps {
  level: levelDataProbs;
  onLevelChange: (updates: Partial<levelDataProbs>) => void;
}

export const CustomPromptTab: React.FC<CustomPromptTabProps> = ({
  level,
  onLevelChange,
}) => {
  const defaultPrompts = {
    questionGenerationPrompt: `題型只要出問答題型
問答題的question 是要跟玩家聊天的內容, 請都用網路用語來對話,
請根據上下文，詢問玩家想要學什麼聊天用語
問答題不需要選項, 也不用答案, 因為是開放問答
`,
    answerAdvicePrompt: `你的目的是要透過對談教玩家如何使用年輕人常用英文網路聊天用語
所以你回答的語氣要按照角色設定的語氣。將玩家的內容如果是問句，請將問題使用英文網路聊天用語教他
例如：玩家問說，我該怎麼開始對話
在dialogue中教他一句，開始聊聊天常用的英文句子將玩家的內容如果是非問句子，請將他這句話使用英文網路聊天用語改寫後教他
例如：玩家問說，你好漂亮
在dialogue中教他一句，年輕人在網路上的文字聊天用英文句子`,
    imagePrompt: "",
  };

  const handleSetDefaultPrompts = () => {
    onLevelChange(defaultPrompts);
  };

  const handleClearPrompts = () => {
    onLevelChange({
      questionGenerationPrompt: "",
      answerAdvicePrompt: "",
      imagePrompt: "",
    });
  };

  return (
    <ToggleSection
      label="進階設定"
      defaultEnabled={!!level.questionGenerationPrompt}
      helpText="用於創造特殊關卡, 一般題型不需要編輯此項目"
    >
      <>
        <div className="flex gap-2 mb-4">
          <button
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
            onClick={handleSetDefaultPrompts}
          >
            參考題詞
          </button>
          <button
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
            onClick={handleClearPrompts}
          >
            清除題詞
          </button>
        </div>

        <TextAreaField
          label="生成題目題詞"
          placeholder=""
          isRequired={false}
          helpText="用於無預設題目，在遊戲過程中開放生成題目"
          value={level.questionGenerationPrompt || ""}
          onChange={(value) => {
            onLevelChange({ questionGenerationPrompt: value });
          }}
        />

        {/*     <TextAreaField
          label="問題提示題詞"
          placeholder=""
          isRequired={false}
          helpText="客製化題詞，用於生成解釋問題的提示內容"
          value={level.questionAdvicePrompt || ""}
          onChange={(value) => {
            onLevelChange({ questionAdvicePrompt: value });
          }}
        /> */}
        <TextAreaField
          label="回答判斷題詞"
          placeholder=""
          isRequired={false}
          helpText="用於如何回應玩家輸入的內容，可以是評價，或是建議，或是引導。"
          value={level.answerAdvicePrompt || ""}
          onChange={(value) => {
            onLevelChange({ answerAdvicePrompt: value });
          }}
        />
        {/*  <TextAreaField
          label="過關評分題詞"
          placeholder=""
          isRequired={false}
          helpText="客製化題詞，用於生成分數評價"
          value={level.scoreEvaluatePrompt || ""}
          onChange={(value) => {
            onLevelChange({ scoreEvaluatePrompt: value });
          }}
        /> */}
        {
          <TextAreaField
            label="圖片生成題詞"
            placeholder=""
            isRequired={false}
            helpText="客製化題詞，用於生成圖片"
            value={level.imagePrompt || ""}
            onChange={(value) => {
              onLevelChange({ imagePrompt: value });
            }}
          />
        }
      </>
    </ToggleSection>
  );
};
