import React, { useState, useEffect, useRef } from "react";
import { useAIGenerateLevel } from "./utils/useAIGenerateLevel";
import { levelDataProbs } from "@core/sdgs_types";
import { userAuthState } from "@core/recoil/atoms";
import CreatorNavi from "./CreatorNavi";
import { CharacterProbs, LearningLevel } from "@core/sdgs_types";
import { useRecoilValue } from "recoil";
import { useParams } from "react-router-dom";
import { useLoadTalkGameData } from "@core/hooks/LoadData/useGameDataLoader";
import Dropdown from "./common/Dropdown";
import MetaTagsProps from "@components/SEOTags/MetaTages";
import { Question } from "@core/sdgs_types";
import ReactGA from "react-ga4";
import Tabs from "./common/Tabs";
import { useLoadPromptsData } from "@core/hooks/LoadData/useGameDataLoader";
import Overlay from "@components/Overlay";
import { v4 as uuidv4 } from "uuid";
import useLogger from "@hooks/useLogger";
import { getTabs } from "./config/getTabs";
import { useSaveLevel } from "./utils/useSaveLevel";
import {
  additionalGenerationPrompName,
  gameOptions,
} from "./config/CreateContentConfig";

export const CreateSDGSLevel: React.FC = () => {
  const { mutate } = useLogger();

  const { levelID } = useParams();

  const getLearninglevelDescription = (level: string) => {
    try {
      return LearningLevel[level];
    } catch (e) {
      return LearningLevel["A2"];
    }
  };

  const {
    level,
    generatedQuestions,
    setGeneratedQuestions,
    processing,
    generateLevel,
    startFormatQuestions,
    generateQuestionContent,
    setLevel,
  } = useAIGenerateLevel();
  const [formatedQuestions, setFormatedQuestions] = useState<Question[]>([]);
  const [isEditableForAll, setIsEditableForAll] = useState(false);
  const [isMaterialLocked, setIsMaterialLocked] = useState(false);
  const user = useRecoilValue(userAuthState);
  const promptId = process.env.REACT_APP_PROMPTS_ID || "";
  const { prompts } = useLoadPromptsData(promptId);

  const sessionRef = useRef("");

  const { gameData, error, loading, loadData } = useLoadTalkGameData();
  useEffect(() => {
    if (generatedQuestions) {
      setFormatedQuestions(generatedQuestions);
    }
  }, [generatedQuestions]);
  useEffect(() => {
    sessionRef.current = uuidv4();
    mutate({
      type: "進入關卡編輯器",
      username: user?.username,
      session_id: sessionRef.current,
    });

    // 創建一個新的 link 元素
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/css/dashboard.css";

    // 將 link 元素添加到 head 中
    document.head.appendChild(link);

    // 清理函數：當組件卸載時移除 link 元素
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const handleSelectionChange = (selectedValue: string) => {
    setLevel((prev) => ({
      ...prev,
      gameType: selectedValue,
    }));
  };
  const handleQuestionGenerate = () => {
    ReactGA.event({
      category: "一鍵生成關卡",
      action: level.gameType,
    });

    mutate({
      type: "按下一鍵生成",
      username: user?.username,
      game_type: level.gameType,
      session_id: sessionRef.current,
    });
    let aiLevelProbs: levelDataProbs = level;
    aiLevelProbs.difficulty = getLearninglevelDescription(level.difficulty);
    generateLevel(
      aiLevelProbs,
      prompts[additionalGenerationPrompName[level.gameType]],
      isMaterialLocked
    );
  };

  useEffect(() => {
    if (levelID) {
      console.log(`有levelID: ${levelID}, 要進行讀取檔案，並且顯示資訊`);

      loadData(levelID);
    }
  }, [levelID]);
  useEffect(() => {
    if (gameData) {
      setLevel(gameData);
      const formatedQuestions = JSON.parse(gameData.questions) as Question[];
      if (formatedQuestions) {
        setFormatedQuestions(formatedQuestions);
      }
    }
  }, [gameData]);

  const { saveLevel, isUploading } = useSaveLevel();

  const handleSave = async () => {
    try {
      await saveLevel({
        level,
        formatedQuestions,
        user,
        isEditableForAll,
        sessionRef,
        levelID,
      });
    } catch (error) {
      alert((error as Error).message);
    }
  };

  const handleClearContent = () => {
    const confirmClear = window.confirm("確定要清除所有內容嗎？");
    if (confirmClear) {
      setLevel({
        title: "",
        subtitle: "",
        description: "",
        learningMaterial: "",
        questions: "",
        story: "",
        gameType: level.gameType, // 保留遊戲類型
        difficulty: level.difficulty, // 保留難度設定
        avatarName: "",
        avatarUrl: "",
        avatarVoice: "",
        avatarId: "",
        numberOfQuestions: "5",
        multiPlayer: false,
        visualDescription: "",
        wellcomeMessage: "", // 添加缺少的 wellcomeMessage 屬性
        writing_subject: "",
        writing_description: "",
        writing_rules: "",
        writing_minWords: 20,
        writing_maxWords: 150,
      });
      setFormatedQuestions([]);
      setGeneratedQuestions([]);
    }
  };

  if (loading) return <Overlay show={true} />;

  const isEditableAndUerMatch = () => {
    if (gameData === null || gameData.Creator === undefined) {
      return true;
    }

    if (gameData.Creator === user?.username) {
      return true;
    } else {
      return false;
    }
  };
  const onCharacterSelect = (character: CharacterProbs) => {
    setLevel((prev) => ({
      ...prev,
      avatarName: character.Name,
      avatarUrl: character.imageUrl,
      avatarVoice: character.VoiceName,
      avatarId: character.ID,
    }));
  };
  const tabs = getTabs({
    level,
    onLevelChange: (updates) => setLevel((prev) => ({ ...prev, ...updates })),
    formatedQuestions,
    setFormatedQuestions,
    processing,
    onGenerateContent: () =>
      generateQuestionContent(
        level.learningMaterial,
        level.questions,
        level.story,
        prompts[additionalGenerationPrompName[level.gameType]]
      ),
    onFormatQuestions: () => startFormatQuestions(level.questions),
    isMaterialLocked,
    onMaterialLockToggle: setIsMaterialLocked,
    onCharacterSelect,
  });
  const isAllNessaryFieldFilled = () => {
    if (
      !level.title ||
      !level.subtitle ||
      !level.description ||
      !level.learningMaterial ||
      !formatedQuestions
    ) {
      return false;
    }
    return true;
  };
  return (
    <div className="db-container">
      <MetaTagsProps
        title="immersive 生成式遊戲關卡編輯器"
        description="ＡＩ輔助，創造屬於你自己的學習內容"
      />
      <CreatorNavi />
      <div className="db-body">
        <div className="header"></div>
        <div className="content">
          <div className="container">
            <div className="form-container">
              <div className="form-item title">
                <h2>建立遊戲</h2>
              </div>
              <Dropdown
                label="遊戲類型"
                options={gameOptions}
                defaultValue={level.gameType}
                isRequired={true}
                onChange={handleSelectionChange}
              />
              <Tabs tabs={tabs} />

              {isEditableAndUerMatch() ? (
                <div className="form-item btn-area">
                  <div className="left">
                    <button
                      className="btn --large"
                      onClick={handleQuestionGenerate}
                      disabled={processing}
                    >
                      {processing ? (
                        <span>生成中...</span>
                      ) : (
                        <span>AI補完內容</span>
                      )}
                    </button>

                    <button
                      className="btn --large --warning"
                      onClick={handleClearContent}
                      disabled={processing || isUploading}
                    >
                      <span>清除內容</span>
                    </button>

                    {isAllNessaryFieldFilled() && (
                      <button
                        className={`btn --large`}
                        onClick={handleSave}
                        disabled={isUploading}
                      >
                        {!processing ? (
                          isUploading ? (
                            <span>儲存中...</span>
                          ) : (
                            <span>儲存關卡</span>
                          )
                        ) : (
                          <span>填寫後儲存關卡</span>
                        )}
                      </button>
                    )}
                  </div>
                  <div className="right"></div>
                </div>
              ) : (
                <span>無編輯權限</span>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
