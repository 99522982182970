import React, { useEffect, useState } from "react";
import { Keywords } from "../../sdgs_types";
import SimplePopup from "../GeneralComponent/SimplePopup";
import { Avatar } from "./AdviceAvatar";
import { DialogueContent } from "./DialogueContent";
import { Advice, ExplanationData } from "./Advice";
import { useTranslation } from "react-i18next";
import {
  useAiOutputGenerator,
  AutoTypeDescription,
} from "../../hooks/useAiOutputGenerator";
export const responseType: AutoTypeDescription<ExplanationData> = {
  explanation: "string",
  example: "string[]",
  translation: "string[]",
};

interface AdvicePopupProps {
  avatarUrl: string;
  lastUserMessage?: string;
  lastBotMessage: string;
  keywords: Keywords;
  isPopupVisible: boolean;
  setIsPopupVisible: (isVisible: boolean) => void;
  voiceName: string;
}

const QuestionAdvicePopup: React.FC<AdvicePopupProps> = ({
  avatarUrl,
  lastUserMessage,
  lastBotMessage,
  keywords,
  isPopupVisible,
  setIsPopupVisible,
  voiceName,
}) => {
  const { t } = useTranslation();
  const defaultAdvice: ExplanationData = {
    example: [],
    translation: [],
    explanation: t("WaitAdvice"),
  };
  const tipText = "學習提示";
  const { result, generateOutput } = useAiOutputGenerator<ExplanationData>({
    defaultSystemPrompt: `是一位針對台灣的高中生程度進行英語教學的輔導助手，注意，請直接生成解釋與例句與例句翻譯，不需要輸出其他多餘的文字。
      目的是鼓勵使用者無痛的學英語。
      請用繁體中文來說明解釋，例句的英文要夠簡單，簡潔，請根據，目前的提問問問題，先有解釋，再給予使用者建議的英文練習例句內容，例句數量在兩句即可之間。
`,
    globalConfigPromptKey: "QuestionAdvice",
    levelDataPromptKey: "questionAdvicePrompt",
    responseType,
  });

  useEffect(() => {
    if (isPopupVisible === false) {
      return;
    }
    if (lastBotMessage) {
      const userInput = ` 目前問題的內容是：${lastBotMessage} ，請針對目前問題給予使用者回答的建議。`;
      generateOutput(userInput);
    }
  }, [lastBotMessage, isPopupVisible]);
  return (
    <>
      <SimplePopup
        isPopup={isPopupVisible}
        setIsPopup={setIsPopupVisible}
        popupClassName="for-dialogue"
      >
        <div className="dialogue-container">
          <div className="container">
            <DialogueContent
              dialogueText={lastBotMessage}
              tipText={tipText}
              keywords={keywords}
            />
            <Advice
              advice={result ? result : defaultAdvice}
              voiceName={voiceName}
            />
          </div>
        </div>

        <Avatar url={avatarUrl} />
      </SimplePopup>
    </>
  );
};
/*
先移除題目
*/
export default QuestionAdvicePopup;
