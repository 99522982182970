import React, { useState, useRef, useEffect } from "react"
interface InputTextContainerProps {
  placeholder: string
  onMessageSend: Function
  disabled?: boolean
}

const InputTextContainer: React.FC<InputTextContainerProps> = ({
  placeholder,
  onMessageSend,
  disabled,
}) => {
  const [text, setText] = useState("")
  const [isComposing, setIsComposing] = useState(false)
  const textareaRef = useRef<HTMLTextAreaElement>(null)
  function handleChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setText(event.target.value)
  }

  function handleSend() {
    if (disabled || text.trim() === "") return
    onMessageSend(text)
    setText("")
    textareaRef.current?.focus()
  }
  function onKeyDown(event: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (event.shiftKey && event.key === "Enter") {
    } else if (event.key === "Enter" && !isComposing) {
      handleSend()
      event.preventDefault()
    }
  }
  const handleCompositionStart: React.CompositionEventHandler<HTMLTextAreaElement> = () => {
    setIsComposing(true)
  }
  const handleCompositionEnd: React.CompositionEventHandler<HTMLTextAreaElement> = () => {
    setIsComposing(false)
  }

  useEffect(() => {
    textareaRef.current?.focus()
  }, [])

  return (
    <div className="wrapper">
      <textarea
        ref={textareaRef}
        name="inputText"
        id="inputText"
        cols={30}
        rows={10}
        placeholder={placeholder}
        value={text}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
      ></textarea>
      <div className="fn">
        <button className="br-btn icon-only btn-submit" title="送出" onClick={handleSend}>
          <i className="icon icon-10"></i>
        </button>
      </div>
    </div>
  )
}

export default InputTextContainer
