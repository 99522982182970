import { useEffect, useState } from "react";
import { gameFlowTypes } from "../sdgs_types";
import {
  extractedMatchesToSuggestions,
  extractMatchesWithTypeAndCleanString,
  updatedBaseUrlWithName,
} from "@utils/functions";
function parseMessage(message: string): {
  status: gameFlowTypes | null;
  cleanedMessage: string;
} {
  // 檢查每一個枚舉值是否在字串中
  for (const key of Object.keys(gameFlowTypes)) {
    const value = gameFlowTypes[key as keyof typeof gameFlowTypes];
    if (message.includes(value)) {
      // 去除關鍵字，並返回處理後的字串和枚舉值
      const cleanedMessage = message.replace(value, "").trim();
      // console.log("找到的狀態", value, message);
      return { status: value, cleanedMessage };
    }
  }
  // 如果沒有找到任何關鍵字，返回null和原始字串
  return { status: gameFlowTypes.QuestionAnswering, cleanedMessage: message };
}
export const useOneChatMessages = (messages: any[]) => {
  const [lastBotMessage, setLastBotMessage] = useState("");
  const [lastSelfMessage, setLastSelfMessage] = useState("");
  const [isBotChatShow, setBotChatIsShow] = useState(false);
  const [isSelfChatShow, setIsSelfChatShow] = useState(false);
  const [isPlayerTurn, setIsPlayerTurn] = useState(false);
  const [gameFlow, setGameFlow] = useState<gameFlowTypes | null>(null);
  const [lastBotSuggestions, setLastBotSuggestions] = useState<string[]>([]);
  function extractOptionsAndCleanText(text: string): {
    options: string[];
    cleanText: string;
  } {
    const pattern = /\[([^\]]+)\]/g;
    let match;
    const options: string[] = [];
    let cleanText = text;

    // 使用 while 迴圈找到所有符合 pattern 的選項
    while ((match = pattern.exec(text)) !== null) {
      options.push(match[1]);
    }

    // 去除選項 pattern
    cleanText = cleanText.replace(pattern, "").trim();

    return { options, cleanText };
  }

  useEffect(() => {
    if (messages.length > 0) {
      const lastTwoMessages = messages.slice(-2);
      const selfs = messages.filter((m) => m.sender === "self").reverse();
      const bots = lastTwoMessages.filter((m) => m.sender === "others");

      // 檢查遊戲是否結束
      const lastMessage = messages[messages.length - 1];

      // 根據最後一條訊息的發送者更新玩家回合狀態
      setIsPlayerTurn(lastMessage.sender === "others");

      // 更新訊息和顯示狀態
      if (bots.length > 0) {
        const rawBotMessage = bots[0].content;
        const { status, cleanedMessage } = parseMessage(rawBotMessage);
        const { cleanedString, extractedMatches } =
          extractMatchesWithTypeAndCleanString(cleanedMessage);
        const result = extractOptionsAndCleanText(cleanedString);
        //console.log('orignl : ',cleanedMessage,'extractedMatches',extractedMatches);
        const buttons = extractedMatches["button"] || [];
        let buttonSuggestions = buttons.map((b: { text: string }) => b.text);
        result.options.forEach((option) => {
          buttonSuggestions.push(option);
        });
        //console.log('buttonSuggestions',buttonSuggestions);

        if (bots[0].suggestions) {
          const lastSugestions = bots[0].suggestions.map(
            (s: { text: string }) => s.text
          );
          buttonSuggestions = lastSugestions.concat(buttonSuggestions);
        }
        if (buttonSuggestions.length > 0) {
          //console.log('addedSuggestions',buttonSuggestions);
          setLastBotSuggestions(buttonSuggestions);
        } else {
          setLastBotSuggestions([]);
        }

        setGameFlow(status);
        setLastBotMessage(result.cleanText);
        setBotChatIsShow(true);
      }

      if (selfs.length > 1) {
        //console.log('selfs msg is more than 2', selfs[1]);
        setLastSelfMessage(selfs[0].content);
        setIsSelfChatShow(true);
      }
    }
  }, [messages]);

  return {
    lastBotMessage,
    lastSelfMessage,
    isBotChatShow,
    isSelfChatShow,
    isPlayerTurn,
    gameFlow,
    lastBotSuggestions,
  };
};
