import React, { useRef, useState, useEffect } from 'react';
//彩帶特效
/*使用方式
return (
    <div>
      {showConfetti && <Confettiful />}
    </div>
  );
*/
interface ConfettiProps {
  duration?: number; // Optional duration prop to customize how long the confetti runs
}

const Confettiful: React.FC<ConfettiProps> = ({ duration = 5000 }) => {
  const elRef = useRef<HTMLDivElement>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const confettiColors = ["#EF2964", "#00C09D", "#2D87B0", "#48485E", "#EFFF1D"];
  const confettiAnimations = ["slow", "medium", "fast"];

  const renderConfetti = () => {
    if (!isAnimating && elRef.current) {
      const confettiInterval = setInterval(() => {
        const confettiEl = document.createElement("div");
        const confettiSize = `${Math.floor(Math.random() * 3) + 7}px`;
        const confettiBackground = confettiColors[Math.floor(Math.random() * confettiColors.length)];
        const confettiLeft = `${Math.floor(Math.random() * (elRef.current?.offsetWidth || 0))}px`;
        const confettiAnimation = confettiAnimations[Math.floor(Math.random() * confettiAnimations.length)];

        confettiEl.classList.add("confetti", `confetti--animation-${confettiAnimation}`);
        confettiEl.style.left = confettiLeft;
        confettiEl.style.width = confettiSize;
        confettiEl.style.height = confettiSize;
        confettiEl.style.backgroundColor = confettiBackground;
        
        elRef.current?.appendChild(confettiEl);
        setTimeout(() => {
          confettiEl.parentNode?.removeChild(confettiEl);
        }, 3000);
      }, 25);

      setIsAnimating(true);

      setTimeout(() => {
        clearInterval(confettiInterval);
        if (elRef.current) {
          elRef.current.style.opacity = "0";
          setTimeout(() => {
            if (elRef.current) {
              elRef.current.innerHTML = "";
              elRef.current.style.opacity = "1";
            }
            setIsAnimating(false);
          }, 1000);
        }
      }, duration);
    }
  };

  useEffect(() => {
    renderConfetti();
  }, []); // Empty dependency array means this effect only runs once on mount

  return <div ref={elRef} className="confetti-container" style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', overflow: 'hidden', pointerEvents: 'none', transition: 'opacity 1s' }} />;
};

export default Confettiful;