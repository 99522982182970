import { BotsProps, MenuOption, PageStateProps, PageTypes, CompleteAIGameData } from "../sdgs_types"
import { SettingsType } from "@utils/types"
import { atom } from "recoil"
export const currentMenuOptionState = atom<MenuOption | null>({
  key: "currentMenuOption",
  default: null,
})

export const currentCustomBotsState = atom<BotsProps[] | null>({
  key: "currentCustomBotsState",
  default: null,
})

export const pageState = atom<PageStateProps | null>({
  key: "pageState",
  default: { pageType: PageTypes.Home },
})

//儲存遊戲選單的關卡列表內容
export const gameMenuState = atom<MenuOption[]>({
  key: "gameMenuState", // 唯一標識
  default: [], // 預設值
})

export const aiGameDataState = atom<CompleteAIGameData | null>({
  key: "aiGameDataState",
  default: null,
})

export const promptsState = atom<Record<string, string>>({
  key: "promptsState",
  default: {},
})
