import {
  GamePerformanceData,
  GameRewardsResult,
  Highlight,
} from "@core/sdgs_types";

const getHighlights = (data: GamePerformanceData): Highlight[] => {
  const highlights: Highlight[] = [];

  if (data.averageAnswerTime < 5) highlights.push(Highlight.QUICK_ANSWER);
  if (data.averageAnswerTime > 20) highlights.push(Highlight.DEEP_THINKER);
  if (data.averageStoryTime && data.averageStoryTime > 15)
    highlights.push(Highlight.STORY_LOVER);
  if (data.score >= 90) highlights.push(Highlight.PERFECT_SCORE);
  else if (data.score >= 80) highlights.push(Highlight.HIGH_SCORE);
  if (data.averagePronunciationScore && data.averagePronunciationScore >= 90)
    highlights.push(Highlight.ARTICULATION_MASTER);
  if (data.averageQAScore && data.averageQAScore >= 90)
    highlights.push(Highlight.QA_EXPERT);

  if (
    data.averagePronunciationPraticCount &&
    data.averagePronunciationPraticCount > 3
  )
    highlights.push(Highlight.PRO_PRACTICE_CHAMPION);

  return highlights;
};

const calculateExp = (
  data: GamePerformanceData,
  highlights: Highlight[]
): number => {
  let exp = 10; // 基礎經驗值
  console.log("基礎經驗值", exp);
  exp += Math.floor(data.score / 10);
  exp += highlights.length * 5;
  console.log("加上分數和亮點獎勵", exp);
  // 額外獎勵
  if (data.averageQAScore) exp += Math.floor(data.averageQAScore / 20);
  if (data.averagePronunciationScore)
    exp += Math.floor(data.averagePronunciationScore / 20);
  console.log("final exp", exp);
  return exp;
};

const getCompletion = (exp: number): string => {
  if (exp >= 50) return "簡直開掛了吧";
  if (exp >= 40) return "不要太厲害哦";
  if (exp >= 30) return "哎呦不錯喔";
  if (exp >= 20) return "還能再強一點";
  if (exp >= 15) return "及格萬歲";
  return "你是真的想及格嗎";
};

const getDynamicEncouragement = (data: GamePerformanceData): string => {
  if (data.averageAnswerTime < 5) return "回答速度驚人！下次試試更快？";
  if (data.averageStoryTime && data.averageStoryTime > 15)
    return "看來你很喜歡劇情呢！看得很認真耶！";
  if (data.averageQAScore && data.averageQAScore >= 90)
    return "問答能力超強！繼續保持！";
  if (data.averagePronunciationScore && data.averagePronunciationScore >= 90)
    return "發音太棒了！你是語言天才嗎？";
  if (data.score >= 50 && data.score < 70)
    return "表現不錯！離高分只差一步之遙！";
  if (
    data.averagePronunciationPraticCount &&
    data.averagePronunciationPraticCount > 3
  )
    return "發音練習很多次！繼續加油！";
  if (data.score < 50) return "繼續努力，你的潛力無限！";
  return "有點可惜！你離獲得閃光亮點只差一點點！";
};

export const calculateGameRewards = (
  data: GamePerformanceData
): GameRewardsResult => {
  const highlights = getHighlights(data);
  const totalExp = calculateExp(data, highlights);
  const completion = getCompletion(totalExp);
  const dynamicEncouragement = getDynamicEncouragement(data);

  return {
    highlights,
    totalExp,
    completion,
    dynamicEncouragement,
  };
};
