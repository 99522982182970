import React, { useEffect, useState } from "react";
import ShareLink from "@core/components/ImmersiveMenu/Game/EndComponents/ShareLink";
import SimplePopup from "@core/components/GeneralComponent/SimplePopup";
import ResultCard, { ResultCardProps } from "./ResultCard";
import Confettiful from "@core/components/Effects/Confettiful";
export interface PlayerScore {
  username: string;
  score: number;
  avatarUrl: string;
}

type GameEndDataProps = {
  isShow: boolean;
  leaningJourneyLogUrl: string;
  playerCards: ResultCardProps[];
  showCommentOrHighlights?: boolean;
};
const MultiGameEnd: React.FC<GameEndDataProps> = ({
  isShow,
  leaningJourneyLogUrl,
  playerCards,
  showCommentOrHighlights = false,
}) => {
  const [isPopup, setIsPopup] = useState(isShow);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (isShow && playerCards.length > 0) {
      setShowConfetti(true);
    }
  }, [isShow, playerCards]);

  return (
    <div className="popup-container">
      <SimplePopup
        isPopup={isPopup}
        setIsPopup={setIsPopup}
        popupClassName={"for-share"}
        isShowCloseBtn={false}
      >
        {playerCards.length === 0
          ? null
          : showConfetti && <Confettiful duration={5000} />}

        <div className={`end-container `}>
          <div className="end-frame --for-multi">
            {playerCards.length === 0 ? (
              <div className="score-generating">評分中，請稍候...</div>
            ) : (
              playerCards.map((card, index) => (
                <ResultCard
                  key={index}
                  userName={card.userName}
                  nickname={card.nickname}
                  avatarUrl={card.avatarUrl}
                  score={card.score}
                  gameRewardsResult={card.gameRewardsResult}
                  contribution={card.contribution}
                  showCommentOrHighlights={showCommentOrHighlights}
                />
              ))
            )}
          </div>
        </div>
        {playerCards.length === 0 ? null : (
          <ShareLink leaningJourneyLogUrl={leaningJourneyLogUrl} />
        )}
      </SimplePopup>
    </div>
  );
};
export default MultiGameEnd;
