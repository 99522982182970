import React, { useState, useEffect } from "react";

interface CheckboxProps {
  label: string;
  isRequired?: boolean;
  onChange: (checked: boolean) => void;
  defaultChecked?: boolean;
  isShowHelp?: boolean;
  helpText?: string;
}

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  isRequired = false,
  onChange,
  defaultChecked = false,
  isShowHelp = true,
  helpText = "",
}) => {
  const [isChecked, setIsChecked] = useState<boolean>(defaultChecked);

  useEffect(() => {
    console.log("useEffect預設是否勾選", defaultChecked, typeof defaultChecked);
    setIsChecked(defaultChecked);
  }, [defaultChecked]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setIsChecked(checked);
    onChange(checked);
  };

  return (
    <div
      className={`form-item ${isRequired ? "must" : ""} ${
        isShowHelp ? "show-help" : ""
      }`}
    >
      <div className="field">{label}</div>
      <div className="value">
        <input
          type="checkbox"
          checked={isChecked}
          onChange={handleChange}
          id={`checkbox-${label}`}
        />
        <label htmlFor={`checkbox-${label}`}>{label}</label>
        {isShowHelp && helpText && (
          <div className="help-word">
            <i className="icon icon-28 inline"></i>
            {helpText}
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkbox;
