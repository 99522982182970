import { useAiOutputGenerator } from "./useAiOutputGenerator";
import { CharacterProbs, CompleteAIGameData } from "@core/sdgs_types";

import { useMemo } from "react";

interface UseGenerateQuestionProps {
  coversations: string[];
  questionIndex: number;
  maxQuestionCount: number;
  characterData: CharacterProbs;
  gameData: CompleteAIGameData;
  prompts: Record<string, string>;
  levelMemory?: string;
}

export function useGenerateQuestion({
  coversations,
  questionIndex,
  maxQuestionCount,
  characterData,
  gameData,
  prompts,
  levelMemory,
}: UseGenerateQuestionProps) {
  function getSystemInstruction(): string {
    let characterPrompts = "";
    if (characterData?.Name) {
      characterPrompts = `你將會扮演以下角色與玩家進行對談
      <角色設定>
      ${characterData?.Name}，${characterData?.Appearance}，${characterData?.TalkStyle}。
      </角色設定>`;
    }
    const systemPrompt = selectPrompt(gameData, prompts);
    const levelDataPrompts = `
      遊戲的背景資訊如下：
      <遊戲背景>
      遊戲名稱：${gameData.IntroductionProps.title}
      sub_title：${gameData.IntroductionProps.subtitle}
      遊戲說明：${gameData.IntroductionProps.description}
      遊戲劇情：${gameData.levelData?.story}
      學習資料：${gameData.learningMaterial}
      </遊戲背景>
      無論如何都要以下面的格式輸出，即使你要說的是 "抱歉，我無法協助滿足該要求。" 也要依照以下格式輸出。 或是當你無法協助的時候，輸出一個劇情選擇題
      請以以下json格式輸出:
       {
         "type": "問答", //題型
         "question": "",
         "options": ["選項1","選項2",...],
         "answer": "正確答案",
     }`;
    let systemInstruction = `${characterPrompts}  ${systemPrompt} ${levelDataPrompts}`;
    if (levelMemory && gameData.levelData?.gameType === "漸進學習") {
      systemInstruction += `\n 請依據，過去的學習紀錄，訂立這次的學習計劃，加強複習上次錯誤的部分，已經學會的部分佔比不要超過一半，另外再加入一兩個新的學習項目：${levelMemory}`;
    }
    return systemInstruction;
  }

  const systemInstruction = useMemo(getSystemInstruction, [
    characterData,
    gameData,
    prompts,
    levelMemory,
  ]);

  const { generateOutput, result, error, debugInfo } = useAiOutputGenerator({
    defaultSystemPrompt: systemInstruction,
    responseType: {
      type: "string",
      question: "string",
      options: "string[]",
      answer: "string",
    },
  });

  const generateQuestion = () => {
    const nextQuestionIndex = questionIndex + 1;

    const historyMessage = coversations.join(";");
    const userMessage = `請依據對話紀錄的脈絡產生新的一個問題，目前是${nextQuestionIndex}題，最多只能到${maxQuestionCount}題數就要結束遊戲。對話紀錄：${historyMessage}，`;

    generateOutput(userMessage);
  };

  return {
    generateQuestion,
    result,
    error,
    debugInfo,
  };
}

function selectPrompt(
  gameData: CompleteAIGameData,
  prompts: Record<string, string>
): string {
  if (gameData?.AIGameData?.customPrompts?.questionGenerationPrompt) {
    return gameData.AIGameData.customPrompts.questionGenerationPrompt;
  }
  const promptName = getPromptNameByType(gameData.levelData?.gameType || "");
  return (
    prompts[promptName] ||
    `你是一位英語遊戲出題助手，請根據遊戲背景資訊，以及玩家過去的歷史對話紀錄，產生新的一個問題。`
  );
}

function getPromptNameByType(type: string): string {
  switch (type) {
    case "自由口說":
      return "LiveQuestion_SpeakOnly";
    case "漸進學習":
      return "LiveQuestion_SpeakOnly";
    default:
      return "LiveQuestion_AllTypeGame";
  }
}
