import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { useEffect, useState } from "react";
import useAvatar from "@hooks/useAvatar";
import usePlayerProgress from "@hooks/usePlayerProgress";
import { useProfile, useUserSettings } from "@core/context/UserSettingsContext";
import { useNavigate } from "react-router-dom";
import { UserData } from "@utils/types";
import ReactGA from "react-ga4";

export default function Selector() {
  const navigate = useNavigate();
  const { data: avatars = [] } = useAvatar();
  const { settings, setProfile } = useUserSettings();
  const defaultAvatar = avatars.length > 0 ? avatars[0] : undefined;
  const { getter } = useProfile();
  const { data: profile, refetch } = getter;
  const [currentAvatar, setCurrentAvatar] = useState(defaultAvatar);
  const [nickname, setNickname] = useState("");
  const [isDataSet, setIsDataSet] = useState({
    progress: false,
    profile: false,
  });

  const { setter: progressSetter } = usePlayerProgress();
  const { setter: profileSetter } = useProfile();
  const { mutate: progressMutate } = progressSetter;
  const { mutate: profileMutate } = profileSetter;

  const savingProgress = () => {
    progressMutate(
      {},
      {
        onSuccess: (res) => {
          console.log("saving progress success");
          setIsDataSet((prev) => ({ ...prev, progress: true }));
        },
        onError: (error: any) => {
          console.log(error.message);
        },
      }
    );
  };

  const savingProfile = () => {
    const userData: UserData = {
      nickname: nickname,
      avatarId: currentAvatar?.id as string,
      config: settings,
    };
    ReactGA.event({
      category: "創角頁",
      action: "選擇角色",
      label: currentAvatar?.name,
    });
    profileMutate(userData, {
      onSuccess: (res) => {
        setIsDataSet((prev) => ({ ...prev, profile: true }));
        setProfile({
          nickname: nickname,
          avatarId: currentAvatar?.id as string,
          avatar: currentAvatar,
        });
        console.log("saving profile success");
        setTimeout(() => {
          refetch();
        }, 200);
      },
      onError: (error: any) => {
        console.log(error.message);
      },
    });
  };

  const handleSubmit = () => {
    if (nickname.length > 0) {
      savingProgress();
      savingProfile();
      console.log("saving profile");
    }
  };

  useEffect(() => {
    if (isDataSet.progress && isDataSet.profile) navigate("/");
  }, [isDataSet]);

  useEffect(() => {
    if (avatars.length > 0) setCurrentAvatar(avatars[0]);
  }, [avatars]);

  return (
    <div className="game-character-container --center-mode">
      <div className="character-swiper">
        <Swiper
          className="swiper-container"
          modules={[Navigation]}
          spaceBetween={10}
          speed={800}
          loop={false}
          centeredSlides={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            740: {
              slidesPerView: 3,
            },
            980: {
              slidesPerView: 3,
            },
          }}
          onSlideChange={(swiper: any) => {
            if (Number.isInteger(swiper.realIndex))
              setCurrentAvatar(avatars[swiper.realIndex]);
          }}
        >
          <div className="swiper-wrapper">
            {avatars.map((avatar, index) => (
              <SwiperSlide key={index}>
                <div className="avatar">
                  <div
                    className="img"
                    style={{ backgroundImage: `url('${avatar.image}')` }}
                  ></div>
                </div>
              </SwiperSlide>
            ))}
          </div>
        </Swiper>
        <div className="swiper-ctrl --text">
          <div className="swiper-button-prev">
            <span>PREV</span>
          </div>
          <div className="swiper-button-next">
            <span>NEXT</span>
          </div>
        </div>
      </div>
      <div className="character-info">
        <div className="character-card">
          <div className="text-holder">
            <h2>{currentAvatar?.name}</h2>
            <p>{currentAvatar?.description}</p>
          </div>
        </div>
        <div className="sci-frame character-form">
          <i className="deco-top"></i>
          <div className="select-title-area">
            <h1>Create Character</h1>
          </div>
          <div className="sci-form-item">
            <div className="field">暱稱</div>
            <div className="value">
              <input
                type="text"
                placeholder="請輸入暱稱"
                value={nickname}
                onChange={(e) => setNickname(e.target.value.trim())}
              />
            </div>
          </div>

          <div className="item btn-area">
            <button className="btn-text-light" onClick={handleSubmit}>
              <span>建立</span>
            </button>
          </div>
        </div>
      </div>

      <div className="character-name">
        <h1>{currentAvatar?.name}</h1>
      </div>
    </div>
  );
}
