import {
  GamePerformanceData,
  GameRewardsResult,
  WriteScoreDimension,
  Highlight,
} from "@core/sdgs_types";

const getHighlights = (
  writeScoreDimension: WriteScoreDimension,
  gamePerformanceData: GamePerformanceData
): Highlight[] => {
  const highlights: Highlight[] = [];
  const { content, organization, grammarAndSyntax, vocabularyAndSpelling } =
    writeScoreDimension;

  // 計算總分 0-20分
  const totalScore =
    content + organization + grammarAndSyntax + vocabularyAndSpelling;

  // 計算寫作時間（分鐘）
  const writingTime = gamePerformanceData.gameDuration / 60;

  // 優秀表現判定
  if (totalScore >= 4.8) highlights.push(Highlight.PERFECT_SCORE); // 近乎完美
  else if (totalScore >= 4.2) highlights.push(Highlight.HIGH_SCORE); // 優秀表現

  // 時間相關判定
  if (writingTime < 10) {
    highlights.push(Highlight.QUICK_ANSWER);
    // 快速且高分可獲得神速文豪成就
    if (totalScore >= 4.2) {
      highlights.push(Highlight.SPEED_MASTER);
    }
  } else if (writingTime >= 20 && writingTime <= 30) {
    highlights.push(Highlight.DEEP_THINKER); // 適中的思考時間
  } else if (writingTime > 30) {
    highlights.push(Highlight.MARATHON_WRITER);
  }
  // 極簡主義者判定
  if (
    gamePerformanceData.averageAnswerLength &&
    gamePerformanceData.averageAnswerLength < 50 &&
    totalScore >= 4
  ) {
    highlights.push(Highlight.MINIMALIST);
  }

  return highlights;
};

const calculateExp = (
  writeScoreDimension: WriteScoreDimension,
  highlights: Highlight[]
): number => {
  const { content, organization, grammarAndSyntax, vocabularyAndSpelling } =
    writeScoreDimension;

  let exp = 10; // 基礎經驗值

  // 各維度分數轉換為經驗值
  exp += Math.floor(
    (content + organization + grammarAndSyntax + vocabularyAndSpelling) / 20
  );

  // 亮點獎勵
  exp += highlights.length * 5;

  return exp;
};

const getCompletion = (exp: number): string => {
  if (exp >= 50) return "文采出眾！";
  if (exp >= 40) return "妙筆生花！";
  if (exp >= 30) return "筆力可嘉！";
  if (exp >= 20) return "繼續加油！";
  if (exp >= 15) return "漸入佳境！";
  return "文思泉湧中...";
};
const getDynamicEncouragement = (
  writeScoreDimension: WriteScoreDimension,
  highlights: Highlight[]
): string => {
  const { content, organization, grammarAndSyntax, vocabularyAndSpelling } =
    writeScoreDimension;

  if (highlights.includes(Highlight.PERFECT_SCORE))
    return "完美的作品！你就是天生的作家！";
  if (highlights.includes(Highlight.HIGH_SCORE))
    return "太棒了！你的表現相當優秀！";
  if (highlights.includes(Highlight.SPEED_MASTER))
    return "才思敏捷，文筆流暢，令人讚嘆！";
  if (highlights.includes(Highlight.QUICK_ANSWER))
    return "反應真快！思路清晰明確！";
  if (highlights.includes(Highlight.DEEP_THINKER))
    return "深思熟慮的好文章，字字珠璣！";
  if (highlights.includes(Highlight.MARATHON_WRITER))
    return "沉浸在寫作中，時間都忘記了呢！";
  if (highlights.includes(Highlight.MINIMALIST)) return "言簡意賅，一針見血！";
  if (content > 85) return "內容精彩，繼續保持！";
  if (organization < 70) return "試著多注意文章的結構安排喔！";
  if (grammarAndSyntax < 70) return "多練習文法，讓表達更加準確！";

  return "每次寫作都是一次成長，繼續加油！";
};

export const calculateGameRewards = (
  gamePerformanceData: GamePerformanceData,
  writeScoreDimension: WriteScoreDimension
): GameRewardsResult => {
  const highlights = getHighlights(writeScoreDimension, gamePerformanceData);
  const totalExp = calculateExp(writeScoreDimension, highlights);
  const completion = getCompletion(totalExp);
  const dynamicEncouragement = getDynamicEncouragement(
    writeScoreDimension,
    highlights
  );

  return {
    highlights,
    totalExp,
    completion,
    dynamicEncouragement,
  };
};
