import { AxiosResponse, AxiosError } from "axios";
import { useQuery } from "react-query";
import { request } from "@utils/axios-util";

import { LearningJourneyLog, CompleteAIGameData } from "@core/sdgs_types";
interface LogProps {
  is_success: boolean;
  game_data: CompleteAIGameData;
  update_timestamp: string;
  learning_journey_log: LearningJourneyLog;
  username: string;
  nickname: string;
  avatar: string;
}
export default function useGetLog(query: string | undefined) {
  const queryFn = () =>
    request({
      url: `/game/log`,
      method: "get",
      params: { query: query },
    });

  return useQuery(["fetch-get-log-api", query], {
    queryFn,
    onError: (error: AxiosError) => {
      console.log(error);
    },
    select: (res: AxiosResponse): LogProps => {
      console.log("取得後台log data:", res.data);
      const pharsedData = {
        is_success: res.data.is_success,
        username: res.data.data[0]?.user_id,
        nickname: res.data.data[0]?.nickname,
        avatar: res.data.data[0]?.avatar,
        game_data: res.data.data[0]?.game_data,
        update_timestamp: res.data.data[0]?.update_timestamp,
        learning_journey_log: res.data.data[0]?.learning_journey_log,
      };
      return pharsedData;
    },
    enabled: !!query,
  });
}
