
import React, { useState, useEffect, useCallback } from "react";
const useUserInput = () => {
    const [userLastInput, setUserLastInput] = useState("");
    const [isUserSentMsg, setIsUserSentMsg] = useState(false);
    const [conversationCount, setConversationCount] = useState(0);
  
    return {
      userLastInput,
      setUserLastInput,
      isUserSentMsg,
      setIsUserSentMsg,
      conversationCount,
      setConversationCount
    };
  };
  
    export default useUserInput;