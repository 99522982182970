import { useMutation } from "react-query";
import { request } from "@utils/axios-util";

interface DeleteFileResponse {
  file_id: string;
  is_success: boolean;
  error_message?: string;
  file_owner: string;
}

export default function useDeleteLevelFiles() {
  const mutation = useMutation({
    mutationFn: (fileIds: string[]) =>
      request({
        url: `/delete_files_from_google_drive`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
        data: { file_ids: fileIds },
      }),
  });

  return {
    ...mutation,
    isAllSuccess: () =>
      mutation.data?.data?.every(
        (file: DeleteFileResponse) => file.is_success
      ) ?? false,
    getErrorMessages: () => {
      const failedFiles =
        mutation.data?.data?.filter(
          (file: DeleteFileResponse) => !file.is_success
        ) || [];
      if (failedFiles.length === 0) return "";

      const errorDetails = failedFiles
        .map(
          (file: DeleteFileResponse) =>
            `${file.error_message} (擁有者: ${file.file_owner})`
        )
        .join("\n");

      return `共有 ${failedFiles.length} 個檔案刪除失敗：\n${errorDetails}`;
    },
  };
}
