// 定義一個幫助函數來獲取更友好的語言名稱
export const getLanguageName = (languageCode: string) => {
  const languageMap: { [key: string]: string } = {
    'en': 'English',
    'zh-CN': '简体中文',
    'zh-TW': '繁體中文',
    'ja': '日本語'
  };
  return languageMap[languageCode] || languageCode;
};
