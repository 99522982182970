import { useMutation } from "react-query";
import { request } from "@utils/axios-util";

interface SpeechToTextData {
  jwt_token?: string;
  audio_data: string;
}

export default function useSpeechToText() {
  const mutationFn = (data: SpeechToTextData) =>
    request({
      url: "/audio_to_text",
      method: "POST",
      data: data,
    });

  return useMutation({
    mutationFn,
  });
}