import { Keywords } from "@core/sdgs_types";
// 定義 TypeScript 類型
interface ParseTextProps {
  tags: string[];
  text: string;
}
type ParsedTagContent = {
  type: string;
  content: string;
};
interface ParseLabelProps {
  text: string;
  labels: string[];
}

// 定义label返回类型
type ParsedData = Record<string, string>;

// 定义tag返回类型
type ParsedResult = Record<string, ParsedTagContent>;
// 提取所有標籤tags的內容
export function ExtractAllTagsContent(text: string): ParsedResult {
  const data: ParsedResult = {};

  // 匹配所有標籤的正則表達式
  const allTagsRegex =
    /<(\w+)(?:\s+type=([^>]+))?>((?:(?!<\1[\s>])[\s\S])*?)<\/\1>/g;

  let match;
  while ((match = allTagsRegex.exec(text)) !== null) {
    const tag = match[1];
    const type = match[2] || "default";
    const content = match[3];

    if (content) {
      data[tag] = {
        type: type.replace(/['"]/g, "").trim(), // 移除引號並去除首尾空白
        content: content.trim(),
      };
    }
  }

  return data;
}

//根據指定的標籤tags 提取內容
export function parseTextFromLabels({
  text,
  labels,
}: ParseLabelProps): ParsedData {
  const data: ParsedData = {};
  labels.forEach((label) => {
    // 创建正则表达式来查找格式为 <label>: 内容 的文本
    const regex = new RegExp(`${label}:\\s*(.+)`, "g");
    const match = regex.exec(text);
    if (match) {
      data[label] = match[1].trim(); // 将捕获的内容存储在字典中，键为标签名
    }
  });
  return data;
}

export function TagBasedContentExtractor({
  tags,
  text,
}: ParseTextProps): ParsedResult {
  const data: ParsedResult = {};
  // console.log("tags", tags);
  // console.log("text", text);

  tags.forEach((tag) => {
    // 支持帶有type參數的標籤
    const regexWithType = new RegExp(
      `<${tag}\\s+type=([^>]+)>\\s*([\\s\\S]*?)\\s*</${tag}>`,
      "g"
    );
    let match;
    while ((match = regexWithType.exec(text)) !== null) {
      data[tag] = { type: match[1], content: match[2].trim() };

      //console.log("尋找match的tag內容", tag, data[tag]);
    }

    // 支持沒有參數的標籤，並給它一個預設的類型
    const regex = new RegExp(`<${tag}>\\s*([\\s\\S]*?)\\s*</${tag}>`, "g");
    while ((match = regex.exec(text)) !== null) {
      data[tag] = { type: "default", content: match[1].trim() };
    }
  });

  return data;
}
export function parseListByNewLine(content: string): string[] {
  return content
    .split("\n")
    .map((line) => line.trim())
    .filter((line) => line.length > 0);
}
export function parseKeywords(input: string): Keywords {
  // 檢查輸入是否為 undefined 或 null
  if (!input) {
    //console.error("No keywords found in game data.:");
    return [];
  }

  // 將輸入字串按行分割
  const lines = input.split("\n").filter((line) => line.trim() !== "");

  // 將每行分割成對應的鍵值對
  const keywords: [string, string][] = lines.map((line) => {
    const [key, value] = line.split(":");
    if (!key || !value) {
      throw new Error(`Invalid format: ${line}`);
    }
    return [key.trim(), value.trim()];
  });

  return keywords;
}
// 從文本中刪除標籤, 並返回新的文本 <tag>...</tag>
export function removeTagsFromText(text: string, tags: string[]): string {
  tags.forEach((tag) => {
    // 正則表達式以匹配開始和結束標籤，包括標籤內的所有內容
    const regex = new RegExp(`<${tag}>[\\s\\S]*?<\\/${tag}>`, "gi");
    // 將符合的標籤和內容替換為空字符串
    text = text.replace(regex, "");
  });
  return text;
}
