import ReactDOM from "react-dom/client";
import AppImmersive from "./AppImmerse";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import { AxiosInterceptor } from "@utils/axios-util";

import { QueryClientProvider, QueryClient } from "react-query";
import { UserSettingsProvider } from "@core/context/UserSettingsContext";
import { CacheProvider } from "@core/context/CacheContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});
root.render(
  <BrowserRouter basename="/">
    <RecoilRoot>
      <AxiosInterceptor>
        <CacheProvider>
          <QueryClientProvider client={queryClient}>
            <UserSettingsProvider>
              <AppImmersive />
            </UserSettingsProvider>
          </QueryClientProvider>
        </CacheProvider>
      </AxiosInterceptor>
    </RecoilRoot>
  </BrowserRouter>
);
