import { useState, useEffect } from "react";
import { levelDataProbs } from "@core/sdgs_types";
import { Question } from "@core/sdgs_types";
import {
  useAiOutputGenerator,
  AutoTypeDescription,
} from "@core/hooks/useAiOutputGenerator";
import { freeFormGameTypes } from "../config/CreateContentConfig";

interface LevelData {
  title: string;
  subtitle: string;
  description: string;
  story: string;
  visualDescription: string;
  learningMaterial: string;
  questions: string;
}
const aiLevelDataType: AutoTypeDescription<LevelData> = {
  title: "string",
  subtitle: "string",
  description: "string",
  story: "string",
  visualDescription: "string",
  learningMaterial: "string",
  questions: "string",
};
interface questionsContent {
  questions: string;
}
const aiQuestionContentType: AutoTypeDescription<questionsContent> = {
  questions: "string",
};

const aiQuestionDataType: AutoTypeDescription<Question> = {
  type: "string",
  question: "string",
  options: "string[]",
  answer: "string",
};

const defaultSystemPrompt = `

你產生的遊戲主題，與劇情，必須要有吸引力，不能太過於無聊，不能太過於正經，不能沒有特色。
遊戲節奏以快為主，遊戲關卡的問題數量以5-10題為宜。

請依照使用者輸入的資料，輸入的關卡難度，題目數量等等，生成遊戲關卡內容，
"title": "名稱很重要，要能夠吸引玩家進入遊戲",
"subtitle": "副標題，可以是遊戲的主題，遊戲的目標等等",
"description": "顯示在遊戲進入頁面，給玩家閱讀的關卡說明，可以是遊戲目標，遊戲規則等等",
"story": "遊戲劇情敘述，用於引導劇情，生成關卡劇情與題目內容",
"visualDescription": "詳細遊戲場景敘述，用於生成遊戲關卡的主視覺背景圖，請以遊戲適當的背景圖來設計視覺描述",
"learningMaterial": "根據本關卡的學習資料與題目，整理出適當難度，適當數量，高中生容易學習閱讀的英語學習教材，請用繁體中文解釋教材內容，要有學習的單字，單字說明，簡單例句解釋，與其他相關知識說明。目的讓學生閱讀後，更容易答對遊戲裡的問題，這個欄位是字串，不可以使用markdown格式，注意要換行的地方要有換行字元",
"questions": "遊戲題目，遊戲題目的答案，遊戲題目的提示，遊戲題目的教學說明，這個欄位是字串格式，但是可以多行的字串，不可以使用markdown格式，注意要換行的地方要有換行字元"


questions 遊戲題目注意事項：
通用規則：
所有題型都要遵守選項格式：[選項]
避免使用數字或字母標記選項（如 1. xxx 或 A. xxx）
每種題型開頭必須標明對應的標籤（如 =劇情選擇=）
題型分類及具體要求： 
a) 劇情選擇（=劇情選擇=）
使用聊天語氣進行對話設計
提供兩個有趣的對話選項
目的是讓玩家體驗選擇的樂趣，無對錯之分
b) 問答題（=問答=）
要求玩家用簡單的英文句子回答，不限於單字
提供一個目標學習語言的示範例句作為提示，例句只需要開頭的指引即可，不要整整句子
c) 單選題（=單選=）
提供最多三個選項
選項可以是單字或短句
d) 多選題（=多選=）
提供不超過六個選項
每個選項最好為一到兩個單字
e) AI生圖文字描述（=生圖=）
專門用於AI生圖的文字描述題
選項格式示例： =單選=如果你遇到困難，你會依靠什麼？ [Faith] [Strength] [Intelligence]
`;

const defaultLevel: levelDataProbs = {
  multiPlayer: false,
  title: "",
  subtitle: "",
  description: "",
  story: "",
  visualDescription: "",
  questions: "",
  gameType: "自由口說",
  difficulty: "A2",
  numberOfQuestions: "10",
  learningMaterial: "",
  IsEditableForAll: true,
  Creator: "",
  avatarName: "",
  avatarUrl: "",
  avatarVoice: "",
  wellcomeMessage: "",
  avatarId: "",
  writing_subject: "",
  writing_description: "",
  writing_rules: "",
  writing_minWords: 20,
  writing_maxWords: 150,
};

export const useAIGenerateLevel = (
  levelPromptName: string = "LevelCreator",
  questionsPromptName: string = "QuestionFormater"
) => {
  const [level, setLevel] = useState<levelDataProbs>(defaultLevel);
  const [processing, setProcessing] = useState<boolean>(false);
  const [inputLevel, setInputLevel] = useState<levelDataProbs | null>(null);
  const [isLearningMaterialLocked, setIsMaterialLocked] =
    useState<boolean>(false);
  const onGenerateError = () => {
    setProcessing(false);
  };
  const { result, generateOutput } = useAiOutputGenerator<LevelData>({
    defaultSystemPrompt,
    globalConfigPromptKey: levelPromptName,
    responseType: aiLevelDataType,
    onFail: onGenerateError,
  });

  const { result: questionsResult, generateOutput: questionsGenerate } =
    useAiOutputGenerator<Question>({
      defaultSystemPrompt,
      globalConfigPromptKey: questionsPromptName,
      responseType: aiQuestionDataType,
    });
  const {
    result: questionContentResult,
    generateOutput: questionsContentGenerate,
  } = useAiOutputGenerator<questionsContent>({
    defaultSystemPrompt,
    globalConfigPromptKey: levelPromptName,
    responseType: aiQuestionContentType,
  });

  const generateLevel = (
    aiLevelProbs: levelDataProbs,
    additionalPrompt: string = "",
    isMaterialLocked: boolean = false
  ) => {
    setInputLevel(aiLevelProbs);
    setIsMaterialLocked(isMaterialLocked);
    const MaterialLoacedPrompt = isMaterialLocked
      ? "學習資料已鎖定，完全一個字都不要更改學習資料，直接輸出即可，但注意要符合json規範。題目出題請依循學習資料內容。"
      : "";
    console.log("isMaterialLocked是否被鎖定:", MaterialLoacedPrompt);
    const userMessage =
      (aiLevelProbs.title
        ? `遊戲名稱: ${aiLevelProbs.title}`
        : "遊戲名稱: Generate a unique name.") +
      ", " +
      (aiLevelProbs.subtitle
        ? `副標題: ${aiLevelProbs.subtitle}`
        : "副標題: Generate a unique subtitle.") +
      ", " +
      (aiLevelProbs.description
        ? `關卡描述: ${aiLevelProbs.description}`
        : "關卡描述: Generate a unique level description.") +
      ", " +
      (aiLevelProbs.story
        ? `劇情: ${aiLevelProbs.story}`
        : "劇情: Generate a unique story.") +
      " " +
      (aiLevelProbs.visualDescription
        ? `場景敘述: ${aiLevelProbs.visualDescription}`
        : "場景敘述: Generate a unique visual description.") +
      ", " +
      `難度: ${aiLevelProbs.difficulty}, ` +
      `題目數量: ${aiLevelProbs.numberOfQuestions}, ` +
      `出題來源的英語學習資料: ${aiLevelProbs.learningMaterial}, ` +
      MaterialLoacedPrompt +
      (aiLevelProbs.questions ? `題目: ${aiLevelProbs.questions}` : "") +
      " " +
      additionalPrompt;
    generateOutput(userMessage);
    setProcessing(true);
  };
  function validateLevelData(data: any): data is levelDataProbs {
    return (
      typeof data === "object" &&
      typeof data.title === "string" &&
      typeof data.subtitle === "string" &&
      typeof data.description === "string" &&
      typeof data.story === "string" &&
      typeof data.learningMaterial === "string" &&
      typeof data.visualDescription === "string" &&
      typeof data.questions === "string" &&
      typeof data.gameType === "string" &&
      typeof data.difficulty === "string" &&
      typeof data.numberOfQuestions === "string"
    );
  }
  useEffect(() => {
    if (result) {
      try {
        console.log("生成關卡內容完成，收到的result:", result);
        // 確保 result 是一個對象
        const parsedResult =
          typeof result === "string" ? JSON.parse(result) : result;

        var newLevel: levelDataProbs = {
          multiPlayer:
            inputLevel?.multiPlayer === undefined
              ? false
              : inputLevel?.multiPlayer,
          avatarId: inputLevel?.avatarId || "",
          title: parsedResult.title,
          subtitle: parsedResult.subtitle,
          description: parsedResult.description,
          story: parsedResult.story,
          visualDescription: parsedResult.visualDescription,
          questions: parsedResult.questions,
          gameType: inputLevel?.gameType || "對話類型遊戲",
          difficulty: inputLevel?.difficulty || "初學者",
          numberOfQuestions: inputLevel?.numberOfQuestions || "5",
          learningMaterial: parsedResult.learningMaterial,
          avatarName: "指引者",
          avatarUrl:
            "http://www.cameo.tw/ntso/SDGs_botrun_image/avatar_dune_oldman.png",
          avatarVoice: "onyx",
          wellcomeMessage: "我是你的遊戲指引者，想要進入挑戰嗎？[Yes]",
          writing_subject: parsedResult.writing_subject || "",
          writing_description: parsedResult.writing_description || "",
          writing_rules: parsedResult.writing_rules || "",
          writing_minWords: Number(parsedResult.writing_minWords) || 20,
          writing_maxWords: Number(parsedResult.writing_maxWords) || 150,
        };
        if (isLearningMaterialLocked && inputLevel) {
          newLevel.learningMaterial = inputLevel.learningMaterial;
        }
        console.log("生成關卡內容完成,生成了關卡資料:", newLevel);
        // 驗證 newLevel 是否符合 levelDataProbs 的結構
        if (!validateLevelData(newLevel)) {
          console.error("生成的newLevel關卡資料格式不正確", newLevel);
          throw new Error("生成的newLevel關卡資料格式不正確");
        }
        if (freeFormGameTypes.includes(newLevel.gameType)) {
          newLevel.questions = "";
        }
        setLevel(newLevel);

        if (!freeFormGameTypes.includes(newLevel.gameType)) {
          startFormatQuestions(newLevel.questions);
        } else {
          console.log("目前是遊戲類型，不需要格式化題目", newLevel.gameType);
          setProcessing(false);
        }
      } catch (e) {
        console.error("Error 生成關卡內容失敗:", e, result);
        // 可以在這裡添加錯誤處理邏輯，例如顯示錯誤消息給用戶
      }
      setProcessing(false);
    }
  }, [result]);

  const [generatedQuestions, setGeneratedQuestions] = useState<Question[]>([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

  const generateOneQuestion = (
    questionText: string,
    curIndex: number,
    generatedQuesiton: Question[] = []
  ) => {
    if (!questionText) {
      console.error("題目內容是空的，沒有題目可以生成");
      setProcessing(false);
      return;
    }
    if (curIndex >= parseInt(level?.numberOfQuestions || "5")) {
      console.log("已經生成過所有題目了，不需要再生成了");
      setProcessing(false);
      return;
    }
    console.log("生成問題格式:", curIndex + 1);
    const generatedQuestionString = generatedQuesiton
      ? "已經產生過的問題如下，注意不要再產生相同問題了:" +
        generatedQuesiton.map((q) => q.question).join(",") +
        "。"
      : "";
    const userMessage =
      `問題列表： ${questionText}。要輸出第 ${curIndex + 1}題問題。` +
      generatedQuestionString;
    try {
      questionsGenerate(userMessage);
    } catch (e) {
      console.error("Error 生成問題格式:", userMessage);
    }
  };

  // 生成問題內容, 這個函數會根據學習資料，題目內容，背景設定，生成一個問題內容
  const generateQuestionContent = (
    learningMaterial: string,
    questionText: string,
    backgroundSetting: string,
    additionalPrompt: string
  ) => {
    const userMessage = `請依據學習資料: ${learningMaterial}, 與現有的題目方向: ${questionText}, 遊戲的背景設定: ${backgroundSetting} 來產生出適合的題目內容，請注意，只需要產生questions欄位的資料，不需要產生其他內容，請依據以下規則產生題目： ${additionalPrompt}`;
    questionsContentGenerate(userMessage);
  };
  useEffect(() => {
    if (questionContentResult) {
      console.log("生成問題內容完成:", questionContentResult);

      startFormatQuestions(questionContentResult.questions);
    }
  }, [questionContentResult]);
  const startFormatQuestions = (rawQuesitons: string) => {
    setProcessing(true);
    setGeneratedQuestions([]);
    setCurrentQuestionIndex(0);
    setLevel((prev) => {
      if (prev) {
        return {
          ...prev,
          questions: rawQuesitons,
        };
      }
      return prev;
    });
    generateOneQuestion(rawQuesitons, 0);
  };
  useEffect(() => {
    if (questionsResult) {
      console.log(
        "一題問題格式化完成:",
        questionsResult,
        currentQuestionIndex + 1
      );
      const newestIndex = currentQuestionIndex + 1;
      const newestQuestionList: Question[] =
        generatedQuestions.concat(questionsResult);
      setGeneratedQuestions((prev) => [...prev, questionsResult]);
      setCurrentQuestionIndex((prev) => prev + 1);
      if (currentQuestionIndex < parseInt(level?.numberOfQuestions || "5")) {
        console.log(
          `執行生成下一題 ${currentQuestionIndex + 1}/${
            level?.numberOfQuestions
          }`,
          level
        );
        generateOneQuestion(
          level?.questions || "",
          newestIndex,
          newestQuestionList
        );
      } else {
        setProcessing(false);
        console.log("所有questions格式化完成:", generatedQuestions);
      }
    }
  }, [questionsResult]);

  return {
    level,
    generatedQuestions,
    setGeneratedQuestions,
    processing,
    generateLevel,
    startFormatQuestions,
    generateQuestionContent,
    setLevel,
  };
};
