import React, { ReactNode } from "react";

interface PopupProps {
  children: ReactNode;
  isPopup: boolean;
  setIsPopup: (isVisible: boolean) => void;
  popupClassName?: string;
  onClosed?: () => void;
  isShowCloseBtn?: boolean;
  popupContainerClassName?: string;
}

const SimplePopup: React.FC<PopupProps> = ({
  children,
  isPopup,
  setIsPopup,
  popupClassName,
  onClosed,
  isShowCloseBtn = true,
  popupContainerClassName = "popup-container",
}) => {
  if (!isPopup) {
    return null;
  }

  const onClose = () => {
    setIsPopup(false);
    if (onClosed) {
      onClosed();
    }
  };

  return (
    <div
      className={`game-popup-area ${popupClassName} ${
        isPopup ? "--active" : ""
      }`}
    >
      <div className={popupContainerClassName}>
        {children}
        {isShowCloseBtn && (
          <div className="btn-close" data-closepopup onClick={onClose}>
            <i className="icon icon-9"></i>
          </div>
        )}
      </div>
    </div>
  );
};

export default SimplePopup;
