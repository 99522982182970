import { useRecoilState } from "recoil";
import React, { useState, useEffect } from "react";
import GameBackground from "../GeneralComponent/GameBackground";
import "@core/CSS/StartPage.css";
import { userAuthState } from "@core/recoil/atoms";
import { useNavigate } from "react-router-dom";
import AnimatedBG from "../GeneralComponent/AnimatedBG";
import { useTranslation } from "react-i18next";
import { authTokenState } from "@core/recoil/atoms";
export default function GameLoginPage({
  title = "immerse",
  subtitle = "全生成式英語學習平台",
  description = "請輸入您的高中名稱與暱稱，按下下方的按鈕進入遊戲。",
  backgroundImageUrl = "/images/bg-stage.jpg",
  naviUrl = "/",
}) {
  const { t } = useTranslation();
  const [, setUser] = useRecoilState(userAuthState);
  const [, setToken] = useRecoilState(authTokenState);
  const [schoolName, setSchoolName] = useState("");
  const [nickname, setNickname] = useState("");
  const navigate = useNavigate();
  const handleFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault(); // 防止表單的預設提交行為
    if (!schoolName || !nickname) {
      alert(t("fillAllFields"));
    } else {
      console.log(
        "button is pressed with school name:",
        schoolName,
        "and nickname:",
        nickname
      );
      // 建立帶有參數的 URL
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || "";
      const authUrl = API_BASE_URL + "/sdgs_request_token";
      const url = new URL(authUrl);
      url.searchParams.append("username", nickname);

      // 以 GET 方法呼叫 API
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("API response:", data);
          setUser({
            token: data["access_token"],
            type: "bearer",
            username: nickname,
          });
          setToken({
            accessToken: data["access_token"],
            tokenType: "bearer",
          });
          navigate(naviUrl);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  };

  useEffect(() => {
    setUser(null);
    setToken(null);
    console.log("測試用登入"); // 當組件第一次載入時，在控制台打印 "hello"
  }, []); // 依賴陣列留空，這表示這個效果只在組件第一次渲染後執行

  return (
    <div className="game-container">
      <form className="game-stage-container" onSubmit={handleFormSubmit}>
        <div className="game-logo">
          <h1>{t("title")}</h1>
          <p>{t("subtitle")}</p>
        </div>
        <div className="login-container">
          <div className="login">
            <div className="form-item">
              <input
                type="text"
                placeholder={t("schoolName")}
                className="styled-input"
                value={schoolName}
                onChange={(e) => setSchoolName(e.target.value)}
              />
              <i className="icon icon-21"></i>
            </div>

            <div className="form-item">
              <input
                type="text"
                placeholder={t("playerNickname")}
                className="styled-input"
                value={nickname}
                onChange={(e) => setNickname(e.target.value)}
              />
              <i className="icon icon-20"></i>
            </div>

            <button className="btn-stage click-sound" type="submit">
              <div>
                <i className="icon icon-6"></i>
                <span>{t("enter")}</span>
              </div>
            </button>
          </div>
        </div>
      </form>
      <AnimatedBG />
    </div>
  );
}
