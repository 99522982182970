import React from "react";
import { useNavigate } from "react-router-dom";

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
} from "react-share";
import { Share } from "lucide-react";

interface ShareLinkProps {
  imageUrl?: string;
  leaningJourneyLogUrl?: string;
}

const ShareLink: React.FC<ShareLinkProps> = ({
  imageUrl,
  leaningJourneyLogUrl,
}) => {
  const navigate = useNavigate();
  const handleReplay = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault(); // 防止預設行為
    navigate("/"); // 導航到根目錄
  };
  const handleDownload = () => {
    // 生成隨機的 UUID 並取前 8 個字元
    const generateUUID = () => {
      return "xxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        const v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      });
    };

    const uuid = generateUUID();
    const downloadUrl = `${imageUrl}?download=${uuid}.jpg`;

    // 使用 window.open 在新視窗中打開下載連結
    window.open(downloadUrl, "_blank");
  };
  const handleLearningJourney = () => {
    if (leaningJourneyLogUrl) {
      console.log("前往leaningJourneyLogUrl", leaningJourneyLogUrl);
      window.open(leaningJourneyLogUrl, "_blank");
    } else {
      console.error("No leaningJourneyLogUrl");
    }
  };
  return (
    <div className="fn-area">
      <FacebookShareButton
        url={window.location.href}
        hashtag="#AI英語學習 #english.botrun.ai"
      >
        <a href="#" className="btn-system">
          <span>
            分享到 <i className="icon icon-51"></i>
          </span>
        </a>
      </FacebookShareButton>
      {/* <a href="#" className="btn text" onClick={handleReplay}>
        更多遊戲{" "}
      </a> */}
      {imageUrl ? (
        <a href="#" className="btn text" onClick={handleDownload}>
          下載圖片{" "}
        </a>
      ) : null}
      {leaningJourneyLogUrl && (
        <div className="btn --white --small" onClick={handleLearningJourney}>
          <i className="icon icon-17"></i>
          <span>學習歷程</span>
        </div>
      )}
      <a href="#" className="btn-system" onClick={handleReplay}>
        <span>
          <i className="icon icon-33"></i>完成任務
        </span>
      </a>
    </div>
  );
};

export default ShareLink;
