import JSON5 from "json5";
function removePrefixAndSuffixIfPresent(
  input: string,
  prefix: string,
  suffix: string
): string {
  let result = input;
  //console.log("移除前綴和後綴:", prefix, suffix, result);
  // 移除前綴（如果存在）
  if (result.startsWith(prefix)) {
    result = result.slice(prefix.length);
    //console.log("移除前綴:", prefix, result);
  }

  // 移除後綴（如果存在）
  if (result.endsWith(suffix)) {
    result = result.slice(0, -suffix.length);
    //console.log("移除後綴:", suffix, result);
  }

  return result;
}

export function jsonPharseTool(
  jsonRaw: string | object,
  useCleanText: boolean = true
): any {
  //console.log("開始解析，輸入類型:", typeof jsonRaw);

  if (typeof jsonRaw === "object" && jsonRaw !== null) {
    //console.log("輸入已經是對象，直接返回");
    return jsonRaw;
  }

  let sourceText =
    typeof jsonRaw === "string" ? jsonRaw : JSON.stringify(jsonRaw);
  //console.log("原始文本:", sourceText);

  // 處理可能的嵌套JSON結構
  if (sourceText.startsWith("{") && sourceText.endsWith("}")) {
    try {
      const outerObj = JSON.parse(sourceText);
      if (outerObj.message && typeof outerObj.message === "string") {
        sourceText = outerObj.message;
        console.log(
          "檢測到嵌套JSON結構，提取message, 注意，你的json命名不能叫message",
          sourceText
        );
      }
    } catch (e) {
      console.log("外層解析失敗，繼續處理原始文本");
    }
  }
  if (useCleanText) {
    sourceText = sourceText
      .replace(/\\n/g, "\\n")
      .replace(/\\'/g, "\\'")
      .replace(/\\"/g, '\\"')
      .replace(/\\&/g, "\\&")
      .replace(/\\r/g, "\\r")
      .replace(/\\t/g, "\\t")
      .replace(/\\b/g, "\\b")
      .replace(/\\f/g, "\\f");

    //console.log("移除非法字元後:", sourceText);
  }
  sourceText = removePrefixAndSuffixIfPresent(sourceText, "```json", "```");

  //console.log("清理後的文本:", sourceText);
  const parseMethods = [
    { name: "JSON.parse", func: JSON.parse },
    { name: "JSON5.parse", func: JSON5.parse },
    {
      name: "Custom parse",
      func: (text: string) => {
        // 自定義解析邏輯，處理可能的問題
        return Function('"use strict";return (' + text + ")")();
      },
    },
  ];

  for (const method of parseMethods) {
    try {
      //console.log(`嘗試使用 ${method.name} 解析`);
      const result = method.func(sourceText);
      //console.log(`${method.name} 解析成功`);
      return result;
    } catch (error) {
      console.error(`${method.name} 解析錯誤:`, error, sourceText);
    }
  }

  throw new Error("無法解析JSON，所有方法都失敗");
}
