import { useEffect, useState, useRef } from "react";
import StoryMode from "@components/StoryMode";
import Selector from "./Selector";
import { useSearchParams } from "react-router-dom";
import { useUserSettings } from "@core/context/UserSettingsContext";
import ReactGA from "react-ga4";

export default function CreateAvatar() {
  const [, setSearchParams] = useSearchParams();
  const { setSettings } = useUserSettings();
  const [isComplete, setIsComplete] = useState(false);
  useEffect(() => {
    // 創建一個新的 link 元素
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/css/imrs.css";

    // 將 link 元素添加到 head 中
    document.head.appendChild(link);

    // 清理函數：當組件卸載時移除 link 元素
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  const storyLines = [
    {
      content:
        "你好，非常歡迎你的加入！\n我是哈卡賽博士，今天將由我來協助你完成任務！",
      options: [
        {
          content: "我很高興能幫忙！",
        },
      ],
    },
    {
      content:
        "首先讓我們來選擇難度吧！\n難度將會影響任務中的字彙與句型長度，不過別擔心！你可以隨時在遊戲設定中更改難度。",
      options: [
        {
          content: "初階",
          onClick: () => {
            ReactGA.event({
              category: "創角頁",
              action: "選擇難度",
              label: "初階",
            });
            setSettings((prev) => ({
              ...prev,
              difficulty: "beginner",
            }));
          },
        },
        {
          content: "進階",
          onClick: () => {
            ReactGA.event({
              category: "創角頁",
              action: "選擇難度",
              label: "進階",
            });
            setSettings((prev) => ({
              ...prev,
              difficulty: "advanced",
            }));
          },
        },
      ],
    },
    {
      content: "太棒了！只差一個步驟了！\n讓我們選擇一個角色吧！你準備好了嗎？",
      options: [
        {
          content: "我準備好了！",
        },
      ],
    },
  ];
  useEffect(() => {
    console.log("創角頁開啟");
    setSearchParams({ story: "open" }, { replace: true });

    return () => {
      console.log("創角頁關閉");
      setSearchParams({}, { replace: true });
    };
  }, []);
  const onComplete = () => {
    console.log("創角頁完成");
    setSearchParams({}, { replace: true });
    setIsComplete(true);
  };
  return isComplete ? (
    <Selector />
  ) : (
    <StoryMode storyLines={storyLines} onComplete={onComplete} />
  );
}
