import { LearningJourneyLog, GamePerformanceData } from "@core/sdgs_types";
import { getSecondsBetweenISOTimestamps } from "@utils/functions";
import { WritingContributionAssessment } from "@core/hooks/useWritingContributionAssessment";

const calculateMultiPlayerGamePerformance = (
  learningJourneyLog: LearningJourneyLog,
  writingContributionResults: WritingContributionAssessment,
  userName: string
): GamePerformanceData => {
  const { logs, gamestart_timestamp, gameend_timestamp } = learningJourneyLog;

  let answerDurations: number[] = [];
  let playerScore = 0;

  // 計算特定玩家的回答時間
  for (let i = 0; i < logs.length - 1; i++) {
    const currentLog = logs[i];
    const nextLog = logs[i + 1];

    if (
      currentLog.userType === "player" &&
      nextLog.userType === "player" &&
      nextLog.username === userName
    ) {
      const duration = getSecondsBetweenISOTimestamps(
        currentLog.timestamp,
        nextLog.timestamp
      );
      answerDurations.push(duration);
    }
  }

  // 計算特定玩家的總分
  const player = Object.values(writingContributionResults).find(
    (player) => player.playerName === userName
  );

  if (player) {
    const { content, organization, grammarAndSyntax, vocabularyAndSpelling } =
      player;
    playerScore =
      content + organization + grammarAndSyntax + vocabularyAndSpelling;
  }

  const calculateAverage = (arr: number[]): number | null =>
    arr.length > 0 ? arr.reduce((sum, val) => sum + val, 0) / arr.length : null;

  const averageAnswerTime = calculateAverage(answerDurations);
  const totalTime =
    gamestart_timestamp && gameend_timestamp
      ? getSecondsBetweenISOTimestamps(gamestart_timestamp, gameend_timestamp)
      : 0;

  return {
    score: Math.floor(playerScore),
    gameDuration: totalTime,
    averageAnswerTime: averageAnswerTime !== null ? averageAnswerTime : 0,
  };
};

export default calculateMultiPlayerGamePerformance;
