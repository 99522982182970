import React from "react";

interface AvatarMemoProps {
  avatarUrl: string;
  avatarComment: string;

  resultCompletion?: string;
}

const AvatarMemo: React.FC<AvatarMemoProps> = ({
  avatarUrl,
  avatarComment,
  resultCompletion,
}) => {
  return (
    <>
      <div className="avatar">
        <div
          className="img"
          style={{ backgroundImage: `url(${avatarUrl})` }}
        ></div>
      </div>
      <div className="memo">
        <strong>{resultCompletion}</strong>
        <p>{avatarComment}</p>
      </div>
    </>
  );
};

export default AvatarMemo;
