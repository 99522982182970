import React, { useState, useEffect } from "react"

import { MenuOption } from "@core/sdgs_types"
export const extractedMenuOptionFromID = (gameId: string): MenuOption => {
  const extensionMatch = gameId.match(/\.([^.]*)$/) // 查找文件名中的副文件名
  const gameType = extensionMatch ? extensionMatch[1] : undefined // 如果找到副文件名，则使用它the extesion of gameID is the game type
  const gameName = gameId.replace(/\.[^/.]+$/, "") // 移除副文件名
  return { id: gameId, name: gameName, botrunId: gameId, gameType: gameType }
}
// 翻譯組件
const Translator: React.FC = () => {
  const [isActive, setIsActive] = useState<boolean>(false)

  const toggleTranslate = () => {
    setIsActive(!isActive)
  }

  return (
    <div>
      <button data-btnTranslate onClick={toggleTranslate}>
        Toggle Translate
      </button>
      <div data-translate className={isActive ? "--active" : ""}>
        Translation Content
      </div>
    </div>
  )
}

// 輸入模式切換組件
const InputModeSwitcher: React.FC = () => {
  const [voiceMode, setVoiceMode] = useState<boolean>(false)

  const toggleInputMode = () => {
    setVoiceMode(!voiceMode)
  }

  return (
    <div>
      <button data-btnInputchange onClick={toggleInputMode}>
        Toggle Input Mode
      </button>
      <div data-inputArea className={voiceMode ? "--voice-mode" : ""}>
        Input Area
      </div>
    </div>
  )
}

// 音頻控制組件
const AudioController: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState<boolean>(false)

  const playAudio = () => setIsPlaying(true)
  const pauseAudio = () => setIsPlaying(false)

  return (
    <div data-audioCtrl className={isPlaying ? "--active" : ""}>
      <button data-btnPlay onClick={playAudio}>
        Play
      </button>
      <button data-btnPause onClick={pauseAudio}>
        Pause
      </button>
    </div>
  )
}

// 彈出窗組件
const PopupManager: React.FC = () => {
  const [activePopup, setActivePopup] = useState<string | null>(null)

  useEffect(() => {
    const btnsPopup = document.querySelectorAll("[data-btnPopup]")
    btnsPopup.forEach(btn => {
      btn.addEventListener("click", () => {
        const popupId = (btn as HTMLElement).getAttribute("data-btnPopup") as string
        setActivePopup(popupId)
      })
    })

    return () => {
      // Clean up listeners
      btnsPopup.forEach(btn => {
        btn.removeEventListener("click", () => {})
      })
    }
  }, [])

  return (
    <div>
      {activePopup && (
        <div data-popup={activePopup} className="--active">
          <button data-closePopup onClick={() => setActivePopup(null)}>
            Close
          </button>
          Popup Content for {activePopup}
        </div>
      )}
    </div>
  )
}

export { Translator, InputModeSwitcher, AudioController, PopupManager }
