import React from "react";
import { MenuOption } from "@core/sdgs_types";
interface MenuBTNProps {
  option: MenuOption;
  menuCallBack: (option: MenuOption) => void; // 直接使用函數型別
  onDelete?: (option: MenuOption) => void; // 新增刪除功能的回調
}

export default function MenuBTN({
  option,
  menuCallBack,
  onDelete,
}: MenuBTNProps) {
  const handleClickSelection = (e: React.MouseEvent<HTMLButtonElement>) => {
    menuCallBack(option);
  };

  const handleDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation(); // 防止觸發父元素的點擊事件
    onDelete?.(option);
  };

  return (
    <div className="menu-btn-container">
      <button
        className="btn-stage click-sound"
        key={option.id}
        onClick={(e) => handleClickSelection(e)}
      >
        <div>{option.name}</div>
      </button>
      {onDelete && (
        <button className="delete-btn" onClick={handleDelete} title="刪除">
          x
        </button>
      )}
    </div>
  );
}
