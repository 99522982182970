// src/hooks/useLoadAIGameData.ts
import { useRecoilState } from "recoil";
import { OneDeiveFileDownloadResponse } from "@core/hooks/interfaces";
import { Question } from "@core/sdgs_types";
import { useEffect, useState, useCallback } from "react";
import { aiGameDataState, promptsState } from "@core/recoil/sdgs_atoms";
import {
  CompleteAIGameData,
  AIGameData,
  Keywords,
  WriteGameDataProps,
  LevelData,
} from "@core/sdgs_types";
import { useDownloadSingleFile } from "../useApi";
import {
  TagBasedContentExtractor,
  parseTextFromLabels,
  parseKeywords,
  ExtractAllTagsContent,
} from "./gameDataPhaser";
import {
  IntroductionPropsLabels,
  gameDataPropsTags,
  AIGameDataLabels,
  LevelDataLabels,
  WriteGameDataLabels,
} from "@core/sdgs_types";
import { levelDataProbs } from "@core/sdgs_types";
import { stringToBoolean } from "@core/utils/functions";
export const useLoadAIGameData = (fileId: string) => {
  const [gameData, setGameData] = useState<CompleteAIGameData | null>(null);
  const { response, error, loading, downloadSingleFile } =
    useDownloadSingleFile();

  useEffect(() => {
    async function fetchData() {
      downloadSingleFile(fileId); // 直接使用 downloadSingleFile 返回的结果
    }
    fetchData(); // 現在無論是否存在資料都會檢查
  }, [fileId]); // 確保 fileId 和 gameData 的变化都能觸發 effect

  useEffect(() => {
    if (response && response.content) {
      try {
        const newGameData = pharseAIGameData(response);
        setGameData(newGameData || null);
      } catch (error) {
        console.error("Failed to process game data:", error);
      }
    }
  }, [response]);

  return { data: gameData, error, loading };
};
const pharseAIGameData = (response: OneDeiveFileDownloadResponse) => {
  if (response && response.content) {
    //console.log(`Data下载完成 ${fileId}`, response);
    try {
      const parsedData = TagBasedContentExtractor({
        tags: gameDataPropsTags,
        text: response.content,
      });
      //console.log(`解析数据 ${fileId}`, parsedData);
      let keywords: Keywords = [];
      if (parsedData.Keywords) {
        keywords = parseKeywords(parsedData.Keywords.content);
      }
      //     console.log(`解析数据 ${fileId}`, parsedData)
      const pharsedIntroduction = parseTextFromLabels({
        text: parsedData.IntroductionProps.content,
        labels: IntroductionPropsLabels,
      });
      const introduction = {
        title: pharsedIntroduction.title,
        subtitle: pharsedIntroduction.subtitle,
        description: pharsedIntroduction.description,
        backgroundImageUrl: pharsedIntroduction.backgroundImageUrl,
        backgroundAudioUrl: pharsedIntroduction.backgroundAudioUrl,
      };
      //console.log("writeGameData", parsedData.WriteGameData?.content);
      const pharsedWriteGameData = parseTextFromLabels({
        text: parsedData.WriteGameData?.content || "",
        labels: WriteGameDataLabels,
      });
      //console.log("pharsedWriteGameData", pharsedWriteGameData);
      const writeGameData: WriteGameDataProps | undefined = pharsedWriteGameData
        ? {
            writing_subject: pharsedWriteGameData.subject,
            writing_description: pharsedWriteGameData.description,
            writing_rules: pharsedWriteGameData.rules,
            writing_minWords: Number(pharsedWriteGameData.minWords),
            writing_maxWords: Number(pharsedWriteGameData.maxWords),
          }
        : undefined;
      const pharsedLevelData = parseTextFromLabels({
        text: parsedData.LevelData?.content || "",
        labels: LevelDataLabels,
      });

      var gameData = new AIGameData(
        parseTextFromLabels({
          text: parsedData.AIGameData.content,
          labels: AIGameDataLabels,
        })
      );
      if (parsedData.imagePrompt) {
        gameData.customPrompts.imagePrompt = parsedData.imagePrompt.content;
      }
      if (parsedData.questionAdvicePrompt) {
        gameData.customPrompts.questionAdvicePrompt =
          parsedData.questionAdvicePrompt.content;
      }
      if (parsedData.answerAdvicePrompt) {
        gameData.customPrompts.answerAdvicePrompt =
          parsedData.answerAdvicePrompt.content;
      }

      if (parsedData.questionGenerationPrompt) {
        gameData.customPrompts.questionGenerationPrompt =
          parsedData.questionGenerationPrompt.content;
      }
      if (parsedData.scoreEvaluatePrompt) {
        gameData.customPrompts.scoreEvaluatePrompt =
          parsedData.scoreEvaluatePrompt.content;
      }
      if (parsedData.turnWritePrompt) {
        gameData.customPrompts.turnWritePrompt =
          parsedData.turnWritePrompt.content;
      }
      let questions: Question[] = [];
      if (parsedData.QuestionData) {
        console.log("QuestionData", parsedData.QuestionData.content);
        questions = JSON.parse(parsedData.QuestionData.content);
      }
      const levelData: LevelData = {
        gameType: pharsedLevelData.gameType,
        difficulty: pharsedLevelData.difficulty,
        story: pharsedLevelData.story,
        numberOfQuestions: pharsedLevelData.numberOfQuestions
          ? Number(pharsedLevelData.numberOfQuestions)
          : 5,
        visualDescription: pharsedLevelData.visualDescription,
        Creator: pharsedLevelData.Creator,
        IsEditableForAll:
          pharsedLevelData.IsEditableForAll !== undefined
            ? stringToBoolean(pharsedLevelData.IsEditableForAll)
            : false,
        multiPlayer:
          pharsedLevelData.multiPlayer !== undefined
            ? stringToBoolean(pharsedLevelData.multiPlayer)
            : false,
      };
      const newGameData: CompleteAIGameData = {
        AIGameData: gameData,
        IntroductionProps: introduction,
        Keywords: keywords,
        WriteGameData: writeGameData,
        game_type: pharsedLevelData?.gameType || "talk_game",
        questions: questions,
        levelData: levelData,
        learningMaterial: parsedData.LearningMaterial?.content,
        rawQuestionText: parsedData.QuestionData?.content,
        multiplayer: pharsedLevelData.multiPlayer
          ? stringToBoolean(pharsedLevelData.multiPlayer)
          : false,
      };

      return newGameData;
    } catch (error) {
      console.error("Failed to process game data:", error);
    }
  }
};

export const useLoadTalkGameData = () => {
  const { response, error, loading, downloadSingleFile } =
    useDownloadSingleFile();
  const [gameData, setGameData] = useState<levelDataProbs | null>(null);
  const loadData = useCallback(
    async (fileId: string) => {
      try {
        await downloadSingleFile(fileId);
      } catch (error) {
        console.error("Failed to download file:", error);
      }
    },
    [downloadSingleFile]
  );

  useEffect(() => {
    if (response && response.content) {
      try {
        const gameData = pharseAIGameData(response);
        const newGameData: levelDataProbs = {
          avatarId: gameData?.AIGameData.avatarId || "",
          multiPlayer: gameData?.levelData?.multiPlayer || false,
          title: gameData?.IntroductionProps.title || "",
          subtitle: gameData?.IntroductionProps.subtitle || "",
          description: gameData?.IntroductionProps.description || "",
          backgroundImageUrl:
            gameData?.IntroductionProps.backgroundImageUrl || "",
          gameType: gameData?.levelData?.gameType || "",
          difficulty: gameData?.levelData?.difficulty || "",
          story: gameData?.levelData?.story || "",
          numberOfQuestions:
            gameData?.levelData?.numberOfQuestions.toString() || "5",
          visualDescription: gameData?.levelData?.visualDescription || "",
          learningMaterial: gameData?.learningMaterial || "",
          questions: gameData?.rawQuestionText || "",
          Creator: gameData?.levelData?.Creator,
          IsEditableForAll: gameData?.levelData?.IsEditableForAll,
          avatarUrl: gameData?.AIGameData.avatarUrl || "",
          avatarName: gameData?.AIGameData.avatarName || "",
          avatarVoice: gameData?.AIGameData.avatarVoice || "",
          wellcomeMessage: gameData?.AIGameData.wellcomeMessage || "",
          imagePrompt: gameData?.AIGameData.customPrompts.imagePrompt,
          questionAdvicePrompt:
            gameData?.AIGameData.customPrompts.questionAdvicePrompt,
          answerAdvicePrompt:
            gameData?.AIGameData.customPrompts.answerAdvicePrompt,
          questionGenerationPrompt:
            gameData?.AIGameData.customPrompts.questionGenerationPrompt,
          scoreEvaluatePrompt:
            gameData?.AIGameData.customPrompts.scoreEvaluatePrompt,
          turnWritePrompt: gameData?.AIGameData.customPrompts.turnWritePrompt,
          writing_subject: gameData?.WriteGameData?.writing_subject || "",
          writing_description:
            gameData?.WriteGameData?.writing_description || "",
          writing_rules: gameData?.WriteGameData?.writing_rules || "",
          writing_minWords: gameData?.WriteGameData?.writing_minWords || 120,
          writing_maxWords: gameData?.WriteGameData?.writing_maxWords || 150,
        };

        setGameData(newGameData);
      } catch (error) {
        console.error("Failed to process game data:", error);
      }
    }
  }, [response]);
  return { gameData, error, loading, loadData };
};
/// 專門用來下載提示資料promprs
export const useLoadPromptsData = (fileId: string) => {
  const [prompts, setPrompts] = useRecoilState(promptsState);
  const { response, error, loading, downloadSingleFile } =
    useDownloadSingleFile();

  useEffect(() => {
    async function fetchData() {
      downloadSingleFile(fileId); // 直接使用 downloadSingleFile 返回的结果
    }
    console.log("useLoadPromptsData：", fileId);
    fetchData();
  }, [fileId]);

  useEffect(() => {
    if (!response) {
      console.error(`useLoadPromptsData： 下載失敗 ${fileId}`);
      return;
    }

    if (
      typeof response.content !== "string" ||
      response.content.trim() === ""
    ) {
      console.error(`useLoadPromptsData： 回應內容無效 ${fileId}`);
      return;
    }

    try {
      const parsedData = ExtractAllTagsContent(response.content);

      // 將 ParsedResult 轉換為 Record<string, string>
      const promptsData: Record<string, string> = Object.entries(
        parsedData
      ).reduce((acc, [key, value]) => {
        acc[key] = value.content;
        return acc;
      }, {} as Record<string, string>);
      setPrompts(promptsData);
    } catch (error) {
      console.error(
        " 解析 prompt.conf 檔案有錯誤，fail useLoadPromptsData：",
        error
      );
    }
  }, [response]);

  return { prompts, error, loading };
};
/// 專門用來下載單一檔案，區分html tags提示資料promprs
export const useLoadTagsData = (fileId: string) => {
  const { response, error, loading, downloadSingleFile } =
    useDownloadSingleFile();
  const [tags, setTags] = useState<Record<string, string>>({});
  useEffect(() => {
    async function fetchData() {
      downloadSingleFile(fileId); // 直接使用 downloadSingleFile 返回的结果
    }
    console.log("useLoadPromptsData：", fileId);
    fetchData();
  }, [fileId]);

  useEffect(() => {
    if (!response) {
      console.error(`useLoadPromptsData： 下載失敗 ${fileId}`);
      return;
    }

    if (
      typeof response.content !== "string" ||
      response.content.trim() === ""
    ) {
      console.error(`useLoadPromptsData： 回應內容無效 ${fileId}`);
      return;
    }

    try {
      const parsedData = ExtractAllTagsContent(response.content);

      const promptsData: Record<string, string> = Object.entries(
        parsedData
      ).reduce((acc, [key, value]) => {
        acc[key] = value.content;
        return acc;
      }, {} as Record<string, string>);
      setTags(promptsData);
    } catch (error) {
      console.error(" 解析  檔案有錯誤，fail useLoadPromptsData：", error);
    }
  }, [response]);

  return { tags, error, loading };
};
