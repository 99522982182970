import { useEffect, useState } from "react";
import { request } from "@utils/axios-util";

interface AIChatResponse {
  message: string;
}

export const useAiChat = () => {
  const [aiResponse, setAiResponse] = useState<string>("");
  const [lastUserMessage, setLastMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const aiChat = async (
    userMessage: string,
    systemInstruction: string,
    forceGenerate: boolean = false
  ) => {
    try {
      if (
        userMessage + systemInstruction === lastUserMessage &&
        !forceGenerate
      ) {
        console.log(
          "發送相同的訊息：同樣的提示詞沒有改變，再次重新生成",
          userMessage
        );
      }
      setAiResponse("建議生成中...");
      setLastMessage(userMessage + systemInstruction);
      setLoading(true);
      setError(null);

      const message_json = JSON.stringify([
        { role: "system", content: systemInstruction },
        { role: "user", content: userMessage },
      ]);

      const response = await request<AIChatResponse>({
        method: "POST",
        url: "/ai_chat",
        data: {
          message_json,
        },
        timeout: 80000,
      });

      setAiResponse(response.data.message);
    } catch (err) {
      console.error("AI 聊天錯誤:", err, userMessage);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const aiChatAsync = async (
    userMessage: string,
    systemInstruction: string
  ) => {
    if (userMessage + systemInstruction === lastUserMessage) {
      console.log("跳過發送相同的訊息:", userMessage);
      return;
    }

    setAiResponse("建議生成中...");
    setLastMessage(userMessage + systemInstruction);

    const message_json = JSON.stringify([
      { role: "system", content: systemInstruction },
      { role: "user", content: userMessage },
    ]);

    try {
      const result = await request<AIChatResponse>({
        method: "POST",
        url: "/ai_chat",
        data: {
          message_json,
        },
      });

      return result.data;
    } catch (err) {
      console.error("發送用戶訊息時出錯:", err);
      throw err;
    }
  };

  return { aiResponse, error, loading, aiChat, aiChatAsync };
};
