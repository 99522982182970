import useLogger from "@hooks/useLogger";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { processAudioData } from "./elsaApiUtils";
import { ElsaAPIResponse, ElsaPayload } from "./types";
import ElsaSentenceDisplay from "./ElsaSentenceDisplay";
import ReactGA from "react-ga4";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";

import useCountdown from "@hooks/useCountdown";
interface ElsaGameProps {
  logo?: string;
  sentence: string;
  onSuccess?: (result: ElsaAPIResponse) => void;
  onError?: (error: string) => void;
  voiceLimit?: number;
  onEnd?: (score: number, praticeCount: number) => void;
}

const ElsaGame: React.FC<ElsaGameProps> = ({
  logo = "/images/elsa/logo-elsa.png",
  sentence,
  onSuccess,
  onError,
  voiceLimit = 30,
  onEnd,
}) => {
  const user = useRecoilValue(userAuthState);
  const { mutate } = useLogger();
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [isApiRequesting, setIsApiRequesting] = useState<boolean>(false);
  const [apiResponse, setApiResponse] = useState<ElsaAPIResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioChunksRef = useRef<Blob[]>([]);
  const [praticeCount, setPraticeCount] = useState(0);
  const [score, setScore] = useState<number | null>(null);
  const handleRecordingEnd = useCallback(() => {
    console.log("Stop recording");
    mediaRecorderRef.current?.stop();
  }, []);
  const { countdown, startCountdown, stopCountdown } = useCountdown({
    initialTime: voiceLimit,
    onComplete: handleRecordingEnd,
  });

  const stopRecording = useCallback(() => {
    handleRecordingEnd();
    stopCountdown();
  }, [stopCountdown]);
  const feedback = useCallback((result: ElsaAPIResponse) => {
    if (result.success) {
      const score = result.utterance[0].nativeness_score;
      if (score >= 90) {
        return "Great job!";
      } else if (score >= 80) {
        return "Good job!";
      } else if (score >= 60) {
        return "Not bad!";
      } else {
        return "Keep going!";
      }
    } else {
      return result.error_message;
    }
  }, []);
  const initMediaRecorder = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.addEventListener("dataavailable", (e) => {
        audioChunksRef.current.push(e.data);
      });

      mediaRecorderRef.current.addEventListener("stop", async () => {
        try {
          setIsApiRequesting(true);
          const audioBlob = new Blob(audioChunksRef.current);
          const defaultPayload: ElsaPayload = {
            sentence: sentence,
          };
          const result = await processAudioData(
            audioBlob,
            user?.token || "",
            defaultPayload
          );

          mutate({
            type: "elsa口說",
            sentence: sentence,
            score: result.utterance[0].nativeness_score,
          });
          setPraticeCount((prev) => prev + 1);
          setApiResponse(result);
          setScore(result.utterance[0].nativeness_score);
          if (onSuccess) {
            onSuccess(result);
          }
          if (!result.success) {
            console.log("elsa error", result.error_message);
            handleError(result.error_message || "");
          }
          setIsRecording(false);
          setIsApiRequesting(false);
        } catch (e) {
          setIsRecording(false);
          setIsApiRequesting(false);
          const errorMessage = e instanceof Error ? e.message : String(e);
          handleError(errorMessage);
          console.error(errorMessage);
          if (onError) onError(errorMessage);
        }
      });
    } catch (e) {
      const errorMessage = e instanceof Error ? e.message : String(e);
      setError(errorMessage);
      console.error(errorMessage);
      if (onError) onError(errorMessage);
    }
  };
  const handleError = (errorMessage: string) => {
    mutate({
      type: "elsa口說出錯",
      sentence: sentence,
      error: errorMessage,
    });
    setError(errorMessage);
    console.error(errorMessage);
    if (onError) onError(errorMessage);
  };
  useEffect(() => {
    console.log("ElsaGame mounted, 打開口說介面");
    let stream: MediaStream;

    initMediaRecorder();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const handleVoiceInput = async () => {
    if (isApiRequesting) return;
    if (isRecording) {
      stopRecording();
    } else {
      console.log("Start recording");
      setIsRecording(true);
      audioChunksRef.current = [];
      setScore(null);
      setError(null);
      setApiResponse(null);
      if (!mediaRecorderRef.current) {
        await initMediaRecorder();
      }
      mediaRecorderRef.current?.start();
      startCountdown();
    }
  };

  const handleNextQuestion = () => {
    // Implement next question functionality
    mutate({
      type: "elsa口說練習次數",
      sentence: sentence,
      score: apiResponse?.utterance[0].nativeness_score,
      praticeCount: praticeCount,
    });

    onEnd && onEnd(score || 0, praticeCount);
  };

  return (
    <div className="elsa-container">
      <div className="elsa-game">
        <div className="banner">
          <div
            className="img"
            style={{ backgroundImage: `url('${logo}')` }}
          ></div>
        </div>
        <div className="stage">
          <div className="pronunciation --for-sentence">
            <ElsaSentenceDisplay
              response={apiResponse}
              originalText={sentence}
            />
          </div>
          <div className="elsafeedback">
            {isApiRequesting && <span>分析中...</span>}

            {isRecording && !isApiRequesting && (
              <span>
                錄音中...
                {countdown !== null && countdown <= 10 && (
                  <strong>
                    剩餘 {countdown} 秒，錄音時間要結束了，加快腳步！
                  </strong>
                )}
                {countdown !== null && countdown > 10 && `再按一下結束錄音`}
              </span>
            )}
            {score === null && !isRecording && <span>請開始錄音</span>}
            {score !== null && <strong className="score">{score}分</strong>}

            {apiResponse && <span>{feedback(apiResponse)}</span>}
            {isRecording && <i className="highlight"></i>}
          </div>
        </div>
        <div className="fn-bar">
          <div className="part-left"></div>
          <div className="part-center">
            <div className="elsa-input">
              <div
                className={`input-voice --active ${
                  isRecording ? "--pause" : ""
                }`}
                onClick={handleVoiceInput}
              >
                <i className="icon icon-1"></i>
              </div>
            </div>
          </div>
          <div className="part-right">
            <div className="pagination">
              <span>
                練習次數:
                <strong>{praticeCount}</strong>
              </span>
            </div>
            <button className="btn-text" onClick={handleNextQuestion}>
              <span>下一題</span>
              <i className="icon icon-40"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ElsaGame;
