import { useState, useRef, useEffect } from "react"
import { request } from "@utils/axios-util"
import { useNavigate, useParams } from "react-router-dom"

type JoinProps = {
  setOption: React.Dispatch<React.SetStateAction<"selecting" | "join">>
}

export default function Join({ setOption }: JoinProps) {
  const { gameId } = useParams<{ gameId?: string }>();
  const navigate = useNavigate()
  const [roomId, setRoomId] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const joinRoom = async () => {
    try {
      console.log("gameId:", gameId)
      const response = await request({
        url: `/sdgs/multi/${gameId}/rooms/${roomId}/join`,
        method: "get",
      })
      if (response.data.is_success) {
        navigate(`./${roomId}`)
      } else {
        if (response.data.error_code === "err_room_not_waiting") {
          alert("遊戲已開始，無法加入")
        } if (response.data.error_code === "err_room_not_found") {
          alert("房間不存在")
        } else {
          alert(response.data.error_code)
        }
      }
    } catch (error) {
      console.error("Error joining room:", error)
    }
  }

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <div className="sci-frame teamup">
      <i className="deco-top"></i>
      <div className="teamup-item">
        <div className="teamup-item step-2">
          <div className="sci-form-item">
            <div className="field">組隊碼</div>
            <div className="value">
              <input
                ref={inputRef}
                type="text"
                placeholder="輸入組隊碼"
                value={roomId}
                onChange={e => setRoomId(e.target.value)}
              />
            </div>
          </div>

          <div className="btn-area">
            <button className="btn-system" onClick={() => setOption("selecting")}>
              <span>回上頁</span>
            </button>
            <button className="btn-system" onClick={joinRoom}>
              <span>加入</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
