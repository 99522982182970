import { LearningJourneyLog, GamePerformanceData } from "@core/sdgs_types";
import { getSecondsBetweenISOTimestamps } from "@utils/functions";
import { WriteScoreDimension } from "sdgs_types";
const calculateGamePerformance = (
  learningJourneyLog: LearningJourneyLog,
  writeScoreDimension: WriteScoreDimension
): GamePerformanceData => {
  const { logs, gameDuration, gamestart_timestamp, gameend_timestamp } =
    learningJourneyLog;

  let answerDurations: number[] = [];
  let totalScore = 0;

  // 計算寫作時間
  for (let i = 0; i < logs.length - 1; i++) {
    const currentLog = logs[i];
    const nextLog = logs[i + 1];

    if (currentLog.userType === "ai" && nextLog.userType === "player") {
      const duration = getSecondsBetweenISOTimestamps(
        currentLog.timestamp,
        nextLog.timestamp
      );
      answerDurations.push(duration);
    }
  }

  // 計算寫作總分
  if (writeScoreDimension) {
    totalScore =
      writeScoreDimension.content +
      writeScoreDimension.organization +
      writeScoreDimension.grammarAndSyntax +
      writeScoreDimension.vocabularyAndSpelling;
  }

  const calculateAverage = (arr: number[]): number | null =>
    arr.length > 0 ? arr.reduce((sum, val) => sum + val, 0) / arr.length : null;

  const averageAnswerTime = calculateAverage(answerDurations);
  const totalTime =
    gameDuration ||
    (gameend_timestamp && gamestart_timestamp
      ? getSecondsBetweenISOTimestamps(gamestart_timestamp, gameend_timestamp)
      : 0);

  return {
    score: Math.floor(totalScore),
    gameDuration: totalTime,
    averageAnswerTime: averageAnswerTime !== null ? averageAnswerTime : 0,
  };
};

export default calculateGamePerformance;
