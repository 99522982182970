import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkBreaks from "remark-breaks";
import { getVoice, Keywords } from "@core/sdgs_types";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { useTextToSpeech } from "@core/hooks/useApi";
import SimpleAudioPlayer from "./SimpleAudioPlayer";

interface EnhancedMessageProps {
  message: string;
  keywords?: Keywords;
  voiceName?: string;
  isNewMessageBegin?: boolean;
}

function emphasizeKeywords(message: string, words: Keywords): string {
  const keywords: string[] = words.map(([keyword]) => keyword);
  const escapedKeywords = keywords.map((keyword) =>
    keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
  );
  const regexPattern = new RegExp(`\\b(${escapedKeywords.join("|")})\\b`, "g");

  return message.replace(regexPattern, "***$1***");
}

function createKeywordMap(keywords: Keywords): Record<string, string> {
  return keywords.reduce((acc, [keyword, definition]) => {
    acc[keyword] = definition;
    return acc;
  }, {} as Record<string, string>);
}

const ListenButton = ({
  text,
  voiceName,
}: {
  text: string;
  voiceName: string;
}) => {
  //const [voiceUrl, setVoiceUrl] = useState<null | string>(null);
  const { voiceUrl, error, loading, text2voice } = useTextToSpeech();
  useEffect(() => {
    text2voice(text, getVoice(voiceName));
  }, []);
  return voiceUrl ? <SimpleAudioPlayer audioUrl={voiceUrl} /> : null;
};

/**
 * Component to display a message with marked keywords.
 * @param props - Contains message and keywords array.
 */
const EnhancedMessage: React.FC<EnhancedMessageProps> = ({
  message,
  keywords = [],
  voiceName = "nova",
  isNewMessageBegin = false,
}) => {
  const keywordMap = createKeywordMap(keywords);
  const emphasizeMessage = message ? emphasizeKeywords(message, keywords) : "";

  return (
    <Markdown
      className="markdown-wrapper"
      remarkPlugins={[remarkGfm, remarkBreaks]}
      components={{
        code({ children, className, node }) {
          if (!children) return <code></code>;
          const text = (children as string).replaceAll("*", "");
          return (
            <div className="speech">
              <strong>{text}</strong>
              {isNewMessageBegin ? (
                <ListenButton text={text} voiceName={voiceName} />
              ) : null}
            </div>
          );
        },
        strong: ({ node, children, ...props }) => {
          if ((children as string) in keywordMap) {
            return (
              <Tippy
                content={keywordMap[children as string]}
                placement="bottom"
                key={`${children}`}
              >
                <strong className="marker">{children}</strong>
              </Tippy>
            );
          }
          return <strong>{children}</strong>;
        },
      }}
    >
      {emphasizeMessage}
    </Markdown>
  );
};

export default EnhancedMessage;
