import React, { useContext, useState, useEffect } from "react"
import { useAudio } from "@core/context/AudioContext" // 確保導入路徑正確

import { useSound } from "use-sound"
interface AudioPlayerProps {
  audioUrl: string
  isAutoPlay?: boolean
}

const SimpleAudioPlayer: React.FC<AudioPlayerProps> = ({ audioUrl, isAutoPlay = false }) => {
  const [isPlaying, setIsPlaying] = useState(isAutoPlay)
  const [play, { stop }] = useSound(audioUrl, {
    autoplay: isAutoPlay,
    interrupt: true,
    onend: () => {
      setIsPlaying(false)
    },
  })

  const playAudio = () => {
    if (isPlaying) {
      pauseAudio()
      return
    }
    console.log("Audio played")
    play()
    setIsPlaying(true)
  }
  const pauseAudio = () => {
    console.log("Audio stopped")
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    // 清理函數，在元件消滅時停止音頻播放
    return () => {
      stop()
    }
  }, [stop]) // 空依賴陣列確保只在元件卸載時執行一次

  return (
    <div className="btn btn-multi-play --white --small">
      <div className={`audio-ctrl  ${isPlaying ? "--active" : ""}`}>
        <div className={`play`} data-btnplay onClick={playAudio}>
          <i className="icon icon-3"></i>
        </div>
        <div className={`pause`} data-btnpause onClick={pauseAudio}>
          <i className="icon icon-5"></i>
        </div>
      </div>
    </div>
  )
}

export default SimpleAudioPlayer
