import React, { useState } from "react";

interface Tab {
  label: string;
  content: React.ReactNode;
}

interface TabsProps {
  tabs: Tab[];
}
const tabButtonStyle: React.CSSProperties = {
  padding: "12px 24px",
  fontSize: "16px",
  fontWeight: 500,
  color: "#333",
  backgroundColor: "#f5f5f5",
  border: "none",
  borderBottom: "2px solid transparent",
  cursor: "pointer",
  transition: "all 0.3s ease",
  marginRight: "4px",
  minWidth: "auto",
  minHeight: "auto",
  lineHeight: "normal",
};

const Tabs = ({ tabs }: TabsProps) => {
  const [activeTab, setActiveTab] = useState(0);
  const [hoveredTab, setHoveredTab] = useState<number | null>(null);

  return (
    <div className="create-game-tabs-container">
      <div className="create-game-tabs-header">
        {tabs.map((tab, index) => (
          <button
            key={index}
            style={{
              ...tabButtonStyle,
              ...(activeTab === index
                ? {
                    backgroundColor: "#fff",
                    borderBottom: "2px solid #0a9e3c",
                    color: "#0a9e3c",
                  }
                : {}),
              ...(hoveredTab === index && activeTab !== index
                ? {
                    backgroundColor: "#e0f2e9",
                  }
                : {}),
            }}
            onClick={() => setActiveTab(index)}
            onMouseEnter={() => setHoveredTab(index)}
            onMouseLeave={() => setHoveredTab(null)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="create-game-tab-content">{tabs[activeTab].content}</div>
    </div>
  );
};
export default Tabs;
