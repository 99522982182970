import { useParams } from "react-router-dom";
import {
  ProfilerOnRenderCallback,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Overlay from "@components/Overlay";
import useLogger from "@hooks/useLogger";
import { v4 as uuidv4 } from "uuid";
import { useLoadAIGameData } from "@core/hooks/LoadData/useGameDataLoader";
import { useUserSettings } from "@core/context/UserSettingsContext";
import { useRecoilState } from "recoil";
import { aiGameDataState } from "@core/recoil/sdgs_atoms";
import WriteGame from "@core/components/ImmersiveMenu/Game/WriteGame/WriteGame";
import MultiWriteGame from "@core/components/ImmersiveMenu/Game/WriteGame/MultiWriteGame";

import LogPopup from "@components/ImmersiveMenu/Game/LogPopup";
import { useLoadPromptsData } from "@core/hooks/LoadData/useGameDataLoader";
import TalkGame from "@core/components/ImmersiveMenu/Game/TalkGame/TalkGame";
import MultiTalkGame from "@core/components/ImmersiveMenu/Game/MultiTalkGame/MultiTalkGame";
import {
  useUserLevelMemory,
  UserLevelMemoryLog,
} from "@hooks/useUserLevelMemory";
import { LearningJourneyLog } from "@core/sdgs_types";
import AdaptiveTalkGame from "./AdaptiveTalkGame/AdaptiveTalkGame";
export default function Game() {
  const { profile } = useUserSettings();
  const { gameId, gameType, roomId } = useParams();
  const { mutate } = useLogger();
  const location = useLocation();
  const sessionRef = useRef("");
  const promptId = process.env.REACT_APP_PROMPTS_ID || "";
  const { prompts: configPrompts } = useLoadPromptsData(promptId);
  const [, setGameData] = useRecoilState(aiGameDataState);
  const { data, loading, error } = useLoadAIGameData(gameId ? gameId : "");
  const {
    fetchLevelMemory,
    levelMemory,
    recordLevelMemory,
    getLearnedAndMistakes,
  } = useUserLevelMemory(gameId ? gameId : "");
  const dataRef = useRef(data);
  const gameStarted = useRef(false);
  const isUnmounting = useRef(false);
  const navigate = useNavigate();
  const refLearningJourneyLog = useRef<LearningJourneyLog>({
    logs: [],
    isFinished: false,
  });

  const onScored = (score: any) => {
    console.log("送出獲得分數log", score);
    mutate({
      type: "獲得分數",
      game_id: gameId,
      game_name: data?.IntroductionProps.title,
      game_type: data?.game_type,
      is_multi: roomId && data?.multiplayer ? "multi" : "single",
      session_id: sessionRef.current,
      score: score,
      location: location.pathname,
    });
  };

  const onFinished = () => {
    mutate({
      type: "完成遊戲",
      game_id: gameId,
      game_name: data?.IntroductionProps.title,
      game_type: data?.game_type,
      is_multi: roomId && data?.multiplayer ? "multi" : "single",
      session_id: sessionRef.current,
      location: location.pathname,
    });

    recordLevelMemory(
      gameId || "",
      data?.game_type || "",
      refLearningJourneyLog.current
    );
  };
  const onLogLearningJourney = (learning_journey_log: any) => {
    const logData = {
      type: "遊戲歷程紀錄",
      game_id: gameId,
      game_data: data,
      nickname: profile?.nickname,
      avatar: profile?.avatar?.image,
      is_multi: roomId ? "multi" : "single",
      session_id: sessionRef.current,
      learning_journey_log: learning_journey_log,
      log_sn: sessionRef.current,
      location: location.pathname,
    };
    refLearningJourneyLog.current = learning_journey_log;
    console.log("寫入後台 遊戲歷程紀錄", logData);
    mutate(logData);
  };
  useEffect(() => {
    if (!levelMemory) return;
    const { statistics } = levelMemory;
    console.log("讀取關卡紀錄", statistics);
    console.log("讀取關卡記憶正確點", getLearnedAndMistakes());
  }, [levelMemory]);
  useEffect(() => {
    sessionRef.current = uuidv4();
    return () => {
      isUnmounting.current = true;
    };
  }, []);
  useEffect(() => {
    fetchLevelMemory();
  }, [fetchLevelMemory]);
  useEffect(() => {
    if (!data || gameStarted.current || data === dataRef.current) return;

    gameStarted.current = true;
    dataRef.current = data;

    setGameData(data);

    mutate({
      type: "開始遊戲",
      game_id: gameId,
      game_name: data?.IntroductionProps.title,
      game_type: data?.game_type,
      game_data: data,
      nickname: profile?.nickname,
      avatar: profile?.avatar?.image,
      is_multi: roomId && data.multiplayer ? "multi" : "single",
      session_id: sessionRef.current,
      location: location.pathname,
    });
    return () => {
      if (isUnmounting.current) {
        console.log("離開遊戲", data);
        mutate({
          type: "離開遊戲",
          game_id: gameId,
          game_name: data?.IntroductionProps.title,
          game_type: data?.game_type,
          is_multi: roomId && data.multiplayer ? "multi" : "single",
          session_id: sessionRef.current,
          location: location.pathname,
        });
      }
    };
  }, [data]);

  // 追蹤 useUserLevelMemory 的調用
  useEffect(() => {
    console.log("levelMemory changed:", {
      previous: JSON.stringify(levelMemory),
      new: JSON.stringify(levelMemory),
    });
  }, [levelMemory]);

  if (!gameId) {
    navigate("/");
    return <Overlay show={true} />;
  }
  if (loading || !data || !gameStarted.current) {
    return <Overlay show={true} />;
  }
  const leaningJourneyLogUrl = `${process.env.REACT_APP_LEARNING_JOURNEY_PATH}/${sessionRef.current}`;

  switch (gameType) {
    case "card_game":
      return (
        <>
          <LogPopup sessionId={sessionRef.current} />
          <TalkGame
            CompleteAIGameData={data}
            onEnd={onFinished}
            onScored={onScored}
            leaningJourneyLogUrl={leaningJourneyLogUrl}
            onLogLearningJourney={onLogLearningJourney}
          />
        </>
      );

    case "talk_game":
      return roomId ? (
        <>
          <MultiTalkGame
            CompleteAIGameData={data}
            onEnd={onFinished}
            onScored={onScored}
            leaningJourneyLogUrl={leaningJourneyLogUrl}
            onLogLearningJourney={onLogLearningJourney}
          />
          <LogPopup sessionId={sessionRef.current} />
        </>
      ) : (
        <>
          <LogPopup sessionId={sessionRef.current} />
          <TalkGame
            CompleteAIGameData={data}
            onEnd={onFinished}
            onScored={onScored}
            leaningJourneyLogUrl={leaningJourneyLogUrl}
            onLogLearningJourney={onLogLearningJourney}
          />
        </>
      );
    case "adaptive_talk_game":
      return (
        <>
          <LogPopup sessionId={sessionRef.current} />
          <AdaptiveTalkGame
            CompleteAIGameData={data}
            onEnd={onFinished}
            onScored={onScored}
            leaningJourneyLogUrl={leaningJourneyLogUrl}
            onLogLearningJourney={onLogLearningJourney}
            levelMemory={getLearnedAndMistakes()}
          />
        </>
      );
    case "write_game":
      return roomId ? (
        <MultiWriteGame
          title={data.WriteGameData?.writing_subject || ""}
          descriptions={data.WriteGameData?.writing_description || ""}
          rules={data.WriteGameData?.writing_rules || ""}
          background={data.IntroductionProps.backgroundImageUrl}
          minWords={20}
          maxWords={150}
          onEnd={onFinished}
          leaningJourneyLogUrl={leaningJourneyLogUrl}
          onLogLearningJourney={onLogLearningJourney}
        />
      ) : (
        <>
          <LogPopup sessionId={sessionRef.current} />
          <WriteGame
            title={data?.WriteGameData?.writing_subject || ""}
            descriptions={data?.WriteGameData?.writing_description || ""}
            rules={data?.WriteGameData?.writing_rules || ""}
            background={data?.IntroductionProps.backgroundImageUrl || ""}
            minWords={120}
            maxWords={150}
            onEnd={onFinished}
            onScored={onScored}
            onLogLearningJourney={onLogLearningJourney}
            leaningJourneyLogUrl={leaningJourneyLogUrl}
          />
        </>
      );
    default:
      return <></>;
  }
}
