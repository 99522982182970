import React, { createContext, useContext, ReactNode } from "react"
import { useUserSettings } from "@core/context/UserSettingsContext"

type SoundType = keyof typeof soundMap

interface SoundContextType {
  playSound: (type: SoundType, force?: boolean) => void
}

const SoundContext = createContext<SoundContextType | undefined>(undefined)

const soundMap = {
  click: "https://www.cameo.tw/ntso/SDGs_music/click.aac",
  wrong: "https://www.cameo.tw/ntso/SDGs_music/click_wronganswer.aac",
  right: "https://www.cameo.tw/ntso/SDGs_music/click_rightanswer.aac",
  success: "https://www.cameo.tw/ntso/SDGs_music/done_surprice.aac",
  start: "https://www.cameo.tw/ntso/SDGs_music/click_01.aac",
  level_start: "https://www.cameo.tw/ntso/SDGs_music/level_start01.aac",
  upgrade: "https://www.cameo.tw/ntso/SDGs_music/upgrade_badge.aac",
  menu_toggle: "https://www.cameo.tw/ntso/SDGs_music/menu_toggle.aac",
  menu_click: "https://www.cameo.tw/ntso/SDGs_music/menu_click.aac",
  nav_backward: "https://www.cameo.tw/ntso/SDGs_music/nav_backward.aac",
  nav_forward: "https://www.cameo.tw/ntso/SDGs_music/nav_forward.aac",
  nav_left_right: "https://www.cameo.tw/ntso/SDGs_music/nav_left_right.aac",
  locked: "https://www.cameo.tw/ntso/SDGs_music/locked.aac",
}

const SoundProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const { settings } = useUserSettings()

  const playSound = (type: SoundType, force = false) => {
    if (settings.soundEffects || force) {
      const soundFile = soundMap[type]
      if (soundFile) {
        const audio = new Audio(soundFile)
        audio.volume = 0.5
        audio.play()
      }
    }
  }

  return <SoundContext.Provider value={{ playSound }}>{children}</SoundContext.Provider>
}

const useSound = (): SoundContextType => {
  const context = useContext(SoundContext)
  if (!context) {
    throw new Error("useSound must be used within a SoundProvider")
  }
  return context
}

export { SoundProvider, useSound }
export type { SoundType }
