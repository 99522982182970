import { QuestionTypeEnum } from "./types";
export const pharseMessageAndOptions = (content: string) => {
  // 將訊息與選項分離ex: 嘎嘎嘎嘎嘎嘎！[什麼鳥吵死了！][什麼聲音？]
  let [message, ...options] = content.split("[");
  //console.log("當前filtre message", message);
  // 如果訊息中包含 =劇情選擇= , 則將這一個功能字串移除
  if (message.includes("=劇情選擇=")) {
    //console.log("劇情選擇有進入到filter", message);
    message = message.replace("=劇情選擇=", "");
  }
  return {
    message,
    options: options.map((option) => option.replace("]", "")),
  };
};
export const StringToQuestionTypeEnum = (type: string) => {
  return type as QuestionTypeEnum;
};
