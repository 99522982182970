import React from "react";
import ShareLink from "@core/components/ImmersiveMenu/Game/EndComponents/ShareLink";
import { WriteScoreDimension } from "sdgs_types";
import AvatarMemo from "../../EndComponents/AvatarMemo";
import TalkGameScoreBoard from "../../TalkGame/GameEnd/TalkGameScoreBoard";
import calculateGamePerformance from "../../WriteGame/GameEnd/calculateGamePerformance";
import { calculateGameRewards } from "../../WriteGame/GameEnd/calculateGameRewards";
import {
  LearningJourneyLog,
  GamePerformanceData,
  GameRewardsResult,
} from "@core/sdgs_types";
import WriteScoreDisplay from "./WriteScoreDisplay";
interface WriteGameEndContainerProps {
  avatarUrl: string;
  description: string;
  writeScoreDimension: WriteScoreDimension;
  isScoreGenerated?: boolean;
  learningJourneyLog: LearningJourneyLog;
  leaningJourneyLogUrl?: string;
}

const WriteGameEndContainer: React.FC<WriteGameEndContainerProps> = ({
  avatarUrl,
  description,
  writeScoreDimension,
  learningJourneyLog,
  leaningJourneyLogUrl,
}) => {
  const [gamePerformanceData, setGamePerformanceData] =
    React.useState<GamePerformanceData>({
      score: 0,
      averageAnswerTime: 0,
      gameDuration: 0,
    });
  const [gameRewardsResult, setGameRewardsResult] =
    React.useState<GameRewardsResult>({
      highlights: [],
      totalExp: 0,
      completion: "",
      dynamicEncouragement: "",
    });

  React.useEffect(() => {
    if (writeScoreDimension && learningJourneyLog) {
      const gamePerformanceData = calculateGamePerformance(
        learningJourneyLog,
        writeScoreDimension
      );
      const gameRewardsResult = calculateGameRewards(
        gamePerformanceData,
        writeScoreDimension
      );
      setGamePerformanceData(gamePerformanceData);
      setGameRewardsResult(gameRewardsResult);
    }
  }, [writeScoreDimension, learningJourneyLog]);
  return (
    <>
      <div className="end-container --lengthy">
        <div className="end-frame">
          <div className="left">
            <AvatarMemo
              avatarUrl={avatarUrl}
              avatarComment={description}
              resultCompletion={gameRewardsResult.completion}
            />
          </div>

          <div className="center">
            {writeScoreDimension && (
              <WriteScoreDisplay writeScoreDimension={writeScoreDimension} />
            )}
          </div>

          <div className="right">
            {
              <TalkGameScoreBoard
                gamePerformanceData={gamePerformanceData}
                gameRewardsResult={gameRewardsResult}
              />
            }
          </div>

          <i className="highlight"></i>
        </div>
      </div>

      <ShareLink leaningJourneyLogUrl={leaningJourneyLogUrl} />
    </>
  );
};

export default WriteGameEndContainer;
