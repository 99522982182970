import { useCallback, useMemo, useState, useEffect, useRef } from "react";

import { useRecoilValue } from "recoil";
import { aiGameDataState, promptsState } from "@core/recoil/sdgs_atoms";
import { useAiChat } from "./useAiChat";
import { jsonPharseTool } from "../utils/jsonPharseTool";
import { AIGameData, customPrompts } from "../sdgs_types";
import { useTranslation } from "react-i18next";
import { replaceKeywords, ReplacementMap } from "../utils/LanguageChangeUtils";

type PrimitiveType =
  | "string"
  | "number"
  | "boolean"
  | "string[]"
  | "number[]"
  | "boolean[]"
  | AutoTypeDescription<any>;

export type AutoTypeDescription<T> = {
  [key in keyof T]: T[key] extends string
    ? "string"
    : T[key] extends number
    ? "number"
    : T[key] extends boolean
    ? "boolean"
    : T[key] extends string[]
    ? "string[]"
    : T[key] extends number[]
    ? "number[]"
    : T[key] extends boolean[]
    ? "boolean[]"
    : AutoTypeDescription<T[key]>;
};

function generateOutputFormat<T extends Record<string, unknown>>(
  type: AutoTypeDescription<T>
): string {
  const entries = Object.entries(type).map(
    ([key, value]) => `    "${key}": ${value}`
  );
  return `{\n${entries.join(",\n")}\n}`;
}

interface UseAiOutputGeneratorProps<T> {
  defaultSystemPrompt: string;
  globalConfigPromptKey?: string;
  levelDataPromptKey?: keyof customPrompts | null;
  responseType: AutoTypeDescription<T>;
  parseResponse?: (response: string) => T;
  gameDataTimeout?: number;
  onFail?: (error: Error) => void;
  maxRetries?: number;
}

export function useAiOutputGenerator<T extends object>({
  defaultSystemPrompt,
  globalConfigPromptKey = "",
  levelDataPromptKey = null,
  responseType,
  gameDataTimeout = 3000,
  onFail = () => {},
  maxRetries = 3,
}: UseAiOutputGeneratorProps<T>) {
  const { aiResponse, aiChat } = useAiChat();
  const gameData = useRecoilValue(aiGameDataState);
  const prompts = useRecoilValue(promptsState);
  const [result, setResult] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [debugInfo, setDebugInfo] = useState<string | null>(null);
  const [isDataReady, setIsDataReady] = useState(false);
  const selectedPromptRef = useRef<string>(defaultSystemPrompt);
  const { t } = useTranslation();
  const replacements = {
    LANGUAGE: t("LANGUAGE"),
  };
  const refRetryCount = useRef<number>(0);
  const refUserMessage = useRef<string>("");
  const refFullPrompt = useRef<string>("");
  useEffect(() => {
    if (
      levelDataPromptKey &&
      gameData?.AIGameData?.customPrompts?.[levelDataPromptKey]
    ) {
      //console.log("selectedPrompt is levelDataPrompt");
      selectedPromptRef.current =
        gameData.AIGameData.customPrompts?.[levelDataPromptKey] || "";
    } else if (globalConfigPromptKey && prompts[globalConfigPromptKey]) {
      //console.log("selectedPrompt is globalConfigPrompt");
      selectedPromptRef.current = prompts[globalConfigPromptKey];
    } else {
      //console.log("selectedPrompt is defaultSystemPrompt");
      selectedPromptRef.current = defaultSystemPrompt;
    }

    if (gameData) {
      setIsDataReady(true);
    }
    if (prompts && prompts[globalConfigPromptKey]) {
      setIsDataReady(true);
    }
  }, [
    prompts,
    gameData,
    levelDataPromptKey,
    globalConfigPromptKey,
    defaultSystemPrompt,
  ]);

  const outputFormat = useMemo(
    () => generateOutputFormat(responseType),
    [responseType]
  );

  const generateOutput = useCallback(
    async (userMessage: string) => {
      setError(null);
      setDebugInfo(null);

      const waitForData = new Promise<void>((resolve) => {
        if (isDataReady) {
          resolve();
        } else {
          const checkInterval = setInterval(() => {
            if (isDataReady) {
              clearInterval(checkInterval);
              resolve();
            }
          }, 100);
        }
      });

      const timeoutPromise = new Promise<void>((_, reject) => {
        setTimeout(() => {
          reject(new Error("Data preparation timed out"));
        }, gameDataTimeout);
      });

      try {
        await Promise.race([waitForData, timeoutPromise]);
      } catch (e) {
        console.warn("Timed out waiting for data, proceeding anyway");
      }

      // console.log("selectedPrompt", selectedPromptRef.current);
      const finalPrompt = replaceKeywords(
        selectedPromptRef.current,
        replacements as ReplacementMap
      );
      const fullPrompt = `${finalPrompt}。\n\n 注意!，請以嚴格按照以下json格式輸出，不需要輸出其他多餘的文字。：\n${outputFormat}`;
      // console.log("fullPrompt", fullPrompt);
      refUserMessage.current = userMessage;
      refFullPrompt.current = fullPrompt;
      aiChat(userMessage, fullPrompt);
    },
    [aiChat, outputFormat, isDataReady, gameDataTimeout]
  );

  useEffect(() => {
    if (!aiResponse) {
      return;
    }
    var layerStep = "";
    if (aiResponse && aiResponse !== "建議生成中...") {
      setDebugInfo(`Raw AI Response: ${JSON.stringify(aiResponse)}`);
      try {
        // 嘗試第一階段解析為JSON
        layerStep = "第一階段ai response 先進行";
        console.log("第一階段ai response 先進行，解析為JSON");
        let parsedResult;
        if (typeof aiResponse === "object") {
          console.log("aiResponse is already an object", aiResponse);
        }

        const tryFirstLayerJsonParse = JSON.parse(aiResponse);
        if (typeof tryFirstLayerJsonParse === "object") {
          console.log(
            "第一階段ai tryFirstLayerJsonParse 轉化為json obj",
            tryFirstLayerJsonParse
          );
          parsedResult = tryFirstLayerJsonParse as T;
          console.log(
            "tryFirstLayerJsonParse轉為特定形態成功 ：",
            parsedResult
          );
          setResult(parsedResult);
          return;
        }
      } catch (jsonError) {
        console.error("Error 第一階段失敗，嘗試第二階段", layerStep, jsonError);
        try {
          // 嘗試第二階段解析為JSON
          layerStep = "第二階段再進行";
          const trySecondLayerJsonParse = jsonPharseTool(aiResponse);

          const parsedResult = trySecondLayerJsonParse as T;
          console.log("再將json轉為特定形態成功 ：", parsedResult);
          setResult(parsedResult);
        } catch (jsonError) {
          console.error(
            "第二階段解析json錯誤發生，不解析了，直接回報：",
            jsonError
          );
          setError("無法解析AI回應");
          if (refRetryCount.current < maxRetries) {
            aiChat(refUserMessage.current, refFullPrompt.current);
            refRetryCount.current = refRetryCount.current + 1;
            console.log("重試aiChat", refRetryCount.current);
          } else {
            onFail(new Error("An error occurred."));
          }
        }
      }
    } else {
      //console.log("等待內容生成中 Result set to null");
      setResult(null);
    }
  }, [aiResponse]);

  return {
    result,
    generateOutput,
    error,
    debugInfo,
  };
}
