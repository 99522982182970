import { request as axiosRequest } from "@utils/axios-util";
import {
  DriveFilesResponse,
  FileItem,
  OneDeiveFileDownloadResponse,
  FileUploadResponse,
} from "./interfaces"; // 確保將接口放在正確的位置
import { useEffect, useState, useMemo, useRef } from "react";
import { extractedMenuOptionFromID } from "@core/utils/gameFunction";
//import { useCache } from "../context/CacheContext";
//import { MenuOption } from "@core/sdgs_types";
import {
  useAiOutputGenerator,
  AutoTypeDescription,
} from "@core/hooks/useAiOutputGenerator";
import { useTranslation } from "react-i18next";

export const useUploadFileToDrive = () => {
  const [response, setResponse] = useState<FileUploadResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const uploadFileToDrive = async (
    content: string,
    fileName: string,
    parentFolderId: string,
    fileType: "sheet" | "document",
    fileId?: string,
    targetFolderPath?: string
  ): Promise<{ id: string; message: string } | undefined> => {
    setLoading(true);
    setError(null);

    const uploadData = {
      content,
      file_name: fileName,
      parent_folder_id: parentFolderId,
      file_type: fileType,
      file_id: fileId,
      target_folder_path: targetFolderPath,
    };

    try {
      const result = await axiosRequest<{ id: string; message: string }>({
        method: "POST",
        url: "/upload_content_to_google_drive",
        data: uploadData,
      });

      setResponse(result.data);
      return result.data;
    } catch (err) {
      console.error("Error uploading file to Drive:", err);
      setError("上傳文件到 Drive 時發生錯誤");
      return undefined;
    } finally {
      setLoading(false);
    }
  };

  return { response, error, loading, uploadFileToDrive };
};

/// 這個鉤子用於從 Google Drive 文件夾中列出所有文件，並選擇特定結尾的文件 ex: _game
export const useListGameMenu = (
  folderIdOrUrl: string,
  endsWith: string = "_game",
  userId: string = ""
) => {
  const maxFiles = 1000;
  const { gameFiles, error, loading } = useListCardGameFiles(
    folderIdOrUrl,
    maxFiles,
    endsWith,
    userId,
    true
  );

  const gameMenu = useMemo(() => {
    if (!loading && !error && gameFiles.length > 0) {
      const extractedMenu = gameFiles.map((file) => ({
        ...extractedMenuOptionFromID(file.name),
        id: file.id,
      }));
      return extractedMenu;
    }
    return [];
  }, [gameFiles]);

  return useMemo(
    () => ({ gameMenu, error, loading }),
    [gameMenu, error, loading]
  );
};

// 這個鉤子用於從 Google Drive 文件夾中列出所有文件，並選擇特定結尾的文件 ex: _game
export const useListCardGameFiles = (
  folderIdOrUrl: string,
  maxFiles: number,
  endsWith?: string,
  userName?: string,
  isUseCache?: boolean // 是否使用快取
) => {
  const { response, error, loading, listDriveFiles } = useListDriveFiles();
  const [gameFiles, setGameFiles] = useState<FileItem[]>([]);
  /*   const { addToCache, getFromCache } = useCache();
  const cacheKey = `listFiles_${folderIdOrUrl}_${endsWith}_${userName}`;
  const cachedList = getFromCache<FileItem[]>(cacheKey);
  useEffect(() => {
    if (cachedList && isUseCache) {
      console.log("使用快取的遊戲文件");
      setGameFiles(cachedList);
    }
  }, [cachedList]); */
  useEffect(() => {
    const fetchData = async () => {
      const filesFolder = userName ? "users/" + userName : "";
      //  console.log("取得檔案清單:", folderIdOrUrl, maxFiles, filesFolder);
      await listDriveFiles(folderIdOrUrl, maxFiles, filesFolder);
    };
    //if (!cachedList || !isUseCache)
    fetchData();
  }, [folderIdOrUrl, maxFiles]);

  useEffect(() => {
    //if (!cachedList && response && response.items) {
    if (response && response.items) {
      const filteredFiles = endsWith
        ? response.items.filter((item) => item.name.endsWith(endsWith))
        : response.items;
      console.log(
        `在${userName}目錄下，共找到 ${
          filteredFiles.length
        } 個遊戲文件（檔名結尾為 ${endsWith || "任何"} 的文件）`
      );
      setGameFiles(filteredFiles);
      // addToCache(cacheKey, filteredFiles);
    }
  }, [response, endsWith]);

  return { gameFiles, error, loading };
};

// 這個鉤子用於從 Google Drive 文件夾中列出所有文件
export const useListDriveFiles = () => {
  const [response, setResponse] = useState<DriveFilesResponse | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const listDriveFiles = async (
    parentFolderIdOrUrl: string,
    maxFiles: number,
    targetFolderPath?: string
  ) => {
    setLoading(true);
    setError(null);

    try {
      const result = await axiosRequest<DriveFilesResponse>({
        method: "GET",
        url: "/drive_list_files_api",
        params: {
          parent_folder_id_or_url: parentFolderIdOrUrl,
          max_files: maxFiles,
          target_folder_path: targetFolderPath ? targetFolderPath : "",
        },
      });

      setResponse(result.data);
    } catch (err) {
      console.error("列出 Drive 檔案時發生錯誤:", err);
      setError("列出 Drive 檔案時發生錯誤");
    } finally {
      setLoading(false);
    }
  };

  return { response, error, loading, listDriveFiles };
};

// 這個鉤子用於從 Google Drive 下載單個文件
export const useDownloadSingleFile = () => {
  // const { containCache, getFromCache, addToCache } = useCache();
  const [response, setResponse] = useState<OneDeiveFileDownloadResponse | null>(
    null
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const downloadSingleFile = async (fileId: string) => {
    setLoading(true);
    setError(null);

    try {
      const result = await axiosRequest<DriveFilesResponse>({
        method: "GET",
        url: "/download_single_file_from_google_drive",
        params: {
          file_id: fileId,
        },
      });
      console.log("downloadSingleFile下載完成，加入到快取:", fileId);

      setResponse(result.data);
    } catch (err) {
      console.error("列出 Drive 檔案時發生錯誤:", err);
      setError("列出 Drive 檔案時發生錯誤");
    } finally {
      setLoading(false);
    }
  };

  return { response, error, loading, downloadSingleFile };
};

interface GenerateImageResponse {
  url: string;
}

export const useAITranslateToCH = () => {
  type TranslationData = {
    translatedText: string;
  };
  const responseType: AutoTypeDescription<TranslationData> = {
    translatedText: "string",
  };
  const { result, generateOutput } = useAiOutputGenerator<TranslationData>({
    defaultSystemPrompt: `將內文中，不是{LANGUAGE}的地方，翻譯成{LANGUAGE}，直接翻譯，不用解釋，請注意訊息中已經是{LANGUAGE}的部分不用翻譯，可以略過不輸出`,
    globalConfigPromptKey: "AITranslateToCH",
    responseType,
  });

  const { t } = useTranslation();
  const [translatedText, setTranslatedText] = useState<string>(
    t("WaitTranslation")
  );
  const [lastMessage, setlastMessage] = useState<string>("");
  const translateCH = (message: string) => {
    if (message === lastMessage) {
      console.log("Skip translating the same message:", message);
      return;
    }
    setTranslatedText(t("WaitTranslation"));
    setlastMessage(message);
    generateOutput(message);
  };
  useEffect(() => {
    if (result) {
      console.log("翻譯完成result.translatedText:", result);
      setTranslatedText(result.translatedText);
    } else {
      setTranslatedText(t("WaitTranslation"));
    }
  }, [result]);

  return { translatedText, translateCH };
};

export const useAITranslateToEN = () => {
  const [translatedText, setTranslatedText] = useState<string>("翻譯中...");
  const [lastMessage, setLastMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const translateEN = async (message: string) => {
    if (message === lastMessage) {
      console.log("跳過翻譯相同的訊息:", message);
      return;
    }

    setTranslatedText("翻譯中...");
    setLastMessage(message);
    setLoading(true);
    setError(null);

    try {
      const response = await axiosRequest<string>({
        method: "GET",
        url: "/ai_translate_en",
        params: { message: message },
      });

      setTranslatedText(response.data);
    } catch (err) {
      console.error("翻譯時出錯:", err);
      setError("翻譯時發生錯誤");
      setTranslatedText("翻譯失敗");
    } finally {
      setLoading(false);
    }
  };

  return { translatedText, error, loading, translateEN };
};

export const useTextToSpeech = () => {
  const [voiceUrl, setVoiceUrl] = useState<string>("");
  const [hint, setHint] = useState<string>("播放");
  const lastMessageRef = useRef<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const requestIdRef = useRef<number>(0);
  const text2voice = async (text: string, voice: string = "nova") => {
    if (text === lastMessageRef.current) {
      console.error("生成相同的語音，跳過:", text);
      return;
    }
    setVoiceUrl("");
    lastMessageRef.current = text + voice;
    setHint("語音準備中...");
    setLoading(true);
    setError(null);
    const currentRequestId = ++requestIdRef.current;

    const getFormData = () => {
      const params = new URLSearchParams();
      const filtered_text = text.replace(/[`【】]/g, "");
      params.append("text", filtered_text);
      params.append("voice", voice);
      params.append("model", "tts-1");
      return params;
    };

    try {
      const response = await axiosRequest<string>({
        // 使用重命名後的 axiosRequest
        method: "POST",
        url: "/text_to_speech",
        data: getFormData(),
      });
      // 檢查這個回應是否對應於最新的請求
      if (currentRequestId === requestIdRef.current) {
        setVoiceUrl(response.data);
        setHint("播放");
      } else {
        console.log("忽略舊的請求結果");
      }
    } catch (err) {
      console.error("生成語音時出錯:", err);
      setError("生成語音時發生錯誤");
    } finally {
      setLoading(false);
    }
  };

  return { voiceUrl, hint, error, loading, text2voice };
};

interface GenerateImageResponse {
  url: string;
}

export const useGenerateImage = () => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [imagePrompt, setImagePrompt] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const generateImage = async (
    dialogue: string,
    pngFileName: string = "user_generate_image",
    imageSize: string = "1024x1024"
  ) => {
    setImagePrompt(dialogue);
    setLoading(true);
    setError(null);

    try {
      const response = await axiosRequest<GenerateImageResponse>({
        method: "POST",
        url: "/direct_generate_image",
        params: {
          dialogue,
          png_file_name: pngFileName,
          image_size: imageSize,
        },
      });

      setImageUrl(response.data.url);
    } catch (err) {
      console.error("生成圖片時發生錯誤:", err);
      setError("生成圖片時發生錯誤");
    } finally {
      setLoading(false);
    }
  };

  return { imageUrl, imagePrompt, error, loading, generateImage };
};
