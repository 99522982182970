import { RefObject, useCallback, useEffect } from "react";
import { useRef } from "react";
import { QuestionTypeEnum, UserResult } from "./types";
import { AiMessage } from "./types";
import {
  LearningJourneyLog,
  GamePerformanceData,
  GameRewardsResult,
} from "@core/sdgs_types";

// 處理學習歷程相關的邏輯
export function useLearningJourney(
  onLogLearningJourney: Function,
  learningJourneyLogRef: RefObject<LearningJourneyLog> | undefined
) {
  const refLearningJourneyLog = useRef<LearningJourneyLog>(
    learningJourneyLogRef?.current || {
      logs: [],
      isFinished: false,
    }
  );
  useEffect(() => {
    if (learningJourneyLogRef?.current) {
      refLearningJourneyLog.current = learningJourneyLogRef.current;
    }
  }, [learningJourneyLogRef]);
  const logAIMessage = (aiMessage: AiMessage, avatarName: string) => {
    if (!refLearningJourneyLog.current) {
      console.error("學習歷程紀錄尚未初始化");
      return;
    }
    const lastLog =
      refLearningJourneyLog.current?.logs?.length > 0
        ? refLearningJourneyLog.current.logs[
            refLearningJourneyLog.current.logs.length - 1
          ]
        : undefined;

    if (
      lastLog &&
      lastLog.userType === "ai" &&
      lastLog?.content &&
      (lastLog.content === aiMessage.content ||
        aiMessage.content.startsWith(lastLog.content))
    ) {
      // 如果內容相同或是新訊息以舊訊息開頭，則覆蓋原本的log
      refLearningJourneyLog.current.logs[
        refLearningJourneyLog.current.logs.length - 1
      ] = {
        content: aiMessage.content,
        userType: "ai",
        username: avatarName,
        options: aiMessage.options,
        questionType: aiMessage.currentQuestionType,
        timestamp: new Date().toISOString(),
      };
    } else {
      // 如果內容不同，則push新的log
      refLearningJourneyLog.current.logs.push({
        content: aiMessage.content,
        userType: "ai",
        username: avatarName,
        options: aiMessage.options,
        questionType: aiMessage.currentQuestionType,
        timestamp: new Date().toISOString(),
      });
    }
    onLogLearningJourney(refLearningJourneyLog.current);
  };

  const logGameEnd = (
    imageUrl: string | null,
    onScored: (score: number) => void,
    gamePerformanceData: GamePerformanceData,
    gameRewardsResult: GameRewardsResult
  ): void => {
    if (!refLearningJourneyLog.current) {
      console.error("學習歷程紀錄尚未初始化");
      return;
    }
    refLearningJourneyLog.current.isFinished = true;
    refLearningJourneyLog.current.generated_image = imageUrl || "";
    refLearningJourneyLog.current.gameend_timestamp = new Date().toISOString();
    refLearningJourneyLog.current.finalScore = gamePerformanceData.score;
    refLearningJourneyLog.current.comment = gameRewardsResult.completion;
    refLearningJourneyLog.current.gamePerformanceData = gamePerformanceData;
    refLearningJourneyLog.current.gameRewardsResult = gameRewardsResult;

    onScored(gamePerformanceData.score);
    onLogLearningJourney(refLearningJourneyLog.current);
  };
  const logUserAnswer = (
    userResult: UserResult,
    username: string,
    questionType: QuestionTypeEnum
  ) => {
    console.log("學習歷程紀錄，玩家回答結果", userResult);
    if (!refLearningJourneyLog.current) {
      console.error("學習歷程紀錄尚未初始化");
      return;
    }
    refLearningJourneyLog.current.logs.push({
      content: userResult.content,
      userType: "player",
      isCorrect: userResult.isCorrect,
      advice: userResult.advice,
      score: userResult.score,
      username: username,
      questionType: questionType,
      timestamp: new Date().toISOString(),
      praticeCount: userResult.praticeCount || 1,
    });
    onLogLearningJourney(refLearningJourneyLog.current);
  };
  const logGameStart = () => {
    if (!refLearningJourneyLog.current) {
      console.error("學習歷程紀錄尚未初始化");
      return;
    }
    const curTime = new Date().toISOString();
    if (refLearningJourneyLog.current) {
      refLearningJourneyLog.current.gamestart_timestamp = curTime;
    }
  };
  return {
    learningJourneyLog: refLearningJourneyLog.current,
    logAIMessage,
    logUserAnswer,
    logGameEnd,
    logGameStart,
  };
}
