import { useEffect, useState } from "react"
import FormItem from "@components/MenuFrame/SettingsFrame/FormItem"
import Badge from "@components/MenuFrame/SettingsFrame/Badge"
import { NavLink, useSearchParams } from "react-router-dom"
import i18n, { languages } from "@utils/i18n"
import { useSound } from "@core/context/SoundContext"
import { useUserSettings } from "@core/context/UserSettingsContext"
import ReactGA from "react-ga4"
import { useRecoilState } from "recoil"
import { authTokenState, userAuthState } from "@core/recoil/atoms"
export default function Settings() {
  const { playSound } = useSound()
  const [searchParams, setSearchParams] = useSearchParams()
  const [content, setContent] = useState<"profile" | "settings">("profile")
  const { profile, settings, setSettings } = useUserSettings()
  const [user, setUser] = useRecoilState(userAuthState)

  const [, setToken] = useRecoilState(authTokenState)
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target
    ReactGA.event({
      category: "設定頁",
      action: name,
      label: value,
    })
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value,
    }))
    playSound("click")
  }

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, checked } = event.target
    ReactGA.event({
      category: "設定頁",
      action: id,
      label: checked ? "開啟" : "關閉",
    })
    setSettings(prevSettings => ({
      ...prevSettings,
      [id]: checked,
    }))
    playSound("click")
  }

  useEffect(() => {
    if (searchParams.get("settings") !== "open")
      setTimeout(() => {
        setContent("profile")
      }, 400)
  }, [searchParams])

  return (
    <nav className="game-nav-area">
      <div className="nav-container">
        <div className="tab-nav">
          <div
            className={`${content === "profile" ? "--active" : ""}`}
            onClick={() => setContent("profile")}
          >
            {i18n.t("profile")}
          </div>
          <div
            className={`${content === "settings" ? "--active" : ""}`}
            onClick={() => setContent("settings")}
          >
            {i18n.t("settings")}
          </div>
        </div>
        <div className="tab-content">
          <div className={`content ${content === "profile" ? "--active" : ""}`}>
            <div className="profile">
              <ul>
                <li className="title">
                  <h2>{profile?.nickname}</h2>
                </li>
                <li>exp {profile?.exp}</li>
                <li></li>
              </ul>
              <div className="avatar">
                <div
                  className="img"
                  style={{ backgroundImage: `url(${profile?.avatar?.image})` }}
                ></div>
              </div>
              <i className="highlight"></i>
            </div>

            <div className="nav-menu">
              <NavLink
                to="/"
                onClick={() => {
                  setUser(null)
                  setToken(null)
                  ReactGA.event({
                    category: "設定頁",
                    action: "登出遊戲",
                  })
                }}
              >
                <span>{i18n.t("logout")}</span>
              </NavLink>
              <NavLink
                to={"/ms/" + user?.username + "/" + profile?.nickname}
                onClick={() => {
                  ReactGA.event({
                    category: "設定頁",
                    action: "點擊個人頁面",
                  })
                }}
              >
                <span>{i18n.t("myStudio")}</span>
              </NavLink>
              {/*  <NavLink
                to="/"
                onClick={() => {
                  ReactGA.event({
                    category: "設定頁",
                    action: "點擊排行榜",
                  });
                }}
              >
                <span>{i18n.t("leaderboard")}</span>
              </NavLink> */}
              <NavLink
                to="/createLevel/"
                onClick={() => {
                  ReactGA.event({
                    category: "設定頁",
                    action: "點擊進入關卡編輯器",
                  })
                }}
              >
                <span>{i18n.t("createLevel")}</span>
              </NavLink>
              <NavLink
                to="/"
                onClick={() => {
                  ReactGA.event({
                    category: "設定頁",
                    action: "回到遊戲目錄",
                  })
                }}
              >
                <span>{i18n.t("backToLevelSelect")}</span>
              </NavLink>
            </div>
          </div>
          <div className={`content ${content === "settings" ? "--active" : ""}`}>
            <div className="game-setting-container">
              {/* <FormItem
                label={i18n.t("interfaceLanguage")}
                name="language"
                options={languages.map(lang => ({
                  id: lang.code,
                  value: lang.code,
                  label: lang.name,
                }))}
                checkedValue={settings.language}
                handleChange={handleRadioChange}
              /> */}
              <FormItem
                label={i18n.t("difficulty")}
                name="difficulty"
                options={[
                  {
                    id: "level-1",
                    value: "beginner",
                    label: i18n.t("beginner"),
                  },
                  {
                    id: "level-2",
                    value: "advanced",
                    label: i18n.t("advanced"),
                  },
                ]}
                checkedValue={settings.difficulty}
                handleChange={handleRadioChange}
              />
              <FormItem
                label={i18n.t("backgroundMusic")}
                id="backgroundMusic"
                checked={settings.backgroundMusic}
                handleChange={handleSwitchChange}
                isSwitch
              />
              <FormItem
                label={i18n.t("soundEffects")}
                id="soundEffects"
                checked={settings.soundEffects}
                handleChange={handleSwitchChange}
                isSwitch
              />
              <FormItem
                label={i18n.t("npcAutoVoice")}
                id="autoNpcVoice"
                checked={settings.autoNpcVoice}
                handleChange={handleSwitchChange}
                isSwitch
              />
              <FormItem
                label={i18n.t("movingBackground")}
                id="movingBackground"
                checked={settings.movingBackground}
                handleChange={handleSwitchChange}
                isSwitch
              />
            </div>
          </div>
        </div>
      </div>
    </nav>
  )
}
