//玩家的基本資訊
export type PlayerInfo = {
  username: string;
  nickname: string;
  avatarUrl: string;
  userType?: "player" | "ai";
};

//玩家的學習歷程紀錄
export interface PronunciationResult {
  game_type: string;
  score: number;
  praticeCount: number;
}
export interface GamePerformanceData {
  score: number;
  averageAnswerTime: number; // in seconds
  averageStoryTime?: number; // in seconds
  averageQATime?: number; // in seconds
  averagePronunciationTime?: number; // in seconds
  averageQAScore?: number;
  averagePronunciationScore?: number;
  averagePronunciationPraticCount?: number;
  averageAnswerLength?: number; // 平均答案長度（字數）
  gameDuration: number; // in seconds
}
export function highlightToSkillClass(highlight: Highlight): string {
  const highlightOrder = {
    [Highlight.QUICK_ANSWER]: 5,
    [Highlight.DEEP_THINKER]: 7,
    [Highlight.STORY_LOVER]: 3,
    [Highlight.HIGH_SCORE]: 8,
    [Highlight.PERFECT_SCORE]: 9,
    [Highlight.ARTICULATION_MASTER]: 4,
    [Highlight.QA_EXPERT]: 2,
    [Highlight.PRO_PRACTICE_CHAMPION]: 1,
    [Highlight.MARATHON_WRITER]: 10,
    [Highlight.MINIMALIST]: 11,
    [Highlight.SPEED_MASTER]: 12,
  };

  return `skill --skill-${highlightOrder[highlight]}`;
}
export enum Highlight {
  QUICK_ANSWER = "眼明快手",
  DEEP_THINKER = "深思熟慮",
  STORY_LOVER = "劇情達人",
  HIGH_SCORE = "高分達人",
  PERFECT_SCORE = "完美無缺",
  ARTICULATION_MASTER = "發音大師",
  QA_EXPERT = "問答專家",
  PRO_PRACTICE_CHAMPION = "發音練習冠軍",
  MARATHON_WRITER = "寫作馬拉松", // 寫作時間 > 30分鐘
  MINIMALIST = "極簡主義者", // 平均字數 < 50 但總分 >= 4.0
  SPEED_MASTER = "神速文豪", // 快筆如飛 + 總分 >= 4.2
}
export interface GameRewardsResult {
  highlights: Highlight[];
  totalExp: number;
  completion: string;
  dynamicEncouragement: string;
}
export type OneLearningLog = {
  userType: "player" | "ai";
  username: string;
  content: string; //玩家的回答
  score?: number; //分數
  advice?: string; //建議
  isCorrect?: boolean;
  options?: string[]; //選項
  questionType?: string; //問題類型
  timestamp: string; //時間戳
  praticeCount?: number; //練習次數, 用於發音遊戲, 其他遊戲只玩一次
};
export type LearningJourneyLog = {
  logs: OneLearningLog[];
  finalScore?: number;
  comment?: string;
  gameDuration?: number;
  isFinished: boolean;
  generated_image?: string;
  gamestart_timestamp?: string;
  gameend_timestamp?: string;
  gamePerformanceData?: GamePerformanceData;
  gameRewardsResult?: GameRewardsResult;
};
export type MenuOption = {
  id: string;
  name: string;
  botrunId?: string;
  gameType?: string;
};
export type WriteScoreDimension = {
  content: number;
  organization: number;
  grammarAndSyntax: number;
  vocabularyAndSpelling: number;
  comment: string;
};

// 多維度評分用，最早設計用於無固定腳本，全口說遊戲，判斷玩家一系列對談的整體表現，包括內容、組織、語法和詞彙、評語, 但是目前沒有作用，因為目前的遊戲都是固定腳本
export type ScoreProps = {
  total: number;
  vocabulary: number;
  grammar: number;
  comprehension: number;
  interactivity: number;
};

export interface Question {
  type: string;
  question: string;
  options: string[]; // 用于选择题和故事选择
  answer: string; //如果是選擇題或多選題，則是選項的名稱 optionName1,optionName2,optionName3, 如果是開放問答題，則是例句的開頭提示。如果是劇情選擇，則是空白
}
// 定義解析後的數據結構
interface ParsedData {
  [key: string]: string;
}

//關卡的入口頁面解說資訊 定義 IntroductionProps 接口
export type IntroductionProps = {
  title: string;
  subtitle: string;
  description: string;
  backgroundImageUrl: string;
};

export type WriteGameDataProps = {
  writing_subject: string;
  writing_description: string;
  writing_rules: string;
  writing_minWords: number;
  writing_maxWords: number;
};

//遊戲的對話或是問答題目都用 QuestionProbs 來定義
export type QuestionProbs = {
  question: string;
  answer: string;
  hint: string;
};

export const LearningLevel: Record<string, string> = {
  A2: "CEFR A2 - 初學者：基本日常詞彙（1000-1500），簡單句型（現在時、過去時、未來時），簡單日常交流",
  B1: "CEFR B1 - 中級：更多詞彙（2500-3000），較複雜句型（現在完成時、條件句），大部分旅遊生活情況",
  B2: "CEFR B2 - 中高級：較廣泛詞彙，複雜句型（被動語態、間接引語），流利日常和專業交流",
  C1: "CEFR C1 - 高級：廣泛詞彙，高度複雜句型，靈活應對各種情況",
  Adaptive: "自適應：根據玩家表現調整難度",
};
export const QuestionType: Record<string, string> = {
  單選: "單選",
  多選: "多選",
  問答: "問答",
  劇情選擇: "劇情選擇",
  生圖: "生圖",
  發音: "發音",
};
// 固定出題的題目結構

export type QuestionSet = {
  title: string;
  questions: QuestionProbs[];
};

//以下用於遊戲關卡生成的數據結構
export type AiLevelProbs = {
  title: string;
  subtitle: string;
  description: string;
  story: string;
  learningMaterial: string;
  visualDescription: string;
  questions: string;
};
export type userDefinedLevelProbs = {
  gameType: string;
  difficulty: string;
  numberOfQuestions: string;
  backgroundImageUrl?: string;
  multiPlayer: boolean;
};
export type CreatorProbs = {
  Creator?: string;
  IsEditableForAll?: boolean;
};
export type AIGenerateCharacterResponse = {
  Name: string;
  Appearance: string;
  TalkStyle: string;
  wellcomeMessage: string;
};
export type CharacterProbs = AIGenerateCharacterResponse &
  CreatorProbs & {
    imageUrl: string;
    VoiceName: string;
    ID: string;
  };
export type avatarProbs = {
  avatarUrl: string;
  avatarName: string;
  avatarVoice: string;
  wellcomeMessage: string;
  avatarId: string;
};
export type customPrompts = {
  imagePrompt?: string; //針對圖片產生提示說明的prompt, 沒有則會用prompt.config
  questionAdvicePrompt?: string; //針對問題產生提示說明的prompt, 沒有則會用prompt.config
  answerAdvicePrompt?: string; //針對答案是否正確，產生說明的prompt, 沒有則會用prompt.config
  scoreEvaluatePrompt?: string; //針對分數評價的prompt, 沒有則會用prompt.config
  turnWritePrompt?: string; //針對寫作接龍，提示玩家下一步的prompt, 沒有則會用prompt.config
  questionGenerationPrompt?: string; //自動產生問題題型的prompt, 沒有則會用prompt.config
};
export type levelDataProbs = userDefinedLevelProbs &
  AiLevelProbs &
  CreatorProbs &
  avatarProbs &
  customPrompts &
  WriteGameDataProps;
//語音選項
enum AvatarVoice {
  Alloy = "alloy",
  Echo = "echo",
  Fable = "fable",
  Onyx = "onyx",
  Nova = "nova",
  Shimmer = "shimmer",
}
// 定義 getVoice 函數
export function getVoice(name: string): AvatarVoice {
  // 檢查傳遞的 name 是否在 AvatarVoice 枚舉中
  if (Object.values(AvatarVoice).includes(name as AvatarVoice)) {
    return name as AvatarVoice;
  }
  // 如果 name 不在枚舉中，返回默認值 AvatarVoice.Nova
  return AvatarVoice.Nova;
}

// 定義 Keywords 接口
export type Keywords = [string, string][];

//定義talkGame的leveldata
export class LevelData {
  gameType!: string;
  difficulty!: string;
  story!: string;
  numberOfQuestions!: number;
  visualDescription!: string;
  Creator!: string;
  IsEditableForAll!: boolean;
  multiPlayer!: boolean;
  constructor(data: ParsedData) {
    Object.assign(this, data);
  }
}
// 定義 AIGameData 接口
export class AIGameData {
  botrunId!: string;
  avatarUrl: string;
  avatarName: string;
  playerUrl: string;
  avatarVoice: string;
  avatarId!: string;
  wellcomeMessage!: string;
  customPrompts: customPrompts;
  constructor(data: ParsedData) {
    Object.assign(this, data);
    this.avatarUrl = data.avatarUrl || "/images/avatar/avatar.png";
    this.playerUrl = data.playerUrl || "/images/avatar-2.png";
    this.avatarVoice = data.avatarVoice || "nova";
    this.avatarName = data.avatarName || "研究員";

    this.avatarId = data.avatarId || "";
    this.wellcomeMessage = data.wellcomeMessage || "";
    this.customPrompts = {
      questionAdvicePrompt: data.questionAdvicePrompt,
      imagePrompt: data.imagePrompt,
      answerAdvicePrompt: data.answerAdvicePrompt,
      scoreEvaluatePrompt: data.scoreEvaluatePrompt,
      turnWritePrompt: data.turnWritePrompt,
      questionGenerationPrompt: data.questionGenerationPrompt,
    };
  }
}

// 定義包含三個獨立物件的 CompleteAIGameData 接口
export interface CompleteAIGameData {
  AIGameData: AIGameData;
  Keywords: Keywords;
  IntroductionProps: IntroductionProps;
  WriteGameData?: WriteGameDataProps;
  game_type?: string;
  questions?: Question[];
  levelData?: LevelData;
  learningMaterial?: string;
  rawQuestionText?: string;
  multiplayer?: boolean;
}

export //遊戲設定檔案中，我們需要提取的標籤tags <tag></tag>
const gameDataPropsTags = [
  "AIGameData",
  "IntroductionProps",
  "Keywords",
  "questionAdvicePrompt",
  "imagePrompt",
  "answerAdvicePrompt",
  "LevelData",
  "LearningMaterial",
  "QuestionData",
  "scoreEvaluatePrompt",
  "turnWritePrompt",
  "WriteGameData",
  "questionGenerationPrompt",
];
// 讀取遊戲數據時，我們需要提取的標籤 labels ，要符合相對應的Props
export const LevelDataLabels = [
  "gameType",
  "difficulty",
  "story",
  "numberOfQuestions",
  "visualDescription",
  "Creator",
  "IsEditableForAll",
  "multiPlayer",
];
export const IntroductionPropsLabels = [
  "title",
  "subtitle",
  "description",
  "backgroundImageUrl",
  "backgroundAudioUrl",
];
export const AIGameDataLabels = [
  "botrunId",
  "avatarUrl",
  "playerUrl",
  "avatarVoice",
  "wellcomeMessage",
  "avatarName",
  "imagePrompt",
  "questionAdvicePrompt",
  "answerAdvicePrompt",
  "scoreEvaluatePrompt",
  "avatarId",
  "multiplayer",
];
export const WriteGameDataLabels = ["subject", "description", "rules"];

export const aiBuddyPropsTags = "CharacterData";
export const CharacterDataLabels = [
  "Name",
  "Appearance",
  "TalkStyle",
  "wellcomeMessage",
  "imageUrl",
  "VoiceName",
  "Creator",
  "IsEditableForAll",
];

export type BotsProps = {
  avatar: string;
  name: string;
  botrunId: string;
};

export type PageStateProps = {
  pageType: string;
};

export enum PageTypes {
  Home = "Home",
  Game = "Game",
  Timtraveler = "time_game", // 時光旅行
  FreeTalk = "自由對談",
  MysticEscape = "card_game", // 神秘逃脫, 圖卡遊戲
}

export enum gameFlowTypes {
  GameStart = "=遊戲開始=",
  Story = "=劇情選擇=",
  QuestionAnswering = "=問答=",
  QuestionSingleSelect = "=單選=",
  QuestionMultiSelect = "=多選=",
  ImageGeneration = "=生圖=",
  Pronounce = "=發音=",
  Review = "=評語=",
}
export type ScoreAndComment = {
  comment: string;
  vocabulary: number;
  grammar: number;
  comprehension: number;
  interactivity: number;
};
