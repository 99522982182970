import { MenuOption } from "@core/sdgs_types";
import { useListGameMenu } from "@core/hooks/useApi"; // 確保路徑是正確的
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
import MenuBTN from "@components/CreateContent/CreatorNavi/MenuItems/CreatorMenuBTN";
import AnimatedBG from "@components/GeneralComponent/AnimatedBG";
import NaviMenu from "@components/CreateContent/CreatorNavi/CreatorNaviMenu";
import i18n from "@core/utils/i18n";
import Overlay from "@components/Overlay";
import MetaTagsProps from "@components/SEOTags/MetaTages";
import { useRecoilState } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
import { useEffect } from "react";
import useDeleteLevelFiles from "@hooks/useDeleteLevelFiles";
// 讀取學習夥伴目錄
export default function MenuLevels() {
  const [user] = useRecoilState(userAuthState);
  const navigate = useNavigate();
  const {
    mutateAsync: deleteFiles,
    isAllSuccess,
    getErrorMessages,
  } = useDeleteLevelFiles();
  // 指定的資料夾 ID 或 URL 和最大文件數量
  const rootFolderId =
    process.env.REACT_APP_ROOT_FOLDER_ID || "1ZHRyYiqhzSmxUCSweGWiYXkagFS6TLGc";

  // 使用 useState 来模拟数据的加载状态，未來可以改为使用 useQuery

  const { gameMenu, error, loading } = useListGameMenu(
    rootFolderId,
    "_game",
    user?.username
  );
  const onDelete = async (option: MenuOption) => {
    console.log(`delete ${option.name} clicked!`);

    await deleteFiles([option.id]);
    if (isAllSuccess()) {
      console.log("刪除成功");
    } else {
      console.log("刪除失敗", getErrorMessages());
    }
  };
  const onMenuBTNClick = (option: MenuOption) => {
    ReactGA.event({
      category: "編輯學習關卡",
      action: option.name,
    });

    console.log("選擇了 option.id", option.id);
    navigate(`/createLevel/l/${option.id}`);
  };
  useEffect(() => {
    console.log("user", user);
  }, [user]);
  if (loading) return <Overlay show={true} />;
  return (
    <div className="game-container">
      <MetaTagsProps
        title="immersive 生成式遊戲關卡編輯器"
        description="ＡＩ輔助，創造屬於你自己的學習內容"
      />
      <div className="game-stage-container">
        <div className="game-logo">
          <h1>immerse</h1>
          <p>編輯你的學習關卡</p>
        </div>
        <NaviMenu />
        {gameMenu.length === 0 ? (
          <p>{i18n.t("noContent")}</p>
        ) : (
          <ul className="game-menu">
            {gameMenu.map((option) => (
              <li key={option.id}>
                <MenuBTN
                  option={option}
                  menuCallBack={onMenuBTNClick}
                  onDelete={onDelete}
                />
              </li>
            ))}
            <li key="建立新關卡">
              <MenuBTN
                option={{ id: "", name: "建立新關卡" }}
                menuCallBack={onMenuBTNClick}
              />
            </li>
          </ul>
        )}
      </div>
      <AnimatedBG />
    </div>
  );
}
