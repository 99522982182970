import React, { useEffect } from "react";
import { LearningJourneyLog } from "@core/sdgs_types";
import { useParams } from "react-router-dom";
import useGetLog from "@hooks/useGetLog";
import Score from "@core/components/GameEnd/Score";
import TalkGameScoreBoard from "@core/components/ImmersiveMenu/Game/TalkGame/GameEnd/TalkGameScoreBoard";
import {
  getSecondsBetweenISOTimestamps,
  formatSecondsToMMSS,
} from "@utils/functions";
//顯示口說遊戲的學習歷程紀錄
const TalkGameLog: React.FC<{ log_session_id?: string }> = ({
  log_session_id,
}) => {
  const { sessionId } = useParams();
  const final_session_id = log_session_id || sessionId;
  const queryString = JSON.stringify({
    session_id: final_session_id,
    type: "遊戲歷程紀錄",
  });

  const { data, isLoading, isError, error } = useGetLog(queryString);

  useEffect(() => {
    // 創建一個新的 link 元素
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = `${process.env.PUBLIC_URL}/css/log.css`;

    // 將 link 元素添加到 head 中
    document.head.appendChild(link);

    // 清理函數：當組件卸載時移除 link 元素
    return () => {
      document.head.removeChild(link);
    };
  }, []);

  const userData = {
    avatar: data?.avatar,
    nickname: data?.nickname,
  };

  const learningJourneyLog =
    data?.learning_journey_log || ({} as LearningJourneyLog);

  console.log("取得finalScore", learningJourneyLog.finalScore);
  //finalMessageWithResult(learningJourneyLog);
  const formatTimestamp = (timestamp: string | undefined) => {
    const date = new Date(timestamp || "");

    const formatter = new Intl.DateTimeFormat("zh-TW", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
      timeZone: "Asia/Taipei",
    });

    return formatter.format(date);
  };
  if (isLoading) return <div>正在加載日誌...</div>;
  if (isError) return <div>獲取日誌時出錯: {(error as Error).message}</div>;

  return (
    <div className="br-chat-component">
      <div className="br-chat-display-container --has-avatar --layout-chatmode">
        <div className="br-chat-display">
          <div className="br-chat-default">
            <div className="avatar-block">
              <div
                className="avatar"
                style={{ backgroundImage: `url('${userData.avatar}')` }}
              ></div>
              <div className="name">{userData.nickname}</div>
              <h1>{data?.game_data.IntroductionProps.title}</h1>

              <ul className="mission-info">
                <li>
                  <div className="field">遊玩日期</div>
                  <div className="value">
                    {formatTimestamp(data?.update_timestamp)}
                  </div>
                </li>
                {learningJourneyLog.finalScore ||
                learningJourneyLog.finalScore === 0 ? (
                  <li>
                    <div className="field">平均分數</div>
                    <div className="value">{learningJourneyLog.finalScore}</div>
                  </li>
                ) : null}
                {learningJourneyLog.gameend_timestamp &&
                learningJourneyLog.gamestart_timestamp ? (
                  <li>
                    <div className="field">遊玩時間</div>
                    <div className="value">
                      {formatSecondsToMMSS(
                        getSecondsBetweenISOTimestamps(
                          learningJourneyLog.gamestart_timestamp,
                          learningJourneyLog.gameend_timestamp
                        )
                      )}
                    </div>
                  </li>
                ) : null}
              </ul>
            </div>
            <div
              className="bg-mission"
              style={{
                backgroundImage: `url('${data?.game_data.IntroductionProps.backgroundImageUrl}')`,
              }}
            ></div>
          </div>
          {data?.game_data.WriteGameData ? (
            <div className="write-game-info">
              <h2>{data?.game_data.WriteGameData.writing_subject}</h2>
              {data?.game_data.WriteGameData.writing_description && (
                <p>描述：{data?.game_data.WriteGameData.writing_description}</p>
              )}
              {data?.game_data.WriteGameData.writing_rules && (
                <p>規則：{data?.game_data.WriteGameData.writing_rules}</p>
              )}
            </div>
          ) : null}
          {learningJourneyLog.logs &&
            learningJourneyLog.logs.map((oneLog, index) => (
              <div
                key={index}
                className={`br-chat-item ${
                  oneLog.userType == "player" ? "self" : ""
                }`}
              >
                <div className="avatar-block">
                  <div
                    className="avatar"
                    style={{
                      backgroundImage: `url('${
                        oneLog.userType == "player"
                          ? userData.avatar
                          : data?.game_data.AIGameData.avatarUrl
                      }')`,
                    }}
                  ></div>
                  <div className="name">
                    {oneLog.userType == "player"
                      ? userData.nickname
                      : data?.game_data.AIGameData.avatarName}
                  </div>
                </div>
                <div
                  className={`br-chat-block ${
                    oneLog.isCorrect !== undefined
                      ? oneLog.isCorrect
                        ? "correct"
                        : "wrong"
                      : ""
                  }`}
                >
                  <div className="markdown-wrapper">
                    <p>{oneLog.content}</p>
                  </div>
                </div>
                {oneLog.questionType &&
                  oneLog.questionType !== "劇情選擇" &&
                  oneLog.questionType !== "advice" &&
                  oneLog.questionType !== "發音" &&
                  (oneLog.options ?? []).length > 0 && (
                    <div className="br-options">
                      <strong>選項：</strong>
                      <ul>
                        {(oneLog.options ?? []).map((option, index) => (
                          <li key={index}>[{option}]</li>
                        ))}
                      </ul>
                    </div>
                  )}

                {oneLog.questionType &&
                  oneLog.questionType !== "劇情選擇" &&
                  oneLog.questionType !== "advice" && (
                    <div className="br-result-block">
                      {oneLog.score !== undefined && (
                        <div className="result-score">
                          <p>
                            <strong>Score：</strong>
                            {oneLog.score}
                          </p>
                        </div>
                      )}
                      {oneLog.advice && (
                        <div className="result-advice">
                          <p>
                            <strong>Advice：</strong>
                            {oneLog.advice}
                          </p>
                        </div>
                      )}
                      {oneLog.praticeCount &&
                        oneLog.questionType === "發音" && (
                          <div className="result-praticeCount">
                            <p>
                              <strong>練習次數：</strong>
                              {oneLog.praticeCount}
                            </p>
                          </div>
                        )}
                    </div>
                  )}
              </div>
            ))}

          {learningJourneyLog.generated_image && (
            <div className="generated-image">
              <h3>生成的圖片</h3>
              <img
                src={learningJourneyLog.generated_image}
                alt="Generated Image"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TalkGameLog;
