import React from "react";
import TextAreaField from "../common/TextAreaField";
import QuestionList from "../common/QuestionList";
import { levelDataProbs, Question } from "@core/sdgs_types";
import { freeFormGameTypes } from "../config/CreateContentConfig";

interface ContentSettingsTabProps {
  level: levelDataProbs;
  onLevelChange: (level: Partial<levelDataProbs>) => void;
  formatedQuestions: Question[];
  setFormatedQuestions: (questions: Question[]) => void;
  processing: boolean;
  onGenerateContent: () => void;
  onFormatQuestions: () => void;
  isMaterialLocked: boolean;
  onMaterialLockToggle: (isLocked: boolean) => void;
}

export const ContentSettingsTab: React.FC<ContentSettingsTabProps> = ({
  level,
  onLevelChange,
  formatedQuestions,
  setFormatedQuestions,
  processing,
  onGenerateContent,
  onFormatQuestions,
  isMaterialLocked,
  onMaterialLockToggle,
}) => {
  return (
    <div className="content-settings">
      <TextAreaField
        label="學習資料"
        placeholder=""
        isRequired={true}
        helpText="學習資料，可能是單字，句子，文章等等，用來產生關卡劇情與題目內容。"
        value={level.learningMaterial || ""}
        onChange={(value) => onLevelChange({ learningMaterial: value })}
        isLocked={isMaterialLocked}
        onLockToggle={onMaterialLockToggle}
      />
      {freeFormGameTypes.includes(level.gameType) ? null : (
        <>
          <button
            className="btn --small"
            onClick={onGenerateContent}
            disabled={processing}
          >
            {processing ? <span>生成中...</span> : <span>產生關卡題目</span>}
          </button>

          <TextAreaField
            label="關卡題目"
            placeholder=""
            isRequired={false}
            helpText="放入你想要的題目內容，或是期待生成題目方向與範例"
            value={level.questions || ""}
            onChange={(value) => onLevelChange({ questions: value })}
          />
          <button
            className="btn --small"
            onClick={onFormatQuestions}
            disabled={processing}
          >
            {processing ? <span>生成中...</span> : <span>產生題目列表</span>}
          </button>

          <QuestionList
            questions={formatedQuestions}
            setQuestions={setFormatedQuestions}
          />
        </>
      )}
    </div>
  );
};
