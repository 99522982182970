import React from "react";
import TextInputField from "../common/TextInputField";
import TextAreaField from "../common/TextAreaField";
import { levelDataProbs } from "@core/sdgs_types";

interface WritingSettingsTabProps {
  level: levelDataProbs;
  onLevelChange: (level: Partial<levelDataProbs>) => void;
}

export const WritingSettingsTab: React.FC<WritingSettingsTabProps> = ({
  level,
  onLevelChange,
}) => {
  return (
    <div className="writing-settings">
      <TextInputField
        label="主題"
        placeholder=""
        isRequired={true}
        helpText="請輸入寫作的主題"
        value={level.writing_subject || ""}
        onChange={(value) => onLevelChange({ writing_subject: value })}
      />
      <TextAreaField
        label="描述"
        placeholder=""
        isRequired={true}
        helpText="請提供寫作主題的詳細描述"
        value={level.writing_description || ""}
        onChange={(value) => onLevelChange({ writing_description: value })}
      />
      <TextAreaField
        label="規則"
        placeholder=""
        isRequired={true}
        helpText="請列出寫作的相關規則"
        value={level.writing_rules || ""}
        onChange={(value) => onLevelChange({ writing_rules: value })}
      />
      <TextInputField
        label="最少字數"
        placeholder=""
        isRequired={true}
        helpText="請輸入寫作的最少字數"
        useAIAssistant={false}
        value={level.writing_minWords?.toString() || ""}
        onChange={(value) => {
          const numValue = Number(value);
          if (!isNaN(numValue)) {
            onLevelChange({ writing_minWords: numValue });
          }
        }}
      />
      <TextInputField
        label="最多字數"
        placeholder=""
        isRequired={true}
        helpText="請輸入寫作的最多字數"
        useAIAssistant={false}
        value={level.writing_maxWords?.toString() || ""}
        onChange={(value) => {
          const numValue = Number(value);
          if (!isNaN(numValue)) {
            onLevelChange({ writing_maxWords: numValue });
          }
        }}
      />
    </div>
  );
};
