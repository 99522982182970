import { useEffect, useState, useCallback, useRef } from "react";
import { useAudioRecorder } from "react-audio-voice-recorder";
import moment from "moment";
import useCountdown from "@hooks/useCountdown";
type newRecorderProps = {
  setText: React.Dispatch<React.SetStateAction<string>>;
  handleSpeechToText?: (file: File) => Promise<string>;
  setRecodingHint: React.Dispatch<React.SetStateAction<string | null>>;
  setMicOpen: (isOpen: boolean) => void;
  voiceLimit?: number;
};

const Recorder: React.FC<newRecorderProps> = ({
  setText,
  handleSpeechToText,
  setRecodingHint,
  setMicOpen,
  voiceLimit = 90,
}) => {
  const { startRecording, stopRecording, recordingBlob, isRecording } =
    useAudioRecorder();

  const [isConverting, setIsConverting] = useState(false);

  function handleRecording() {
    console.log("呼叫handleRecording, 是否recoding", isRecording);
    if (isConverting) return;
    if (isRecording) {
      StopRecoding();
      stopCountdown();
    } else {
      console.log("錄音開始");
      setMicOpen(true);
      setRecodingHint("錄音中...");
      startRecording();
      startCountdown();
    }
  }
  const StopRecoding = useCallback(() => {
    setMicOpen(false);
    setRecodingHint("語音轉文字中，請稍後");
    setIsConverting(true);
    stopRecording();
  }, [stopRecording, setMicOpen, setRecodingHint]);

  const { countdown, startCountdown, stopCountdown } = useCountdown({
    initialTime: voiceLimit,
    onComplete: StopRecoding,
  });
  useEffect(() => {
    const convertSpeechToText = async () => {
      if (recordingBlob !== undefined) {
        const now = moment().format("YYYY-MM-DD_HH-mm-ss");
        const fileName = `record_${now}.webm`;
        const file = new File([recordingBlob], fileName, {
          type: "audio/webm",
        });

        if (handleSpeechToText !== undefined) {
          console.log("Converting speech to text錄音轉文字中...");
          const text = await handleSpeechToText(file);
          setText(text);
        }
        setRecodingHint(null);
      }
    };

    convertSpeechToText();
  }, [recordingBlob]);
  useEffect(() => {
    if (!countdown) return;
    const progress = ((voiceLimit - countdown) / voiceLimit) * 360;
    const degree = progress * 360;

    // 使用 CSS 變數來更新進度
    document.documentElement.style.setProperty(
      "--progress-degree",
      `${degree}deg`
    );
  }, [countdown, voiceLimit]);
  return (
    <div
      className={`input-voice --countdown ${!isConverting ? "--active" : ""} ${
        isRecording ? "--pause" : ""
      }`}
      onClick={handleRecording}
    >
      <i className="icon icon-1"></i>
      <i
        className="circle"
        style={
          {
            "--progress-degree":
              countdown !== null
                ? `${((voiceLimit - countdown) / voiceLimit) * 360}deg`
                : "360deg",
          } as React.CSSProperties
        }
      ></i>
    </div>
  );
};

export default Recorder;
