import { useParams } from "react-router-dom";
import { useCallback, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Overlay from "@components/Overlay";
import useLogger from "@hooks/useLogger";
import { v4 as uuidv4 } from "uuid";
import { useLoadAIGameData } from "@core/hooks/LoadData/useGameDataLoader";
import { useUserSettings } from "@core/context/UserSettingsContext";
import { useRecoilState } from "recoil";
import { aiGameDataState } from "@core/recoil/sdgs_atoms";
import PlantGame from "./PlantGame";
import { useLoadPromptsData } from "@core/hooks/LoadData/useGameDataLoader";
export default function PlantTreeGame() {
  const { profile, setSettings } = useUserSettings();
  const { gameId, gameType, roomId } = useParams();
  const { mutate } = useLogger();
  const location = useLocation();
  const sessionRef = useRef("");
  const promptId = process.env.REACT_APP_PROMPTS_ID || "";
  const { prompts: configPrompts } = useLoadPromptsData(promptId);
  const [, setGameData] = useRecoilState(aiGameDataState);
  const { data, loading, error } = useLoadAIGameData(gameId ? gameId : "");

  const dataRef = useRef(data);
  const gameStarted = useRef(false);
  const isUnmounting = useRef(false);
  const navigate = useNavigate();

  const onScored = useCallback(
    (score: any) => {
      console.log("送出獲得分數log", score);
      mutate({
        type: "獲得分數",
        game_id: gameId,
        game_name: data?.IntroductionProps.title,
        game_type: data?.game_type,
        is_multi: roomId && data?.multiplayer ? "multi" : "single",
        session_id: sessionRef.current,
        score: score,
        location: location.pathname,
      });
    },
    [gameId, roomId, data]
  );

  const onFinished = useCallback(() => {
    mutate({
      type: "完成遊戲",
      game_id: gameId,
      game_name: data?.IntroductionProps.title,
      game_type: data?.game_type,
      is_multi: roomId && data?.multiplayer ? "multi" : "single",
      session_id: sessionRef.current,
      location: location.pathname,
    });
  }, [gameId, roomId, data]);
  const onLogLearningJourney = useCallback(
    (learning_journey_log: any) => {
      const logData = {
        type: "遊戲歷程紀錄",
        game_id: gameId,
        game_data: data,
        nickname: profile?.nickname,
        avatar: profile?.avatar?.image,
        is_multi: roomId ? "multi" : "single",
        session_id: sessionRef.current,
        learning_journey_log: learning_journey_log,
        log_sn: sessionRef.current,
        location: location.pathname,
      };
      console.log("寫入後台 遊戲歷程紀錄", logData);
      mutate(logData);
    },
    [gameId, roomId, data]
  );
  useEffect(() => {
    setSettings({
      language: "zh",
      difficulty: "beginner",
      backgroundMusic: false,
      soundEffects: true,
      autoNpcVoice: true,
      movingBackground: true,
    });
    sessionRef.current = uuidv4();
    console.log("遊戲載入：", sessionRef.current);
    return () => {
      isUnmounting.current = true;
    };
  }, []);
  useEffect(() => {
    if (!data || gameStarted.current || data === dataRef.current) return;

    gameStarted.current = true;
    dataRef.current = data;

    setGameData(data);

    mutate({
      type: "開始遊戲",
      game_id: gameId,
      game_name: data?.IntroductionProps.title,
      game_type: data?.game_type,
      is_multi: roomId && data.multiplayer ? "multi" : "single",
      session_id: sessionRef.current,
    });
    return () => {
      if (isUnmounting.current) {
        console.log("離開遊戲", data);
        mutate({
          type: "離開遊戲",
          game_id: gameId,
          game_name: data?.IntroductionProps.title,
          game_type: data?.game_type,
          is_multi: roomId && data.multiplayer ? "multi" : "single",
          session_id: sessionRef.current,
        });
      }
    };
  }, [data]);
  if (!gameId) {
    navigate("/");
    return <Overlay show={true} />;
  }
  if (loading || !data || !gameStarted.current) {
    return <Overlay show={true} />;
  }
  const leaningJourneyLogUrl = "/log/" + sessionRef.current;
  switch (gameType) {
    case "talk_game":
      return (
        <>
          <PlantGame
            CompleteAIGameData={data}
            onEnd={onFinished}
            onScored={onScored}
            leaningJourneyLogUrl={leaningJourneyLogUrl}
            onLogLearningJourney={onLogLearningJourney}
          />
        </>
      );

    default:
      return <></>;
  }
}
