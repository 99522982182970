import { AxiosResponse, AxiosError } from "axios";
import { useQuery } from "react-query";
import { request } from "@utils/axios-util";
import { useCache } from "../context/CacheContext";

interface PublicUserData {
  username: string;
  nickname: string;
  school: string | null;
  avatarId: string;
  avatar?: {
    _id: {
      $oid: string;
    };
    id: string;
    name: string;
    image: string;
    description: string;
    備註: string;
  };
}

const useGetUserInfo = (username?: string) => {
  const { containCache, getFromCache, addToCache } = useCache();
  const cacheKey = `user-info-${username}`;

  const queryFn = async () => {
    if (containCache(cacheKey)) {
      const cachedData = getFromCache<PublicUserData>(cacheKey);
      return { data: cachedData };
    }

    const response = await request({
      url: `/game/user/public/info`,
      method: "get",
      params: {
        username,
        with_avatar: true,
      },
    });

    addToCache(cacheKey, response.data);
    return response;
  };

  const { data, isLoading, error } = useQuery(
    ["fetch-public-user-info", username],
    queryFn,
    {
      onError: (error: AxiosError) => {
        console.log(error);
      },
      select: (res): PublicUserData => {
        return res.data;
      },
      enabled: !!username,
      staleTime: 5 * 60 * 1000,
      cacheTime: 30 * 60 * 1000,
    }
  );

  if (!username) {
    return { data: undefined, isLoading: false, error: null };
  }

  return { data, isLoading, error };
};

export { useGetUserInfo };
export type { PublicUserData };
