// /components/MetaTags.tsx
import React, { ReactNode } from "react";
import { Helmet } from "react-helmet";

interface MetaTagsProps {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
}

const MetaTags: React.FC<MetaTagsProps> = ({
  title = "immersive",
  description = "探索Immersive，使用生成式AI技術的英語學習平台，提供互動遊戲和個性化學習體驗，提升您的英語能力。",
  image = "images/avatar.png", // 添加一个默认图片 URL
  url = "https://english.botrun.ai", // 添加您网站的默认 URL
}) => (
  <Helmet>
    <meta charSet="utf-8" />
    <title>{title}</title>
    <meta name="description" content={description} />
    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content={url} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={url} />
    <meta property="twitter:title" content={title} />
    <meta property="twitter:description" content={description} />
    <meta property="twitter:image" content={image} />
  </Helmet>
);

export default MetaTags;
