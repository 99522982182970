import { NavLink, useParams } from "react-router-dom";
import { useSound } from "@core/context/SoundContext";
import Overlay from "@components/Overlay";
import ReactGA from "react-ga4";
import MetaTagsProps from "@components/SEOTags/MetaTages";
import { truncateString } from "@utils/functions";
import { useLoadAIGameData } from "@core/hooks/LoadData/useGameDataLoader";
export default function Intro() {
  const { gameId, gameType } = useParams();
  const { playSound } = useSound();
  //const { data: mission, isLoading } = useMissionData(gameId);
  const {
    data: mission,
    loading: isLoading,
    error,
  } = useLoadAIGameData(gameId ? gameId : "");
  const introduction = mission?.IntroductionProps;
  if (isLoading) return <Overlay show={true} />;
  return (
    <div className="game-intro-area">
      <MetaTagsProps
        title={truncateString("immers - " + introduction?.title, 50)}
        description={truncateString(introduction?.description || "", 50)}
        image={introduction?.backgroundImageUrl}
        url={`https://english.botrun.ai/game/${gameId}`}
      />
      <div className="text-holder">
        <div className="subheading">
          <h3 className="ff-en">{introduction?.title}</h3>
        </div>
        <h1 className="ff-en fz-A">{introduction?.subtitle}</h1>
        <p>{introduction?.description}</p>
      </div>
      <div className="btn-holder">
        <NavLink
          to="./play"
          className="btn-system --large"
          onClick={() => {
            playSound("click");
            ReactGA.event({
              category: "遊戲簡介頁",
              action: "單人挑戰",
              label: `${introduction?.title}, ${gameId}`,
            });
          }}
        >
          <span>單人挑戰</span>
        </NavLink>
        {gameType != "card_game" && mission?.multiplayer && (
          <NavLink
            to="./room"
            className="btn-system --large"
            onClick={() => {
              playSound("click");
              ReactGA.event({
                category: "遊戲簡介頁",
                action: "組隊遊玩",
                label: `${introduction?.title}, ${gameId}`,
              });
            }}
          >
            <span>組隊遊玩</span>
          </NavLink>
        )}
      </div>
    </div>
  );
}
