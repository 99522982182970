import React, { ChangeEvent } from "react";
import { Lock, Unlock } from "lucide-react";

import { AIAssistantButton } from "./AIAssistantButton";
interface TextAreaFieldProps {
  label: string;
  placeholder: string;
  isRequired?: boolean;
  helpText?: string;
  onChange: (value: string) => void;
  value: string;
  isShowHelp?: boolean;
  isLocked?: boolean;
  onLockToggle?: (isLocked: boolean) => void;
}

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  label,
  placeholder,
  isRequired = false,
  helpText,
  onChange,
  value,
  isShowHelp = false,
  isLocked = false,
  onLockToggle,
}) => {
  const handleInputChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    if (!isLocked) {
      onChange(event.target.value);
    }
  };

  const handleLockToggle = () => {
    if (onLockToggle) {
      onLockToggle(!isLocked);
    }
  };

  const showLockFeature = !!onLockToggle;

  return (
    <div
      className={`form-item ${isRequired ? "must" : ""} ${
        isShowHelp ? "show-help" : ""
      }`}
    >
      <div className="field">{label}</div>
      <div className="value relative">
        <textarea
          placeholder={placeholder}
          value={value}
          onChange={handleInputChange}
          cols={30}
          rows={10}
          className={isLocked && showLockFeature ? "bg-gray-100 pr-8" : ""}
          readOnly={isLocked && showLockFeature}
        ></textarea>
        {showLockFeature && (
          <button
            onClick={handleLockToggle}
            className="absolute right-2 top-2 transform"
          >
            {isLocked ? (
              <Lock className="h-5 w-5 text-gray-500" />
            ) : (
              <Unlock className="h-5 w-5 text-gray-500" />
            )}
          </button>
        )}
      </div>
      {helpText && (
        <div className="help-word">
          <i className="icon icon-28 inline"></i>
          {helpText}
        </div>
      )}
      <AIAssistantButton
        onComplete={onChange}
        currentContent={value}
        fieldName={label}
        backgroundInfo={`這是一個遊戲關卡的${label}。`}
      />
    </div>
  );
};

export default TextAreaField;
