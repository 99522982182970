import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import HttpBackend from "i18next-http-backend"

export const languages = [
  { code: "zh", name: "繁體中文" },
  { code: "ja", name: "日本語" },
  { code: "en", name: "English" },
]

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: languages[0].code,
    supportedLngs: languages.map(lang => lang.code),
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/lang.json`,
    },
    detection: {
      order: ["path", "cookie", "localStorage", "navigator", "htmlTag"],
      caches: ["cookie", "localStorage"],
      lookupFromPathIndex: 0,
      lookupFromSubdomainIndex: 0,
      checkWhitelist: true,
    },
  })

export default i18n
