import { AxiosResponse, AxiosError } from "axios";
import { useQuery } from "react-query";
import { request } from "@utils/axios-util";

type OneDeiveFileDownloadResponse = {
  content: string;
};

export default function useDownloadSingleFile(fileId: string | undefined) {
  const queryFn = () =>
    request<OneDeiveFileDownloadResponse>({
      url: `/download_single_file_from_google_drive`,
      method: "get",
      params: { file_id: fileId },
    });

  return useQuery(["downloadSingleFile", fileId], queryFn, {
    onError: (error: AxiosError) => {
      console.log(error);
    },
    select: (res: AxiosResponse): OneDeiveFileDownloadResponse => {
      return {
        ...res.data,
      };
    },
    enabled: !!fileId,
  });
}
