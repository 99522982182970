import React, {
  createContext,
  useContext,
  useRef,
  ReactNode,
  useCallback,
} from "react";

interface CacheContextType {
  containCache: (key: string) => boolean;
  getFromCache: <T>(key: string) => T | undefined;
  addToCache: <T>(key: string, value: T) => void;
  removeFromCache: (key: string) => void;
  clearCache: () => void;
}

const CacheContext = createContext<CacheContextType | undefined>(undefined);

interface CacheProviderProps {
  children: ReactNode;
}

export const CacheProvider: React.FC<CacheProviderProps> = ({ children }) => {
  const cacheRef = useRef<Map<string, any>>(new Map());

  const getFromCache = useCallback(<T,>(key: string): T | undefined => {
    return cacheRef.current.get(key) as T | undefined;
  }, []);

  const addToCache = useCallback(<T,>(key: string, value: T): void => {
    cacheRef.current.set(key, value);
  }, []);

  const containCache = useCallback((key: string): boolean => {
    return cacheRef.current.has(key);
  }, []);

  const removeFromCache = useCallback((key: string): void => {
    cacheRef.current.delete(key);
  }, []);

  const clearCache = useCallback((): void => {
    cacheRef.current.clear();
  }, []);

  return (
    <CacheContext.Provider
      value={{
        containCache,
        getFromCache,
        addToCache,
        removeFromCache,
        clearCache,
      }}
    >
      {children}
    </CacheContext.Provider>
  );
};

export const useCache = (): CacheContextType => {
  const context = useContext(CacheContext);
  if (!context) {
    throw new Error("useCache must be used within a CacheProvider");
  }
  return context;
};
