import React from "react";
import { useState } from "react";
import SimplePopup from "../GeneralComponent/SimplePopup";
import { useTranslation } from "react-i18next";
interface UserInfoPopupProps {
  isPopup: boolean;
  setIsPopup: (isVisible: boolean) => void;
  infomation: string;
  handlePopupClose: () => void;
}

const UserInfoPopup: React.FC<UserInfoPopupProps> = ({
  isPopup,
  setIsPopup,
  infomation,
  handlePopupClose,
}) => {
  const { t } = useTranslation();
  return (
    <SimplePopup
      isPopup={isPopup}
      setIsPopup={setIsPopup}
      popupClassName="for-instantbox"
      isShowCloseBtn={false}
    >
      <div className="instantbox-container">
        <div className="container">
          <div className="information">{infomation}</div>
          <div className="btn-area">
            <button
              className="btn"
              onClick={() => {
                setIsPopup(false);
                handlePopupClose();
              }}
            >
              <span>{t("comfirm")}</span>
            </button>
          </div>
        </div>
      </div>
    </SimplePopup>
  );
};

export default UserInfoPopup;
