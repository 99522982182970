import React, { useState, useEffect } from "react"
import { useCreateRoom } from "@hooks/useMultiGameApis"
import { useNavigate, useParams } from "react-router-dom"
import Join from "./Join"

const GameTeamCreateOrJoin: React.FC = () => {
  const { gameId } = useParams<{ gameId?: string }>();
  const navigate = useNavigate()
  const { refetch: refetchCreateRoom } = useCreateRoom(gameId)
  const [option, setOption] = useState<"selecting" | "join">("selecting")

  const createRoom = async () => {
    try {
      const { data } = await refetchCreateRoom()
      if (data.is_success) {
        navigate(`./${data.room_id}`)
      }
    } catch (error) {
      console.error("Error create room:", error)
    }
  }

  return (
    <div className="teamup-container">
      {option === "selecting" ? (
        <div className="teamup-intro">
          <div className="btn-type">
            <div className="sci-frame">
              <i className="deco-top"></i>
              <div className="wrapper" onClick={createRoom}>
                <div
                  className="img square"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/item/team-create.png)`,
                  }}
                ></div>
                <strong>創建隊伍</strong>
              </div>
            </div>
          </div>
          <div className="btn-type">
            <div className="sci-frame">
              <i className="deco-top"></i>
              <div className="wrapper" onClick={() => setOption("join")}>
                <div
                  className="img square"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/images/item/team-add.png)`,
                  }}
                ></div>
                <strong>加入</strong>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Join setOption={setOption} />
      )}
    </div>
  )
}
export default GameTeamCreateOrJoin
