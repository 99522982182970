import React, { useEffect, useState, useCallback } from "react";
import SimplePopup from "@core/components/GeneralComponent/SimplePopup";
import { useTranslation } from "react-i18next";

import TalkGameLog from "@core/components/ImmersiveMenu/TalkGameLog";
interface LogPopupProps {
  sessionId: string;
}

const LogPopup: React.FC<LogPopupProps> = ({ sessionId }) => {
  const { t } = useTranslation();

  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const onLogClick = useCallback(() => {
    console.log("log click");
    setIsPopupVisible((prev) => !prev);
  }, [setIsPopupVisible]);
  if (!isPopupVisible) {
    return (
      <div className="btn --white --small" onClick={onLogClick}>
        <i className="icon icon-17"></i>
        <span>學習歷程</span>
      </div>
    );
  }
  return (
    <>
      <div className="btn --white --small" onClick={onLogClick}>
        <i className="icon icon-17"></i>
        <span>學習歷程</span>
      </div>
      <SimplePopup
        isPopup={isPopupVisible}
        setIsPopup={setIsPopupVisible}
        popupClassName="for-log"
      >
        <TalkGameLog log_session_id={sessionId} />
      </SimplePopup>
    </>
  );
};
export default LogPopup;
