import useStage from "@hooks/useStage";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useUserSettings } from "@core/context/UserSettingsContext";
import useMusic from "@core/hooks/useMusic";
import { useLoadAIGameData } from "@core/hooks/LoadData/useGameDataLoader";
export default function BackGround() {
  const panSpeed = 12;
  const defaultBackground = `${process.env.PUBLIC_URL}/images/bg-stage.jpg`;
  const characterBackground = `${process.env.PUBLIC_URL}/images/bg-seamless/bg-character.jpg`;
  let lastTimestamp: number | null = null;

  const bgRef = useRef<HTMLDivElement>(null);
  const isInitiated = useRef(false);
  const animationFrameId = useRef<number | null>(null);

  const [background, setBackground] = useState("");
  const [url, setUrl] = useState<string>("");
  const [isTransitioning, setIsTransitioning] = useState(false);
  const { settings } = useUserSettings();
  useMusic(settings);

  const { data: stages = [] } = useStage();
  const location = useLocation();
  const { stageId, gameId } = useParams();
  // const { data: mission } = useMissionData(gameId);
  const {
    data: mission,
    loading: isLoading,
    error,
  } = useLoadAIGameData(gameId ? gameId : "");
  const transitioning = () => {
    setIsTransitioning(true);
    setTimeout(() => {
      setUrl(background);
      setIsTransitioning(false);
    }, 400);
  };

  useEffect(() => {
    if (gameId) return;

    const path = location.pathname;
    if (path === "/create") setBackground(characterBackground);
    else if (path.includes("st")) {
      const stageIndex = stages.findIndex((s) => s.id === stageId);
      const background =
        stageIndex === -1 ? defaultBackground : stages[stageIndex].background;
      setBackground(background);
    } else setBackground(defaultBackground);
  }, [location, stages, gameId]);

  useEffect(() => {
    if (!isInitiated.current) {
      setUrl(background);
      isInitiated.current = true;
    } else transitioning();
  }, [background]);

  useEffect(() => {
    if (gameId) return;
    function moveBackground(timestamp: number) {
      if (!lastTimestamp) lastTimestamp = timestamp;
      const elapsed = timestamp - lastTimestamp;
      const moveDistance = (elapsed / 1000) * panSpeed; // Movement distance in pixels
      if (bgRef.current) {
        const currentPosX = parseFloat(
          bgRef.current.style.backgroundPositionX || "0"
        );
        bgRef.current.style.backgroundPositionX = `${
          currentPosX - moveDistance
        }px`;
      }
      lastTimestamp = timestamp;
      if (settings.movingBackground) {
        animationFrameId.current = requestAnimationFrame(moveBackground);
      }
    }

    if (settings.movingBackground) {
      animationFrameId.current = requestAnimationFrame(moveBackground);
    } else if (animationFrameId.current) {
      cancelAnimationFrame(animationFrameId.current);
      animationFrameId.current = null;
    }

    return () => {
      if (animationFrameId.current) {
        cancelAnimationFrame(animationFrameId.current);
        if (bgRef.current)
          bgRef.current.style.removeProperty("background-position-x");
      }
    };
  }, [settings.movingBackground, url]);

  useEffect(() => {
    if (gameId && mission?.IntroductionProps.backgroundImageUrl) {
      if (bgRef.current)
        bgRef.current.style.removeProperty("background-position-x");
      setBackground(mission?.IntroductionProps.backgroundImageUrl);
    }
  }, [gameId, mission?.IntroductionProps.backgroundImageUrl]);

  return (
    <div className={`game-bg-area ${isTransitioning ? "fade-in" : ""}`}>
      <div className="layer-fade"></div>
      <div
        ref={bgRef}
        className={`bg ${gameId ? "--animate" : "for-stage"}`}
        style={{
          backgroundImage: `url(${url})`,
        }}
      ></div>
    </div>
  );
}
