import { UserResult, QuestionTypeEnum } from "./types";

import { jsonPharseTool } from "@core/utils/jsonPharseTool";
import { PronunciationResult } from "@core/sdgs_types";
// 判斷集中在有答案的問題中，使用者回答是否正確，並回傳結果，包含是否正確、分數、建議

export const isSkipValidationQuestion = (type: QuestionTypeEnum) =>
  [QuestionTypeEnum.劇情選擇, QuestionTypeEnum.advice].includes(type);

export const getQuestionResult = (
  type: QuestionTypeEnum,
  question: any,
  content: string
): UserResult => {
  switch (type) {
    case QuestionTypeEnum.單選:
      const isCorrect = content === question.answer;

      console.log("單選題判斷", content, question.answer, isCorrect);
      return isCorrect
        ? { content: content, isCorrect: true, score: 100, advice: "" }
        : { content: content, isCorrect: false, score: 0, advice: "" };
    case QuestionTypeEnum.多選:
      return validateMultipleChoice(question.answer, content);
    case QuestionTypeEnum.問答:
      return { content: content, isCorrect: false, score: 0, advice: "" }; // 需要 AI 評估
    case QuestionTypeEnum.發音:
      return validatePronunciation(content);
    case QuestionTypeEnum.生圖:
      return { content: content, isCorrect: true, score: 100, advice: "" };
    default:
      return { content: content, isCorrect: false, score: 0, advice: "" };
  }
};

export const validateMultipleChoice = (
  correctAnswer: string,
  userAnswer: string
): UserResult => {
  const correctAnswers = correctAnswer.split(",");
  const userAnswers = userAnswer.split(",");
  const isCorrect =
    correctAnswers.every((answer) => userAnswers.includes(answer)) &&
    userAnswers.every((answer) => correctAnswers.includes(answer));
  return isCorrect
    ? { content: userAnswer, isCorrect: true, score: 100, advice: "" }
    : { content: userAnswer, isCorrect: false, score: 0, advice: "" };
};

export const validatePronunciation = (content: string): UserResult => {
  const passScore = 60;
  const pronunciationResult = jsonPharseTool(content) as PronunciationResult;
  return {
    content: "發音練習",
    isCorrect: pronunciationResult.score >= passScore,
    score: pronunciationResult.score,
    advice: "",
    praticeCount: pronunciationResult.praticeCount,
  };
};
