// 通用的數據加載和解析函數
// 這個函數用於加載通用的數據，並且根據不同的類型進行解析
// 這個函數的返回值是一個對象，包含了加載的數據、加載狀態和錯誤信息
// ex:
// const { gameData: menuRecommandList, loadData: loadMenuRecommandList } =
//    useGetGeneralData<string[]>();
// loadMenuRecommandList() : 參數是 fileId, dataPropsTag, dataLabels
// 分別是資料夾 ID 或 URL
// dataPropsTag 是文件中的標籤，dataLabels 是文件中的標籤

import { useState, useEffect, useCallback, useRef } from "react";
//import { useDownloadSingleFile } from "../useApi";
import {
  TagBasedContentExtractor,
  parseTextFromLabels,
  parseListByNewLine,
} from "./gameDataPhaser";
//import { useCache } from "@core/context/CacheContext";
import useDownloadSingleFile from "@core/hooks/useDownloadSingleFile";
export const useLoadGeneralData = <T>() => {
  const [gameData, setGameData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const fileIdRef = useRef<string | null>(null);
  const dataPropsTagRef = useRef<string | null>(null);
  const dataLabelsRef = useRef<string[]>([]);
  const { data: response } = useDownloadSingleFile(
    fileIdRef.current ? fileIdRef.current : ""
  );

  //const { addToCache, getFromCache } = useCache();
  const loadData = useCallback(
    async (fileId: string, dataPropsTag: string, dataLabels: string[]) => {
      setLoading(true);
      setError(null);
      fileIdRef.current = fileId;
      dataPropsTagRef.current = dataPropsTag;
      dataLabelsRef.current = dataLabels;
      /*  const cacaheKey = `${fileId}_${dataPropsTag}_${dataLabels.join("_")}`;
      const cachedData = getFromCache<T>(cacaheKey);
      if (cachedData) {
        console.log("Data loaded from cache:", cacaheKey, cachedData);
        setGameData(cachedData);
        setLoading(false);
        return;
      } */
    },
    []
  );
  useEffect(() => {
    if (
      response &&
      response.content &&
      fileIdRef.current &&
      dataPropsTagRef.current
    ) {
      console.log("下載資料有變動 Data downloaded:", response);
      const parsedData = TagBasedContentExtractor({
        tags: [dataPropsTagRef.current],
        text: response.content,
      });
      //  console.log('Parsed data:', parsedData);
      let extractedData: T;
      if (parsedData[dataPropsTagRef.current] === undefined) {
        console.error(" tag ${dataPropsTag} is No found in data:${parsedData}");
        return;
      }
      if (parsedData[dataPropsTagRef.current].type === undefined) {
        console.log("No data type found:", parsedData[dataPropsTagRef.current]);
        parsedData[dataPropsTagRef.current].type = "default";
      }
      switch (parsedData[dataPropsTagRef.current].type) {
        case "default":
          extractedData = parseTextFromLabels({
            text: parsedData[dataPropsTagRef.current].content,
            labels: dataLabelsRef.current,
          }) as T;
          break;
        case "list":
          extractedData = parseListByNewLine(
            parsedData[dataPropsTagRef.current].content
          ) as T;
          break;
        case "text":
          extractedData = parsedData[dataPropsTagRef.current]
            .content as unknown as T;
          break;
        default:
          console.error(
            "Unknown data type:",
            parsedData[dataPropsTagRef.current].type
          );
          return; // 在遇到未知類型時提前返回
      }
      // console.log('Extracted data:', extractedData);
      setGameData(extractedData);
      setLoading(false);
      const cacheKey = `${fileIdRef.current}_${
        dataPropsTagRef.current
      }_${dataLabelsRef.current.join("_")}`;

      // addToCache(cacheKey, extractedData);
    } else if (response && !response.content) {
      setError("No content found in response");
      setLoading(false);
    }
  }, [response]);

  return { gameData, loading, error, loadData };
};

export default useLoadGeneralData;
