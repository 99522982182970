import { SuggestionProps } from "@botrun/chat";
import { MessageProps } from "@components/GameChat/types";

//計算兩個ISO時間戳之間的秒數
export function getSecondsBetweenISOTimestamps(
  isoTimestampStart: string,
  isoTimestampEnd: string
): number {
  const date1 = new Date(isoTimestampStart);
  const date2 = new Date(isoTimestampEnd);
  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
  return diffInMilliseconds / 1000;
}
export function formatSecondsToMMSS(seconds: number): string {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedMinutes}:${formattedSeconds}`;
}
export const truncateString = (str: string, maxLength: number) => {
  return str.length > maxLength ? str.slice(0, maxLength) + "..." : str;
};

const CheckIsAllLetters = (items: string[]): boolean => {
  for (let i = 0; i < items.length; i++) {
    // 檢查是否包含空格
    if (items[i].includes(" ")) {
      return false;
    }

    // 檢查是否包含中文字符且長度超過六個字
    const chineseChars = items[i].match(/[\u4e00-\u9fff]/g);
    if (chineseChars && chineseChars.length > 6) {
      return false;
    }
  }
  return true;
};
export const LabbelSwitch = (items: string[]): string => {
  let className = "";
  if (items.length >= 5) {
    className += " --more-than-5";
  }
  if (CheckIsAllLetters(items)) {
    className += " --for-letter";
  }
  return className;
};
export const extractConversation = (messages: MessageProps[]): string => {
  // 移除 system 訊息，並設定其他訊息的發送者為 game_hoster 和 user
  const conversation = messages
    .filter((message) => message.sender !== "system")
    .map((message) => {
      const sender = message.sender === "others" ? "game_hoster" : "user";
      return `${sender}: ${message.content}`;
    });
  return conversation.join("\n");
};

type ExtractData = {
  text: string;
  info?: string | undefined;
};
export const stringToBoolean = (value: string): boolean => {
  if (value === undefined || value === null) {
    console.error("Invalid boolean value", value);
    return false;
  }
  // 將字符串轉換為小寫，避免大小寫不同造成的影響
  switch (value.toLowerCase()) {
    case "true":
    case "1":
    case "yes":
    case "y":
      return true;
    case "false":
    case "0":
    case "no":
    case "n":
      return false;
    default:
      // 你可以選擇是要拋出錯誤還是返回 false，這裡選擇拋出錯誤
      console.error("Invalid boolean value", value);
      return false;
  }
};

export const extractedMatchesToSuggestions = (
  extractedMatches: ExtractData[],
  onMessageSend: Function
): SuggestionProps[] => {
  return extractedMatches.map((data, index) => {
    const text = data.text;
    const onClick = () => onMessageSend(text);
    return { text, onClick };
  });
};

export const extractMatchesWithTypeAndCleanString = (
  input: string
): {
  cleanedString: string;
  extractedMatches: Record<string, ExtractData[]>;
} => {
  const pattern = /@begin\s*(\w+)\("([^"]+)"\)\s*([\s\S]*?)\s*@end/g;
  const extractedMatches: Record<string, ExtractData[]> = {};
  let cleanedString = input;

  cleanedString = input.replace(pattern, (match, type, value, info) => {
    if (!extractedMatches[type]) {
      extractedMatches[type] = [];
    }
    // Checking if the optional content exists and is not just whitespace
    const infoContent = info.trim().length > 0 ? info.trim() : undefined;
    extractedMatches[type].push({ text: value, info: infoContent });
    if (type === "img") return `![](${value})`;
    if (type === "code") return `\`\`\`bash\n${infoContent}\n\`\`\``;
    if (type === "link") return `[${infoContent}](${value})`;
    return ""; // Remove the matched pattern from the cleaned string
  });

  return { cleanedString, extractedMatches };
};

export const updatedBaseUrlWithName = (url: string, username: string) => {
  const baseApiUrl = url?.replace(/\/+$/, "");
  const sanitizedUsername = username.replace(/[@.]/g, "_");

  return `${baseApiUrl}/${sanitizedUsername}`;
};
