// ./src/recoil/atoms.ts
import { atom } from "recoil"
import { recoilPersist } from "recoil-persist"
import { UserStateProps, TokenProps } from "@utils/types"

const { persistAtom } = recoilPersist()

export const userAuthState = atom<UserStateProps | null>({
  key: "userAuthState",
  default: null,
  effects_UNSTABLE: [persistAtom],
})

export const authTokenState = atom<TokenProps | null>({
  key: "authTokenState",
  default: null,
  effects_UNSTABLE: [persistAtom],
})
