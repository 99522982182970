import React, { useEffect, useState, useRef } from "react";
import VoicePlayer from "./VoicePlayer"; // 確保導入路徑正確
import EnhancedMessage from "./EnhancedMessage"; // 確保導入路徑正確
import { useAITranslateToCH } from "../../hooks/useApi";
import { useTextToSpeech } from "@core/hooks/useApi";
import { Keywords, getVoice } from "@core/sdgs_types";
import { useUserSettings } from "@core/context/UserSettingsContext";
interface ChatItemProps {
  avatarUrl: string;
  avatarName: string;
  message: string;
  keywords: Keywords;
  voiceName: string;
  isShow: boolean;
  onMessageSend: (message: string) => void;
  suggestions: string[];
  isNewMessageBegin: boolean;
  onAdviceClick: () => void;
  isForceVoiceStop: boolean;
}

const BotChatItem: React.FC<ChatItemProps> = ({
  avatarUrl,
  avatarName,
  message,
  keywords,
  voiceName,
  isShow,
  onMessageSend,
  suggestions,
  isNewMessageBegin,
  onAdviceClick,
  isForceVoiceStop,
}) => {
  const { translatedText, translateCH } = useAITranslateToCH();
  const { voiceUrl, hint, error, loading, text2voice } = useTextToSpeech();
  const [popupActive, setPopupActive] = useState(false);
  const { settings } = useUserSettings();
  const [isAudioStop, setIsAudioStop] = useState(!settings.autoNpcVoice);
  const [isAudioPlayable, setIsAudioPlayable] = useState(false);
  const preTrasnlateMessage = useRef("");
  const toggleTranslation = () => {
    console.log("Translation toggled");
    preTrasnlateMessage.current = message;
    translateCH(message);
    setPopupActive((prev) => !prev);
  };
  useEffect(() => {
    if (popupActive) {
      if (message !== preTrasnlateMessage.current) {
        setPopupActive(false);
      }
    }
  }, [message]);

  useEffect(() => {
    if (isNewMessageBegin && voiceName) {
      if (message && settings.autoNpcVoice) {
        //console.log("createAudio 文字轉語音", message, isNewMessageBegin);
        createAudio();
      }
      if (!settings.autoNpcVoice) {
        setIsAudioStop(true);
        setIsAudioPlayable(false);
      }
    }
  }, [isNewMessageBegin, voiceName]);
  useEffect(() => {
    if (voiceUrl) {
      setIsAudioPlayable(true);
    }
  }, [voiceUrl]);

  const createAudio = () => {
    console.log("createAudio", message);
    text2voice(message, getVoice(voiceName));
    setIsAudioStop(false);
  };

  return (
    <div className={`chat-item npc ${isShow ? "--active" : ""}`}>
      <div className="chat-item-container">
        <div className="avatar">
          <div
            className="img"
            style={{ backgroundImage: `url('${avatarUrl}')` }}
          ></div>
          <div className="name">{avatarName}</div>
        </div>
        <div className="content">
          <EnhancedMessage
            message={message}
            keywords={keywords}
            voiceName={voiceName}
            isNewMessageBegin={isNewMessageBegin}
          />
          <div className={`translate ${popupActive ? "--active" : ""}`}>
            <p>{translatedText}</p>
          </div>

          <div
            className={`chat-fn-bar ${isNewMessageBegin ? "" : "--disabled"}`}
          >
            <div className="left">
              {isAudioPlayable ? (
                <VoicePlayer
                  voiceUrl={voiceUrl}
                  isForceStop={isForceVoiceStop}
                />
              ) : !isAudioStop ? (
                <div>{hint}</div>
              ) : (
                <div className="btn btn-multi-play --white --small">
                  <div className={`audio-ctrl }`}>
                    <div
                      className={`play`}
                      data-btnplay
                      onClick={isNewMessageBegin ? createAudio : () => {}}
                    >
                      <i className="icon icon-3"></i>
                      <span>開啟語音</span>
                    </div>{" "}
                  </div>
                </div>
              )}

              <div
                className="btn --white --small"
                onClick={isNewMessageBegin ? toggleTranslation : () => {}}
              >
                <i className="icon icon-2"></i>
              </div>
              <div
                className="btn --white --small"
                onClick={isNewMessageBegin ? onAdviceClick : () => {}}
              >
                <i className="icon icon-17"></i>
                <span>提示</span>
              </div>
            </div>

            <div className="right">
              {suggestions.map((btn, index) => (
                <div
                  className="btn system-sound"
                  key={index}
                  onClick={() => {
                    onMessageSend(btn);
                  }}
                >
                  <span>{btn}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotChatItem;
