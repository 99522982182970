import React from "react";
import { MenuOption } from "@core/sdgs_types";
import { useLoadAIGameData } from "@core/hooks/LoadData/useGameDataLoader";
import { useGetUserInfo } from "@core/hooks/useGetUserInfo";
interface MenuBTNProps {
  option: MenuOption;
  menuCallBack: (option: MenuOption) => void; // 直接使用函數型別
}

export default function MenuBTN({ option, menuCallBack }: MenuBTNProps) {
  const handleClickSelection = (e: React.MouseEvent<HTMLButtonElement>) => {
    menuCallBack(option);
  };
  const { data, loading, error } = useLoadAIGameData(
    option.id ? option.id : ""
  );
  const { data: userInfo } = useGetUserInfo(data?.levelData?.Creator || "");
  if (!option.id || !data)
    return (
      <button
        className="btn-stage  click-sound"
        key={option.id}
        onClick={(e) => handleClickSelection(e)}
      >
        <div>{option.name}</div>
      </button>
    );

  return (
    <div className="imrs-game">
      <div className="img-holder" onClick={() => menuCallBack(option)}>
        <a
          className="img"
          style={{
            backgroundImage: `url(${data.IntroductionProps.backgroundImageUrl})`,
          }}
        ></a>
      </div>
      <div className="text-holder">
        <a onClick={() => menuCallBack(option)}>
          <h6>{option.name}</h6>
        </a>
        <div className="fn">
          {/* <span>遊玩次數：{}次</span> */}
          <a
            href={process.env.PUBLIC_URL + "/ms/" + data.levelData?.Creator}
            className="auther"
          >
            <span>{userInfo?.nickname}</span>
          </a>
        </div>
      </div>
    </div>
  );
}
