import { levelDataProbs, CharacterProbs, Question } from "@core/sdgs_types";

export const LevelDataToString = (
  level: levelDataProbs,
  questions: Question[] = [],
  userName: string,
  isEditableForAll: boolean,
  imageUrl?: string
) => {
  if (!level) return "";
  return `
<AIGameData>
botrunId:波${level.title}
${`
avatarUrl:${
  level?.avatarUrl
    ? level?.avatarUrl
    : "http://www.cameo.tw/ntso/SDGs_botrun_image/avatar_dune_oldman.png"
}
avatarVoice: ${level?.avatarVoice ? level?.avatarVoice : "onyx"}
avatarName: ${level?.avatarName ? level?.avatarName : "指引者"}
avatarId:${level.avatarId}
wellcomeMessage:${
  level.wellcomeMessage
    ? level.wellcomeMessage
    : "我是你的遊戲指引者，想要進入挑戰嗎？[Yes]"
}
`}
</AIGameData>
${
  level.questionGenerationPrompt != undefined
    ? `<questionGenerationPrompt>${level.questionGenerationPrompt}</questionGenerationPrompt>`
    : ""
}
${
  level.questionAdvicePrompt != undefined
    ? `<questionAdvicePrompt>${level.questionAdvicePrompt}</questionAdvicePrompt>`
    : ""
}
${
  level.answerAdvicePrompt != undefined
    ? `<answerAdvicePrompt>${level.answerAdvicePrompt}</answerAdvicePrompt>`
    : ""
}
${level.imagePrompt && `<imagePrompt>${level.imagePrompt}</imagePrompt>`}

${
  level.scoreEvaluatePrompt != undefined
    ? `<scoreEvaluatePrompt>${level.scoreEvaluatePrompt}</scoreEvaluatePrompt>`
    : ""
}

${
  level.turnWritePrompt != undefined
    ? `<turnWritePrompt>${level.turnWritePrompt}</turnWritePrompt>`
    : ""
}
<LevelData>
gameType: ${level.gameType}
difficulty: ${level.difficulty}
story: ${level.story}
numberOfQuestions: ${level.numberOfQuestions}
visualDescription: ${level.visualDescription}
Creator:${userName}
IsEditableForAll:${isEditableForAll}
multiPlayer:${level.multiPlayer}
</LevelData>

<IntroductionProps>
title: ${level.title}
subtitle: ${level.subtitle}
description: ${level.description}
backgroundImageUrl:  ${
    imageUrl
      ? imageUrl
      : "http://www.cameo.tw/ntso/SDGs_botrun_image/bk_dune_01.jpeg"
  } 
</IntroductionProps>

<LearningMaterial>
${level.learningMaterial}
</LearningMaterial>

<QuestionData>
${JSON.stringify(questions)}
</QuestionData>

<Keywords>
</Keywords>

${
  level?.writing_subject
    ? `<WriteGameData>
    subject:${level.writing_subject}
    description:${level.writing_description}
    rules:${level.writing_rules}
    minWords:${level.writing_minWords}
    maxWords:${level.writing_maxWords}
    </WriteGameData>`
    : ""
}
    `;
};
