import { useEffect, useState } from "react"
import { MessageProps } from "@botrun/chat"
import useWebSocket from "react-use-websocket"
// import useMyBots from "@hooks/useMyBots"
import { useRecoilState } from "recoil"
import { useTranslation } from "react-i18next"
import {
  extractedMatchesToSuggestions,
  extractMatchesWithTypeAndCleanString,
} from "@utils/functions"

type WebSocketProps = {
  enable: boolean
  setAssistant: React.Dispatch<React.SetStateAction<string | null>>
  setMessages: React.Dispatch<React.SetStateAction<MessageProps[]>>
  onMessageSend: Function
  setIsSocketError: React.Dispatch<React.SetStateAction<boolean>>
}

interface SystemData {
  code: string
  [key: string]: any
}

export default function useBotrunWebSocket(props: WebSocketProps) {
  const { enable, setAssistant, setMessages, onMessageSend, setIsSocketError } = props
  // const user = useRecoilValue(userAuthState)
  // const { data: bots } = useMyBots()
  const { t } = useTranslation()
  const [socketUrl, setSocketUrl] = useState<string | null>(null)

  const getSocketUrl = () => {
    let url = process.env.REACT_APP_WEB_SOCKET_URL || ""
    // const useDockerInDocker = process.env.REACT_APP_USE_DOCKER_IN_DOCKER === "True"
    // if (useDockerInDocker && user) url = updatedBaseUrlWithName(url, user.username)
    return url + "/chat"
  }

  const sendSystemMessage = (message: string) => {
    setMessages(prevMessages => {
      const newMessages = [...prevMessages]
      newMessages.push({
        content: message,
        sender: "system",
        avatar: "",
        name: "",
      })
      return newMessages
    })
  }

  const handleSystemMessage = (systemData: SystemData) => {
    let message = ""
    switch (systemData.code) {
      case "permission_not_found":
        message = t("permission_not_found")
        sendSystemMessage(message)
        break
      case "user_not_found":
        message = t("user_not_found")
        sendSystemMessage(message)
        break
      case "permission_expired":
        let expiryDate = systemData.expiry_date
        const localExpiryDate = new Date(expiryDate).toLocaleString()
        message = t("expiredMessage", { expiryDate: localExpiryDate })
        sendSystemMessage(message)
        break
      case "restart_user_docker":
        setIsSocketError(true)
        break
    }
  }

  const onMessage = (event: WebSocketEventMap["message"]) => {
    const data = event.data

    const parsedData = JSON.parse(data)
    if (parsedData.finished) {
      setTimeout(() => {
        setAssistant(null)
      }, 200)
      return
    }

    if (parsedData.system) {
      handleSystemMessage(parsedData.system)
      setAssistant(null)
      return
    }

    // const currentBot = bots?.find(bot => bot.id === parsedData.sender)
    // const name = currentBot?.name as string ?? "default"
    // const avatar = currentBot?.avatar as string
    const name = ""
    const avatar = ""

    setMessages(prevMessages => {
      const newMessages = [...prevMessages]
      if (newMessages.length > 0) {
        if (newMessages[newMessages.length - 1].sender === "self") {
          newMessages.push({
            content: parsedData.message,
            sender: "others",
            avatar: avatar,
            name: name,
          })
        } else {
          const lastIndex = newMessages.length - 1
          const lastMessage = newMessages[lastIndex]
          let updatedMessage = lastMessage.content + parsedData.message
          const { cleanedString, extractedMatches } =
            extractMatchesWithTypeAndCleanString(updatedMessage)
          let suggestions = newMessages[lastIndex].suggestions || []
          if (extractedMatches["button"]) {
            suggestions = [
              ...suggestions,
              ...extractedMatchesToSuggestions(extractedMatches["button"], onMessageSend),
            ]
          }
          newMessages[lastIndex] = {
            ...lastMessage,
            content: cleanedString,
            suggestions: suggestions.length ? suggestions : undefined,
          }
        }
      } else {
        newMessages.push({
          content: parsedData.message,
          sender: "others",
          avatar: avatar,
          name: name,
        })
      }
      return newMessages
    })
  }

  useEffect(() => {
    const url = enable ? getSocketUrl() : null
    setSocketUrl(url)
  }, [enable])

  return useWebSocket(socketUrl, {
    shouldReconnect: closeEvent => true,
    onMessage,
    reconnectAttempts: 10,
    // heartbeat: {
    //   message: "ping",
    //   returnMessage: "pong",
    //   timeout: 60000,
    //   interval: 25000,
    // },
  })
}
