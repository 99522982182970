import React, { useEffect, useState, useCallback } from "react";
import MenuBTN from "./GameBTN";
import { useSetRecoilState } from "recoil";
import { MenuOption } from "@core/sdgs_types";
import { useListGameMenu } from "@core/hooks/useApi"; // 確保路徑是正確的
import { currentMenuOptionState } from "@core/recoil/sdgs_atoms";
import { useNavigate, useParams } from "react-router-dom";
import ReactGA from "react-ga4";
import { useAudio } from "@core/context/AudioContext";
import useLoadGeneralData from "@core/hooks/LoadData/useLoadGeneralData";
import i18n from "@core/utils/i18n";
import Overlay from "@components/Overlay";

import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";
// 讀取目錄
export default function PersonalMenu() {
  const { userId, userNickName } = useParams();

  const { gameData: menuRecommandList, loadData: loadMenuRecommandList } =
    useLoadGeneralData<string[]>();
  const { stopBackground } = useAudio();
  const navigate = useNavigate();
  const setCurrentOption = useSetRecoilState(currentMenuOptionState);
  // 指定的資料夾 ID 或 URL 和最大文件數量
  const rootFolderId =
    process.env.REACT_APP_ROOT_FOLDER_ID || "1ZHRyYiqhzSmxUCSweGWiYXkagFS6TLGc";
  const recommandationListId =
    process.env.REACT_APP_RECOMMAND_MENU_ID ||
    "1RTXjtwSQ-wmTchvgm8P11WkM5vaq1YwjdAGiRkqnFiE";
  const [recommedOptions, setRecommedOptions] = useState<MenuOption[]>([]);
  // 使用 useState 来模拟数据的加载状态，未來可以改为使用 useQuery
  const [showRecommended, setShowRecommended] = useState(userId ? false : true);
  const user = useRecoilValue(userAuthState);
  const { gameMenu, error, loading } = useListGameMenu(
    rootFolderId,
    "_game",
    userId ? userId : ""
  );
  useEffect(() => {
    // 創建一個新的 link 元素
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = "/css/imrs.css";

    // 將 link 元素添加到 head 中
    document.head.appendChild(link);

    // 清理函數：當組件卸載時移除 link 元素
    return () => {
      document.head.removeChild(link);
    };
  }, []);
  useEffect(() => {
    loadMenuRecommandList(recommandationListId, "Recommendations", []);
  }, []);
  useEffect(() => {
    if (menuRecommandList && gameMenu) {
      console.log("menuRecommandList", menuRecommandList);
      //find recommanded options by list
      const recommandedOptions = gameMenu.filter((option) =>
        menuRecommandList.includes(option.name)
      );
      setRecommedOptions(recommandedOptions);
    }
  }, [menuRecommandList, gameMenu]);

  const onMenuOptionClick = (option: MenuOption) => {
    console.log(`menu ${option.name} clicked!`);
    ReactGA.event({
      category: "ImmersiveMenu",
      action: option.name,
    });

    setCurrentOption(option);
    if (option.gameType) {
      navigate(`/g/${option.gameType}/${option.id}`);
      stopBackground();
    } else {
      console.log("gameType not define in setup, navigate to Home");
    }
  };
  if (loading) return <Overlay show={true} />;
  return (
    <div className="game-stage-container">
      <div className="game-logo">
        <h1>{i18n.t("title")}</h1>
        <p>{userNickName ? userNickName + "專屬頁面" : "專屬頁面"}</p>
      </div>
      <div className="tab-buttons">
        {!userId && (
          <>
            <button
              className={`${showRecommended ? "--active" : ""}`}
              onClick={() => setShowRecommended(true)}
            >
              {i18n.t("featuredGames")}
            </button>
            <button
              className={`${showRecommended ? "" : "--active"}`}
              onClick={() => setShowRecommended(false)}
            >
              {i18n.t("allGames")}
            </button>
          </>
        )}
      </div>
      <div className="imrs-game-container">
        {showRecommended ? (
          recommedOptions.map((option) => (
            <MenuBTN
              key={option.id}
              option={option}
              menuCallBack={onMenuOptionClick}
            />
          ))
        ) : gameMenu.length === 0 ? (
          <li>{i18n.t("noContent")}</li>
        ) : (
          gameMenu.map((option) => (
            <MenuBTN
              key={option.id}
              option={option}
              menuCallBack={onMenuOptionClick}
            />
          ))
        )}
      </div>
    </div>
  );
}
