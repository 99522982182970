import React from "react";

interface ItemProps {
  label: string;
  onClick: () => void;
  isSelected?: boolean;
}

const ChoiceItem: React.FC<ItemProps> = ({
  label,
  onClick,
  isSelected = false,
}) => (
  <button
    className={`btn --white system-sound ${isSelected ? "selected" : ""}`}
    onClick={onClick}
  >
    <span>{label}</span>
  </button>
);

export default ChoiceItem;
