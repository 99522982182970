import React, { useState, useEffect, useCallback, useRef } from "react";
import "./DanmakuDisplay.css";

interface DanmakuMessage {
  id: number;
  text: string;
  position: number;
}

interface DanmakuDisplayProps {
  addMessageRef: React.MutableRefObject<((message: string) => void) | null>;
}

const DanmakuDisplay: React.FC<DanmakuDisplayProps> = ({ addMessageRef }) => {
  const [displayMessages, setDisplayMessages] = useState<DanmakuMessage[]>([]);

  const createNewMessages = useCallback((text: string) => {
    const newDisplayMessages = Array(Math.floor(Math.random() * 3) + 1)
      .fill(null)
      .map((_, index) => ({
        id: Date.now() + index,
        text,
        position: Math.random() * 80 + 10, // 10% to 90% of screen width
      }));
    setDisplayMessages((prev) => [...prev, ...newDisplayMessages]);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setDisplayMessages((prev) =>
        prev.filter((msg) => Date.now() - msg.id < 10000)
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    addMessageRef.current = createNewMessages;
  }, [addMessageRef, createNewMessages]);

  return (
    <div className="danmaku-container">
      {displayMessages.map((msg) => (
        <div
          key={msg.id}
          className="danmaku-message"
          style={{
            left: `${msg.position}%`,
            animationDelay: `${(msg.id % 5) * 0.2}s`,
          }}
        >
          {msg.text}
        </div>
      ))}
    </div>
  );
};

export default DanmakuDisplay;
