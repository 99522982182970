import { useRecoilState } from "recoil";
import React, { useState, useEffect } from "react";
import "@core/CSS/StartPage.css";
import { userAuthState } from "@core/recoil/atoms";
import { useNavigate } from "react-router-dom";
import AnimatedBG from "../GeneralComponent/AnimatedBG";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { request } from "@utils/axios-util";
import { authTokenState } from "@core/recoil/atoms";
export default function GameLoginPage({
  title = "immerse",
  subtitle = "全生成式英語學習平台",
  description = "請輸入您的高中名稱與暱稱，按下下方的按鈕進入遊戲。",
  backgroundImageUrl = "/images/bg-stage.jpg",
  naviUrl = "/",
}) {
  const { t } = useTranslation();
  const [, setUser] = useRecoilState(userAuthState);
  const navigate = useNavigate();
  const client_id = process.env.REACT_APP_CLIENT_ID;

  const [, setToken] = useRecoilState(authTokenState);
  const mutationFn = (data: FormData) =>
    request({
      url: `/google-login`,
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      data,
    });

  const { mutate } = useMutation({
    mutationFn,
  });

  const handleCallback = (res: any) => {
    const formData = new FormData();
    formData.append("id_token", res.credential);
    mutate(formData, {
      onSuccess: (res) => {
        const data = res.data;
        if (data["is_success"]) {
          setUser({
            token: data["access_token"],
            type: "bearer",
            username: data["username"],
          });
          setToken({
            accessToken: data["access_token"],
            tokenType: "bearer",
          });
          navigate(naviUrl);
        }
      },
    });
  };

  function waitForGoogleAccounts(callback: Function) {
    if ((window as any).google && (window as any).google.accounts) {
      callback();
    } else {
      setTimeout(function () {
        waitForGoogleAccounts(callback);
      }, 100);
    }
  }

  useEffect(() => {
    waitForGoogleAccounts(function () {
      (window as any).google.accounts.id.initialize({
        client_id: client_id,
        callback: handleCallback,
      });
      (window as any).google.accounts.id.renderButton(
        document.getElementById("sign-in-button"),
        {
          theme: "outline",
          size: "large",
        }
      );
      (window as any).google.accounts.id.prompt();
    });
  }, []);

  useEffect(() => {
    setUser(null);
  }, []);

  return (
    <div className="game-container">
      <div className="game-stage-container">
        <div className="game-logo">
          <h1>{t("title")}</h1>
          <p>{t("subtitle")}</p>
        </div>
        <div className="login-container">
          <div id="sign-in-button" className="login"></div>
        </div>
      </div>
      <AnimatedBG />
    </div>
  );
}
