import React, { useEffect, useState, useCallback, useRef } from "react";
import BaseWritingGame, { BaseWritingGameRef } from "./BaseWritingGame";
import {
  useTurnBasedWriting,
  Writer,
} from "@core/components/ImmersiveMenu/Game/WriteGame/useTurnBasedWriting";
import useMultiGameUtils from "@components/GameTeam/useMultiGameUtils";
import Overlay from "@components/Overlay";
import {
  HandleReciveAllTypeMessage,
  sendCommandMessage,
  CommandMessage,
  messageType,
} from "@core/components/OneChat/SendUserResultMessage";
import { useRecoilValue } from "recoil";
import { userAuthState } from "@core/recoil/atoms";

import MultiGameEnd from "@components/ImmersiveMenu/Game/MultiTalkGame/MultiTalkGameEnd";
import { ResultCardProps } from "../MultiTalkGame/ResultCard";
import { useWritingContributionAssessment } from "@core/hooks/useWritingContributionAssessment";

import { sendFinalResultLog, sendGameEndLog } from "./WriteGame";
import { convertContributionResultsToPlayerCards } from "./MultiWriteGame/multiWriteGameResultToPlayerCards";
import { WritingContributionAssessment } from "@core/hooks/useWritingContributionAssessment";

interface MultiPlayerWritingGameProps {
  title: string;
  descriptions: string;
  rules: string;
  background: string;
  minWords?: number;
  maxWords?: number;
  onEnd: () => void;
  onScored?: (score: any) => void;
  onLogLearningJourney?: (learning_journey_log: any) => void;
  leaningJourneyLogUrl?: string;
}
export default function MultiWriteGame(props: MultiPlayerWritingGameProps) {
  const user = useRecoilValue(userAuthState);
  const [writerList, setWriterList] = useState<Writer[]>([]);
  const baseWritingGameRef = useRef<BaseWritingGameRef>(null);
  const [isMultiGameEnd, setIsMultiGameEnd] = useState(false);
  const playerCards = useRef<ResultCardProps[]>([]);
  const isEndRef = useRef(false);
  const {
    participants,
    handleSendMessage,
    handleLeaveRoom,
    messageHistory,
    lastUserMessage,
  } = useMultiGameUtils();
  const { writingContributionResults, generateWritingContributionAssessment } =
    useWritingContributionAssessment();
  const hasScoreSentRef = useRef(false);

  useEffect(() => {
    // 設定writers是多人
    if (participants) {
      const writers = participants.map(
        (participant) =>
          ({
            username: participant.username || "player",
            nickname: participant.nickname || "玩家",
            userType: "player",
          } as Writer)
      );
      console.log("多人寫作設定writers", writers);
      setWriterList(writers);
    }
  }, [participants]);

  const writingLogic = useTurnBasedWriting(
    writerList,
    props.title,
    props.descriptions,
    props.rules
  );

  useEffect(() => {
    if (lastUserMessage.content && lastUserMessage.username) {
      const baseMessage = HandleReciveAllTypeMessage(lastUserMessage.content);
      if (!baseMessage) {
        // 如果不是command message，就是單純玩家的原始訊息
        console.log("當網路上有其他玩家，lastUserMessage", lastUserMessage);
        writingLogic.othersEntry(lastUserMessage.content, {
          username: lastUserMessage.username,
          nickname:
            participants?.find((p) => p.username === lastUserMessage.username)
              ?.nickname || "玩家",
          userType: "player",
        } as Writer);
        return;
      }
      switch (baseMessage.type) {
        case messageType.Command:
          const commandMessage = baseMessage.content as CommandMessage;
          if (commandMessage.command === "end") {
            // 其他玩家結束遊戲, 一起結束
            onGameEndByOtherPlayer();
          }
          if (commandMessage.command === "score") {
            // 記錄所有玩家分數
            console.log("收到其他玩家分數", commandMessage.parameters[0]);
            const sender = lastUserMessage.username;
            // 檢查參數是否已經是物件
            const writeGameResults =
              typeof commandMessage.parameters[0] === "string"
                ? JSON.parse(commandMessage.parameters[0])
                : commandMessage.parameters[0];
            playerCards.current = playerCards.current =
              convertContributionResultsToPlayerCards(
                writeGameResults,
                participants,
                baseWritingGameRef
              );
            GameEndProcess(writeGameResults);
          }
          break;
        default:
          break;
      }
    }
  }, [lastUserMessage]);

  const onUserClickGameEnd = (result: any) => {
    if (isEndRef.current) return;
    // 玩家自己結束遊戲,在baseWritingGameRef中自己按下結束按鈕
    console.log("onUserClickGameEnd 玩家自己結束遊戲 開始計算分數");
    isEndRef.current = true;
    if (baseWritingGameRef.current && props.onLogLearningJourney) {
      sendGameEndLog(
        props.onLogLearningJourney,
        baseWritingGameRef.current.refWritingLearningJourneyLog
      );
    }
    sendCommandMessage("end", [], handleSendMessage);
    generateWritingContributionAssessment(
      props.title,
      props.rules,
      props.descriptions,
      result.writingText,
      baseWritingGameRef.current?.refWritingLearningJourneyLog.logs || []
    );
    setIsMultiGameEnd(true);
  };

  useEffect(() => {
    if (!writingContributionResults || hasScoreSentRef.current) return;
    console.log("寫作貢獻評估結果", writingContributionResults);

    playerCards.current = convertContributionResultsToPlayerCards(
      writingContributionResults,
      participants,
      baseWritingGameRef
    );
    sendCommandMessage(
      "score",
      [writingContributionResults],
      handleSendMessage
    );
    hasScoreSentRef.current = true;
    GameEndProcess(writingContributionResults);
  }, [writingContributionResults]);

  const onGameEndByOtherPlayer = useCallback(() => {
    // 其他玩家結束遊戲, 一起結束，網路傳來end command, 同樣按下在baseWritingGameRef中自己按下結束按鈕
    if (isEndRef.current) return;
    isEndRef.current = true;
    if (baseWritingGameRef.current && props.onLogLearningJourney) {
      sendGameEndLog(
        props.onLogLearningJourney,
        baseWritingGameRef.current.refWritingLearningJourneyLog
      );
    }
    console.log("其他玩家結束遊戲, 一起結束");
  }, [props.onEnd]);

  const GameEndProcess = (
    wirtingGameResults: WritingContributionAssessment
  ) => {
    GameEndSendFinalResultLog(wirtingGameResults);
    if (!isMultiGameEnd) {
      console.log(
        "多人寫作遊戲結束,如果不是按下結束按鈕的其他玩家，會在這裡顯示結尾頁面"
      );
      setIsMultiGameEnd(true);
      props.onEnd();
    }
  };

  const refIsSendFinalResultLog = useRef(false);
  const GameEndSendFinalResultLog = (
    wirtingGameResults: WritingContributionAssessment
  ) => {
    if (refIsSendFinalResultLog.current) {
      return;
    }
    const userResult = Object.values(wirtingGameResults || {}).find(
      (player) => player.userName === user?.username
    );

    if (props.onLogLearningJourney && userResult) {
      refIsSendFinalResultLog.current = true;
      sendFinalResultLog(
        props.onLogLearningJourney,
        userResult,
        baseWritingGameRef.current?.refWritingLearningJourneyLog!
      );
    }
  };

  const useWritingLogic = useCallback(() => writingLogic, [writingLogic]);
  if (writerList.length < 1) return <Overlay show={true} />;
  return (
    <>
      <BaseWritingGame
        ref={baseWritingGameRef}
        title={props.title}
        background={props.background}
        rules={props.rules}
        descriptions={props.descriptions}
        minWords={props.minWords}
        maxWords={props.maxWords}
        onEnd={onUserClickGameEnd}
        useWritingLogic={useWritingLogic}
        handleSendMessage={handleSendMessage}
        onLogLearningJourney={props.onLogLearningJourney}
        leaningJourneyLogUrl={props.leaningJourneyLogUrl}
      />
      {isMultiGameEnd ? (
        <MultiGameEnd
          leaningJourneyLogUrl={props.leaningJourneyLogUrl || ""}
          playerCards={playerCards.current}
          isShow={isMultiGameEnd}
          showCommentOrHighlights={true} //顯示評論而非顯示high lights
        />
      ) : null}
    </>
  );
}
