export enum QuestionTypeEnum {
  "劇情選擇" = "劇情選擇",
  "advice" = "advice",
  "評語" = "評語",
  "單選" = "單選",
  "多選" = "多選",
  "問答" = "問答",
  "發音" = "發音",
  "生圖" = "生圖",
}
export type Status =
  | "idle" // 空閒
  | "ai_thinking" // AI思考中
  | "ai_response" // AI回應
  | "game_end"; // 遊戲結束

export interface AiMessage {
  options: string[];
  currentQuestionType: QuestionTypeEnum;
  content: string;
}

export type UserResult = {
  content: string;
  isCorrect?: boolean;
  advice: string;
  score: number;
  praticeCount?: number;
};

export interface TalkGameCommunicationResult {
  //  messages: MessageProps[];
  sendUserMessage: (content: string) => void;
  startNewChat: () => void;
  nextQuestion: () => void;
  aiMessage: AiMessage;
  status: Status;
  loading: boolean;
}
export type aiAnswerAdviceResponse = {
  dialogue: string;
  success: boolean;
  score: number;
  advice: string;
};
