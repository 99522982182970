import React, { useState, useEffect, useRef } from "react"
import { useUserSettings } from "@core/context/UserSettingsContext"
import { useSound } from "use-sound"

interface VoicePlayerProps {
  voiceUrl: string
  isAutoPlay?: boolean
  isForceStop?: boolean
}

const VoicePlayer: React.FC<VoicePlayerProps> = ({ voiceUrl, isForceStop = false }) => {
  const { settings } = useUserSettings()

  const [isPlaying, setIsPlaying] = useState(settings.autoNpcVoice)
  const [selectedSpeed, setSelectedSpeed] = useState("1") // 默认速度为1倍
  const preUrlRef = useRef<string | null>(null)
  useEffect(() => {
    setIsPlaying(settings.autoNpcVoice)
  }, [])
  const [play, { stop }] = useSound(voiceUrl, {
    autoplay: settings.autoNpcVoice,
    interrupt: true,
    onend: () => {
      setIsPlaying(false)
    },
  })

  const playAudio = () => {
    if (isPlaying) {
      pauseAudio()
      return
    }

    play({ playbackRate: parseFloat(selectedSpeed) })
    setIsPlaying(true)
  }
  const pauseAudio = () => {
    setIsPlaying(false)
    stop()
  }

  useEffect(() => {
    // 清理函數，在元件消滅時停止音頻播放
    return () => {
      stop()
    }
  }, [stop]) // 空依賴陣列確保只在元件卸載時執行一次

  useEffect(() => {
    if (isForceStop) {
      pauseAudio()
    }
  }, [isForceStop])

  useEffect(() => {
    if (preUrlRef.current !== voiceUrl && settings.autoNpcVoice) {
      setIsPlaying(true)
      preUrlRef.current = voiceUrl
    }
  }, [voiceUrl, settings.autoNpcVoice])

  const handleSpeedChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSpeed(event.target.value)
  }

  return (
    <div className="btn btn-multi-play --white --small">
      <select
        name="speed"
        id="speedSelect"
        className="speed"
        value={selectedSpeed}
        onChange={handleSpeedChange}
      >
        <option value="1">正常</option>
        <option value="0.8">稍慢</option>
        <option value="1.2">稍快</option>
      </select>
      <div className={`audio-ctrl  ${isPlaying ? "--active" : ""}`}>
        <div className={`play`} data-btnplay onClick={playAudio}>
          <i className="icon icon-3"></i>
          <span>播放</span>
        </div>
        <div className={`pause`} data-btnpause onClick={pauseAudio}>
          <i className="icon icon-5"></i>
          <span>停止</span>
        </div>
      </div>
    </div>
  )
}

export default VoicePlayer
