import React, { useEffect, useState } from "react";

interface CountBoardProps {
  count: number;
  onTimeUp: () => void;
}

const CountBoard: React.FC<CountBoardProps> = ({ count, onTimeUp }) => {
  const [remainingTime, setRemainingTime] = useState(count);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainingTime((prevTime) => prevTime - 1);
    }, 1000);

    if (remainingTime <= 0) {
      clearInterval(timer);
      onTimeUp();
    }

    return () => {
      clearInterval(timer);
    };
  }, [remainingTime, onTimeUp]);

  return (
    <div className="count-board">
      <div className="wrapper">
        <div className="count">{formatTime(remainingTime)}</div>
      </div>
    </div>
  );
};

export default CountBoard;
